import { Component, EventEmitter, Output,  } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { select, Store } from '@ngrx/store';
import {  BehaviorSubject, Subscription } from 'rxjs';
import { BankDetailsDto } from 'src/app/shared/models/BankDetailsDto';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';
import { BidderUiDto } from 'src/app/shared/models/UserEntityDto';
import { RegistrationService } from 'src/app/shared/services/registration.service';
import { UserService } from 'src/app/shared/services/user.service';
import { SessionActions } from 'src/app/shared/state-management/session/session.actions';
import { selectBidderUiDto } from 'src/app/shared/state-management/session/session.features';
import { AuctionExtUtil } from 'src/app/shared/util/AuctionExtUtil';
import { Pattern } from 'src/app/shared/util/Patterns';

@Component({
  selector: 'app-user-bank-details',
  templateUrl: './user-bank-details.component.html',
  styleUrls: ['./user-bank-details.component.sass']
})
export class UserBankDetailsComponent{
  @Output() onNext: EventEmitter<void> = new EventEmitter();
  @Output() onPrevious: EventEmitter<void> = new EventEmitter();

  formGroup: FormGroup;
  userEntityDto?: BidderUiDto;
  userEntityDtoSubscription$?: Subscription;
  errorMsg: string = '';
  isLoading: boolean = false;
  
  _showSuccessToast$ = new BehaviorSubject<boolean>(false);
  _showErrorToast$ = new BehaviorSubject<boolean>(false);


  constructor(
    private registrationService: RegistrationService,
    private messageService: MessageService,  
    private formBuilder: FormBuilder,
    private userService: UserService,
    private store: Store
  ) {
    this.formGroup = this.formBuilder.group({
      accountNumber: new FormControl ('',[Validators.required, Validators.maxLength(50), Validators.pattern(Pattern.numberGreaterZero)]),
      bankName: new FormControl ('',[Validators.required, Validators.maxLength(250)]),
      branch: new FormControl ('',[Validators.required, Validators.maxLength(250)]),
      accountHolderName: new FormControl ('',[Validators.required, Validators.maxLength(250)]),
      ifscCode:new FormControl ('',[Validators.required, Validators.maxLength(50)]),
    });
  }

  ngOnInit(): void {
    this.formGroup.reset();
    this.userEntityDtoSubscription$ = this.store.pipe(select(selectBidderUiDto)).subscribe(data => {
      if (data) {
        this.userEntityDto = data;
        this.populateBankDetails();
      }
    })
  }

  get fc() { return this.formGroup.controls; }

  populateBankDetails(){
    this.formGroup.controls['accountNumber'].patchValue(this.userEntityDto?.bankDetailsDto?.accountNumber);
    this.formGroup.controls['bankName'].patchValue(this.userEntityDto?.bankDetailsDto?.bankName);
    this.formGroup.controls['branch'].patchValue(this.userEntityDto?.bankDetailsDto?.branch);
    this.formGroup.controls['accountHolderName'].patchValue(this.userEntityDto?.bankDetailsDto?.accountHolderName);
    this.formGroup.controls['ifscCode'].patchValue(this.userEntityDto?.bankDetailsDto?.ifscCode);
  }
  
  prevStep() {
    this.onPrevious.emit()
  }

  mergeBankDetails() {
    let userEntityDto: BidderUiDto = AuctionExtUtil.clone(this.userEntityDto);

    if(!userEntityDto.bankDetailsDto){
      userEntityDto.bankDetailsDto = new BankDetailsDto();
    }

    let formValue = this.formGroup.value;
    userEntityDto.bankDetailsDto!.accountNumber = formValue.accountNumber;
    userEntityDto.bankDetailsDto!.bankName = formValue.bankName;
    userEntityDto.bankDetailsDto!.branch = formValue.branch;
    userEntityDto.bankDetailsDto!.accountHolderName = formValue.accountHolderName;
    userEntityDto.bankDetailsDto!.ifscCode = formValue.ifscCode;

    return userEntityDto;
  }

  saveUserProfile() {
    if (this.formGroup.invalid) {
      this.formGroup.markAllAsTouched();
      return;
    }
    this.isLoading = true;

    let userEntityDto = this.mergeBankDetails();

    this.userService.updateUserDetails(userEntityDto).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        this.isLoading = false;

        if (apiResponseDto && apiResponseDto.code == "200") {
          let bidderUiDto = apiResponseDto.data as BidderUiDto;
          this.store.dispatch(SessionActions.updateBidderUiDto({ bidderUiDto }));

          this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Bank details are saved Successfully'});

          setTimeout(() => {
            this.onNext.emit();
          }, 2000)

        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: apiResponseDto.message! });
        }
      },
      error: (err) => {
        console.log(err);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error while updating details' });
        this.isLoading = false;
      }
    })
  }

  ngOnDestroy(): void {
    if(this.userEntityDtoSubscription$) {
      this.userEntityDtoSubscription$.unsubscribe();
    }
  }

}