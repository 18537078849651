import { SourcingCalenderEventDto } from './../../models/user/SourcingCalenderEventDto';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
// import { CalendarEvent, CalendarEventTimesChangedEvent, CalendarMonthViewBeforeRenderEvent, CalendarView } from 'angular-calendar';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { AdminAuctionDataHolderService } from '../../services/AdminAuctionDataHolder.service';
import { CalendarService } from '../../services/calendar.service';
import { UserService } from '../../services/user.service';
import { isSameDay, isSameMonth } from 'date-fns';
import { AuctionExtUtil } from '../../util/AuctionExtUtil';
// import { EventColor } from 'calendar-utils';

// const colors: Record<string, EventColor> = {
//   live: {
//     primary: '#1488d2f5',
//     secondary: '#1488d2f5',
//   },
//   close: {
//     primary: '#e30909db',
//     secondary: '#e30909db',
//   },
//   upcoming: {
//     primary: '#ffc107',
//     secondary: '#ffc107',
//   },
//   other: {
//     primary: '#495057',
//     secondary: '#495057',
//   },
// };

@Component({
  selector: 'app-auction-calendar-view',
  templateUrl: './auction-calendar-view.component.html',
  styleUrls: ['./auction-calendar-view.component.sass']
})
export class AuctionCalendarViewComponent implements OnInit {
  @Input() calenderEventList$ = new BehaviorSubject<SourcingCalenderEventDto[]>([]);
  @Input() selectedCalenderEvent$ = new BehaviorSubject<SourcingCalenderEventDto|null>(null);
  @Output() onDateSelected = new EventEmitter<Date>()
  @Output() onMonthSelected = new EventEmitter<Date>()

  selectedCalenderEvent?: SourcingCalenderEventDto|null

  // view: CalendarView = CalendarView.Month;

  // CalendarView = CalendarView;

  // viewDate: Date = new Date();
  // selectedDate?: Date;

  // refresh = new Subject<void>();

  // events: CalendarEvent[] = [];

  // activeDayIsOpen: boolean = false;

  // allCalenderEventsSubscription$?: Subscription;

  // allCalenderEvents: SourcingCalenderEventDto[] = [];

  constructor(
    private calendarService: CalendarService,
    private auctionService: AdminAuctionDataHolderService,
    private userService: UserService,
  ) {}

  ngOnInit(): void {
    // this.calenderEventList$.subscribe(data => {
    //   if (data) {
    //     this.allCalenderEvents = data;
    //     this.populateEvents()
    //   }
    // })

    // this.selectedCalenderEvent$.subscribe(data => {
    //   if (data) {
    //     this.selectedCalenderEvent = data
    //     this.selectedDate = undefined
    //     this.populateEvents()
    //   } else {
    //     this.selectedCalenderEvent = null
    //   }
    // })
  }

  // dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
  //   if (isSameMonth(date, this.viewDate)) {
  //     this.onDateSelected.emit(date);
  //     this.viewDate = date;
  //     this.selectedDate = date;
  //   }
  // }

  // eventTimesChanged({
  //   event,
  //   newStart,
  //   newEnd,
  // }: CalendarEventTimesChangedEvent): void {
  //   this.events = this.events.map((iEvent) => {
  //     if (iEvent === event) {
  //       return {
  //         ...event,
  //         start: newStart,
  //         end: newEnd,
  //       };
  //     }
  //     return iEvent;
  //   });
  //   this.handleEvent('Dropped or resized', event);
  // }

  // handleEvent(action: string, event: CalendarEvent): void {
  //   let auction = this.allCalenderEvents.find(item => item.eventId == event.id)!;
  //   let isUserLoggedIn = this.userService.getUserEntity() != undefined;
  // }

  // getDisplayDate(date?: string, time?: string) {
  //   return AuctionExtUtil.getDisplayDateAdmin(date, time);
  // }

  // closeOpenMonthViewDay(date: Date) {
  //   this.onDateSelected.emit(undefined);
  //   this.onMonthSelected.emit(date);
  //   this.selectedDate = undefined
  // }

  // populateEvents() {
  //   this.events =  this.allCalenderEvents.map((item) => {
  //     return {
  //       id: item.eventId,
  //       title: `#${item.sequenceNo} ${item.eventName
  //         } : ${this.getDisplayDate(
  //           item.startDate!,
  //           item.startTime!
  //         )} to ${this.getDisplayDate(item.endDate!, item.endTime!)}`,
  //       start: AuctionExtUtil.convertedDate(
  //         item.startDate!,
  //         item.startTime!
  //       )!,
  //       end: AuctionExtUtil.convertedDate(item.endDate!, item.endTime!)!,
  //       color:
  //         item.status == 'LIVE'
  //           ? { ...colors['live'] }
  //           : item.status == 'LIVE_WAIT'
  //             ? { ...colors['upcoming'] }
  //             : item.status == 'CLOSE'
  //               ? { ...colors['close'] }
  //               : { ...colors['other'] },
  //       draggable: false,
  //       resizable: {
  //         beforeStart: true,
  //         afterEnd: true,
  //       },
  //     } as CalendarEvent;
  //   });
  // }

  // beforeMonthViewRender(renderEvent: CalendarMonthViewBeforeRenderEvent): void {
  //   renderEvent.body.forEach((day) => {
  //     const dayOfMonth = day.date.getDate();

  //     if (this.selectedCalenderEvent && day.inMonth) {
  //       let event = day.events.find(item => item.id == this.selectedCalenderEvent!.eventId);

  //       if (event && day.events.indexOf(event) > -1) {
  //         day.backgroundColor =
  //           (event.color && event.color.secondary) || '#D1E8FF';
  //       }
  //       else {
  //         delete day.backgroundColor;
  //       }
  //     }

  //     if (this.selectedDate && day.inMonth) {
  //       let selectedDateIsSameDay = isSameDay(this.selectedDate, day.date);
  //       if (selectedDateIsSameDay) {
  //         day.backgroundColor = '#CED4DA'
  //       }
  //     }
  //   });
  // }
}
