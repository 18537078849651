import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Unsubscribe } from '@angular/fire/firestore';
import { LandingAndBidderService } from 'src/app/shared/services/LandingAndBidder.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';
import { AuctionExtConstant } from 'src/app/shared/util/AuctionExtConstant';
import { DataRedirectionService } from 'src/app/shared/services/data-redirection.service';
import { OrganizationUiDto } from 'src/app/shared/models/user/AuctionHouseDto';
import { ApplicationListenerService } from 'src/app/shared/services/application-listener.service';
import { FormControl, Validators } from '@angular/forms';
import { Pattern } from 'src/app/shared/util/Patterns';

@Component({
  selector: 'app-unsubscribe-notification',
  templateUrl: './unsubscribe-notification.component.html',
  styleUrls: ['./unsubscribe-notification.component.sass']
})
export class UnsubscribeNotificationComponent implements OnInit {


  _showSuccessToast$ = new BehaviorSubject<boolean>(false);
  _showErrorToast$ = new BehaviorSubject<boolean>(false);
  _isSaveButtonEnable$ = new BehaviorSubject<boolean>(true);
  _showErrorMsg$ = new BehaviorSubject<string>("");

  auctionHouse?: OrganizationUiDto;
  logoId?: string;
  ctrlEmail: FormControl = new FormControl('', [Validators.required, Validators.pattern(Pattern.email)])

  isLoading: boolean = false;
  constructor(private bidderService: LandingAndBidderService,
     private router: Router,
     private ngbModal: NgbModal,
     private dataRedirectionService: DataRedirectionService,
     private applicationListenerService: ApplicationListenerService){}

  ngOnInit() {
     this.loadAuctionHouse();

      this.bidderService.getCurrentAuctionHouse$.subscribe(auctionHouse => {
        if(auctionHouse){
          this.auctionHouse = auctionHouse;
          if(auctionHouse.logo){
              this.logoId = auctionHouse.logo.fileId!
          }
        }
      })
  }

  async loadAuctionHouse(){
    await this.applicationListenerService.getOrganizationDtoFromServerSync();
  }


  navigateToHomePage() {
    this.dataRedirectionService.navigationToHomePage();
  }

  unsubscribe() {
    this._showErrorMsg$.next("");
    this._showErrorToast$.next(false);

    if(this.ctrlEmail.invalid){
      return;
    }
    this.isLoading = true;
    this._isSaveButtonEnable$.next(false);

    this.bidderService.emailUnsubscribe(this.ctrlEmail.value).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == AuctionExtConstant.SUCCESS_CODE) {
          this._showSuccessToast$.next(true);
          this._isSaveButtonEnable$.next(true);
          this.isLoading = false;
          setTimeout(() => {
            this._showSuccessToast$.next(false);
            this._isSaveButtonEnable$.next(true);
            this.closeModal();
            this.navigateToHomePage()

          }, 2000)
        } else {

          this._showErrorMsg$.next(apiResponseDto.message as string);
          this._showErrorToast$.next(true);
          this._isSaveButtonEnable$.next(true);
          this.isLoading = false;
        }
      },
      error: (err) => {
        console.error(err);
        this._showErrorMsg$.next("Error while adding global bidder. Try again.");
        this.isLoading = false;
        this._isSaveButtonEnable$.next(true);
      }
    })
  }

  openUnsubscribeModal(content: any){
    this.ctrlEmail.reset();
    this.isLoading = false;
    this._showErrorMsg$.next("");
    this._showErrorToast$.next(false);
    this.ngbModal.open(content, {size: 'sm', backdrop: 'static', keyboard: false , centered: true});
  }

  closeModal(){
    this.ngbModal.dismissAll()
  }


}
