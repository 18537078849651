<div class="flex w-4/6 mx-auto mt-5 flex-col">
  <div class="w-full bg-white pt-6 pb-8 px-8 rounded-lg">
    <div class="">
      <div class="flex items-center mb-6">
        <div class="">
          <span class="h-10 w-10  items-center justify-between bg-blue-100 rounded-lg inline-flex p-2 me-2">
            <i-lucide name="file-stack"  color="#374151" class="my-icon" ></i-lucide>
          </span>
        </div>

        <div class="flex flex-row justify-between items-center w-full">
          <div>
            <div class="font-semibold text-gray-800 text-lg">
              Add company documents
            </div>
            <div class="text-gray-500 text-xs text-medium font-medium">
              Please provide all necessary documents to complete your registration.
            </div>
          </div>
          <div>
            <div class="text-end">
              <button (click)="openUploadFile()"
                class="text-center basis-1/2 text-blue-500 border-2 border-blue-500 hover:border-blue-600 focus:ring-4 focus:ring-blue-300 font-medium rounded text-sm px-3 py-1.5">
                Add Document
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="text-center m-auto" *ngIf="!documentArchiveList || documentArchiveList.length == 0">
        <img src="assets/images/upload.png" class="upload-image-large">
        <div class="mt-4 text-small"
          *ngIf="!(listLoading$ | async) &&(!documentArchiveList || documentArchiveList.length) == 0 && !(_showListErrorToast$ | async) && !(_showListSuccessToast$ | async)">
          No Document Added yet
        </div>
        <div class="text-link-md mt-3" (click)="openUploadFile()">
          Add new document
        </div>
      </div> -->

      <div class="bg-blue-100 p-2 flex mb-6">
        <span class="px-2"><i-lucide name="info" color="rgb(37 99 235)" [size]="18" class="my-icon flex items-center "></i-lucide></span>
        <span>A minimum of PAN and GST details is required. Additional documents can be uploaded for enhanced
          verification.</span>
      </div>

      <div>
        <p-table [value]="documentArchiveList" [tableStyle]="{'min-width': '60rem'}" [loading]="(listLoading$ |async)! ">
          <ng-template pTemplate="header">
            <tr class="ng-prime-table border ">
              <th pSortableColumn="code" class="border-end ">S no.</th>
              <th pSortableColumn="code" class="border-end border border-gray-200">
                Document name
                <i-lucide name="chevrons-up-down" color="#1C1B1F80" class="my-icon float-end "></i-lucide>
              </th>


              <th pSortableColumn="quantity" class="border-end border border-gray-200">
                Expiry Date
                <i-lucide name="chevrons-up-down" color="#1C1B1F80" class="my-icon float-end "></i-lucide>
              </th>
              <th pSortableColumn="price" class="border-end  ">Status
                <i-lucide name="chevrons-up-down" color="#1C1B1F80" class="my-icon float-end "></i-lucide>
              </th>
              <th pSortableColumn="price" class="border-end border border-gray-200">Action</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-documentArchive let-i="rowIndex">
            <tr class="mb-2">
              <td class="border-end border border-gray-200">{{i+1}}</td>
              <td class="border-end border border-gray-200"> {{documentArchive.documentType}} </td>

              <td class="border-end border border-gray-200">
                <span *ngIf="!documentArchive.expiryDate">Not avalable</span>
                <span *ngIf="documentArchive.expiryDate">{{documentArchive.expiryDate}}</span>

              </td>
              <td class="border-end border border-gray-200">
                <span
                  class="bg-gray-100 text-gray-800 inline-flex py-1 px-3 text-sm font-medium rounded-full border me-3 ">
                  {{getStatus(documentArchive.status)}}
                </span>
              </td>
              <td class="border-end border border-gray-200">
                <div class="flex items-center">
                  <span class="w-8 h-8 items-center justify-between bg-blue-100 rounded-lg inline-flex ps-1 me-2"
                    (click)="openPanOrGstAddModel(documentArchive)" *ngIf="!documentArchive.fileId">
                    <i-lucide name="plus" color="rgb(37 99 235)" class="my-icon"></i-lucide>
                  </span>

                  <span class="w-8 h-8 items-center justify-center bg-blue-100 rounded-lg inline-flex  me-2"
                    (click)="viewDocument(documentArchive)" *ngIf="documentArchive.fileId">
                    <i-lucide name="eye" color="rgb(37 99 235)" class="my-icon h-4 w-4"></i-lucide>
                  </span>
                  <span class="w-8 h-8 items-center justify-center  bg-blue-100 rounded-lg inline-flex  me-2"
                    (click)="selectDocumentArchive(documentArchive)" *ngIf="documentArchive.fileId">
                    <i-lucide name="pencil" color="rgb(37 99 235)" class="my-icon h-4 w-4"></i-lucide>
                  </span>

                  <span class="w-8 h-8 items-center justify-center bg-red-100 rounded-lg inline-flex  me-2" (click)="confirmRemoveSection(documentArchive.documentArchiveId)"
                    *ngIf="documentArchive.fileId">
                    <i-lucide name="trash-2" color="rgb(239 68 68)" class="my-icon h-4 w-4"></i-lucide>
                  </span>
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>
        
      </div>
      <!-- refrence  -->
      
      
      
      <!-- rwfrence end  -->
    </div>
  </div>
  <div class="flex flex-row justify-between mt-5">
    <div class=" text-start flex justify-start">
      <p-button class="text-center items-center text-blue-500 border-1 border-gray-300 hover:border-blue-600 focus:ring-4 focus:ring-blue-300 font-medium rounded text-sm px-4 py-1.5 inline-flex" (onClick)="prevStep()">
        <i-lucide [size]="16" name="move-left" class="my-icon flex-inline me-1"></i-lucide>Back</p-button>
    </div>
    <div class=" text-end flex justify-end">
      <p-button  class="text-center items-center px-4 bg-blue-700 text-white border-1 border-blue-300 hover:border-blue-600 focus:ring-4 focus:ring-blue-300 font-medium rounded text-sm  inline-flex" (click)="nextStep()">Next: Add DSC <i-lucide [size]="16" name="move-right"
        class="my-icon inline-flex ms-1"></i-lucide></p-button>
    </div>
  </div>
</div>

<p-dialog header="Upload Documents" [(visible)]="openFileModal" [style]="{width: '50vw'}" [modal]="true"
  [draggable]="false" [resizable]="false">
  <div>
    <form action="" class="needs-validation" novalidate [formGroup]="formGroup">

      <div class="">
        <div>
          <div class="mb-3">
            <label for="documentType" class="text-gray-600  text-sm font-medium leading-4 text-start">Document
              Type</label>
            <select placeHolder="" formControlName="documentType"
              class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-400 h-8 px-2 py-1.5 text-gray-800 font-medium text-sm form-select">
              <option [value]="null" disabled selected>Select Document </option>
              <option [value]="item.documentTypeName" *ngFor="let item of documentTypeList">{{item.documentTypeName}}
              </option>
            </select>
            <div class="text-start"
              *ngIf="fc.documentType.invalid && (fc.documentType.dirty || fc.documentType.touched)">
              <p class="text-danger" *ngIf="fc.documentType.errors?.required">Required
              </p>
            </div>
          </div>
          <div class=" mb-3" *ngIf="(fc['documentType'].value) == 'Others'">
            <div class="">
              <label for="documentName" class="text-gray-600  text-sm font-medium leading-4 text-start">Enter
                Document Name</label>
              <input
                class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-600 text-sm"
                id="documentName" formControlName="documentName" />

            </div>
            <div class="text-start"
              *ngIf="fc.documentName.invalid && (fc.documentName.dirty || fc.documentName.touched)">
              <p class="text-danger" *ngIf="fc.documentName.errors?.required">Required
              </p>
            </div>
          </div>
          <div class=" mb-3" *ngIf="isCheckReferenceNoApplicable(fc['documentType'].value)">
            <div class="">
              <label for="documentReferenceNo" class="text-gray-600  text-sm font-medium leading-4 text-start">Enter
                {{fc['documentType'].value}} Number</label>
              <input
                class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-600 text-sm"
                id="documentReferenceNo" formControlName="documentReferenceNo" />

            </div>
            <div class="text-start"
              *ngIf="fc.documentReferenceNo.invalid && (fc.documentReferenceNo.dirty || fc.documentReferenceNo.touched)">
              <p class="text-danger" *ngIf="fc.documentReferenceNo.errors?.required">Required
              </p>
              <p class="text-danger" *ngIf="fc.documentReferenceNo.errors?.maxlength">Max Length Exceed</p>
              <p class="text-danger" *ngIf="fc.documentReferenceNo.errors?.minlength">
                <span *ngIf="fc.documentType.value == DocumentType.GST_REGISTRATION_CERTIFICATE">
                  GST Number should be minimum 15 digits.
                </span>
                <span *ngIf="fc.documentType.value == DocumentType.PAN_CARD">
                  PAN Card Number should be minimum 10 digits.
                </span>
              </p>
            </div>
          </div>

          <div class=" mb-3" *ngIf="isCheckExpiryApplicable(fc['documentType'].value)">
            <div class="">

              <div class="flex items-center">
                <!-- <p-checkbox id="expiryCheckbox" formControlName="isExpiry" inputId="expiryCheckbox" 
                   ></p-checkbox>
                   <label for="expiryCheckbox">Expiry date</label> -->
                <input type="checkbox" class="w-5 h-5 border border-blue-100 rounded" formControlName="isExpiry" />
                <label for="" class="leading-6 text-sm font-medium ms-3">Expiry</label>
              </div>

            </div>
            <!-- <div class="text-start"
                  *ngIf="fc.documentType.invalid && (fc.documentType.dirty || fc.documentType.touched)">
                  <p class="text-danger" *ngIf="fc.documentType.errors?.required">Required
                  </p>
                </div> -->
          </div>

          <div class=" mb-3" *ngIf="(fc['isExpiry'].value) == true">
            <div class="">
              <label for="expiryDate" class="text-gray-600  text-sm font-medium leading-4 text-start">Enter
                Expiry Date</label>
              <div
                class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-500 h-8  py-0 text-gray-700 text-sm"
                [ngClass]="{'is-invalid': fc['expiryDate'].invalid && (fc['expiryDate'].dirty || fc['expiryDate'].touched)}">
                <p-calendar id="expiryDate" formControlName="expiryDate" dateFormat="dd/mm/yy" [showTime]="true"
                  [minDate]="expireyMinimumDate" appendTo="body"></p-calendar>
              </div>
              <div class="text-start" *ngIf="fc.expiryDate.invalid && (fc.expiryDate.dirty || fc.expiryDate.touched)">
                <p class="text-danger" *ngIf="fc.expiryDate.errors?.required">Required
                </p>
              </div>

            </div>
            <!-- <div class="text-start"
                  *ngIf="fc.documentType.invalid && (fc.documentType.dirty || fc.documentType.touched)">
                  <p class="text-danger" *ngIf="fc.documentType.errors?.required">Required
                  </p>
                </div> -->
          </div>


          <!-- <div class="   text-start mb-3">
                <span class="span-class me-2">
                  Set Expiry Date
                </span>
                <span class="form-switch">
                  <input class="form-check-input c-pointer" type="checkbox" id="flexSwitchCheckChecked"
                    (click)="enableAndDisableExpiry()" [checked]="isExpiry$ |async" /></span>
              </div>
              <div class="  mb-3" *ngIf="isExpiry$ | async">
                <label for="expiryDate">Expiry date</label>
                <div class="">

                  <input type="text" ngbDatepicker #date="ngbDatepicker" id="expiryDate" (click)="date.toggle()"
                    [ngClass]="{'border-danger': fc.expiryDate.invalid && (fc.expiryDate.dirty || fc.expiryDate.touched)}"
                    class="form-control form-control-sm rounded-3" placeholder="End Date" formControlName="expiryDate">

                </div>
                <div class="text-start" *ngIf="fc.expiryDate.invalid && (fc.expiryDate.dirty || fc.expiryDate.touched)">
                  <p class="text-danger" *ngIf="fc.expiryDate.errors?.required">Required
                  </p>
                </div>
              </div> -->
          <div class="col-12 mt-3 grid">
            <label for="file-upload" class="text-gray-600  text-sm font-medium leading-4 text-start">Browse File

              <div class="border p-2 rounded-3">
                <div class="w-full h-full flex items-center justify-center py-4 px-3 bg-gray-100 cursor-pointer">
                  <div class="flex flex-col items-center">
                    <div>
                      <img src="assets/images/document.svg" class="svg-icon-large c-pointer mb-2">
                      <!-- Added mb-2 for margin-bottom -->
                    </div>
                    <div class="text-center"> <!-- Added text-center for center alignment -->
                      Drag or drop here or <span class="text-link-md">browse file</span>
                    </div>
                  </div>

                  <input id="file-upload" type="file" onclick="this.value=null;" (change)="chooseFile($event)" />
                </div>
                <!-- <div class="w-full h-full flex items-center justify-center py-4 px-3 bg-gray-100 cursor-pointer">


                  <label for="file-upload">
                    <div class="">
                      <img src="assets/icons/cloud-upload.svg" class="svg-icon-large c-pointer me-2">
                    </div>

                    <div class="">
                      Drag or drop here or <span class="text-link-md">browse file </span>
                    </div>
                  </label>

                  <input id="file-upload" type="file" onclick="this.value=null;" (change)="chooseFile($event)" />
                </div> -->
              </div>
            </label>
          </div>
          <div class="col-12 mb-3" *ngIf="(_fileDisplayName$ | async) &&(_fileSize$ | async)">
            <div class="flex flex-row justify-between items-center">
              <div class="">
                <i-lucide name="file" [size]="16" color="black" class="my-icon inline-flex"></i-lucide>
                <!-- <img src="assets/icons/file_light.svg" class="svg-icon-class cursor-pointer inline-flex"> -->
                {{_fileDisplayName$ |async}}
              </div>

              <div class=" text-end ps-0">
                <div class=" text-end">
                  {{formatBytes(_fileSize$ | async)}}
                  <i-lucide name="trash-2" [size]="16" color="rgb(239 68 68)" class="my-icon cursor-pointer inline-flex"
                    (click)="deleteFile()"></i-lucide>

                </div>

              </div>
            </div>
          </div>

        </div>
        <div class=" text-end mt-3">
          <p-button [disabled]="(isLoading$ | async)!"
            class="text-center basis-1/2 text-white bg-gray-500 hover:bg-gray-600 focus:ring-4 focus:ring-gray-300 font-medium rounded text-sm px-3 py-1.5 me-3"
            (click)="openFileModal = false">Cancel</p-button>
          <p-button [loading]="(isLoading$ | async)!"
            class="text-center basis-1/2 text-white bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-300 font-medium rounded text-sm px-3 py-1.5 "
            (click)="saveDocumentArchive()">
            {{isCheckAddAndVerifiyApplicable(fc['documentType'].value)?'Add and verify' : 'Add' }}
          </p-button>
        </div>

      </div>
      <!-- refrence  -->
       <!-- refrence -->
    </form>
  </div>
</p-dialog>

<p-dialog [header]="'View ' +selectedDocumentArchive?.documentName!" [showHeader]="false"
  [(visible)]="openFileViewModal" [style]="{width: '50vw'}" [modal]="true" [draggable]="false" [resizable]="false">
  <header class="m-2">
    <div class="flex flex-row justify-between">
      <div class="text-gray-900 text-base font-semibold">
        {{'View ' +selectedDocumentArchive?.documentName!}}
      </div>
      <div class="text-xs font-normal text-gray-700 text-end">
        <span *ngIf="selectedDocumentArchive?.documentReferenceNo" class="me-2">PAN Number:
          {{selectedDocumentArchive?.documentReferenceNo}}</span>
        <span *ngIf="selectedDocumentArchive?.expiryDate">Expiry date: {{selectedDocumentArchive!.expiryDate! |
          ordinalDate}}</span>

      </div>
    </div>
  </header>
  <div class="model-body">
    <div class=" col-12 p-2">
      <div class=" bg-white flex-col self-stretch rounded-xl border border-solid border-slate-50">
        <div class="flex py-0 px-4 h-full items-center self-stretch gap-2.5 bg-gray-200 rounded-lg">
          <div class="mb-3 flex flex-row mx-auto justify-center items-center" *ngIf="isLoading$ | async">
            <p-progressSpinner></p-progressSpinner>
          </div>
          <iframe *ngIf="docPdfUrl && !(isLoading$ | async)" [src]="docPdfUrl | safe: 'url'" width="100%"
            height="1000px">
          </iframe>
        </div>
      </div>
    </div>

    <div class=" text-end mt-3">
      <p-button [disabled]="(isLoading$ | async)!"
        class="text-center basis-1/2 text-white bg-gray-500 hover:bg-gray-600 focus:ring-4 focus:ring-gray-300 font-medium rounded text-sm px-3 py-1.5 me-3"
        (click)="openFileViewModal = false">Close</p-button>
    </div>
  </div>
</p-dialog>

<!-- <div class="mt-5">
  <div  class="col-md-9 m-md-auto">
    <div class="text-danger"  *ngIf="_showErrorToast$ | async">
      <img src="assets/icons/close_red.svg" class="svg-icon-class-md c-pointer me-2"> {{errorMsg}}
     </div>
    <div class="text-orange mb-1"  *ngIf="isLoading$ | async">
      <img src="assets/icons/pending-icon.svg" class="svg-icon-class c-pointer me-2"> Your document update is pending
     </div>
     <div class="text-success"  *ngIf="_showSuccessToast$ | async">
      <img src="assets/icons/filter-check.svg" class="svg-icon-class-md c-pointer me-2"> Your document is saved
     </div>
     <div >
      <form action="" class="needs-validation" novalidate [formGroup]="formGroup">
        <div class="row mt-3">
        <div class="col-md-4">
          <div class="div-class">
            Documents could be used for Bid Submission
          </div>
          <div class="border bg-white p-2">
            <h6>Add New Document</h6>
            <div >
              <div class="col-12  mb-3">
                <div class="form-floating">
                  <input class="form-control" id="documentType" formControlName="documentType"
                  [ngClass]="{'border-danger': fc.documentType.invalid && (fc.documentType.dirty || fc.documentType.touched)}"/>
                  <label for="documentType">Document display name</label>
                </div>
                <div class="text-start"
                *ngIf="fc.documentType.invalid && (fc.documentType.dirty || fc.documentType.touched)">
                <p class="text-danger" *ngIf="fc.documentType.errors?.required">Required
                </p>
              </div>
              </div>
              <div class=" col-12  text-start mb-3">
                <span class="span-class me-2">
                  Expiry
                </span>
                <span class="form-switch">
                  <input class="form-check-input c-pointer" type="checkbox" id="flexSwitchCheckChecked" (click)="enableAndDisableExpiry()" [checked]="isExpiry$ |async"/></span>
              </div>
              <div class="col-12  mb-3" *ngIf="isExpiry$ | async">
                <div class="input-group">
                  <div class="form-floating">
                    <input type="text" ngbDatepicker #date="ngbDatepicker" id="expiryDate" (click)="date.toggle()"
                    [ngClass]="{'border-danger': fc.expiryDate.invalid && (fc.expiryDate.dirty || fc.expiryDate.touched)}"
                    class="form-control" placeholder="End Date" formControlName="expiryDate">
                    <label for="expiryDate">Select expiry date</label>
                  </div>
                  <span class="border border-start-0 bg-white input-group-text ">
                    <img src="assets/icons/calendar.svg" class="svg-icon-class c-pointer">
                  </span>
                </div>
                <div class="text-start"
                *ngIf="fc.expiryDate.invalid && (fc.expiryDate.dirty || fc.expiryDate.touched)">
                <p class="text-danger" *ngIf="fc.expiryDate.errors?.required">Required
                </p>
              </div>
              </div>
              <div class="col-12 mb-3">
                <label for="file-upload" class="custom-file-upload" >
                  <div>
                   <img src="assets/icons/cloud-upload.svg" class="svg-icon-large c-pointer me-2">
                  </div>
                     Drag or drop here or browse file to upload
                 </label>
                   <input id="file-upload" type="file" onclick="this.value=null;" type="file" (change)="chooseFile($event)" />
              </div>
              <div class="col-12 mb-3" *ngIf="(_fileDisplayName$ | async) &&(_fileSize$ | async)">
                <div class="row">
                  <div class="col-8" >
                    <img src="assets/icons/file_light.svg" class="svg-icon-class c-pointer">
                    {{_fileDisplayName$ |async}}
                  </div>
                  <div class="col-3 text-end" >
                    {{formatBytes(_fileSize$ | async)}}
                  </div>
                  <div class="col-1 text-end ps-0">
                    <img src="assets/icons/delete_dark.svg" class="svg-icon-class-small c-pointer" (click)="deleteFile()">
                  </div>
                </div>
              </div>
              <div class="mb-3" *ngIf="isLoading$ | async">
                <div class="e-spinner"></div>
              </div>
              <div class="col-12 text-center">
                <button class="btn btn-white-clr-class me-4" [disabled]="isLoading$ | async" (click)="clear()" >
                  Reset
                </button>
                <button class="btn btn-sm btn-primary-clr-class" [disabled]="isLoading$ | async" (click)="saveUserDetails()">
                  Upload
                </button>
              </div>
            </div>
          </div>

        </div>
        <div class="col-md-1 d-md-inline-block d-none"></div>
          <div class="col-md-7  mb-3">
            <div class="row align-items-center">
              <div class="col-9">
                <div class="text-success"  *ngIf="_showListSuccessToast$ | async">
                  <img src="assets/icons/filter-check.svg" class="svg-icon-class-md c-pointer me-2"> document deleted.
                 </div>
                <div class="text-danger"  *ngIf="_showListErrorToast$ | async">
                  <img src="assets/icons/close_red.svg" class="svg-icon-class-md c-pointer me-2"> {{errorListMsg}}
                 </div>
                <div class="text-orange" *ngIf="!(listLoading$ | async) &&(!documentArchiveList || documentArchiveList.length) == 0 && !(_showListErrorToast$ | async) && !(_showListSuccessToast$ | async)">
                  <img src="assets/icons/pending-icon.svg" class="svg-icon-class c-pointer me-2"> You have not uploaded any document yet
                </div>
                <div class="text-success" *ngIf="(documentArchiveList?.length ?? 0 > 0) && !(_showListErrorToast$ | async) && !(_showListSuccessToast$ | async)">
                  <img src="assets/icons/filter-check.svg" class="svg-icon-class-md c-pointer me-2"> You have uploaded {{documentArchiveList?.length ?? 0 }} documents
                </div>
              </div>
              <div class="col-3 text-end">
                <span class="text-danger">
                  {{countExpiredDocument}} expired
                </span>
              </div>
            </div>


            <div class="over-flow-scroll">
              <div class="card bg-white p-2 mb-3" *ngFor="let documentArchive of documentArchiveList index as i;" (click)="selectDocumentArchive(documentArchive)">
                <h6>{{documentArchive.documentType}}</h6>
                <div class="col-12 mb-3">
                  <div class="row medium-grey-font">
                    <div class="col-6">
                      <img src="assets/icons/file_light.svg" class="svg-icon-class c-pointer">
                      {{documentArchive.documentName}}
                    </div>
                    <div class="col-2 text-end">
                      {{formatBytes(documentArchive.size)}}
                    </div>
                    <div class="col-3 text-end" >
                      <span class="me-3"> Expiry</span>
                      <span *ngIf="!documentArchive.expiryDate">NA</span>
                      <span *ngIf="documentArchive.expiryDate"> <span>{{documentArchive.expiryDate}}</span></span>
                    </div>
                    <div class="col-1 text-end">
                      <img src="assets/icons/delete_dark.svg" class="svg-icon-class-small c-pointer" (click)="deleteDocumentArchive(documentArchive.documentArchiveId!)">
                    </div>
                  </div>
                </div>

              </div>
            </div>

            <div class="mb-3" *ngIf="listLoading$ | async">
              <div class="e-spinner"></div>
            </div>
          </div>
        </div>

      </form>
     </div>
  </div>
</div> -->

<p-confirmDialog #cd [style]="{width: '20vw'}">
  <ng-template pTemplate="header" class="">

    <div class="text-black font-bold text-xl ">
      Delete Document
    </div>

  </ng-template>
  <div class="border border-gray-500 w-full flex px-0 mx-0"> </div>
  <ng-template pTemplate="icon">
    <i class="pi pi-user"></i>
  </ng-template>
  <ng-template pTemplate="message">
    <div>
      Are you sure that you want to delete?
    </div>
  </ng-template>
  <ng-template pTemplate="footer" class="bg-gray-100">
    <button type="button" pButton icon="pi pi-times" label="No"
      class="text-center basis-1/2 text-white bg-gray-500 hover:bg-gray-600 focus:ring-4 focus:ring-gray-300 font-medium rounded text-xs px-5 py-1 me-3"
      (click)="cd.reject()"></button>
    <button type="button" pButton icon="pi pi-check" label="Yes"
      class="text-center basis-1/2 text-white bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-300 font-medium rounded text-xs px-5 py-1"
      (click)="cd.accept()"></button>
  </ng-template>
</p-confirmDialog>