import { AfterContentInit, Component, Input, OnInit } from '@angular/core';
import { AuctionEntityDto } from '../../models/user/AuctionEntityDto';
import { LandingAndBidderService } from '../../services/LandingAndBidder.service';
import { AuctionExtUtil } from '../../util/AuctionExtUtil';
import { firstValueFrom, Subscription } from 'rxjs';
import { UserRegistrationStatus } from '../../enums/UserRegistrationStatus';
import { UserAuctionEligibleStatus } from '../../enums/UserAuctionEligibleStatus';
import { EmailVerifyModalComponent } from '../email-verify-modal/email-verify-modal.component';
import { BidderAuctionRegisterComponent } from 'src/app/layouts/bidder/bidder-auction-register/bidder-auction-register.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserAuctionRegistrationDto } from '../../models/user/UserAuctionRegistrationDto';
import { DataRedirectionService } from '../../services/data-redirection.service';
import { CountryCodeDto } from '../../models/CountryCodeDto';
import { OrganizationUiDto } from '../../models/user/AuctionHouseDto';
import { AuctionCardWrapperDto } from '../../models/AuctionCardWrapperDto';
import { AuctionExtConstant } from '../../util/AuctionExtConstant';
import { select, Store } from '@ngrx/store';
import { selectBidderUiDto } from '../../state-management/session/session.features';
import { BidderUiDto } from '../../models/UserEntityDto';

@Component({
  selector: 'app-all-auction-card',
  templateUrl: './all-auction-card.component.html',
  styleUrls: ['./all-auction-card.component.sass']
})
export class AllAuctionCardComponent implements AfterContentInit, OnInit {
  @Input() auctionCardWrapperDto?: AuctionCardWrapperDto;

  auctionHouse?: OrganizationUiDto;
  auctionEntityDto?: AuctionEntityDto;
  currentAuctionHouseSubscription$?: Subscription;

  userAuctionRegistrationDto?: UserAuctionRegistrationDto;
  bidderUiDto?: BidderUiDto;

  public screenWidth: any;
  countdownTimerDays = 0;
  countdownTimerHours = 0;
  countdownTimerMinutes = 0;
  countdownTimerSeconds = 0;
  counterFunction: any;
  timer: boolean = false;
  isLoading: boolean = false;


  onWindowResize() {
    this.screenWidth = window.innerWidth;
  }

  constructor(
    private bidderService: LandingAndBidderService,
    private dataRedirectionService: DataRedirectionService,
    private store: Store,
    // private userService: UserService,
    private ngbModal: NgbModal
  ) { }

  ngOnInit(): void {
    this.auctionEntityDto = this.auctionCardWrapperDto?.auctionEntityDto;
    this.userAuctionRegistrationDto = this.bidderService.getUserAuctionRegistration(this.auctionEntityDto?.auctionId!);

    this.currentAuctionHouseSubscription$ = this.bidderService.getCurrentAuctionHouse$.subscribe((data) => {
      if (data) {
        this.auctionHouse = data;
      }
    })
  }

  ngAfterContentInit(): void {
    this.loadBidderUiDto();
    this.clearCountdown();
    this.countdown(this.auctionEntityDto!);

    this.screenWidth = window.innerWidth;

  }
  
  async loadBidderUiDto() {
    this.bidderUiDto = await firstValueFrom(this.store.pipe(select(selectBidderUiDto)));
  }

  isUserLogin(){
    if(this.bidderUiDto){
      return true;
    }
    return false;
  }


  navigateAuctionView() {
    this.isLoading = true;
    this.dataRedirectionService.storeNavigationContext(AuctionExtConstant.ANY_CONTEXT, AuctionExtConstant.AUCTION, AuctionExtConstant.ANY_PAGE, '', ''+this.auctionEntityDto?.sequenceNo!, undefined)
    this.dataRedirectionService.navigationToHomePage();
  }

  clearCountdown() {
    if (this.timer == true) {
      console.log("For Auction :" + this.auctionEntityDto?.auctionName + " Remaining time clear");
      clearInterval(this.counterFunction);
      this.timer = false;
    }
  }

  countdown(auctionEntityDto: AuctionEntityDto) {
    let _this = this;
    let timeZone = auctionEntityDto.timeZone!;
    let date = auctionEntityDto?.status == 'LIVE' ? auctionEntityDto?.endDate : auctionEntityDto?.startDate;
    let time = auctionEntityDto?.status == 'LIVE' ? auctionEntityDto?.endTime : auctionEntityDto?.startTime;
    let remainingDurations = AuctionExtUtil.getRemainingTime(auctionEntityDto.endDate +" "+ auctionEntityDto.endTime!, timeZone!);

    if (this.timer == false) {
      console.log("Creating a new countdown function in supplier small card component - " + auctionEntityDto?.auctionName);

      this.timer = true;
      this.counterFunction = setInterval(function () {

        let element = document.getElementById("days" + _this.counterFunction + auctionEntityDto.auctionId);
        if (element) {
          let remainingDays = AuctionExtUtil.getCountdownTimerDays(date + " " + time, timeZone);
          let remainingHours = AuctionExtUtil.getCountdownTimerHours(date + " " + time, timeZone);
          let remainingMinutes = AuctionExtUtil.getCountdownTimerMinutes(date + " " + time, timeZone);
          let remainingSeconds = AuctionExtUtil.getCountdownTimerSeconds(date + " " + time, timeZone);

          if (remainingDays) {
            document.getElementById("days" + _this.counterFunction + auctionEntityDto.auctionId)!.hidden = false;
            document.getElementById("hours" + _this.counterFunction + auctionEntityDto.auctionId)!.hidden = false;
            document.getElementById("minutes" + _this.counterFunction + auctionEntityDto.auctionId)!.hidden = true;
            document.getElementById("second" + _this.counterFunction + auctionEntityDto.auctionId)!.hidden = true;

            document.getElementById("days" + _this.counterFunction + auctionEntityDto.auctionId)!.innerText = remainingDays + 'd ';
            document.getElementById("hours" + _this.counterFunction + auctionEntityDto.auctionId)!.innerText = remainingHours + 'h';
          } else {
            document.getElementById("days" + _this.counterFunction + auctionEntityDto.auctionId)!.hidden = true;
            document.getElementById("hours" + _this.counterFunction + auctionEntityDto.auctionId)!.hidden = false;
            document.getElementById("minutes" + _this.counterFunction + auctionEntityDto.auctionId)!.hidden = false;
            document.getElementById("second" + _this.counterFunction + auctionEntityDto.auctionId)!.hidden = false;

            document.getElementById("hours" + _this.counterFunction + auctionEntityDto.auctionId)!.innerText = ('0' + remainingHours).slice(-2) + ' : ';
            document.getElementById("minutes" + _this.counterFunction + auctionEntityDto.auctionId)!.innerText = ('0' + remainingMinutes).slice(-2) + ' : ';
            document.getElementById("second" + _this.counterFunction + auctionEntityDto.auctionId)!.innerText = ('0' + remainingSeconds).slice(-2);
          }

          //do something later when date is reached
          if (remainingDurations < 0) {
            let headline = document.getElementById("headline" + _this.counterFunction + auctionEntityDto.auctionId);
            let countdown = document.getElementById("countdown" + _this.counterFunction + auctionEntityDto.auctionId);
            // let content = document.getElementById("content" + auction.auctionId);

            if (headline) {
              headline.innerText = "Bidding Closed!";
            }
            if (countdown) {
              countdown.style.display = "none";
            }
            // content.style.display = "block";

            clearInterval(_this.counterFunction);
          }
        }

      }, 1000)

      console.log("New count down function created in supplier small card component - " + auctionEntityDto.auctionName + "   : " + this.counterFunction)
    }
  }

  isAuctionRegistrationIncomplete() {
    let toReturn = false;
    if (this.bidderUiDto?.registrationStatus == UserRegistrationStatus.COMPLETED &&
      (this.userAuctionRegistrationDto?.userAuctionRegistrationStatus == null
        || this.userAuctionRegistrationDto.userAuctionRegistrationStatus == UserAuctionEligibleStatus.INCOMPLETE)) {
      toReturn = true;
    }
    return toReturn;
  }

  getCompanyDto(companyId?: string){
    return null;
  }


  getTextForShowRegisterToBid() {
    let status = this.userAuctionRegistrationDto?.userAuctionRegistrationStatus;
    if (status && status == UserAuctionEligibleStatus.AWAITING_APPROVAL) {
      return "AWAITING APPROVAL TO BID"
    }
    return "REGISTER TO BID";
  }

  isShowRegisterToBid() {
    let status = this.userAuctionRegistrationDto?.userAuctionRegistrationStatus;
    if (this.bidderUiDto
      && (status == undefined || this.bidderUiDto.registrationStatus == UserRegistrationStatus.INCOMPLETE || status == UserAuctionEligibleStatus.AWAITING_APPROVAL)) {
      return true;
    }
    return false;
  }

  openRegisterToBidModal(event: any) {
    event.stopPropagation();


    if (this.bidderUiDto?.registrationStatus == UserRegistrationStatus.INCOMPLETE) {
      this.ngbModal.open(EmailVerifyModalComponent, {
        backdrop: 'static', keyboard: false , centered: true
      });
      return;
    }

    if (!this.isAuctionRegistrationIncomplete()) {
      return;
    }
    let model = this.ngbModal.open(BidderAuctionRegisterComponent, { size: 'md', backdrop: 'static', keyboard: false , centered: true });
    model.componentInstance.selectedAuction = this.auctionEntityDto;
  }

  getCountryCode(countryCodeDto?: CountryCodeDto) {
    if (countryCodeDto) {
      return countryCodeDto.countryCode?.replace('(', '').replace(')', '');
    }
    return '+1';
  }



  ngOnDestroy(): void {
    this.clearCountdown();

    if(this.currentAuctionHouseSubscription$ ){
      this.currentAuctionHouseSubscription$.unsubscribe();
    }
  }
}
