<div class="mt-5">
  <div class="flex w-4/6 mx-auto">
    <!-- Left Section (Form) -->
    <div class="w-full bg-white pt-6 pb-8 px-8 rounded-lg">
      <div class="flex items-center mb-6">
        <div>
          <span class="h-10 w-10 items-center justify-between bg-blue-100 rounded-lg inline-flex p-2">
            <i-lucide name="landmark" class="my-icon" color="#374151"></i-lucide>
          </span>
        </div>
        <div class="inline-block ms-2">
          <div class="font-semibold text-gray-800 text-lg">Address</div>
          <div class="text-gray-500 text-xs text-medium font-medium">
            Add your company address to move forward.
          </div>
        </div>
      </div>

      <!-- Form Start -->
      <form class="needs-validation" novalidate [formGroup]="formGroup" (keyup.enter)="handleValidSubmit()">
        <div class="flex flex-row mt-1 gap-3">
          <div class="basis-1/2">
            <!-- Address Form Fields -->
            <div class="mb-4">
              <label for="searchAddress" class="text-gray-600 text-sm font-medium leading-4 text-start">
                Search Address
              </label>
              <!-- <input
              class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-600 text-sm"
              type="text" formControlName="searchAddress" id="searchAddress" /> -->
              <div class="flex w-full mb-3 border border-solid border-slate-50 rounded-md">

                <div class="input-group-append">
                  <span class=" h-8 flex pt-2 px-3" id="basic-addon2">
                    <i-lucide name="map-pin" color="rgb(107 114 128)" [size]="16" class="my-icon"></i-lucide>
                  </span>
                </div>
                <app-places-autocomplete type="text"
                  class="bg-white w-full  flex-col self-stretch h-8 px-2 py-0 input-placeholder"
                  [ngClass]="{'is-invalid': fc.searchAddress.invalid && (fc.searchAddress.dirty || fc.searchAddress.touched)}"
                  formControlName="searchAddress" id="searchAddress"
                  (onAddressChange)="handleAddressChange($event)"></app-places-autocomplete>
              </div>
              <div *ngIf="fc.searchAddress.invalid && (fc.searchAddress.dirty || fc.searchAddress.touched)">
                <p class="text-danger" *ngIf="fc.searchAddress.errors?.required">
                  <i-lucide name="triangle-alert" [size]="16" color="rgb(239 68 68)" class="my-icon inline-flex me-1"></i-lucide>
                  Address Required</p>
                <p class="text-danger" *ngIf="fc.searchAddress.errors?.address">
                  <i-lucide name="triangle-alert" [size]="16" color="rgb(239 68 68)" class="my-icon inline-flex me-1"></i-lucide>
                  Invalid</p>
                  <p class="text-danger" *ngIf="fc.searchAddress.errors?.maxlength">
                    <i-lucide name="triangle-alert" [size]="16" color="rgb(239 68 68)" class="my-icon inline-flex me-1"></i-lucide>
                   Maximum Length 250
                  </p>
              </div>
            </div>

            <!-- Other Form Fields (Address, City, State, etc.) -->
            <div class="mb-4">
              <label for="addressLine1" class="text-gray-600 text-sm font-medium leading-4 text-start">Address Line
                1</label>
              <input
                class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-600 text-sm"
                type="text" formControlName="addressLine1" id="addressLine1" />
              <div *ngIf="fc.addressLine1.invalid && (fc.addressLine1.dirty || fc.addressLine1.touched)">
                <p class="text-danger" *ngIf="fc.addressLine1.errors?.required">
                  <i-lucide name="triangle-alert" [size]="16" color="rgb(239 68 68)" class="my-icon inline-flex me-1"></i-lucide>
                  Address Required
                </p>
                <p class="text-danger" *ngIf="fc.addressLine1.errors?.maxlength">
                  <i-lucide name="triangle-alert" [size]="16" color="rgb(239 68 68)" class="my-icon inline-flex me-1"></i-lucide>
                 Maximum Length 250
                </p>
              </div>
            </div>
            <div class="mb-4">
              <label for="addressLine2" class="text-gray-600  text-sm font-medium leading-4 text-start">Address Line
                2</label>
              <div class="">
                <input
                  class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-600 text-sm"
                  id="addressLine2" type="text" formControlName="addressLine2" />

              </div>
              <div *ngIf="fc.addressLine2.invalid && (fc.addressLine2.dirty || fc.addressLine2.touched)">
                <p class="text-danger" *ngIf="fc.addressLine2.errors?.maxlength">
                  <i-lucide name="triangle-alert" [size]="16" color="rgb(239 68 68)" class="my-icon inline-flex me-1"></i-lucide>
                  Maximum Length 250
                </p>
              </div>
            </div>

            <!-- Continue with Address Line 2, City, State, Country, ZipCode -->
            <div class="mb-4">
              <label for="city" class="text-gray-600 text-sm font-medium leading-4 text-start">City</label>
              <input
                class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-600 text-sm"
                type="text" id="city" formControlName="city" />
              <div *ngIf="fc.city.invalid && (fc.city.dirty || fc.city.touched)">
                <p class="text-danger" *ngIf="fc.city.errors?.required">
                  <i-lucide name="triangle-alert" [size]="16" color="rgb(239 68 68)" class="my-icon inline-flex me-1"></i-lucide>
                  City Required</p>
                  <p class="text-danger" *ngIf="fc.city.errors?.maxlength">
                    <i-lucide name="triangle-alert" [size]="16" color="rgb(239 68 68)" class="my-icon inline-flex me-1"></i-lucide>
                    Maximum Length 250
                  </p>
              </div>
            </div>
            <div class="mb-4">
              <label for="state" class="text-gray-600  text-sm font-medium leading-4 text-start">State</label>
              <div class="">
                <input
                  class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-600 text-sm"
                  type="text" id="state"
                  [ngClass]="{'is-invalid': fc.state.invalid && (fc.state.dirty || fc.state.touched)}"
                  formControlName="state" />

              </div>
              <div *ngIf="fc.state.invalid && (fc.state.dirty || fc.state.touched)">
                <p class="text-danger" *ngIf="fc.state.errors?.required">
                  <i-lucide name="triangle-alert" [size]="16" color="rgb(239 68 68)" class="my-icon inline-flex me-1"></i-lucide>
                  State Required</p>
                <p class="text-danger" *ngIf="fc.state.errors?.maxlength">
                  <i-lucide name="triangle-alert" [size]="16" color="rgb(239 68 68)" class="my-icon inline-flex me-1"></i-lucide>
                  Maximum Length 250
                </p>
              </div>
            </div>
            <div class="mb-4">
              <div class="">
                <label for="country" class="text-gray-600  text-sm font-medium leading-4 text-start">Country</label>
                <input
                  class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-600 text-sm"
                  type="text" id="country"
                  [ngClass]="{'is-invalid': fc.country.invalid && (fc.country.dirty || fc.country.touched)}"
                  formControlName="country" />

              </div>
              <div *ngIf="fc.country.invalid && (fc.country.dirty || fc.country.touched)">
                <p class="text-danger" *ngIf="fc.country.errors?.required">
                  <i-lucide name="triangle-alert" [size]="16" color="rgb(239 68 68)" class="my-icon inline-flex me-1"></i-lucide>
                  Country Required</p>
                  <p class="text-danger" *ngIf="fc.country.errors?.maxlength">
                    <i-lucide name="triangle-alert" [size]="16" color="rgb(239 68 68)" class="my-icon inline-flex me-1"></i-lucide>
                    Maximum Length 250
                  </p>
              </div>
            </div>
            <div class="mb-4">
              <div class="">
                <label for="zipCode" class="text-gray-600  text-sm font-medium leading-4 text-start">Pin Code</label>
                <input
                  class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-600 text-sm"
                  type="text" id="zipCode"
                  [ngClass]="{'is-invalid': fc.zipCode.invalid && (fc.zipCode.dirty || fc.zipCode.touched)}"
                  formControlName="zipCode" />

              </div>
              <div *ngIf="fc.zipCode.invalid && (fc.zipCode.dirty || fc.zipCode.touched)">
                <p class="text-danger" *ngIf="fc.zipCode.errors?.required">
                  <i-lucide name="triangle-alert" [size]="16" color="rgb(239 68 68)" class="my-icon inline-flex me-1"></i-lucide>
                  Zip Code Required</p>
                <p class="text-danger" *ngIf="fc.zipCode.errors?.pattern">
                  <i-lucide name="triangle-alert" [size]="16" color="rgb(239 68 68)" class="my-icon inline-flex me-1"></i-lucide>
                  Invalid</p>
                <p class="text-danger" *ngIf="fc.zipCode.errors?.maxlength">
                  <i-lucide name="triangle-alert" [size]="16" color="rgb(239 68 68)" class="my-icon inline-flex me-1"></i-lucide>
                  Maximum Length 250
                </p>
              </div>
            </div>


            <!-- Additional Fields: State, Country, Pin Code -->
            <!-- Error message and loading spinner -->
            <div class="my-3">
              <app-message-toast *ngIf="_showErrorToast$ | async" [isError]="true"
                [message]="errorMsg"></app-message-toast>
            </div>
            <!-- <div class="d-grid gap-2 my-3" *ngIf="isLoading">
              <div class="e-spinner center-spinner" style="margin-top: -20px !important"></div>
            </div> -->
          </div>

          <!-- Right Section (Map) -->
          <div class="basis-1/2 mb-4  ">
            <div class="border bg-white p-2 rounded-lg">
              <div class="text-center d-flex align-items-center justify-content-center m-auto" style="height: 46vh"
                *ngIf="latitude == undefined">
                Address is not updated.
              </div>
              <google-map height="70vh" width="100%" [options]="options" [center]="position!" [zoom]="zoom"
                *ngIf="latitude != undefined">
                <map-marker #markerElem="mapMarker" [position]="position!" [title]="marker.title"
                  [options]="marker.options" (mapClick)="openInfo(markerElem, marker.info)">
                </map-marker>
                <map-info-window>{{ infoContent }}</map-info-window>
              </google-map>
            </div>

          </div>
        </div>
        <div class="flex flex-row justify-between mt-5">
          <div class=" text-start flex">
             <p-button
              class="text-center items-center px-4 text-blue-500 border-1 border-gray-300 hover:border-blue-600 focus:ring-4 focus:ring-blue-300 font-medium rounded text-sm px-4 py-1.5 inline-flex"
              (onClick)="prevStep()">
              <i-lucide [size]="16" name="move-left" class="my-icon flex-inline me-1"></i-lucide> Back</p-button>
          </div>
          <div class=" text-end flex">
            <p-button
              [loading]="isLoading" class="text-center items-center  bg-blue-700 text-white border-1 border-blue-300 hover:border-blue-600 focus:ring-4 focus:ring-blue-300 font-medium rounded text-sm  inline-flex"
              (onClick)="saveUserProfile()">Next: Documents <i-lucide [size]="16" name="move-right"
                class="my-icon inline-flex ms-1"></i-lucide></p-button>
          </div>
        </div>

      </form>
    </div>
  </div>
</div>
<p-toast></p-toast>