import { UserPrivilege } from './../enums/UserPrivilege';
import { SessionInfoDto } from 'src/app/shared/models/SessionInfoDto';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { BidderUiDto } from '../models/UserEntityDto';
import { DataRedirectionService } from './data-redirection.service';
import { AuctionExtUtil } from '../util/AuctionExtUtil';

@Injectable({
  providedIn: 'root'
})
export class RoutingService {

  constructor(
    private router: Router,
    private dataRedirectionService: DataRedirectionService
  ) { }

  doRouting(session: SessionInfoDto) {
    // Bidder
    if (AuctionExtUtil.checkIfRoleIsAssigned(session.privileges || [], UserPrivilege.BIDDER)) {
      // DOMAIN CHANGES
      //this.router.navigate([AuctionwareUtil.getBaseUrl() + '/Bidder/Dashboard'], { skipLocationChange: true })
      this.router.navigate(['Bidder/Dashboard'], { skipLocationChange: true })
      return;
    }
    // DOMAIN CHANGES
    //this.router.navigate([AuctionwareUtil.getBaseUrl()]);
    this.dataRedirectionService.navigationToHomePage();
  }
}
