

import { UserLotFavouriteDto as UserLotFavoriteDto } from '../models/user/UserLotFavouriteDto';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap, catchError, BehaviorSubject, Observable, firstValueFrom } from 'rxjs';
import { ServerAPIResponseDto } from '../models/ServerAPIResponseDto';
import { AuctionBidsDto } from '../models/user/AuctionBidsDto';
import { AuctionEntityDto } from '../models/user/AuctionEntityDto';
import { AuctionLotEntityDto } from '../models/user/AuctionLotEntityDto';
import { AuctionStatus } from '../models/user/AuctionStatus';
import { UserAuctionRegistrationDto } from '../models/user/UserAuctionRegistrationDto';
import { ErrorService } from './error.service';
import { MyBidsData } from '../models/user/MyBidsData';
import { OrganizationUiDto } from '../models/user/AuctionHouseDto';
import { DocumentData } from '@angular/fire/firestore';
import { UserService } from './user.service';
import { UserRatingDto } from '../models/user/UserRatingDto';
import { AuctionQuestionDto } from '../models/user/AuctionQuestionDto';
import { CalendarService } from './calendar.service';
import { AuctionCardWrapperDto } from '../models/AuctionCardWrapperDto';
import { AuctionDataCardsWrapperDto } from '../models/AuctionDataCardsWrapperDto';
import { AuctionExtUtil } from '../util/AuctionExtUtil';
import { UserLotFavouriteWrapperDto } from '../models/user/UserLotFavouriteWrapperDto';
import { MultiLotWrapperDto } from '../models/MultiLotWrapperDto';
import { CategoryParentWrapperDto } from '../models/CategoryDto';
import { AuctionAggregateDto } from '../models/user/AuctionAggregateDto';
import { AuctionExtConstant } from '../util/AuctionExtConstant';
import { SourcingEventDto } from '../models/SourcingEventDto';
import { UserProjectDto } from '../models/UserProjectDto';
import { TimeZoneDto } from '../models/TimeZoneDto';
import { UserAuctionRegistrationWrapperDto } from '../models/UserAuctionRegistrationWrapperDto';
import { RfxUiDto } from '../models/rfx/RfxEntityDto';
import { RfxSubcategoryEntityDto } from '../models/rfx/RfxSubcategoryEntityDto ';
import { UserRfxSubcategoryUiDto } from '../models/questionnaire/UserRfxSubcategoryDto';
import { CompNormalizeBiddersDto } from '../models/rfx/CompNormalizeBiddersDto';
import { CompNormalizeItemsWrapperDto } from '../models/rfx/CompNormalizeItemsWrapperDto';
import { ApplicationNotificationEntityDto } from '../models/ApplicationNotificationEntityDto';
import { RegistrationUserModalDto } from '../models/user/RegistrationUserModalDto';
import { RfxPurchaseInvoiceDto } from '../models/rfx/RfxPurchaseInvoiceDto';
import { BidderTenderSourcingEventDto } from '../models/BidderTenderSourcingEventDto';
import { BidderTenderSourcingEventWrapperDto } from '../models/BidderTenderSourcingEventWrapperDto';
import { TenderSearchWrapperDto } from '../models/TenderSearchWrapperDto';

@Injectable({
  providedIn: 'root'
})
export class LandingAndBidderService {
  allAuctionCardWrapperDtos?: AuctionCardWrapperDto[] = [];
  allLots?: AuctionLotEntityDto[] = [];

  myUserAuctionRegistrationWrappers: UserAuctionRegistrationWrapperDto[] = []

  allEventsList$ = new BehaviorSubject<SourcingEventDto[]>([]);

  masterCategories: CategoryParentWrapperDto[] = []
  masterTimezones: TimeZoneDto[] = []

  // highestBidsDataMap: { auctionId: string, totalBids: number }[] = []

  sortBy?: string = 'Recent';
  currentAuctionType?: string = 'RUNNING';
  currentNotificationPage: number = 0;
  notificationPageSize: number = 20;

  _selectedAuction?: AuctionEntityDto;
  _selectedRfx?: RfxUiDto;
  _selectedRfxSubcategory?: RfxSubcategoryEntityDto;

  // filteredAuctions$ = new BehaviorSubject<AuctionEntityDto[]>([]);
  allLotsList$ = new BehaviorSubject<AuctionLotEntityDto[]>([]);
  similarAuctionsList$ = new BehaviorSubject<AuctionEntityDto[]>([]);

  allAuctionCardWrapperDtos$ = new BehaviorSubject<AuctionCardWrapperDto[]>([]);
  runningAuctionCardWrapperDtos$ = new BehaviorSubject<AuctionCardWrapperDto[]>([]);
  upcomingAuctionCardWrapperDtos$ = new BehaviorSubject<AuctionCardWrapperDto[]>([]);
  closedAuctionCardWrapperDtos$ = new BehaviorSubject<AuctionCardWrapperDto[]>([]);

  _openEventCount$ = new BehaviorSubject<number>(0);

  enableCustomHeader$ = new BehaviorSubject<boolean>(false);

  selectLotName$ = new BehaviorSubject<string>("");

  _myUserAuctionRegistrationWrappers$ = new BehaviorSubject<Array<UserAuctionRegistrationWrapperDto>>([])
  _selectedAuction$ = new BehaviorSubject<AuctionEntityDto | null>(null);
  _selectedAuctionLot$ = new BehaviorSubject<AuctionLotEntityDto | null>(null);
  _currentAuctionHouse$ = new BehaviorSubject<OrganizationUiDto | null>(null);


  _multiLotWrapperDto$ = new BehaviorSubject<MultiLotWrapperDto | undefined>(undefined);
  _landingMultiLotWrapperDto$ = new BehaviorSubject<MultiLotWrapperDto | undefined>(undefined);
  _myFavoriteLotsWrapperDtos$ = new BehaviorSubject<UserLotFavouriteWrapperDto[]>([]);
  _allAuctionsDataLoaded$ = new BehaviorSubject<boolean>(false);
  userRfxSubcategoryDto$ = new BehaviorSubject<UserRfxSubcategoryUiDto | undefined>(undefined);


  _myBidsData$ = new BehaviorSubject<MyBidsData | null>(null);
  userRatingDtoList$ = new BehaviorSubject<UserRatingDto[]>([]);
  auctionQuestionsList$ = new BehaviorSubject<AuctionQuestionDto[]>([]);

  _selectedRfxDto$ = new BehaviorSubject<RfxUiDto | null>(null);
  _selectedRfxSubcategory$ = new BehaviorSubject<RfxSubcategoryEntityDto | null>(null);

  private _userProjects$ = new BehaviorSubject<BidderTenderSourcingEventWrapperDto | null>(null);
  private _pastUserProjects$ = new BehaviorSubject<UserProjectDto[]>([]);
  subcategoryList$ = new BehaviorSubject<RfxSubcategoryEntityDto[]>([]);

  private _applicationNotificationsList$ = new BehaviorSubject<ApplicationNotificationEntityDto[]>([]);
  private _compNormalizeItemsWrapperDto$ = new BehaviorSubject<CompNormalizeItemsWrapperDto|undefined>(undefined);
  private _selectedApplicationNotificationDto$ = new BehaviorSubject<ApplicationNotificationEntityDto|undefined>(undefined);



  constructor(
    private httpClient: HttpClient,
    private errorService: ErrorService
  ) { }

  get getMultiLotWrapperDto$() { return this._multiLotWrapperDto$.asObservable(); }
  get getLandingMultiLotWrapperDto$() { return this._landingMultiLotWrapperDto$.asObservable(); }

  get getMyLotFavoriteData$() { return this._myFavoriteLotsWrapperDtos$.asObservable(); }
  get getSelectedAuction() { return this._selectedAuction }
  get getSelectedAuction$() { return this._selectedAuction$.asObservable() }
  get getSelectedAuctionLot$() { return this._selectedAuctionLot$.asObservable() }
  get getAllAuctionCardWrapperDtos$() { return this.allAuctionCardWrapperDtos$.asObservable() }
  get getAllLotsList$() { return this.allLotsList$.asObservable() }
  get getMyBidsData$() { return this._myBidsData$ };
  get getCurrentAuctionHouse$() { return this._currentAuctionHouse$; };
  get getMyUserAuctionRegistrationWrappers$() { return this._myUserAuctionRegistrationWrappers$; };
  get getAllEventsList$() { return this.allEventsList$.asObservable() }

  get getOpenEventCount$() { return this._openEventCount$.asObservable(); }

  get getAuctionQuestionsList$() { return this.auctionQuestionsList$; };

  get getRunningAuctionCardWrapperDtos$() { return this.runningAuctionCardWrapperDtos$; };
  get getUpcomingAuctionCardWrapperDtos$() { return this.upcomingAuctionCardWrapperDtos$; };
  get getClosedAuctionCardWrapperDtos$() { return this.closedAuctionCardWrapperDtos$; };
  get getSimilarAuctionsList$() { return this.similarAuctionsList$; };
  get getUserProjects$() { return this._userProjects$; }
  get getPastUserProjects$() { return this._pastUserProjects$; }

  get getMasterCategories() { return this.masterCategories; };
  get getMasterTimezones() { return this.masterTimezones; };
  get getAllAuctionsDataLoaded$() { return this._allAuctionsDataLoaded$; }

  get getSelectedRfx() { return this._selectedRfx }
  get getSelectedRfxDto$() { return this._selectedRfxDto$.asObservable() }
  get getSelectedRfxSubcategory() { return this._selectedRfxSubcategory }
  get getSelectedRfxSubcategory$() { return this._selectedRfxSubcategory$.asObservable() }

  get getSubcategoryList$() { return this.subcategoryList$.asObservable() }
  get getUserRfxSubcategoryDto$() { return this.userRfxSubcategoryDto$.asObservable() }

  get getApplicationNotificationsList$() { return this._applicationNotificationsList$.asObservable() }
  get getCompNormalizeItemsWrapperDto$() { return this._compNormalizeItemsWrapperDto$; };
  get getSelectedApplicationNotificationDto$() { return this._selectedApplicationNotificationDto$; };


  clearData() {

    this._selectedAuction$.next(null);
    this._selectedAuctionLot$.next(null);
    this._myFavoriteLotsWrapperDtos$.next([]);
    this._myBidsData$.next(null);
    this.myUserAuctionRegistrationWrappers = [];
    this._myUserAuctionRegistrationWrappers$.next([]);
  }

  updateCurrentAuctionHouse(doc: DocumentData) {
    let auctionHouse = doc as OrganizationUiDto
    this._currentAuctionHouse$.next(auctionHouse);
  }

  updateMyBidsData(myBidsData: MyBidsData) {
    this._myBidsData$.next(myBidsData);
  }

  updateMultiLotWrapperDto(multiLotWrapperDto: MultiLotWrapperDto) {
    this._multiLotWrapperDto$.next(multiLotWrapperDto);
    this.setAllLotsList(multiLotWrapperDto.allLots ?? []);
  }

  updateLandingMultiLotWrapperDto(multiLotWrapperDto: MultiLotWrapperDto) {
    this._landingMultiLotWrapperDto$.next(multiLotWrapperDto);
  }

  updateMyFavoriteAllLots(userLotFavouriteWrapperDtos: UserLotFavouriteWrapperDto[]) {
    this._myFavoriteLotsWrapperDtos$.next(userLotFavouriteWrapperDtos);
  }

  setRfxDto(rfx: RfxUiDto) {
    this._selectedRfx = rfx;
    this._selectedRfxDto$.next(rfx);
  }

  setRfxSubcategoryDto(rfxSubcategoryDto: RfxSubcategoryEntityDto) {
    this._selectedRfxSubcategory = rfxSubcategoryDto;
    this._selectedRfxSubcategory$.next(rfxSubcategoryDto);
  }

  setUserRfxSubcategoryDto(userRfxSubcategoryDto: UserRfxSubcategoryUiDto) {
    this.userRfxSubcategoryDto$.next(userRfxSubcategoryDto);
  }

  updateApplicationNotificationDtos(notifications: ApplicationNotificationEntityDto[]) {
    let allNotifications = this._applicationNotificationsList$.value;
    allNotifications.push(...notifications);
    this._applicationNotificationsList$.next(allNotifications);
    this.currentNotificationPage = this.currentNotificationPage + 1;
  }

  updateCompNormalizeBiddersListByCompNormalizeBidder(compNormalizeBiddersDto: CompNormalizeBiddersDto) {
    // let compNormalizeBiddersList = this._applicationNotificationsList$.value;

    // let index = compNormalizeBiddersList.findIndex(item => item.subcategoryId == compNormalizeBiddersDto.subcategoryId);
    // Object.assign(compNormalizeBiddersList[index], compNormalizeBiddersDto);

    // this._applicationNotificationsList$.next(compNormalizeBiddersList);
    // this.setSelectedCompNormalizeBiddersDto(compNormalizeBiddersDto);
  }

  updateCompNormalizeItemsWrapperDto(data: CompNormalizeItemsWrapperDto) {
    this._compNormalizeItemsWrapperDto$.next(data);
  }

  setSelectedApplicationNotificationDto(data: ApplicationNotificationEntityDto) {
    this._selectedApplicationNotificationDto$.next(data);
  }

  async loadUserRfxSubcategoryDto(rfxId: string, rfxSubcategoryId: string) {
    try {
      let apiResponseDto = await firstValueFrom(this.getUserRfxSubcategories(rfxId, rfxSubcategoryId));
      if (apiResponseDto && apiResponseDto.code == AuctionExtConstant.SUCCESS_CODE) {
        let userRfxSubcategoryDto = apiResponseDto.data as UserRfxSubcategoryUiDto;
        this.setUserRfxSubcategoryDto(userRfxSubcategoryDto);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async loadRfxSubcategoryDto(rfxId: string, rfxSubcategoryId: string) {
    try {
      let apiResponseDto = await firstValueFrom(this.getRfxSubcategory(rfxId, rfxSubcategoryId));
      if (apiResponseDto && apiResponseDto.code == AuctionExtConstant.SUCCESS_CODE) {
        let rfxSubcategoryEntityDto = apiResponseDto.data as RfxSubcategoryEntityDto;
        this.setRfxSubcategoryDto(rfxSubcategoryEntityDto);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async loadApplicationNotifications(userId: string) {
    try {
      let apiResponseDto = await firstValueFrom(this.getApplicationNotifications(userId, this.currentNotificationPage, this.notificationPageSize));
      if (apiResponseDto && apiResponseDto.code == AuctionExtConstant.SUCCESS_CODE) {
        let notifications = apiResponseDto.data as ApplicationNotificationEntityDto[];
        this.updateApplicationNotificationDtos(notifications);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async getAndLoadCompNormalizeItemsWrapperDtoSync(rfxId: string, rfxSubcategoryId: string, userId: string) {
    this._compNormalizeItemsWrapperDto$.next(undefined);

    try {
      let apiResponseDto: ServerAPIResponseDto = await firstValueFrom(this.getCompNormalizeItemsWrapperDto(rfxId, rfxSubcategoryId, userId));

      if (apiResponseDto && apiResponseDto.code == AuctionExtConstant.SUCCESS_CODE) {
        if (apiResponseDto.data) {
          let data = apiResponseDto.data as CompNormalizeItemsWrapperDto;
          this.updateCompNormalizeItemsWrapperDto(data);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  removeMyFavoriteLotDto(lotId: string) {
    let allFavLots = this._myFavoriteLotsWrapperDtos$.value;
    let selectedLot = allFavLots.find(item => item.auctionLotEntityDto?.lotId == lotId);

    if (selectedLot) {
      let index = allFavLots.findIndex(item => item.auctionLotEntityDto?.lotId == lotId);
      allFavLots.splice(index, 1);
      this._myFavoriteLotsWrapperDtos$.next(allFavLots);
    }

  }

  setAllSubcategoryList(subcategoryList: Array<RfxSubcategoryEntityDto>) {
    this.subcategoryList$.next(subcategoryList);

    if (this._selectedRfx && this._selectedRfx.noOfActiveSubcategories == 1) {
      let subcategory = subcategoryList.find(item => item.rfxId == this._selectedRfx?.rfxId);
      if (subcategory) {
        this.setRfxSubcategoryDto(subcategory);
      }
    }
  }


  setAllLotsList(allLots: Array<AuctionLotEntityDto>) {
    this.allLots = allLots;
    this.allLotsList$.next(allLots);

    //update selected auction lot
    this.refreshSelectedLot(allLots);

    if (this._selectedAuction$.value && this._selectedAuction$.value?.singleLot) {
      let lot = allLots.find(item => item.auctionId == this._selectedAuction$.value?.auctionId);
      if (lot) {
        this.setSelectedAuctionLot(lot);
      }
    }
  }

  private refreshSelectedLot(allLots: AuctionLotEntityDto[]) {
    if (this._selectedAuctionLot$.value) {
      let auctionId = this._selectedAuctionLot$.value.auctionId;
      let lotId = this._selectedAuctionLot$.value.lotId;

      let selectedLot = allLots.find((item) => (item.auctionId == auctionId) && (item.lotId == lotId));
      if (selectedLot) {
        this.setSelectedAuctionLot(selectedLot!);
      }
    }
  }



  updateTimezonesData(timezones: TimeZoneDto[]) {
    this.masterTimezones = timezones;
  }

  updateCategoriesData(categories: CategoryParentWrapperDto[]) {
    this.masterCategories = categories;
  }

  // setLotForSingleLotAuction(auctionId: string) {
  //   if (this.allLots && this.allLots.length > 0) {
  //     let lots = this.allLots.filter(item => item.auctionId == auctionId);
  //     if (lots && lots.length > 0) {
  //       this.setSelectedAuctionLot(lots[0]);
  //     }
  //   }
  // }

  updateSelectedAuctionByAuctionId(selectedAuctionId: string) {
    if (this.allAuctionCardWrapperDtos && this.allAuctionCardWrapperDtos.length > 0) {
      let auctionCardWrapperDto = this.allAuctionCardWrapperDtos.find(item => item.auctionEntityDto?.auctionId == selectedAuctionId);
      this.updateSelectedAuction(auctionCardWrapperDto?.auctionEntityDto!)
    }
  }

  updateSelectedAuction(auctionEntityDto: AuctionEntityDto) {
    this._selectedAuction = auctionEntityDto;
    this._selectedAuction$.next(auctionEntityDto);
  }

  setSelectedAuctionLot(lot: AuctionLotEntityDto) {
    this._selectedAuctionLot$.next(lot);
  }

  // updateUserAuctionRegistration(userAuctionRegistration: UserAuctionRegistrationDto | null) {
  //   this._currentUserAuctionRegistration$.next(userAuctionRegistration);
  //   this.userRegistrationDataLoaded$.next(true);

  //   let oldData = this.myUserAuctionRegistrationWrappers.find(item => item.auctionId == userAuctionRegistration?.auctionId);
  //   if (oldData) {
  //     Object.assign(oldData, userAuctionRegistration)
  //   }

  //   this.checkAllBidsDataLoaded()
  // }

  updateUserAuctionRegistrations(userAuctionRegistrations: UserAuctionRegistrationDto[]) {
    // if (this._currentUserAuctionRegistration$.value) {
    //   let updatedCurrentUserAuctionRegistration = userAuctionRegistrations.find(item => item.auctionId == this._currentUserAuctionRegistration$.value?.auctionId);
    //   if (updatedCurrentUserAuctionRegistration) {
    //     this._currentUserAuctionRegistration$.next(updatedCurrentUserAuctionRegistration);
    //   }
    // }

    userAuctionRegistrations.forEach(updatedUserRegistration => {
      let oldUserAuctionRegistrationWrapper = this.myUserAuctionRegistrationWrappers.find(m => m.auctionId == updatedUserRegistration?.auctionId);
      if (oldUserAuctionRegistrationWrapper) {
        oldUserAuctionRegistrationWrapper.userAuctionRegistrationDto = updatedUserRegistration;
        let index = this.myUserAuctionRegistrationWrappers.findIndex(m => m.auctionId == updatedUserRegistration.auctionId);
        Object.assign(this.myUserAuctionRegistrationWrappers[index], oldUserAuctionRegistrationWrapper);
      } else {
        let userAuctionRegistrationWrapper = new UserAuctionRegistrationWrapperDto();
        userAuctionRegistrationWrapper.auctionId = updatedUserRegistration.auctionId;
        userAuctionRegistrationWrapper.userAuctionRegistrationDto = updatedUserRegistration;
        this.myUserAuctionRegistrationWrappers.push(userAuctionRegistrationWrapper);
      }
    })

    this._myUserAuctionRegistrationWrappers$.next(this.myUserAuctionRegistrationWrappers);

  }

  // This method needs to be called when user is getting logged in to set all the user registrations of user againt
  // auction house.
  setMyUserAuctionRegistrationWrappers(userAuctionRegistrationWrappers: UserAuctionRegistrationWrapperDto[]) {
    this.myUserAuctionRegistrationWrappers = userAuctionRegistrationWrappers;
    this._myUserAuctionRegistrationWrappers$.next(userAuctionRegistrationWrappers);

  }

  getAuctionRegistrationByAuctionId(auctionId: string): UserAuctionRegistrationWrapperDto | undefined {
    let isRegistrationExists = this.myUserAuctionRegistrationWrappers.find(m => m.auctionId == auctionId);
    if (isRegistrationExists) {
      return isRegistrationExists;
    }
    return undefined;
  }

  updateAllEventList(allEventsList: SourcingEventDto[]) {
    this.allEventsList$.next(allEventsList);
  }


  // updateHighestBidsDataMap() {
  //   this.highestBidsDataMap = [];

  //   let allBidsHighestData = this._bidsHighestDataOfAuctionHouse$.value;

  //   console.log(allBidsHighestData);

  //   allBidsHighestData.forEach((bidsHighestData) => {
  //     let _highestBidsDataMap = this.highestBidsDataMap.find((item) => item.auctionId == bidsHighestData.auctionId);

  //     if (_highestBidsDataMap) {
  //       let index = this.highestBidsDataMap.findIndex((item) => item.auctionId == bidsHighestData.auctionId);
  //       let totalBids = this.highestBidsDataMap[index].totalBids;

  //       this.highestBidsDataMap[index].totalBids = totalBids + bidsHighestData.totalBidPlaced!;
  //     } else {
  //       let newHighestBidsDataMap = { auctionId: bidsHighestData.auctionId!, totalBids: bidsHighestData.totalBidPlaced! }
  //       this.highestBidsDataMap.push(newHighestBidsDataMap);
  //     }
  //   })
  // }

  updateUserRatingDtos(ratingList: UserRatingDto[]) {
    this.userRatingDtoList$.next(ratingList);
  }

  updateAuctionQuestions(auctionQuestions: AuctionQuestionDto[]) {
    this.auctionQuestionsList$.next(auctionQuestions);
  }

  getUserAuctionRegistration(auctionId: string): UserAuctionRegistrationDto | undefined {
    let myRegistrations = this.myUserAuctionRegistrationWrappers;
    if (myRegistrations && myRegistrations.length > 0) {
      let userAuctionRegistration = myRegistrations.find(item => item.auctionId == auctionId);
      if (userAuctionRegistration) return userAuctionRegistration.userAuctionRegistrationDto;
    }
    return undefined;
  }

  async loadAuctionBasedOnSequenceNo(sequenceNo: string) {
    try {
      let apiResponseDto = await firstValueFrom(this.getAuctionBasedOnSequenceNo(sequenceNo));
      if (apiResponseDto && apiResponseDto.code == AuctionExtConstant.SUCCESS_CODE) {
        let auctionEntityDto = apiResponseDto.data as AuctionEntityDto;
        this.updateSelectedAuction(auctionEntityDto);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async loadRfxDtoByRfxId(rfxId: string) {
    try {
      let apiResponseDto = await firstValueFrom(this.getRfxBasedOnRfxId(rfxId));
      if (apiResponseDto && apiResponseDto.code == AuctionExtConstant.SUCCESS_CODE) {
        let rfxDto = apiResponseDto.data as RfxUiDto;
        this.setRfxDto(rfxDto);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async loadRfxDtoByRfxSequenceNumber(sequenceNumber: string) {
    try {
      let apiResponseDto = await firstValueFrom(this.getRfxBasedOnRfxSequenceNo(sequenceNumber));
      if (apiResponseDto && apiResponseDto.code == AuctionExtConstant.SUCCESS_CODE) {
        let rfxDto = apiResponseDto.data as RfxUiDto;
        this.setRfxDto(rfxDto);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async getAuctionByAuctionId(auctionId: string) {
    let auctionEntityDto: AuctionEntityDto | undefined = undefined;
    try {
      let apiResponseDto = await firstValueFrom(this.getAuctionBasedOnAuctionId(auctionId));
      if (apiResponseDto && apiResponseDto.code == AuctionExtConstant.SUCCESS_CODE) {
        auctionEntityDto = apiResponseDto.data as AuctionEntityDto;
      }
    } catch (error) {
      console.error(error);
    }
    return auctionEntityDto;
  }

  // This method is called when we get data from server api getAuctionCardWrapperDtos

  /**
   * Update auctioncard wrapper dto
   * @param auctionCardWrapperDtos
   */

  updateAuctionWrapperDtosChanges(auctionCardWrapperDtos: AuctionCardWrapperDto[]) {
    let allAuctionCardWrapperDtos = this.allAuctionCardWrapperDtos$.value;
    let runningAuctionCardWrapperDtos = this.runningAuctionCardWrapperDtos$.value;
    let upcomingAuctionCardWrapperDtos = this.upcomingAuctionCardWrapperDtos$.value;
    let closedAuctionCardWrapperDtos = this.closedAuctionCardWrapperDtos$.value;

    auctionCardWrapperDtos.forEach(item => {
      let isAuctionExistsInAllList = allAuctionCardWrapperDtos.find(m => m.auctionEntityDto?.auctionId == item.auctionEntityDto?.auctionId && item.auctionEntityDto!.version! >= m.auctionEntityDto!.version!);
      let isAuctionExistsInRunningList = runningAuctionCardWrapperDtos.find(m => m.auctionEntityDto?.auctionId == item.auctionEntityDto?.auctionId && item.auctionEntityDto!.version! >= m.auctionEntityDto!.version!);
      let isAuctionExistsInUpcomingList = upcomingAuctionCardWrapperDtos.find(m => m.auctionEntityDto?.auctionId == item.auctionEntityDto?.auctionId && item.auctionEntityDto!.version! >= m.auctionEntityDto!.version!);
      let isAuctionExistsInClosedList = closedAuctionCardWrapperDtos.find(m => m.auctionEntityDto?.auctionId == item.auctionEntityDto?.auctionId && item.auctionEntityDto!.version! >= m.auctionEntityDto!.version!);

      if (isAuctionExistsInAllList) {
        let index = allAuctionCardWrapperDtos.findIndex(m => m.auctionEntityDto?.auctionId == item.auctionEntityDto?.auctionId);
        if (item.auctionEntityDto?.status == AuctionStatus.DISCARD || !item.auctionEntityDto?.active) {
          allAuctionCardWrapperDtos.splice(index, 1)
        } else {
          Object.assign(allAuctionCardWrapperDtos[index], item);
        }
        this.allAuctionCardWrapperDtos$.next(allAuctionCardWrapperDtos);
      } else {
        if (item.auctionEntityDto?.status != AuctionStatus.DISCARD) {
          allAuctionCardWrapperDtos.push(item);
          this.allAuctionCardWrapperDtos$.next(allAuctionCardWrapperDtos);
        }
      }

      if (isAuctionExistsInRunningList) {
        let index = runningAuctionCardWrapperDtos.findIndex(m => m.auctionEntityDto?.auctionId == item.auctionEntityDto?.auctionId);
        if (item.auctionEntityDto?.status == AuctionStatus.DISCARD || !item.auctionEntityDto?.active) {
          runningAuctionCardWrapperDtos.splice(index, 1)
        } else if (item.auctionEntityDto?.status == AuctionStatus.CLOSE) {
          // Remove auction from running as auction has moved from running to close
          runningAuctionCardWrapperDtos.splice(index, 1)
        } else {
          Object.assign(runningAuctionCardWrapperDtos[index], item);
        }
        this.runningAuctionCardWrapperDtos$.next(runningAuctionCardWrapperDtos);
      } else {
        if (item.auctionEntityDto?.status == AuctionStatus.LIVE) {
          runningAuctionCardWrapperDtos.push(item);
          this.runningAuctionCardWrapperDtos$.next(runningAuctionCardWrapperDtos);
        }
      }

      if (isAuctionExistsInUpcomingList) {
        let index = upcomingAuctionCardWrapperDtos.findIndex(m => m.auctionEntityDto?.auctionId == item.auctionEntityDto?.auctionId);
        if (item.auctionEntityDto?.status == AuctionStatus.DISCARD || !item.auctionEntityDto?.active) {
          upcomingAuctionCardWrapperDtos.splice(index, 1)
        } else if (item.auctionEntityDto?.status == AuctionStatus.LIVE) {
          // Remove auction from upcoming as auction has moved from wait to LIVE
          upcomingAuctionCardWrapperDtos.splice(index, 1)
        } else {
          Object.assign(upcomingAuctionCardWrapperDtos[index], item);
        }
        this.upcomingAuctionCardWrapperDtos$.next(upcomingAuctionCardWrapperDtos);
      } else {
        if (item.auctionEntityDto?.status == AuctionStatus.LIVE_WAIT) {
          upcomingAuctionCardWrapperDtos.push(item);
          this.upcomingAuctionCardWrapperDtos$.next(upcomingAuctionCardWrapperDtos);
        }
      }

      if (isAuctionExistsInClosedList) {
        let index = closedAuctionCardWrapperDtos.findIndex(m => m.auctionEntityDto?.auctionId == item.auctionEntityDto?.auctionId);
        if (item.auctionEntityDto?.status == AuctionStatus.DISCARD || !item.auctionEntityDto?.active) {
          closedAuctionCardWrapperDtos.splice(index, 1)
        } else {
          Object.assign(closedAuctionCardWrapperDtos[index], item);
        }
        this.closedAuctionCardWrapperDtos$.next(closedAuctionCardWrapperDtos);
      } else {
        if (item?.auctionEntityDto?.status == AuctionStatus.CLOSE) {
          closedAuctionCardWrapperDtos.push(item);
          this.closedAuctionCardWrapperDtos$.next(closedAuctionCardWrapperDtos);
        }
      }
    })

    this.refreshSelectedAuction();
  }


  updateAuctionDtosChanges(auctionEntityDtos: AuctionEntityDto[]) {
    let allAuctionCardWrapperDtos = this.allAuctionCardWrapperDtos$.value;
    let runningAuctionCardWrapperDtos = this.runningAuctionCardWrapperDtos$.value;
    let upcomingAuctionCardWrapperDtos = this.upcomingAuctionCardWrapperDtos$.value;
    let closedAuctionCardWrapperDtos = this.closedAuctionCardWrapperDtos$.value;

    auctionEntityDtos.forEach(updatedDto => {
      let isAuctionExistsInAllList = allAuctionCardWrapperDtos.find(m => m.auctionEntityDto?.auctionId == updatedDto.auctionId && updatedDto.version! > m.auctionEntityDto!.version!);
      let isAuctionExistsInRunningList = runningAuctionCardWrapperDtos.find(m => m.auctionEntityDto?.auctionId == updatedDto.auctionId && updatedDto.version! > m.auctionEntityDto!.version!);
      let isAuctionExistsInUpcomingList = upcomingAuctionCardWrapperDtos.find(m => m.auctionEntityDto?.auctionId == updatedDto.auctionId && updatedDto.version! > m.auctionEntityDto!.version!);
      let isAuctionExistsInClosedList = closedAuctionCardWrapperDtos.find(m => m.auctionEntityDto?.auctionId == updatedDto.auctionId && updatedDto.version! > m.auctionEntityDto!.version!);

      if (isAuctionExistsInAllList) {
        let index = allAuctionCardWrapperDtos.findIndex(m => m.auctionEntityDto?.auctionId == updatedDto.auctionId);
        if (updatedDto.status == AuctionStatus.DISCARD) {
          allAuctionCardWrapperDtos.splice(index, 1)
        } else {
          Object.assign(allAuctionCardWrapperDtos[index].auctionEntityDto!, updatedDto);
        }
        this.allAuctionCardWrapperDtos$.next(allAuctionCardWrapperDtos);
      }

      if (isAuctionExistsInRunningList) {
        let index = runningAuctionCardWrapperDtos.findIndex(m => m.auctionEntityDto?.auctionId == updatedDto.auctionId);
        if (updatedDto.status == AuctionStatus.DISCARD) {
          runningAuctionCardWrapperDtos.splice(index, 1)
        } else if (updatedDto.status == AuctionStatus.CLOSE) {
          // Remove auction from running as auction has moved from running to close
          runningAuctionCardWrapperDtos.splice(index, 1)
        } else {
          Object.assign(runningAuctionCardWrapperDtos[index].auctionEntityDto!, updatedDto);
        }
        this.runningAuctionCardWrapperDtos$.next(runningAuctionCardWrapperDtos);
      }

      if (isAuctionExistsInUpcomingList) {
        let index = upcomingAuctionCardWrapperDtos.findIndex(m => m.auctionEntityDto?.auctionId == updatedDto.auctionId);
        if (updatedDto.status == AuctionStatus.DISCARD) {
          upcomingAuctionCardWrapperDtos.splice(index, 1)
        } else if (updatedDto.status == AuctionStatus.LIVE) {
          // Remove auction from upcoming as auction has moved from wait to LIVE
          upcomingAuctionCardWrapperDtos.splice(index, 1)
        } else {
          Object.assign(upcomingAuctionCardWrapperDtos[index].auctionEntityDto!, updatedDto);
        }
        this.upcomingAuctionCardWrapperDtos$.next(upcomingAuctionCardWrapperDtos);
      }

      if (isAuctionExistsInClosedList) {
        let index = closedAuctionCardWrapperDtos.findIndex(m => m.auctionEntityDto?.auctionId == updatedDto.auctionId);
        if (updatedDto.status == AuctionStatus.DISCARD) {
          closedAuctionCardWrapperDtos.splice(index, 1)
        } else {
          Object.assign(closedAuctionCardWrapperDtos[index].auctionEntityDto!, updatedDto);
        }
        this.closedAuctionCardWrapperDtos$.next(closedAuctionCardWrapperDtos);
      }
    })

    this.refreshSelectedAuction();
  }



  updateAuctionAggregateChanges(auctionAggregateDto: AuctionAggregateDto[]) {
    let allAuctionCardWrapperDtos = this.allAuctionCardWrapperDtos$.value;
    let runningAuctionCardWrapperDtos = this.runningAuctionCardWrapperDtos$.value;
    let upcomingAuctionCardWrapperDtos = this.upcomingAuctionCardWrapperDtos$.value;

    auctionAggregateDto.forEach(updatedDto => {
      let isAuctionExistsInAllList = allAuctionCardWrapperDtos.find(m => m.auctionEntityDto?.auctionId == updatedDto.auctionId);
      let isAuctionExistsInRunningList = runningAuctionCardWrapperDtos.find(m => m.auctionEntityDto?.auctionId == updatedDto.auctionId);
      let isAuctionExistsInUpcomingList = upcomingAuctionCardWrapperDtos.find(m => m.auctionEntityDto?.auctionId == updatedDto.auctionId);

      if (isAuctionExistsInAllList) {
        let index = allAuctionCardWrapperDtos.findIndex(m => m.auctionEntityDto?.auctionId == updatedDto.auctionId);
        allAuctionCardWrapperDtos[index].totalBids = updatedDto.totalNoOfBids;

        this.allAuctionCardWrapperDtos$.next(allAuctionCardWrapperDtos);
      }

      if (isAuctionExistsInRunningList) {
        let index = runningAuctionCardWrapperDtos.findIndex(m => m.auctionEntityDto?.auctionId == updatedDto.auctionId);
        runningAuctionCardWrapperDtos[index].totalBids = updatedDto.totalNoOfBids;

        this.runningAuctionCardWrapperDtos$.next(runningAuctionCardWrapperDtos);
      }

      if (isAuctionExistsInUpcomingList) {
        let index = upcomingAuctionCardWrapperDtos.findIndex(m => m.auctionEntityDto?.auctionId == updatedDto.auctionId);
        upcomingAuctionCardWrapperDtos[index].totalBids = updatedDto.totalNoOfBids;

        this.upcomingAuctionCardWrapperDtos$.next(upcomingAuctionCardWrapperDtos);
      }
    })

    this.refreshSelectedAuction();
  }


  /**
   *
   * @param auctionId Update total bids in local auctionwrapper dto
   * @param totalBids
   */
  updateTotalBids(auctionId: String, totalBids: number) {
    let allAuctionCardWrapperDtos = this.allAuctionCardWrapperDtos$.value;
    let runningAuctionCardWrapperDtos = this.runningAuctionCardWrapperDtos$.value;
    let closedAuctionCardWrapperDtos = this.closedAuctionCardWrapperDtos$.value;


    let isAuctionExistsInAllList = allAuctionCardWrapperDtos.find(m => m.auctionEntityDto?.auctionId == auctionId);
    let isAuctionExistsInRunningList = runningAuctionCardWrapperDtos.find(m => m.auctionEntityDto?.auctionId == auctionId);
    let isAuctionExistsInClosedList = closedAuctionCardWrapperDtos.find(m => m.auctionEntityDto?.auctionId == auctionId);

    if (isAuctionExistsInAllList) {
      let index = allAuctionCardWrapperDtos.findIndex(m => m.auctionEntityDto?.auctionId == auctionId);
      if (allAuctionCardWrapperDtos[index].totalBids != totalBids) {
        allAuctionCardWrapperDtos[index].totalBids = totalBids;
        this.allAuctionCardWrapperDtos$.next(allAuctionCardWrapperDtos);
      }
    }

    if (isAuctionExistsInRunningList) {
      let index = runningAuctionCardWrapperDtos.findIndex(m => m.auctionEntityDto?.auctionId == auctionId);
      if (runningAuctionCardWrapperDtos[index].totalBids != totalBids) {
        runningAuctionCardWrapperDtos[index].totalBids = totalBids;
        this.runningAuctionCardWrapperDtos$.next(runningAuctionCardWrapperDtos)
      }
    }

    if (isAuctionExistsInClosedList) {
      let index = closedAuctionCardWrapperDtos.findIndex(m => m.auctionEntityDto?.auctionId == auctionId);
      if (closedAuctionCardWrapperDtos[index].totalBids != totalBids) {
        closedAuctionCardWrapperDtos[index].totalBids = totalBids;
        this.closedAuctionCardWrapperDtos$.next(closedAuctionCardWrapperDtos);
      }
    }
  }



  /**
   *
   * @param auctionLotEntityDtos Update Lot changes in local variables
   */
  updateAuctionLotEntityDtosChanges(auctionLotEntityDtos: AuctionLotEntityDto[]) {
    let allAuctionLots = this.allLotsList$.value;

    auctionLotEntityDtos.forEach(updatedLot => {
      let isLotExistsInAllLots = allAuctionLots.find(item => item.lotId == updatedLot.lotId);
      if (isLotExistsInAllLots) {
        let index = allAuctionLots.findIndex(item => item.lotId == updatedLot.lotId);
        Object.assign(allAuctionLots[index], updatedLot);
      }
    })

    this.allLotsList$.next(allAuctionLots);
  }

  // This method is called when we get data from server api getAuctionCardWrapperDtosInitial & getAuctionCardWrapperDtosRemaining
  setApplicationStartupAuctionWrapperDtos(auctionDataCardsWrapperDto: AuctionDataCardsWrapperDto) {
    this.allAuctionCardWrapperDtos = [];
    this._allAuctionsDataLoaded$.next(false);

    // Upcoming Auction Wrappers
    let upcomingAuctionWrapperDtos = auctionDataCardsWrapperDto.upcomingAuctionWrapperDtos ?? [];
    if (upcomingAuctionWrapperDtos && upcomingAuctionWrapperDtos.length > 0) {
      upcomingAuctionWrapperDtos.sort(
        (a, b) =>
          AuctionExtUtil.getRemainingTime(
            a.auctionEntityDto?.endDate!,
            a.auctionEntityDto?.timeZone!
          ) -
          AuctionExtUtil.getRemainingTime(
            b.auctionEntityDto?.endDate!,
            b.auctionEntityDto?.timeZone!
          )
      );
      this.upcomingAuctionCardWrapperDtos$.next(upcomingAuctionWrapperDtos);
    } else {
      this.upcomingAuctionCardWrapperDtos$.next([]);
    }

    // Running Auction Wrappers
    let liveAuctionWrapperDtos = auctionDataCardsWrapperDto.liveAuctionWrapperDtos ?? [];
    if (liveAuctionWrapperDtos && liveAuctionWrapperDtos.length > 0) {
      liveAuctionWrapperDtos.sort(
        (a, b) =>
          AuctionExtUtil.getRemainingTime(
            a.auctionEntityDto?.endDate!,
            a.auctionEntityDto?.timeZone!
          ) -
          AuctionExtUtil.getRemainingTime(
            b.auctionEntityDto?.endDate!,
            b.auctionEntityDto?.timeZone!
          )
      );
      this.runningAuctionCardWrapperDtos$.next(liveAuctionWrapperDtos)
    } else {
      this.runningAuctionCardWrapperDtos$.next([])
    }

    // Close Auction Wrappers
    let closeAuctionWrapperDtos = auctionDataCardsWrapperDto.closeAuctionWrapperDtos ?? [];
    if (closeAuctionWrapperDtos && closeAuctionWrapperDtos.length > 0) {
      closeAuctionWrapperDtos.sort(
        (a, b) =>
          AuctionExtUtil.getRemainingTime(
            a.auctionEntityDto?.endDate!,
            a.auctionEntityDto?.timeZone!
          ) -
          AuctionExtUtil.getRemainingTime(
            b.auctionEntityDto?.endDate!,
            b.auctionEntityDto?.timeZone!
          )
      );
      this.closedAuctionCardWrapperDtos$.next(closeAuctionWrapperDtos);
    } else {
      this.closedAuctionCardWrapperDtos$.next([]);
    }

    // All Auction Wrappers
    let allAuctionWrapperDtos = [...upcomingAuctionWrapperDtos, ...liveAuctionWrapperDtos, ...closeAuctionWrapperDtos];
    if (allAuctionWrapperDtos && allAuctionWrapperDtos.length > 0) {
      allAuctionWrapperDtos.sort(
        (a, b) =>
          AuctionExtUtil.getRemainingTime(
            a.auctionEntityDto?.endDate!,
            a.auctionEntityDto?.timeZone!
          ) -
          AuctionExtUtil.getRemainingTime(
            b.auctionEntityDto?.endDate!,
            b.auctionEntityDto?.timeZone!
          )
      );

      this.allAuctionCardWrapperDtos = allAuctionWrapperDtos;
      this.allAuctionCardWrapperDtos$.next(allAuctionWrapperDtos);
    } else {
      this.allAuctionCardWrapperDtos$.next([]);
    }

    // Get All Auctions
    this.refreshSelectedAuction();
    this._allAuctionsDataLoaded$.next(true);

  }

  private refreshSelectedAuction() {
    if (this._selectedAuction$.value && this.allAuctionCardWrapperDtos && this.allAuctionCardWrapperDtos.length > 0) {
      let auctionId = this._selectedAuction$.value?.auctionId;
      let auctionCardWrapperDto = this.allAuctionCardWrapperDtos.find(item => item.auctionEntityDto?.auctionId == auctionId);
      if (auctionCardWrapperDto) {
        this.updateSelectedAuction(auctionCardWrapperDto.auctionEntityDto!);
      }
    }
  }

  updateSimilarAuctionsList(auctions: AuctionEntityDto[]) {
    this.similarAuctionsList$.next(auctions);
  }

  getAuctionCardWrapperDtoByAuctionId(auctionId: string) {
    return this.allAuctionCardWrapperDtos?.find(item => item.auctionEntityDto?.auctionId == auctionId);
  }

  getAuctionBasedOnAuctionSequence(sequenceNo: number) {
    return this.allAuctionCardWrapperDtos?.find(item => item.auctionEntityDto!.sequenceNo == sequenceNo);
  }

  getAllLots() {
    return this.allLots;
  }

  getLotByLotId(lotId: string) {
    return this.allLots?.find(item => item.lotId == lotId);
  }

  getLotByLotSequence(lotSequence: number) {
    return this.allLots?.find(item => item.lotSequence == lotSequence);
  }

  loadOpenEvent() {
    this.getOpenEventApi().subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        let sourcingEventCount = apiResponseDto.data as string;
        this._openEventCount$.next(Number(sourcingEventCount));
      },
      error: () => {
        console.log("error while getting sourcing events")
      }
    })
  }

  getSimilarAuctions(companyId: string) {
    let allAuctionCardWrapperDtos = this.allAuctionCardWrapperDtos$.value;
    let similarAuctionCardWrapperDtos = allAuctionCardWrapperDtos.filter(item => item.auctionEntityDto?.companyId == companyId &&
      (item.auctionEntityDto.status == "LIVE" || item.auctionEntityDto?.status == "LIVE_WAIT"));
    let similarAuctions = similarAuctionCardWrapperDtos.map(item => item.auctionEntityDto!);
    similarAuctions.sort(
      (a, b) =>
        AuctionExtUtil.getRemainingTime(
          b.endDate! + ' ' + b.endTime!,
          b.timeZone!
        ) -
        AuctionExtUtil.getRemainingTime(
          a.endDate! + ' ' + a.endTime!,
          a.timeZone!
        )
    );
    this.updateSimilarAuctionsList(similarAuctions.slice(0, 50) ?? []);
  }

  async bidderTenderSourcingEvent(searchWrapper: TenderSearchWrapperDto) {
    
    try {
      let apiResponseDto: ServerAPIResponseDto = await firstValueFrom(this.fetchBidderTenderUserProjects(searchWrapper));
      if (apiResponseDto && apiResponseDto.code == AuctionExtConstant.SUCCESS_CODE) {
        if (apiResponseDto.data) {
          let userProjectDtos = apiResponseDto.data as BidderTenderSourcingEventWrapperDto;
          this._userProjects$.next(userProjectDtos);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

// Archive
  // async bidderTenderSourcingEvent() {
  //   let userProjectDtos: BidderTenderSourcingEventDto[] = [];
  //   try {
  //     let apiResponseDto: ServerAPIResponseDto = await firstValueFrom(this.fetchUserProjects());
  //     if (apiResponseDto && apiResponseDto.code == AuctionExtConstant.SUCCESS_CODE) {
  //       if (apiResponseDto.data) {
  //         userProjectDtos = apiResponseDto.data as UserProjectDto[];
  //         this._userProjects$.next(userProjectDtos);
  //       }
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }


  async loadPastUserProjects() {
    let userProjectDtos: UserProjectDto[] = [];
    try {
      let apiResponseDto: ServerAPIResponseDto = await firstValueFrom(this.fetchPastUserProjects());
      if (apiResponseDto && apiResponseDto.code == AuctionExtConstant.SUCCESS_CODE) {
        if (apiResponseDto.data) {
          userProjectDtos = apiResponseDto.data as UserProjectDto[];
          this._pastUserProjects$.next(userProjectDtos);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  // API CALLS
  getAuctionBasedOnSequenceNo(sequenceNo: string) {
    let params = { sequenceNo: sequenceNo };
    return this.httpClient.get<ServerAPIResponseDto>('auctionByIndex', { params }).pipe(
      tap(_ => console.log("Get auctionByIndex")),
      catchError(this.errorService.handleError<any>("Error while getting auctionByIndex")));
  }

  getAuctionBasedOnAuctionId(auctionId: string) {
    let params = { auctionId: auctionId };
    return this.httpClient.get<ServerAPIResponseDto>('auctionByAuctionId', { params }).pipe(
      tap(_ => console.log("Get auctionByIndex")),
      catchError(this.errorService.handleError<any>("Error while getting auctionByIndex")));
  }

  getOpenEventApi() {
    return this.httpClient.get<ServerAPIResponseDto>('countSourcingEventsForLanding').pipe(
      tap(_ => console.log("Get countSourcingEventsForLanding")),
      catchError(this.errorService.handleError<any>("Error while getting countSourcingEventsForLanding")));
  }

  saveUserAuctionRegistration(userAuctionRegistration: UserAuctionRegistrationDto) {
    return this.httpClient.post<ServerAPIResponseDto>('userAuctionRegistrations', userAuctionRegistration).pipe(
      tap(_ => console.log("Save user auction registration" + userAuctionRegistration.userId)),
      catchError(this.errorService.handleError<any>("Error while user auction registration" + userAuctionRegistration.userId)));
  }

  saveUserRfxSubcategory(userRfxSubcategory: UserRfxSubcategoryUiDto) {
    return this.httpClient.post<ServerAPIResponseDto>('userRfxSubcategory', userRfxSubcategory).pipe(
      tap(_ => console.log("Save user auction registration" + userRfxSubcategory.companyId)),
      catchError(this.errorService.handleError<any>("Error while user auction registration" + userRfxSubcategory.companyId)));
  }

  getUserAuctionRegistrationData(auctionId: string, userId: string) {
    let params = { auctionId: auctionId, userId: userId };
    return this.httpClient.get<ServerAPIResponseDto>('userAuctionRegistration', { params: params }).pipe(
      tap(_ => console.log("get user auction registration" + userId)),
      catchError(this.errorService.handleError<any>("Error while getting user auction registration" + userId)));
  }

  emailUnsubscribe(emailId: string) {
    let params = { emailId: emailId };
    return this.httpClient.get<ServerAPIResponseDto>('emails/unsubscribe', { params: params }).pipe(
      tap(_ => console.log("unsubscribe email" + emailId)),
      catchError(this.errorService.handleError<any>("Error while unsubscribe email" + emailId)));
  }

  doBids(auctionBidsDto: AuctionBidsDto) {
    return this.httpClient.post<ServerAPIResponseDto>('doBids', auctionBidsDto).pipe(
      tap(_ => console.log("Bids Placed" + auctionBidsDto.bidPrice)),
      catchError(this.errorService.handleError<any>("Error while Bids Placed" + auctionBidsDto.bidPrice)));
  }

  doMaxBids(auctionBidsDto: AuctionBidsDto) {
    return this.httpClient.post<ServerAPIResponseDto>('managed-bucketSize', auctionBidsDto).pipe(
      tap(_ => console.log("Proxy Bids Placed" + auctionBidsDto.bidPrice)),
      catchError(this.errorService.handleError<any>("Error while Proxy Bids Placed" + auctionBidsDto.bidPrice)));
  }

  makeLeadingBidder(auctionBidsDto: AuctionBidsDto) {
    return this.httpClient.post<ServerAPIResponseDto>('makeLeadingBidder', auctionBidsDto).pipe(
      tap(_ => console.log("Bids Placed" + auctionBidsDto.bidPrice)),
      catchError(this.errorService.handleError<any>("Error while Bids Placed" + auctionBidsDto.bidPrice)));
  }


  markLotFavourite(userLotFavouriteDto: UserLotFavoriteDto) {
    return this.httpClient.post<ServerAPIResponseDto>('markLotFavourite', userLotFavouriteDto).pipe(
      tap(_ => console.log("Mark Lot Favourite" + userLotFavouriteDto.lotId)),
      catchError(this.errorService.handleError<any>("Error while Mark Lot Favourite" + userLotFavouriteDto.lotId)));
  }


  discardLotFavourite(userLotFavouriteDto: UserLotFavoriteDto) {
    return this.httpClient.post<ServerAPIResponseDto>('discardLotFavourite', userLotFavouriteDto).pipe(
      tap(_ => console.log("Mark discardLotFavourite" + userLotFavouriteDto.lotId)),
      catchError(this.errorService.handleError<any>("Error while discardLotFavourite Favourite" + userLotFavouriteDto.lotId)));
  }

  getUserBidsHistoryData(auctionId: string, lotId: string, userId: string) {
    let params = { auctionId: auctionId, lotId: lotId, userId: userId };
    return this.httpClient.get<ServerAPIResponseDto>('userBidsHistoryData', { params: params }).pipe(
      tap(_ => console.log("Get Bid History Data" + lotId)),
      catchError(this.errorService.handleError<any>("Error while Getting Bid History Data" + lotId)));
  }

  getAuctionBidHistoryInfo(auctionId: string, lotId: string, user: any): Observable<any> {
    let params = { auctionId: auctionId, lotId: lotId };
    let apiName = user ? "auctionBidHistoryInfoSupplier" : "auctionBidHistoryInfoLanding";

    return this.httpClient.get<ServerAPIResponseDto>(apiName, { params: params }).pipe(
      tap(_ => console.log("Auction Bids History : " + lotId)),
      catchError(this.errorService.handleError("Error while getting auction bids history data : " + lotId)))
  }



  auctionHouseChartData(): Observable<any> {
    return this.httpClient.get<ServerAPIResponseDto>('auctionHouseChartData').pipe(
      tap(_ => console.log("Auction House Chart Data")),
      catchError(this.errorService.handleError("Error while getting auction house chart data")))
  }


  saveLotActivity(auctionHouseId: string, auctionId: string, lotId: string, type: string,): Observable<any> {
    let params = new HttpParams().set('auctionHouseId', auctionHouseId).set('auctionId', auctionId)
      .set('lotId', lotId).set('type', type);

    return this.httpClient.get<ServerAPIResponseDto>('lotActivity', { params }).pipe(
      tap(_ => console.log("save lot activity")),
      catchError(this.errorService.handleError("Error while save lot activity")))
  }


  getGeneratedInvoiceList(auctionHouseId: string, userId: string,): Observable<any> {
    let params = new HttpParams().set('auctionHouseId', auctionHouseId).set('userId', userId);
    return this.httpClient.get<ServerAPIResponseDto>('supplierInvoiceList', { params }).pipe(
      tap(_ => console.log("Getting invoiceList  Successfully for auctionHouseId : " + auctionHouseId)),
      catchError(this.errorService.handleError("Error while getting invoiceList bidders : " + auctionHouseId)))
  }

  getAuctionSearchData(auctionHouseId: string, statusList: string[]) {
    let params = new HttpParams().set('auctionHouseId', auctionHouseId).set('statusList', JSON.stringify(statusList));
    return this.httpClient.get<ServerAPIResponseDto>('auctionSearchData', { params }).pipe(
      tap(_ => console.log("Search data fetched successfully for auctionHouseId: " + auctionHouseId)),
      catchError(this.errorService.handleError<any>("Error while fetching search data for auctionHouseId: " + auctionHouseId)));
  }

  //TODO rename API call
  fetchSourcingEvents() {
    return this.httpClient.get<ServerAPIResponseDto>('sourcingEventsForLanding').pipe(
      tap(_ => console.log("Get sourcingEventsForLanding")),
      catchError(this.errorService.handleError<any>("Error while getting sourcingEventsForLanding")));
  }

  fetchBidderTenderUserProjects(tenderSearchWrapperDto: TenderSearchWrapperDto) {
    return this.httpClient.post<ServerAPIResponseDto>('tenderSourcingEventDto',tenderSearchWrapperDto).pipe(
      tap(_ => console.log("Get userProjects")),
      catchError(this.errorService.handleError<any>("Error while getting userProjects")));
  }

  fetchPastUserProjects() {
    return this.httpClient.get<ServerAPIResponseDto>('pastUserProjects').pipe(
      tap(_ => console.log("Get pastUserProjects")),
      catchError(this.errorService.handleError<any>("Error while getting pastUserProjects")));
  }

  getRfxBasedOnRfxId(rfxId: string) {
    let params = { rfxId: rfxId };
    return this.httpClient.get<ServerAPIResponseDto>('rfxByRfxId', { params }).pipe(
      tap(_ => console.log("Get getRfxBasedOnRfxId")),
      catchError(this.errorService.handleError<any>("Error while getting getRfxBasedOnRfxId")));
  }

  getRfxBasedOnRfxSequenceNo(sequenceNo: string) {
    let params = { sequenceNo: sequenceNo };
    return this.httpClient.get<ServerAPIResponseDto>('rfxByRfxSequenceNo', { params }).pipe(
      tap(_ => console.log("Get getRfxBasedOnRfxId")),
      catchError(this.errorService.handleError<any>("Error while getting getRfxBasedOnRfxId")));
  }

  getRfxSubcategories(rfxId: string) {
    let params = { rfxId: rfxId };
    return this.httpClient.get<ServerAPIResponseDto>('subcategoriesByRfxId', { params }).pipe(
      tap(_ => console.log("Get subcategoriesByRfxId")),
      catchError(this.errorService.handleError<any>("Error while getting auctionByIndex")));
  }

  getRfxSubcategory(rfxId: string, rfxSubcategoryId: string) {
    let params = new HttpParams().set('rfxId', rfxId).set('rfxSubcategoryId', rfxSubcategoryId);
    return this.httpClient.get<ServerAPIResponseDto>('subcategoryByRfxIdAndRfxSubcategoryId', { params }).pipe(
      tap(_ => console.log("Get subcategoryByRfxIdAndRfxSubcategoryId")),
      catchError(this.errorService.handleError<any>("Error while getting subcategoryByRfxIdAndRfxSubcategoryId")));
  }


  getUserRfxSubcategories(rfxId: string, rfxSubcategoryId: string) {
    let params = new HttpParams().set('rfxId', rfxId).set('rfxSubcategoryId', rfxSubcategoryId);
    return this.httpClient.get<ServerAPIResponseDto>('userRfxSubcategories', { params }).pipe(
      tap(_ => console.log("Get userRfxSubcategories")),
      catchError(this.errorService.handleError<any>("Error while getting userRfxSubcategories")));
  }

  getApplicationNotifications(userId: string, page: number, pageSize: number) {
    let params = new HttpParams().set('userId', userId).set('page', page).set('pageSize', pageSize);
    return this.httpClient.get<ServerAPIResponseDto>('applicationNotifications', { params }).pipe(
      tap(_ => console.log("Get applicationNotifications")),
      catchError(this.errorService.handleError<any>("Error while getting applicationNotifications")));
  }

  seenApplicationNotifications(userId: string, ids: string[]) {
    let idsInString = JSON.stringify(ids).replaceAll('[', '').replaceAll(']', '').replaceAll(' ', '');
    let params = new HttpParams().set('userId', userId).set('ids', idsInString);
    return this.httpClient.post<ServerAPIResponseDto>('applicationNotificationsSeen', null, { params }).pipe(
      tap(_ => console.log("Get applicationNotificationsSeen")),
      catchError(this.errorService.handleError<any>("Error while getting compNormalizeBiddersList")));
  }

  getCompNormalizeItemsWrapperDto(rfxId: string, subcategoryId: string, companyId: string) {
    let params = new HttpParams().set('rfxId', rfxId).set('subcategoryId', subcategoryId).set('companyId', companyId);
    return this.httpClient.get<ServerAPIResponseDto>('compNormalizeItems', { params }).pipe(
      tap(_ => console.log("compNormalizeItems to DB" + subcategoryId)),
      catchError(this.errorService.handleError<any>("Error while compNormalizeItems to DB" + subcategoryId)))
  }

  manageNormaliseRequest(rfxId: string, rfxSubcategoryId: string, companyId: string, status: string) {
    let params = new HttpParams().set('rfxId', rfxId).set('rfxSubcategoryId', rfxSubcategoryId).set('companyId', companyId).set('status', status);
    return this.httpClient.post<ServerAPIResponseDto>('manageNormalizeRequests', null, { params }).pipe(
      tap(_ => console.log("Get manageNormalizeRequests")),
      catchError(this.errorService.handleError<any>("Error while getting manageNormalizeRequests")));
  }

  fetchTeamMembers() {
    return this.httpClient.get<ServerAPIResponseDto>('fetchTeamMembers').pipe(
      tap(_ => console.log("fetchTeamMembers")),
      catchError(this.errorService.handleError<any>("Error while fetchTeamMembers")))
  }

  saveTeamMembers(registrationUserModalDto: RegistrationUserModalDto) {
    return this.httpClient.post<ServerAPIResponseDto>('teamMembers', registrationUserModalDto).pipe(
      tap(_ => console.log("Get teamMembers")),
      catchError(this.errorService.handleError<any>("Error while getting teamMembers")));
  }

  markResourceActiveOrInActive(userId: string, action: string) {
    let params = new HttpParams().set('userId', userId).set('action', action);
    return this.httpClient.post<ServerAPIResponseDto>('enableAndDisableTeamMembers', null, { params }).pipe(
      tap(_ => console.log("User active status change" + userId)),
      catchError(this.errorService.handleError<any>("Error while changing user active status" + userId)))
  }

  getPurchaseOrders() {
    return this.httpClient.get<ServerAPIResponseDto>('purchaseOrders').pipe(
      tap(_ => console.log("get purchaseOrders")),
      catchError(this.errorService.handleError<any>("Error while getting purchaseOrders"))
    );
  }

  createNewInvoice(rfxPurchaseInvoice: RfxPurchaseInvoiceDto) {
    return this.httpClient.post<ServerAPIResponseDto>('purchaseInvoices', rfxPurchaseInvoice).pipe(
      tap(_ => console.log("Saving rfxPurchaseInvoice in DB " + rfxPurchaseInvoice.purchaseOrderId)),
      catchError(this.errorService.handleError<any>("Error while creating rfxPurchaseInvoice: " + rfxPurchaseInvoice.purchaseOrderId)))
  }

  getPurchaseInvoices() {
    return this.httpClient.get<ServerAPIResponseDto>('purchaseInvoices').pipe(
      tap(_ => console.log("get purchaseInvoices")),
      catchError(this.errorService.handleError<any>("Error while getting purchaseInvoices"))
    );
  }
}
