import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-user-dsc-document',
  templateUrl: './user-dsc-document.component.html',
  styleUrls: ['./user-dsc-document.component.sass']
})
export class UserDscDocumentComponent {
  @Output() onPrevious: EventEmitter<void> = new EventEmitter();
  @Output() onFinishProcess = new EventEmitter<void>();

  prevStep() {
    this.onPrevious.emit()
  }

  finishRegistration() {
    this.onFinishProcess.emit()
  }
  
}
