import { AfterViewInit, Component, Input, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LandingAndBidderService } from '../../services/LandingAndBidder.service';
import { CategoryDto } from '../../models/CategoryDto';
import { UserService } from '../../services/user.service';
import { ServerAPIResponseDto } from '../../models/ServerAPIResponseDto';
import { BidderUiDto } from '../../models/UserEntityDto';
import { AuctionExtConstant } from '../../util/AuctionExtConstant';
import { BehaviorSubject } from 'rxjs';
import { UserEntityInfoDto } from '../../models/user/UserEntityInfoDto';
import { GlobalBidderEntityDto } from '../../models/GlobalBidderEntityDto';
import { TreeSelectionMode } from '../../enums/TreeSelectionMode';

@Component({
  selector: 'app-user-category-selection',
  templateUrl: './user-category-selection.component.html',
  styleUrls: ['./user-category-selection.component.sass']
})
export class UserCategorySelectionComponent implements OnInit, AfterViewInit {
  @Input() userId?: string;
  @Input() userDto!: BidderUiDto | UserEntityInfoDto | GlobalBidderEntityDto;
  @Input() isGlobalBidder: boolean = false;
  TreeSelectionMode: typeof TreeSelectionMode = TreeSelectionMode;

  errorMsg: string | undefined;
  isLoading: boolean = false;
  loadingData: boolean = false;

  _showSuccessToast$ = new BehaviorSubject<boolean>(false);
  _showErrorToast$ = new BehaviorSubject<boolean>(false);

  constructor(
    private activeModal: NgbActiveModal,
    private landingAndBidderService: LandingAndBidderService,
    private userService: UserService
  ) { }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
  }

  onFilterChange(event: any) {
    console.log(event);
    // let tempMasterList = AuctionExtUtil.clone(this.masterCategories) as CategoryDto[];
    // let filterCategoryList = tempMasterList.filter(item => item.category!.trim().toLowerCase().includes(event.trim().toLowerCase()) ||
    // item.subcategory!.trim().toLowerCase().includes(event.trim().toLowerCase()) ||
    // item.subcategoryLevelOne!.trim().toLowerCase().includes(event.trim().toLowerCase())).map(item => item);

    // if (event.trim() != '') {
    //   this.treeItemsCategories = this.buildFilterCategoriesTree(filterCategoryList);
    // } else {
    //   this.treeItemsCategories = this.buildCategoriesTree()
    // }


    // this.ngxTreeview?.onFilterTextChange(event);
  }
}
