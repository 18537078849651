import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AuctionEntityDto } from '../../models/user/AuctionEntityDto';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { CalendarService } from '../../services/calendar.service';
import { Subscription } from 'rxjs';
import { AdminDashboardService } from '../../services/admin-dashboard.service';
import { CountryCodeDto } from '../../models/CountryCodeDto';
import { LandingAndBidderService } from '../../services/LandingAndBidder.service';
import { AuctionExtUtil } from '../../util/AuctionExtUtil';
import { AuctionLotEntityDto } from '../../models/user/AuctionLotEntityDto';
import { FirestoreListenerService } from '../../services/firestore-listener.service';
import { DataRedirectionService } from '../../services/data-redirection.service';
import { OrganizationUiDto } from '../../models/user/AuctionHouseDto';
import { DatePipe } from '@angular/common';
import { UserService } from '../../services/user.service';
import { UserPrivilege } from '../../enums/UserPrivilege';
import { AuctionExtConstant } from '../../util/AuctionExtConstant';
import { AuctionCardWrapperDto } from '../../models/AuctionCardWrapperDto';
import { SourcingCalenderEventDto } from '../../models/user/SourcingCalenderEventDto';

@Component({
  selector: 'app-auction-calendar-details',
  templateUrl: './auction-calendar-details.component.html',
  styleUrls: ['./auction-calendar-details.component.sass']
})
export class AuctionCalendarDetailsComponent implements OnInit, AfterViewInit, OnDestroy{

  public screenWidth: any;
  @Input() selectedCalenderEvent?: SourcingCalenderEventDto | null;


  selectedCalenderEventSubscription$?: Subscription;
  auctionHouse?: OrganizationUiDto;
  currentAuctionHouseSubscription$?: Subscription;

  countdownTimerDays = 0;
  countdownTimerHours = 0;
  countdownTimerMinutes = 0;
  countdownTimerSeconds = 0;
  counterFunction: any;
  timer: boolean = false;
  //nextClosingLot?: AuctionLotEntityDto;

  _renderView$ = new BehaviorSubject<boolean>(false);

  onWindowResize() {
    this.screenWidth = window.innerWidth;
  }

  constructor(private userService: UserService, private datePipe: DatePipe, private calendarService: CalendarService,  private firestoreService: FirestoreListenerService,
    private bidderService: LandingAndBidderService, private dataRedirectionService: DataRedirectionService){

  }

  ngOnInit(): void {

  this.currentAuctionHouseSubscription$ = this.bidderService.getCurrentAuctionHouse$.subscribe((data) => {
    if (data) {
      this.auctionHouse = data;
    }
  })
  }


  convertDate(date : string, time: string){
    let convertedDate = AuctionExtUtil.convertedDate(date, time);
    return this.datePipe.transform(convertedDate, 'dd MMM');

  }

  navigateAuctionView() {

      this.dataRedirectionService.storeNavigationContext(AuctionExtConstant.ANY_CONTEXT, AuctionExtConstant.AUCTION, AuctionExtConstant.ANY_PAGE, '', this.selectedCalenderEvent?.sequenceNo!+"", undefined)
      this.dataRedirectionService.navigateToPage();

  }




  ngAfterViewInit(): void {
    this.screenWidth = window.innerWidth;
    this.populateData()
  }

  private populateData() {
    // if (!this.selectedAuction?.singleLot) {
    //   this.firestoreService.queryNextClosingLotOfAuction(this.selectedAuction?.auctionId!)
    //     .then((docs) => {
    //       if (docs && docs.length > 0) {
    //         this.nextClosingLot = AuctionLotEntityDto.parseAuctionLotEntityDto(docs[0]);
    //         this.clearCountdown();
    //         this.countdown(this.selectedAuction!);
    //         Promise.resolve().then(() => this._renderView$.next(true));
    //       }
    //     })
    //     .catch((err) => {
    //       console.error(err);
    //     });
    // } else {
    //   this.clearCountdown();
    //   this.countdown(this.selectedAuction!);
    //   Promise.resolve().then(() => this._renderView$.next(true));
    // }

    this.clearCountdown();
    this.countdown(this.selectedCalenderEvent!);
    Promise.resolve().then(() => this._renderView$.next(true));
  }

  getCountryCode(countryCodeDto?: CountryCodeDto) {
    if (countryCodeDto) {
      return countryCodeDto.countryCode?.replace('(', '').replace(')', '');
    }
    return '+1';
  }

  clearCountdown() {
    if (this.timer == true) {
      console.log("For Auction :" + this.selectedCalenderEvent?.eventName + " Remaining time clear");
      clearInterval(this.counterFunction);
      this.timer = false;
    }
  }

  countdown(auctionEntityDto: SourcingCalenderEventDto) {
    let _this = this;
    let timeZone = auctionEntityDto.timeZone!;

    // let selectedAuctionOrLot = auctionEntityDto?.singleLot ? auctionEntityDto : this.nextClosingLot;
    // let date = auctionEntityDto?.status == 'LIVE' ? selectedAuctionOrLot?.endDate : auctionEntityDto?.startDate;
    // let time = auctionEntityDto?.status == 'LIVE' ? selectedAuctionOrLot?.endTime : auctionEntityDto?.startTime;
    // let remainingDurations = AuctionExtUtil.getRemainingTime(date +" "+ time, timeZone!);

    let date: string ;
    let time: string ;

    if (auctionEntityDto?.singleLot || auctionEntityDto.noOfActiveLots == 1) {
      date = auctionEntityDto?.status == 'LIVE' ? auctionEntityDto?.endDate! : auctionEntityDto?.startDate!;
      time = auctionEntityDto?.status == 'LIVE' ? auctionEntityDto?.endTime! : auctionEntityDto?.startTime!;
    } else {
      let auctionCardWrapperDto =  this.bidderService.getAuctionCardWrapperDtoByAuctionId(auctionEntityDto.eventId!);
      date = auctionEntityDto?.status == 'LIVE' ? auctionCardWrapperDto?.nextLotEndDate! : auctionEntityDto?.startDate!;
      time = auctionEntityDto?.status == 'LIVE' ? auctionCardWrapperDto?.nextLotEndTime! : auctionEntityDto?.startTime!;
    }
    let remainingDurations = AuctionExtUtil.getRemainingTime(date + " " + time, timeZone!);


    if (this.timer == false) {
      console.log("Creating a new countdown function in supplier small card component - " + auctionEntityDto?.eventName);

      this.timer = true;
      this.counterFunction = setInterval(function () {

        let element = document.getElementById("days" + _this.counterFunction + auctionEntityDto.eventId);
        if (element) {
          let remainingDays = AuctionExtUtil.getCountdownTimerDays(date + " " + time, timeZone);
          let remainingHours = AuctionExtUtil.getCountdownTimerHours(date + " " + time, timeZone);
          let remainingMinutes = AuctionExtUtil.getCountdownTimerMinutes(date + " " + time, timeZone);
          let remainingSeconds = AuctionExtUtil.getCountdownTimerSeconds(date + " " + time, timeZone);

          if (remainingDays) {
            document.getElementById("days" + _this.counterFunction + auctionEntityDto.eventId)!.hidden = false;
            document.getElementById("hours" + _this.counterFunction + auctionEntityDto.eventId)!.hidden = false;
            document.getElementById("minutes" + _this.counterFunction + auctionEntityDto.eventId)!.hidden = true;
            document.getElementById("second" + _this.counterFunction + auctionEntityDto.eventId)!.hidden = true;

            document.getElementById("days" + _this.counterFunction + auctionEntityDto.eventId)!.innerText = remainingDays + 'd ';
            document.getElementById("hours" + _this.counterFunction + auctionEntityDto.eventId)!.innerText = remainingHours + 'h';
          } else {
            document.getElementById("days" + _this.counterFunction + auctionEntityDto.eventId)!.hidden = true;
            document.getElementById("hours" + _this.counterFunction + auctionEntityDto.eventId)!.hidden = false;
            document.getElementById("minutes" + _this.counterFunction + auctionEntityDto.eventId)!.hidden = false;
            document.getElementById("second" + _this.counterFunction + auctionEntityDto.eventId)!.hidden = false;

            document.getElementById("hours" + _this.counterFunction + auctionEntityDto.eventId)!.innerText = ('0' + remainingHours).slice(-2) + ' : ';
            document.getElementById("minutes" + _this.counterFunction + auctionEntityDto.eventId)!.innerText = ('0' + remainingMinutes).slice(-2) + ' : ';
            document.getElementById("second" + _this.counterFunction + auctionEntityDto.eventId)!.innerText = ('0' + remainingSeconds).slice(-2);
          }

          //do something later when date is reached
          if (remainingDurations < 0) {
            let headline = document.getElementById("headline" + _this.counterFunction + auctionEntityDto.eventId);
            let countdown = document.getElementById("countdown" + _this.counterFunction + auctionEntityDto.eventId);
            // let content = document.getElementById("content" + auction.auctionId);

            if (headline) {
              headline.innerText = "Bidding Closed!";
            }
            if (countdown) {
              countdown.style.display = "none";
            }
            // content.style.display = "block";

            clearInterval(_this.counterFunction);
          }
        }

      }, 1000)


      console.log("New count down function created in supplier small card component - " + auctionEntityDto.eventName + "   : " + this.counterFunction)
    }
  }

  getCompanyDto(companyId?: string){

    return null;
  }

  ngOnDestroy(): void {
    this.clearCountdown();
    if(this.currentAuctionHouseSubscription$){
      this.currentAuctionHouseSubscription$.unsubscribe()
    }
    if(this.selectedCalenderEventSubscription$){
      this.selectedCalenderEventSubscription$.unsubscribe();

    }
  }
}
