<!-- Email -->

<div class="bg-blue-50 h-screen">
  <div class="px-8 py-12 ">
    <div class="flex justify-center items-center mb-6">
      <div class="text-2xl text-gray-800 font-bold w-96 text-center">
        <span>Reset your password</span>
      </div>
    </div>
    <div class="flex justify-center items-center mb-6">
      <div class=" bg-white rounded-lg p-8 w-96 h-96">
        <div class=" text-gray-700 text-center text-base font-medium mb-4">
          Enter your email address and we'll send you a link to reset your password. 
        </div>
        <form class="needs-validation" novalidate [formGroup]="formGroup">
        <div class=" text-start mb-6">
          <label class="text-gray-600  text-sm font-medium leading-4 text-start" for="userOtp">
            Enter email address</label>
          <input type="text" id="emailID" [formControl]="emailID"
            class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-600 text-sm" placeholder="name@example.com"
            [ngClass]="{'is-invalid': emailID.invalid && (emailID.dirty || emailID.touched)}"/>
            <div *ngIf="emailID.invalid && (emailID.dirty || emailID.touched)">
              <p class="text-danger" *ngIf="emailID.errors?.['required']">
                <i-lucide name="triangle-alert" [size]="16" color="rgb(239 68 68)" class="my-icon inline-flex me-1"></i-lucide>
                Required</p>
              <p class="text-danger" *ngIf="emailID.errors?.['pattern']">
                <i-lucide name="triangle-alert" [size]="16" color="rgb(239 68 68)" class="my-icon inline-flex me-1"></i-lucide>
                Invalid email address</p>
                <p class="text-danger" *ngIf="emailID.errors?.['maxlength']">
                  <i-lucide name="triangle-alert" [size]="16" color="rgb(239 68 68)" class="my-icon inline-flex me-1"></i-lucide>
                  Maximum Length Exceed
                </p>
            </div>
        </div>
      </form>
        <!-- <div class="d-grid gap-2 my-3" *ngIf="isLoading">
          <div class="e-spinner"></div>
        </div> -->

        <div class="grid text-end mb-8 ">
          <p-button
            [loading]="isLoading"
            class="text-center basis-1/2 text-white bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-300 font-medium rounded text-sm px-3 py-1.5 "
            (onClick)="generateOTP()">Reset Password</p-button>
        </div>
      </div>
    </div>

    <div class=" flex justify-center items-center mb-6">
      <div class="text-sm text-gray-800 font-medium">
       Back to login
      </div>
      </div>
   
    <div class="flex justify-center items-center">
      <div class="w-96 text-center text-xs text-gray-700 font-medium">
        Copyright 2024 Procurext - All rights reserved.
     <span class="underline">Terms of Service</span> and <span
          class="underline">
          Privacy Policy
        </span>
      </div>
    </div>
  </div>

</div>
<!-- 
Password -->
<div class="bg-blue-50 h-screen">
  <div class="px-8 py-12 ">
    <div class="flex justify-center items-center mb-6">
      <div class="text-2xl text-gray-800 font-bold w-96 text-center">
        <span>Reset your password</span>
        <span>Finish account setup by creating password</span>
      </div>
    </div>
    <div class="flex justify-center items-center mb-6">
      <div class=" bg-white rounded-lg p-8 w-96 height-36rem">
        <div class=" text-gray-700 text-center text-base font-medium mb-4">
          Please use  
           (<span
            class="text-blue-600 text-base font-bold">neha.com</span>)
            along with this password to login to the account
        </div>
        

        <div class=" text-start mb-6">
          <label class="text-gray-600  text-sm font-medium leading-4 text-start" for="userOtp">
            Create password</label>
          <input type="number" id="otp"
            class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-600 text-sm" />
        </div>

        
        <div class=" text-start mb-6">
          <label class="text-gray-600  text-sm font-medium leading-4 text-start" for="userOtp">
            Confirm password</label>
          <input type="number" id="otp"
            class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-600 text-sm" />
        </div>
       

        <div class="d-grid gap-2 my-3" *ngIf="isLoading">
          <div class="e-spinner"></div>
        </div>

        <div class="grid text-end mb-8 ">
          <button
            class="text-center basis-1/2 text-white bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-300 font-medium rounded text-sm px-3 py-1.5 "
          >Change Password</button>
        </div>
        <div class=" text-start">
           <div class="text-sm text-gray-800 font-medium">
            Password policies
           </div>
        
        <div class="font-normal text-xs text-gray-600 flex items-center">
          <span class=" step">
            <i-lucide  color="#fff" [size]="16" name="check" class="my-icon"> </i-lucide>
            <!-- <i-lucide  color="#fff" [size]="16" name="x" class="my-icon"> </i-lucide> -->
        </span>
          Password length should be minimum 12 characters .
        </div>
        <div class="font-normal text-xs text-gray-600 flex items-center">
          <!-- <span class="complete">
            <i-lucide  color="#fff" [size]="16" name="check" class="my-icon"> </i-lucide>
        </span> -->
        <span class="danger">
          <i-lucide  color="#fff" [size]="16" name="x" class="my-icon"> </i-lucide>
      </span>
          At least 1 uppercase, 1 lowercase, 1 digit, and 1 special character.
        </div>
      </div>
      </div>
    </div>
   
    <div class="flex justify-center items-center">
      <div class="w-96 text-center text-xs text-gray-700 font-medium">
        Copyright 2024 Procurext - All rights reserved.
     <span class="underline">Terms of Service</span> and <span
          class="underline">
          Privacy Policy
        </span>
      </div>
    </div>
  </div>

</div>

<div>
  <ng-container [ngTemplateOutlet]="isShowOtpScreen ? resetPasswordScreen : generateOtpScreen"></ng-container>
</div>

<ng-template #generateOtpScreen>
  <!-- <div class="model-body">
    <div class=" m-3">
    <div class="text-start mb-2">
      Enter your email to receive one time password.
    </div>
    <form class="needs-validation" novalidate>
      <div class=" mb-3">
        <label class="text-gray-600  text-sm font-medium leading-4" for="ctrlEmail">Email address</label>
        <input type="email"
          class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-600 text-sm"
          id="ctrlEmail" placeholder="name@example.com" [formControl]="ctrlEmail" (keyup)="hideToastMessage()"
          [ngClass]="{'is-invalid': ctrlEmail.invalid && (ctrlEmail.dirty || ctrlEmail.touched)}">

        <div *ngIf="ctrlEmail.invalid && (ctrlEmail.dirty || ctrlEmail.touched)">
          <p class="text-danger" *ngIf="ctrlEmail.errors?.['required']">
            <i-lucide name="triangle-alert" [size]="16" color="rgb(239 68 68)" class="my-icon inline-flex me-1"></i-lucide>
            Required</p>
          <p class="text-danger" *ngIf="ctrlEmail.errors?.['pattern']">
            <i-lucide name="triangle-alert" [size]="16" color="rgb(239 68 68)" class="my-icon inline-flex me-1"></i-lucide>
            Invalid email address</p>
            <p class="text-danger" *ngIf="fc.ctrlEmail.errors?.['maxlength']">
              <i-lucide name="triangle-alert" [size]="16" color="rgb(239 68 68)" class="my-icon inline-flex me-1"></i-lucide>
              Maximum Length Exceed
            </p>
        </div>
      </div>
      <div class="my-3">
        <app-message-toast *ngIf="_showSuccessToast$ | async" [message]="_successMsg$ | async"></app-message-toast>
        <app-message-toast *ngIf="_showErrorToast$ | async" [isError]="true"
          [message]="_errorMsg$ | async"></app-message-toast>
      </div>

      <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
        <div class="e-spinner"></div>
      </div>

      <div class="text-end mb-3" *ngIf="!isLoading && !(_showSuccessToast$ | async)">
        <p-button [disabled]="isLoading" label="Cancel"
                class="text-center text-white bg-gray-500 hover:bg-gray-600 focus:ring-4 focus:ring-gray-300 font-medium rounded text-sm px-3 py-1.5 me-3"
                (onClick)="closeDrawer()"></p-button>
        <p-button type="button"
          class="text-center text-white bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-300 font-medium rounded text-sm px-3 py-1.5 "
          (onClick)="generateOTP()" ngbAutoFocus>Send Otp</p-button>
      </div>
    </form>

  </div>
  </div> -->
</ng-template>

<ng-template #resetPasswordScreen>
  <div class="model-body">
      <div class="m-3">
        <div class="text-start mb-2">
          Enter the One Time Password (OTP) which was just sent to the email address you registered with. Be sure to
          check your spam folder.
        </div>
        <form class="needs-validation" novalidate [formGroup]="formGroup">
          <div class=" mb-3">
            <label for="otp" class="text-gray-600  text-sm font-medium leading-4">Enter OTP</label>
            <input type="number"
              class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-600 text-sm"
              id="otp" placeholder="Enter OTP" formControlName="otp" (keyup)="hideToastMessage()"
              [ngClass]="{'is-invalid': fc.otp.invalid && (fc.otp.dirty || fc.otp.touched)}">

            <div *ngIf="(_validationErrorPresent$ |async) || fc.otp.invalid && (fc.otp.dirty || fc.otp.touched)">
              <p class="text-danger" *ngIf="fc.otp.errors?.required">
                <i-lucide name="triangle-alert" [size]="16" color="rgb(239 68 68)" class="my-icon inline-flex me-1"></i-lucide>
                Required</p>
            </div>
          </div>

          <div class="text-end mb-3">
            <button type="button" class="btn btn-link" (click)="resendOTP()">Resend OTP</button>
          </div>

          <div class="text-start mb-3">
            <label for="password" class="text-gray-600  text-sm font-medium leading-4">Password</label>
            <div class="flex w-full">
              <input [type]="isPasswordVisible ? 'text' : 'password'"
                class="bg-white w-full border-r-0 flex-col self-stretch rounded-l-lg border border-solid border-slate-50 h-8 px-2 py-0 input-placeholder"
                id="password" placeholder="Password" formControlName="password" (keyup)="hideToastMessage()"
                (keyup)="onChange()" (change)="onChange()"
                [ngClass]="{'is-invalid': fc.password.invalid && (fc.password.dirty || fc.password.touched)}">
              <div class="input-group-append">
                <span class="border border-solid border-slate-50 border-l-0 rounded-r-lg h-8 flex pt-2 px-3"
                  id="basic-addon2" (click)="togglePasswordVisibility()">
                  <i-lucide name="eye" [size]="16" class="my-icon c-pointer" *ngIf="!isPasswordVisible"></i-lucide>
                  <i-lucide name="eye-off" [size]="16" class="my-icon c-pointer" *ngIf="isPasswordVisible"></i-lucide>
                </span>
              </div>
            </div>
            <div
              *ngIf="(_validationErrorPresent$ |async) && fc.password.invalid || fc.password.invalid && (fc.password.dirty || fc.password.touched)">
              <p class="text-danger" *ngIf="fc.password.invalid && (!fc.password.value)">
                <i-lucide name="triangle-alert" [size]="16" color="rgb(239 68 68)" class="my-icon inline-flex me-1"></i-lucide>
                Required</p>
              <p class="text-danger" *ngIf="fc.password.invalid &&  (fc.password.value)">
                <i-lucide name="triangle-alert" [size]="16" color="rgb(239 68 68)" class="my-icon inline-flex me-1"></i-lucide>
                Password must be more than 8 characters, containing at least one capital letter, one lowercase letter,
                one number and one special character.</p>
            </div>
            <p class="text-danger" *ngIf="fc.password.errors?.['maxlength']">
              <i-lucide name="triangle-alert" [size]="16" color="rgb(239 68 68)" class="my-icon inline-flex me-1"></i-lucide>
              Maximum Length Exceed
            </p>
            <p class="text-danger" *ngIf="fc.password.errors?.['minlength']">
              <i-lucide name="triangle-alert" [size]="16" color="rgb(239 68 68)" class="my-icon inline-flex me-1"></i-lucide>
              Minumum Length required
            </p>

          </div>
          <div class="text-start mt-3">
            <label for="confirmPassword" class="text-gray-600  text-sm font-medium leading-4">Re-enter Password</label>
            <div class="flex w-full">
              <input [type]="isRepeatPasswordVisible ? 'text' : 'password'"
                class="bg-white w-full border-r-0 flex-col self-stretch rounded-l-lg border border-solid border-slate-50 h-8 px-2 py-0 input-placeholder"
                id="confirmPassword" placeholder="Re-enter Password" formControlName="confirmPassword"
                (keyup)="onChange()" (change)="onChange()"
                [ngClass]="{'is-invalid': fc.confirmPassword.invalid && (fc.confirmPassword.dirty || fc.confirmPassword.touched)}">
              <div class="input-group-append">
                <span class="border border-solid border-slate-50 border-l-0 rounded-r-lg h-8 flex pt-2 px-3"
                  (click)="toggleRepeatPasswordVisibility()">
                  <img src="assets/icons/visible_light.svg" class="svg-icon-class c-pointer"
                    *ngIf="!isRepeatPasswordVisible">
                  <img src="assets/icons/invisible_light.svg" class="svg-icon-class c-pointer"
                    *ngIf="isRepeatPasswordVisible">
                </span>
              </div>
            </div>
            <div class="mb-3">
              <p class="text-danger"
                *ngIf="(_validationErrorPresent$ |async) && fc.confirmPassword.invalid || fc.confirmPassword.invalid && (fc.confirmPassword.dirty || fc.confirmPassword.touched)">
                <i-lucide name="triangle-alert" [size]="16" color="rgb(239 68 68)" class="my-icon inline-flex me-1"></i-lucide>
                Required
              </p>
              <p class="text-danger" *ngIf="!fc.confirmPassword.invalid && !(_isPasswordMatch$ | async)">
                <i-lucide name="triangle-alert" [size]="16" color="rgb(239 68 68)" class="my-icon inline-flex me-1"></i-lucide>
                Password Not Match
              </p>
            </div>
          </div>

          <div class="my-3">
            <app-message-toast *ngIf="_showSuccessToast$ | async" [message]="_successMsg$ | async"></app-message-toast>
            <app-message-toast *ngIf="_showErrorToast$ | async" [isError]="true"
              [message]="_errorMsg$ | async"></app-message-toast>
          </div>

          <div class="grid gap-2 mb-3" *ngIf="isLoading">
            <div class="e-spinner"></div>
          </div>

          <div class="text-end mb-3" *ngIf="!isLoading && !(_showSuccessToast$ | async)">
            <button type="button"
              class="text-center basis-1/2 text-white bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-300 font-medium rounded text-sm px-3 py-1.5 "
              ngbAutoFocus (click)="forgotPassword()">Reset Password</button>
          </div>
        </form>
      </div>
  </div>
</ng-template>
