<!-- New Header -->
<header #headerPrimary>
  <div class="flex flex-row w-full justify-between items-center gap-3 px-3 head-pos main-header" *ngIf="!IsAnonymous">
  <div class="">
    <a>
      <img src="assets/logo/procurext-logo.svg" alt="Procurext" class="application-logo | img-fluid my-1 ms-md-5" />
    <!-- <img src="/downloadLandingBlob?fileId={{organizationUiDto?.logo?.fileId}}" [alt]="organizationUiDto?.name" class="application-logo | img-fluid my-1 ms-md-5" /> -->
    </a>
  </div>
  <div class="basis-6/12">
    <div class="flex flex-row items-center justify-start my-2 px-3">
      <div class="basis-1/2 inline-flex items-center mt-2">
        <!-- <span (click)="navigateToPage('/event')">
          <img src="assets/icons/date_light.svg" class="svg-icon-class-md c-pointer me-2">
        </span> -->
        <span class="inline-flex text-xs font-normal text-black underline  "> Home</span>
        <span class="mx-2">/</span>
        <span class="inline-flex text-xs font-normal text-black underline  ">Tender management</span>
        <span class="mx-2">/</span>
        <span class="inline-flex text-xs font-normal text-blue-500 ">Settings</span>
      </div>
    </div>
  </div>
  <div class="basis-5/12 text-end">
    <ul class="flex justify-end items-center">
      <!-- <li class="mt-1">
        <span pTooltip="help" tooltipPosition="bottom" placeholder="Bottom" class="mr-3 c-pointer" container="body" placement="bottom" >
          <button class="bg-blue-500 rounded h-6 w-6 p-1 items-center inline-flex">
          <i-lucide name="plus" [size]="18" color="#fff" class="my-icon "></i-lucide>
        </button>
        </span>
      </li> -->
      <li class="mt-3">
        <span pTooltip="Notification" tooltipPosition="bottom" placeholder="Bottom"  class="mr-3 c-pointer " container="body" placement="bottom" >
          <i-lucide name="bell" [size]="24" class="my-icon inline-flex "></i-lucide>
        </span>
      </li>
      <li class="mt-3">
        <span pTooltip="Help" tooltipPosition="bottom" placeholder="Bottom"  class="mr-3 c-pointer " container="body" placement="bottom" >
          <i-lucide name="circle-help" [size]="24" class="my-icon inline-flex "></i-lucide>
        </span>
      </li>
      <!-- <li>
        <app-notifications class="" layoutType="HEADER"></app-notifications> 
      </li> -->
      <li class="mt-2">
        <span  pTooltip="Logout" tooltipPosition="bottom" placeholder="Bottom"  class="mr-3 c-pointer " container="body" placement="auto"  (click)="logout()">
          <img src="assets/icons/logout.svg" class=" h-6 w-6 inline-flex" >
        </span> 
      </li>
      <li class="mr-3 mt-3">
        <span class="header-line">
          |
        </span>
      </li>
      <li class="mr-3 mt-3 c-pointer ">
        <span class="" pTooltip="My Profile" tooltipPosition="bottom" placeholder="Bottom"  container="body" placement="bottom" >
          <span>
            <img src="/downloadLandingBlob?fileId={{userEntityDto?.profileImage?.fileId}}"  alt="{{userEntityDto?.firstName}}" class="header-image header-image-border"
            *ngIf="userEntityDto?.profileImage">
          <img src="./assets/images/avatar_default.png"
            alt="{{userEntityDto?.firstName}}" class="header-image header-image-border" *ngIf="!userEntityDto?.profileImage">
          </span>
        </span>
      </li>
    </ul>
  </div>
  </div>
  </header>
  <!-- <li ngbDropdownItem class="c-pointer" (click)="navigateToPage('/event')">
    <a><img src="assets/icons/date_light.svg" class="svg-icon-class-md c-pointer me-2">
      <small class="ps-1">Registration Event</small></a>
  </li> -->
<!-- <header class=" bg-white main-header  py-md-1 py-xl-0 head-pos mb-5" #headerPrimary>
 
  <div class="col-12" *ngIf="!IsAnonymous">
    <div class="row  align-items-center justify-content-between ps-3 pe-5 ps-md-0 pe-md-2">
      <div class="col-auto">
        <a>
           <img src="assets/logo/procurext-logo.svg" alt="Procurext" class="application-logo | img-fluid my-1 ms-md-5" /> 
          <img src="assets/logo/AmritCement.png" alt="AmritCement" class="application-logo | img-fluid my-1 ms-md-5" />
        </a>
        <li ngbDropdownItem class="c-pointer" (click)="navigateToPage('/event')">
          <a><img src="assets/icons/date_light.svg" class="svg-icon-class-md c-pointer me-2">
            <small class="ps-1">Registration Event</small></a>
        </li>
      </div>
      <div class="d-block d-md-none col-auto text-end">
          <span ngbDropdown #profileDropdown1="ngbDropdown" autoClose="false" container="body" placement="bottom-right">
            <span ngbDropdownToggle data-bs-toggle="dropdown">
              <span class="icon-wrapper icon-wrapper-alt c-pointer">
                  <button class="header-button-ui btn btn-sm">
                    <img src="assets/icons/hamberger.svg" class=" c-pointer svg-icon-class-small  hamburger">
                  </button>
              </span>
            </span>
            <ul ngbDropdownMenu class="text-start mt-3 p-2 margin-right dropdown-menu shadow">
              <li ngbDropdownItem class="c-pointer" (click)="openMyDetailsModal(myDetails, profileDropdown1)">
                <span class="me-3" >
                  <img
                  src="/downloadLandingBlob?fileId={{userEntityDto?.profileImage?.fileId}}" 
                  alt="{{userEntityDto?.firstName}}" class="header-image" *ngIf="userEntityDto?.profileImage"  >
                <img  src="./assets/images/avatar_default.png"
                  alt="{{userEntityDto?.firstName}}" class="header-image" *ngIf="!userEntityDto?.profileImage" >
                  My Profile
                </span>
              </li>
              <hr class="hr my-1" >
              <li ngbDropdownItem class="c-pointer" (click)="navigateToPage('/calendar')">
                <span class="me-3 ">
                  <img src="assets/icons/date_light.svg" class="svg-icon-class-md" >
                 Auction Calendar
                </span>
              </li>
             
              <hr class="hr my-1" >
              <li ngbDropdownItem class="c-pointer">
                <span class="me-3 ">
                  <img src="assets/icons/notification-bell.svg" class="svg-icon-small me-1">
                  Notifications
                </span>

              </li>
              <hr class="hr my-1" >
              <li ngbDropdownItem (click)="logout(profileDropdown1)">
                <span class="me-3 text-danger">
                  <img src="assets/icons/logout.svg" class="svg-icon-class" >
                  Logout1
                </span>
              </li>
            </ul>
          </span>
      </div>
      <div class="col-auto text-end d-none d-md-block">

        <span class="span-class me-3" *ngIf="!IsAnonymous" >
          Hi, {{userEntityDto?.firstName}} {{userEntityDto?.lastName}}
        </span>
         <span class="me-3 ">
          <img src="assets/icons/date_light.svg" class="svg-icon-class-md c-pointer " ngbTooltip="Calendar" container="body"
          (click)="navigateToPage('/calendar')">
        </span>

        <app-notifications layoutType="HEADER"></app-notifications>

        <span class="me-3 c-pointer" (click)="openMyDetailsModal(myDetails, profileDropdown1)" container="body" placement="top" ngbTooltip="My Profile" >
          <img
          src="/downloadLandingBlob?fileId={{userEntityDto?.profileImage?.fileId}}" 
          alt="{{userEntityDto?.firstName}}" class="header-image" *ngIf="userEntityDto?.profileImage"  >
        <img  src="./assets/images/avatar_default.png"
          alt="{{userEntityDto?.firstName}}" class="header-image" *ngIf="!userEntityDto?.profileImage" >
        </span>
        <span class="me-3 c-pointer" ngbTooltip="Logout" container="body" placement="top">
          <img src="assets/icons/logout.svg" class="svg-icon-class" 
          (click)="logout()">
        </span>
      </div>
       <div class="col-xl-1 col-2 text-end" >
        <div>
          <span ngbDropdown #profileDropdown1="ngbDropdown" autoClose="true" container="body" placement="bottom-right">
            <span ngbDropdownToggle data-bs-toggle="dropdown">
              <span class="icon-wrapper icon-wrapper-alt c-pointer">

                  <button class="header-button-ui btn btn-sm">
                    <img src="assets/icons/hamberger.svg" class=" c-pointer svg-icon-class-small  hamburger">
                    <span *ngIf="!IsAnonymous" class="ms-2">
                      <img
                    src="/downloadLandingBlob?fileId={{userEntityDto?.profileImage?.fileId}}"
                    alt="{{userEntityDto?.firstName}}" class="header-image" *ngIf="userEntityDto?.profileImage"  >
                  <img  src="./assets/images/avatar_default.png"
                    alt="{{userEntityDto?.firstName}}" class="header-image" *ngIf="!userEntityDto?.profileImage" >
                    </span>
                  </button>
              </span>
            </span>

            <ul ngbDropdownMenu class="text-start mt-3 p-2 margin-right dropdown-menu shadow">

              <li ngbDropdownItem class="c-pointer" *ngIf="IsAnonymous" (click)="openLoginModal(loginModal)">
                <a class="text-info">
                  <img src="assets/icons/login_light.svg" class="svg-icon-class-md c-pointer me-2">LOGIN</a>
              </li>
              <hr class="hr my-1" *ngIf="IsAnonymous">
              <li ngbDropdownItem class="c-defoult" *ngIf="!IsAnonymous">
                <div class="row">
                  <div class="col-12">
                    <small class="small-class">Logged In as </small>
                    <div *ngIf="userEntityDto?.firstName">
                      {{userEntityDto?.firstName}} {{userEntityDto?.lastName}}
                    </div>
                    <div *ngIf="!userEntityDto?.firstName">
                      {{userEntityDto?.primaryEmailId}}
                    </div>
                  </div>
                </div>
                <div class="col-12 text-center my-2 ">
                  <span class="button-class me-2 badge-primary-class c-pointer"
                    (click)="openMyDetailsModal(myDetails, profileDropdown1)">
                    My Profile
                  </span>
                </div>
              </li>
              <hr class="hr my-1">
              <li ngbDropdownItem class="c-pointer" (click)="navigateToPage('/calendar')">
                <a>
                  <img src="assets/icons/date_light.svg" class="svg-icon-class-md c-pointer me-2">
                  <small class="ps-1">Auction Calendar</small></a>
              </li>
              <hr class="hr my-1" *ngIf="!IsAnonymous">
              <li ngbDropdownItem class="c-pointer" *ngIf="!IsAnonymous" (click)="logout(profileDropdown1)">
                <a class="text-danger"><img src="assets/icons/logout.svg"
                    class="svg-icon-class-md c-pointer me-2"><b>LOGOUT</b></a>
              </li>
            </ul>
          </span>
        </div>
      </div>  
    </div>
  </div>
  <div class="header-divider" *ngIf="!IsAnonymous">
</div>

</header> -->



<header class="header-divider bg-white header-secondary" #headerSecondary>
  <div class="py-2 ps-md-5 ps-2 row align-items-center">
    <div class=" col-3 col-sm-auto">
      <h4 class="display-class ">
        <img *ngIf="!getCompanyByLogoFileId(auctionEntityDto?.companyId)" class="image-auction-header me-3" src="assets/images/avatar05.jpg"
          alt="REC Auctions" />
        <img *ngIf="getCompanyByLogoFileId(auctionEntityDto?.companyId)"
          src="/downloadLandingBlob?fileId={{getCompanyByLogoFileId(auctionEntityDto?.companyId)}}"
          class="image-auction-header me-3">
      </h4>
    </div>

    <div class=" display-class  mt-2  col-9"  *ngIf="!(bidderService.selectLotName$ | async)">
      <h4 class="overflow-header"># {{auctionEntityDto?.sequenceNo}} {{auctionEntityDto?.auctionName}}</h4>
    </div>
    <div class=" display-class  col-9  mt-2" *ngIf="bidderService.selectLotName$ | async">
      <h4 class="overflow-header"> {{bidderService.selectLotName$ | async}}</h4>
    </div>
  </div>

  <div class="">
    <div class="progress-bar" id="myBar" [style.width]="progress+'%'"></div>
  </div>
</header>

<ng-template #loginModal>
  <app-user-login></app-user-login>
</ng-template>

<ng-template #registrationModal>
  <app-user-registration></app-user-registration>
</ng-template>

<!-- Templates -->
<ng-template #messageDialog>
  <app-message-dialog></app-message-dialog>
</ng-template>
<ng-template #myDetails>
  <app-user-details></app-user-details>
</ng-template>

<app-loader #appLoader></app-loader>