<div class="" >
  <div class="row">
    <div class="col-md-6">
      <h4 class="heading-h5" >GENERATED INVOICE LIST</h4>
    </div>
  <div class="col-md-6 text-md-end">
   <div class="row mt-1 justify-content-md-end me-2">
    <div class="col-md-2">
    </div>
    <div class="col-2">
      <button class="btn btn-new-class btn-sm me-2 ms-2 ms-md-0 mt-1" (click)="loadInvoiceData()">
        <img src="assets/icons/refresh_dark.svg" class=" c-pointer svg-icon-class">
      </button>
     </div>
     <div class="col-8 col-md-6">
      <div class="input-group" >
        <input type="text" placeholder="Search" id="ctrlSearch" [formControl]="ctrlSearch"
          class="form-control border-end-0 rounded-0 border bg-white" />
        <span class="input-group-append">
          <button class="btn btn-outline-secondary  border-start-0 rounded-0 border ms-n5" type="button" >
            <span> <img src="assets/icons/search_light.svg" class=" c-pointer svg-icon-class ms-2"></span>
          </button>
        </span>
      </div>
     </div>

   </div>
  </div>
  </div>
 <div class="m-3">
  <div class="table-responsive">
    <table class="table table-bordered align-middle">
      <thead class="table-secondary">
        <tr>
          <th class="font-weight-bold"><span class="span-class">Auction Name</span></th>
          <th class="font-weight-bold"><span class="span-class">Lot Name</span></th>
          <th class="font-weight-bold"><span class="span-class">Invoice Date</span></th>
          <th class="font-weight-bold"> <span class="span-class">Download Invoice</span></th>
          <th class="font-weight-bold"> <span class="span-class">Payment Status</span></th>
        </tr>
      </thead>
      <tbody class="bg-white">
        <tr *ngIf="isLoading">
          <td colspan="5">
            <div class="d-grid gap-2 mb-3">
              <div class="e-spinner"></div>
            </div>
          </td>
        </tr>
        <tr *ngFor="let item of invoiceList$ | async; index as i" >
          <td>{{item.auctionName}}</td>
          <td>
            <span class="me-1 " *ngIf="currentIndex != i">
              # {{item?.lotInfoDtos![0].lotSequenceNo}}
              {{item?.lotInfoDtos![0].lotName}} </span>
            <span class="ms-3 btn-link" *ngIf="item?.lotInfoDtos!.length > 1 && currentIndex != i" (click)="showLotsMore(i)">
              {{item?.lotInfoDtos!.length - 1}} more
            </span>

            <div *ngIf="currentIndex == i">
              <div *ngFor="let lot of item.lotInfoDtos; index as i">
                <span>
                  # {{lot.lotSequenceNo}}
              {{lot.lotName}}
                </span>
              </div>
            </div>
            <!-- <p *ngFor="let lot of item.lotInfoDtos; index as i">
              <span>
                <span class="me-1 ">
                  # {{lot?.lotSequenceNo}}
                </span> {{lot?.lotName}}
              </span>
          </p> -->

          <td>{{item.invoiceDateInStr}}</td>
          <td (click)="downloadInvoice(item.invoiceFileInfo?.fileId!)">
            <button class="btn btn-new-class btn-sm ms-3">
             Download 
             <!-- <fa-icon [icon]="['fas', 'download']" class=" fa-lg ms-2 c-pointer"></fa-icon> -->
            </button>
          </td>
          <td>
            <span [ngClass]="{'bg-warning': item.status == 'PENDING', 'bg-success': item.status == 'PAID',  'bg-info': item.status == 'PARTIALLY_PAID',
            'bg-danger': item.status == 'NO_SHOW'}"
             class="badge p-2 ms-3" >
            {{getStatus(item.status!)}}
            </span>
          </td>

        </tr>
        <tr *ngIf="!isLoading && (invoiceList$ | async)?.length == 0">
          <td colspan="5">
            <div class="text-center">
              <h5 class="heading-new text-dark">NO INVOICES AVAILABLE</h5>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
 </div>
  <div class="row justify-content-between" *ngIf="(invoiceList$ |async)!.length > 0">
    <div class="col">
      <div ngbDropdown #menuDropdown="ngbDropdown" container="body" placement="bottom-right">
        <span class="span-class">Show rows per page </span>
        <span ngbDropdownToggle data-bs-toggle="dropdown">
          <button class="btn btn-sm btn-outline-secondary px-2">{{pageSize}}</button>
        </span>
        <ul ngbDropdownMenu class="text-center shadow">
          <li ngbDropdownItem class="c-pointer" (click)="changePageSize(50)">50</li>
          <li>
            <hr class="dropdown-divider " >
          </li>
          <li ngbDropdownItem class="c-pointer" (click)="changePageSize(100)">100</li>
        </ul>
      </div>
    </div>
    <div class="col-auto">
      <ngb-pagination [collectionSize]="invoiceList.length!" [(page)]="page" [pageSize]="pageSize"
        (pageChange)="paginateList()">
      </ngb-pagination>
    </div>
  </div>
</div>



