export enum DocumentType {
  ARTICLES_OF_ASSOCIATION = "Articles of Association (AoA)",
  BUSINESS_LICENSE = "Business License/Registration Certificate",
  CERTIFICATE_OF_INCORPORATION = "Certificate of Incorporation",
  COMPANY_MASTER_DATA = "Company Master Data",
  EXEMPTION_CERTIFICATE = "Exemption Certificate (if applicable)",
  FINANCIAL_AUDIT_CERTIFICATE = "Financial Audit Certificate",
  GST_REGISTRATION_CERTIFICATE = "GST Registration Certificate",
  MEMORANDUM_OF_ASSOCIATION = "Memorandum of Association (MoA)",
  PAN_CARD = "PAN Card",
  PARTNERSHIP_DEED = "Partnership Deed (if applicable)",
  STARTUP_CERTIFICATE = "Startup Certificate",
  SHOP_ESTABLISHMENT_CERTIFICATE = "Shop and Establishment Certificate",
  UDYOG_AADHAR_NUMBER = "Udyog Aadhar Number",
  OTHERS = "Others"
}