import { DocumentArchiveStatus } from "../../enums/DocumentArchiveStatus";
import { BidderUiDto } from "../UserEntityDto";

export class DocumentArchiveUiDto {
  documentArchiveId?: string | null; // Optional properties
  documentType?: string| null;
  documentName?: string| null;
  documentReferenceNo?: string| null;
  expiryDate?: string | null; // You may want to use Date type if you're handling date objects
  companyId?: string | null;
  userId?: string | null;
  fileId?: string | null;
  fileName?: string | null;
  size?: string | '0'; // Consider using number if size is represented in bytes
  metaData?: string | null;
  status?: string| null ; // Assuming DocumentArchiveStatus is defined elsewhere

}
