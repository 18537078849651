import { Component, Input, OnInit } from '@angular/core';
import { AuctionEntityDto } from '../../models/user/AuctionEntityDto';
import { CountryCodeDto } from '../../models/CountryCodeDto';
import { DataRedirectionService } from '../../services/data-redirection.service';
import { LandingAndBidderService } from '../../services/LandingAndBidder.service';
import { OrganizationUiDto } from '../../models/user/AuctionHouseDto';
import { Subscription } from 'rxjs';
import { AuctionCardWrapperDto } from '../../models/AuctionCardWrapperDto';
import { AuctionExtConstant } from '../../util/AuctionExtConstant';

@Component({
  selector: 'app-closed-auction-card',
  templateUrl: './closed-auction-card.component.html',
  styleUrls: ['./closed-auction-card.component.sass']
})
export class ClosedAuctionCardComponent implements OnInit{
  @Input() auctionCardWrapperDto?: AuctionCardWrapperDto;

  public screenWidth: any;
  isLoading: boolean = false;
  selectedIndex?: number;

  auctionHouse?: OrganizationUiDto;
  auctionEntityDto?: AuctionEntityDto;
  currentAuctionHouseSubscription$?: Subscription;

  onWindowResize() {
    this.screenWidth = window.innerWidth;
  }

  constructor(
    private dataRedirectionService: DataRedirectionService,
    private bidderService: LandingAndBidderService,
  ) { }


  ngOnInit(): void {
    this.auctionEntityDto = this.auctionCardWrapperDto?.auctionEntityDto;
     this.currentAuctionHouseSubscription$ = this.bidderService.getCurrentAuctionHouse$.subscribe((data) => {
      if (data) {
        this.auctionHouse = data;
      }
    })
  }

  ngAfterContentInit(): void {
    this.screenWidth = window.innerWidth;
  }

  navigateAuctionView() {
    this.isLoading = true;
    this.dataRedirectionService.storeNavigationContext(AuctionExtConstant.ANY_CONTEXT, AuctionExtConstant.AUCTION, AuctionExtConstant.ANY_PAGE, '', this.auctionEntityDto?.auctionId!+"", undefined)
    this.dataRedirectionService.navigateToPage();
  }

  getCompanyDto(companyId?: string){


    return null;
  }



  getCountryCode(countryCodeDto?: CountryCodeDto) {
    if (countryCodeDto) {
      return countryCodeDto.countryCode?.replace('(', '').replace(')', '');
    }
    return '+1';
  }

}
