import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, firstValueFrom, lastValueFrom, Subscription } from 'rxjs';
import { AuctionExtConstant } from 'src/app/shared/util/AuctionExtConstant';
import { FileInfoDto } from 'src/app/shared/models/FileInfoDto';
import { FileSaveResponseDto } from 'src/app/shared/models/FileSaveResponseDto';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';
import { AuctionEntityDto } from 'src/app/shared/models/user/AuctionEntityDto';
import { BidderRequirementsDto } from 'src/app/shared/models/user/BidderRequirementsDto';
import { UserAuctionRegistrationDto } from 'src/app/shared/models/user/UserAuctionRegistrationDto';
import { LandingAndBidderService } from 'src/app/shared/services/LandingAndBidder.service';
import { FileService } from 'src/app/shared/services/file.service';
import { BidderRequirementType } from 'src/app/shared/enums/BidderRequirementType';
import { AuctionExtUtil } from 'src/app/shared/util/AuctionExtUtil';
import { DatePipe } from '@angular/common';
import { atLeastOneCheckboxChecked } from 'src/app/shared/validators/atleast-checkbox-checked.validator';
import { Store } from '@ngrx/store';
import { selectSessionInfo } from 'src/app/shared/state-management/session/session.features';

@Component({
  selector: 'app-bidder-auction-register',
  templateUrl: './bidder-auction-register.component.html',
  styleUrls: ['./bidder-auction-register.component.sass']
})
export class BidderAuctionRegisterComponent implements OnInit, OnDestroy {
  @Input() userAuctionRegistration?: UserAuctionRegistrationDto;
  @Input() readOnly: boolean = false;

  @Input() selectedAuction?: AuctionEntityDto;

  showSuccessToast$ = new BehaviorSubject<boolean>(false);
  showErrorToast$ = new BehaviorSubject<boolean>(false);

  documentTypes = ['application/pdf'];
  errorMsg: string | undefined;

  isEditDocument: boolean = false;
  isLoading: boolean = false;
  isSubmitted: boolean = false;
  isRequirementAvailable: boolean = true;

  formGroup: FormGroup;
  selectedAuctionSubscription$?: Subscription;

  bidderRequirements: Array<BidderRequirementsDto> = [];
  tempBidderRequirements: Array<BidderRequirementsDto> = [];

  bidderRequirementType: typeof BidderRequirementType = BidderRequirementType;

  showCommentBox: string[] = []


  constructor(
    private fb: FormBuilder,
    private activeModal: NgbActiveModal,
    private fileService: FileService,
    private bidderService: LandingAndBidderService,
    private datePipe: DatePipe,
    private store: Store
  ) {
    this.formGroup = this.fb.group({
      requirements: this.fb.array([]),
      terms: ['', Validators.requiredTrue],
    })
  }

  ngOnInit(): void {

    // if (this.selectedAuction?.bidderRequirementsDtos && !this.readOnly) {
    //   this.bidderRequirements = this.selectedAuction?.bidderRequirementsDtos;
    //   this.isRequirementAvailable = this.bidderRequirements.length > 0;
    // }

    this.updateFormGroup();

    let auctionHouseDto = this.bidderService.getCurrentAuctionHouse$.value;
    }


  closeModal() {
    this.activeModal.close()
  }

  updateFormGroup() {
    if (this.readOnly) {
      this.getBidderAuctionRegistrationData();
      this.formGroup.controls['terms'].patchValue(true);
    } else {
      for (let item of this.bidderRequirements) {
        this.addRequirementForm(item.id!, item.questionType!);

        if (item.questionType == BidderRequirementType.MULTIPLE_CHOICE) {
          item.adminQuestionChoices?.forEach(inputChoice => {
            this.addInputChoice(item.id!, inputChoice)
          })
        }

        if (item.questionType == BidderRequirementType.DATE) {
          this.addUserAnswerDate(item.id!);
        }

        if (item.questionType == BidderRequirementType.DATE_RANGE) {
          this.addUserAnswerDate(item.id!);
          this.addUserAnswerDate(item.id!);
        }
      }
    }
  }

  getBidderAuctionRegistrationData() {
    if (this.userAuctionRegistration) {
    //   if (this.userAuctionRegistration.bidderRequirementsDtoList && this.userAuctionRegistration.bidderRequirementsDtoList.length > 0)
    //     for (let item of this.userAuctionRegistration.bidderRequirementsDtoList) {
    //       this.bidderRequirements = this.userAuctionRegistration.bidderRequirementsDtoList;

    //       this.isRequirementAvailable = this.bidderRequirements.length > 0;
    //       if (!this.isRequirementAvailable) {
    //         // this.currentStep = 2;
    //       }

    //       this.updateRequirementForm(item as BidderRequirementsDto);
    //     }
    //   // this.isFormReady$.next(true);
    }
  }

  chooseDocument(event: any, requirementId: string, fileHtmlRef: any) {
    this.errorMsg = undefined;
    this.showErrorToast$.next(false);

    let currentFile = event.target.files[0];

    if (!this.documentTypes.includes(currentFile!.type)) {
      // this.fileUploadError = true;
      this.errorMsg = "Extension not supported";

      this.showErrorToast$.next(true);
      this.removeDocument(requirementId, fileHtmlRef);
      return;
    }

    let index = this.requirementForm.controls.findIndex(m => m.get('id')?.value == requirementId);
    this.requirementForm.controls[index].get('userFile')?.patchValue(currentFile);
    this.requirementForm.controls[index].get('userFile')?.updateValueAndValidity();
  }

  previewDocument(type: string, item: BidderRequirementsDto, isSampleFile: boolean) {
    if (type == 'LOCAL') {
      let currentFile = this.getSelectedFile(item.id!);

      if (currentFile != null) {
        if (this.readOnly) {
          this.isLoading = true;

          this.fileService.downloadFile(currentFile?.fileId!).subscribe({
            next: (response) => {
              if (response) {
                this.isLoading = false;

                let file = new Blob([response], { type: currentFile?.fileType });
                var fileURL = URL.createObjectURL(file);
                window.open(fileURL, '_blank');
              }
            },
            error: (err) => {
              console.error(err);
              console.log("Error while previewing document");
            }
          });
        } else {
          var fileURL = URL.createObjectURL(currentFile);
          window.open(fileURL, '_blank');
        }
      }

    } else {
      this.isLoading = true;

      let uploadedFile = isSampleFile ? item.sampleFile : item.userFile;

      this.fileService.downloadFile(uploadedFile?.fileId!).subscribe({
        next: (response) => {
          if (response) {
            this.isLoading = false;

            let file = new Blob([response], { type: uploadedFile?.fileType });
            var fileURL = URL.createObjectURL(file);
            window.open(fileURL, '_blank');
          }
        },
        error: (err) => {
          console.error(err);
          console.log("Error while previewing document");
        }
      });
    }
  }

  removeDocument(requirementId: string, fileHtmlRef: any) {
    let index = this.requirementForm.controls.findIndex(m => m.get('id')?.value == requirementId);
    this.requirementForm.controls[index].get('userFile')?.reset();
    fileHtmlRef?.setAttribute('value', '');

    // this.checkSaveButtonStatus();
  }

  getSelectedFile(requirementId: string) {
    let index = this.requirementForm.controls.findIndex(m => m.get('id')?.value == requirementId);
    if (index != null && index > -1) {
      let selectedFile = this.requirementForm.controls[index].get('userFile')?.value;
      return selectedFile != null ? selectedFile : null;
    }
    return null;
  }

  getFileNameOfSelectedFile(requirementId: string) {
    let selectedFile = this.getSelectedFile(requirementId);
    if (selectedFile) {
      return this.readOnly ? selectedFile.displayName : selectedFile.name;
    }
    return null;
  }

  // Requirement Form
  get requirementForm(): FormArray {
    return this.formGroup.get("requirements") as FormArray
  }

  newRequirementForm(id: string, type: string): FormGroup {
    return this.fb.group({
      id: [id, Validators.required],
      userInput: type == BidderRequirementType.TEXT || type == BidderRequirementType.TEXT_FILE_UPLOAD || type == BidderRequirementType.NUMERIC ? [null, Validators.required] : [null],
      userFile: type == BidderRequirementType.FILE_UPLOAD || type == BidderRequirementType.TEXT_FILE_UPLOAD ? [null, Validators.required] : [null],
      userAnswerChoices: type == BidderRequirementType.MULTIPLE_CHOICE ? this.fb.array([], atLeastOneCheckboxChecked()) : type == BidderRequirementType.SINGLE_CHOICE ? ['', Validators.required] : null,
      userAnswerDates: this.fb.array([]),
      comments: ['']
    })
  }

  addRequirementForm(id: string, type: string) {
    this.requirementForm.push(this.newRequirementForm(id, type));
  }

  updateRequirementForm(requirement: BidderRequirementsDto) {
    let form = this.fb.group({
      id: [requirement.id],
      userInput: requirement.questionType == BidderRequirementType.TEXT || requirement.questionType == BidderRequirementType.TEXT_FILE_UPLOAD || requirement.questionType == BidderRequirementType.NUMERIC ? [requirement.userInput] : [null],
      userFile: requirement.questionType == BidderRequirementType.FILE_UPLOAD || requirement.questionType == BidderRequirementType.TEXT_FILE_UPLOAD ? [requirement.userFile] : [null],
      userAnswerChoices: requirement.questionType == BidderRequirementType.MULTIPLE_CHOICE ? this.fb.array([]) : requirement.questionType == BidderRequirementType.SINGLE_CHOICE ? [requirement.userAnswerChoices![0]] : [null],
      userAnswerDates: requirement.questionType == BidderRequirementType.DATE || requirement.questionType == BidderRequirementType.DATE_RANGE ? this.fb.array([]) : [null],
      comments: [requirement.comments]
    });

    if (requirement.comments) {
      this.showCommentBox.push(requirement.id!)
    }

    form.disable();
    this.requirementForm.push(form);

    if (requirement.questionType == BidderRequirementType.MULTIPLE_CHOICE) {
      requirement.adminQuestionChoices?.forEach(inputChoice => {
        let isChoiceSelected = requirement.userAnswerChoices!.includes(inputChoice);
        this.updateInputChoice(requirement.id!, isChoiceSelected);
      })
    }

    if (requirement.questionType == BidderRequirementType.DATE || requirement.questionType == BidderRequirementType.DATE_RANGE) {
      requirement.userAnswerDates?.forEach(answerDate => {
        this.updateUserAnswerDates(requirement.id!, answerDate);
      })
    }
  }

  get rc(): any { return this.requirementForm.controls; }
  get fc(): any { return this.formGroup.controls; }

  async handleValidSubmit() {
    this.isSubmitted = true;

    if (this.formGroup.invalid || this.requirementForm.invalid) {
      this.formGroup.markAllAsTouched()
      return;
    }

    this.isLoading = true;
    this.tempBidderRequirements = [];

    if (this.isRequirementAvailable) {
      for (let i = 0; i < this.requirementForm.controls.length; i++) {
        let item = this.requirementForm.controls[i];

        let requirementId = item.get('id')?.value;
        let currentBidderRequirement = this.bidderRequirements.find(m => m.id == requirementId);

        currentBidderRequirement!.comments = item.get('comments')?.value;

        if (currentBidderRequirement?.questionType == 'File Upload') {
          let currentFile = item.get('userFile')?.value;

          await this.uploadDocument(currentFile, currentBidderRequirement!);

        } else if (currentBidderRequirement?.questionType == 'Text & File Upload') {
          let userInput = item.get('userInput')?.value;
          let currentFile = item.get('userFile')?.value;
          currentBidderRequirement!.userInput = userInput;

          await this.uploadDocument(currentFile, currentBidderRequirement!)

        } else if (currentBidderRequirement?.questionType == BidderRequirementType.SINGLE_CHOICE) {
          let userAnswerChoices = item.get('userAnswerChoices')?.value;
          currentBidderRequirement!.userAnswerChoices = [userAnswerChoices];

          this.tempBidderRequirements.push(currentBidderRequirement!);
        } else if (currentBidderRequirement?.questionType == BidderRequirementType.MULTIPLE_CHOICE) {
          let checkedValues = item.get('userAnswerChoices')?.value as boolean[];

          const selectedChoices = currentBidderRequirement?.adminQuestionChoices!.filter((_, index) => checkedValues[index]);

          currentBidderRequirement!.userAnswerChoices = selectedChoices;

          this.tempBidderRequirements.push(currentBidderRequirement!);
        } else if (currentBidderRequirement?.questionType == BidderRequirementType.DATE || currentBidderRequirement?.questionType == BidderRequirementType.DATE_RANGE) {
          let answerDates = item.get('userAnswerDates')?.value as NgbDateStruct[];

          currentBidderRequirement!.userAnswerDates = answerDates.map(m => AuctionExtUtil.getDateFromNgDatePicker(this.datePipe, m)!);

          this.tempBidderRequirements.push(currentBidderRequirement!);
        } else {
          let userInput = item.get('userInput')?.value;
          currentBidderRequirement!.userInput = userInput;

          this.tempBidderRequirements.push(currentBidderRequirement!);
        }
      }
    }

    console.log(this.tempBidderRequirements);

    this.saveAuctionRegistration();
  }

  async uploadDocument(currentFile: File, currentBidderRequirement: BidderRequirementsDto) {
    let metaData = {
      'mimeType': currentFile?.type,
      'version': 0,
      'publicApi': true,
      'dataType': 'Square'
    };

    let formData = new FormData();
    formData.append("file", currentFile!);
    formData.append('metaData', JSON.stringify(metaData));

    let apiResponseDto = await lastValueFrom(this.fileService.uploadFile(formData));
    if (apiResponseDto) {
      let fileSaveResponseDto = apiResponseDto.data as FileSaveResponseDto;

      if (apiResponseDto.code == AuctionExtConstant.SUCCESS_CODE) {
        let currentFileInfoDto = new FileInfoDto();
        currentFileInfoDto.fileId = fileSaveResponseDto.fileId;
        currentFileInfoDto.fileName = fileSaveResponseDto.fileName;
        currentFileInfoDto.fileSize = currentFile.size.toString();
        currentFileInfoDto.fileType = currentFile.type;
        currentFileInfoDto.uploadDate = new Date().toDateString();
        currentFileInfoDto.displayName = fileSaveResponseDto.fileName;

        currentBidderRequirement!.userFile = currentFileInfoDto;

        this.tempBidderRequirements.push(currentBidderRequirement!);
      }
    } else {
      console.error("Error while uploading");
    }
  }

  async saveAuctionRegistration() {
    this.showErrorToast$.next(false);
    this.errorMsg = "";
    
    let sessionInfoDto = await firstValueFrom(this.store.select(selectSessionInfo));

    let userAuctionRegistration = new UserAuctionRegistrationDto();
    userAuctionRegistration.auctionId = this.selectedAuction?.auctionId;
    // userAuctionRegistration.bidderRequirementsDtoList = this.tempBidderRequirements;
    userAuctionRegistration.userId = sessionInfoDto?.bidderUiDto?.userId;
    userAuctionRegistration.companyId = sessionInfoDto?.bidderUiDto?.companyId;
    userAuctionRegistration.emailId = sessionInfoDto?.bidderUiDto?.primaryEmailId;
    userAuctionRegistration.name = sessionInfoDto?.bidderUiDto?.name;
    userAuctionRegistration.auctionHouseId = sessionInfoDto?.orgCode;

    this.isLoading = true;
    this.bidderService.saveUserAuctionRegistration(userAuctionRegistration).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto.code == AuctionExtConstant.SUCCESS_CODE) {
          this.showSuccessToast$.next(true);
          this.isLoading = false;
          setTimeout(() => {
            this.showSuccessToast$.next(false);
            this.isSubmitted = false;
            this.closeModal();

          }, 2000)

        } else {
          this.errorMsg = apiResponseDto.message;
          this.showErrorToast$.next(true);
          this.isLoading = false;

        }
      },
      error: (err) => {
        console.error(err);

        this.errorMsg = "Error while saving Auction registration. Try again.";
        this.showErrorToast$.next(true);
        this.isLoading = false;

      }
    })
  }

  ngOnDestroy(): void {
    if (this.selectedAuctionSubscription$) {
      this.selectedAuctionSubscription$.unsubscribe()
    }
  }

  inputChoices(requirementId: string): FormArray | undefined {
    const requirementControl = this.requirementForm.controls.find(item => item.get('id')?.value === requirementId);
    return requirementControl?.get("userAnswerChoices") as FormArray;
  }

  inputChoiceControls(requirementId: string): FormControl<any>[] {
    const inputChoices = this.inputChoices(requirementId);
    return inputChoices ? (inputChoices.controls as FormControl<any>[]) : [];
  }

  addInputChoice(requirementId: string, itemText: string) {
    const inputChoices = this.inputChoices(requirementId);

    if (inputChoices) {
      const newControl = this.fb.control(false);
      inputChoices.push(newControl);
    }
  }

  updateInputChoice(requirementId: string, isChecked: boolean) {
    const inputChoices = this.inputChoices(requirementId);

    if (inputChoices) {
      const newControl = this.fb.control(isChecked);
      newControl.disable();
      inputChoices.push(newControl);
    }
  }

  addUserAnswerDate(requirementId: string) {
    const requirementControl = this.requirementForm.controls.find(item => item.get('id')?.value === requirementId);
    const userAnswerDates = requirementControl?.get('userAnswerDates') as FormArray;
    if (userAnswerDates) {
      const newDateControl = this.fb.control(null, Validators.required);
      userAnswerDates.push(newDateControl);
    }
  }

  updateUserAnswerDates(requirementId: string, answerDate: string) {
    const requirementControl = this.requirementForm.controls.find(item => item.get('id')?.value === requirementId);
    const userAnswerDates = requirementControl?.get('userAnswerDates') as FormArray;
    if (userAnswerDates) {
      let ngbDate = AuctionExtUtil.getNgbPickerDate(answerDate);
      const newDateControl = this.fb.control(ngbDate, Validators.required);
      newDateControl.disable();
      userAnswerDates.push(newDateControl);
    }
  }

  changeCommentBoxToggle(requirementId: string) {
    if (this.showCommentBox.includes(requirementId)) {
      let index = this.showCommentBox.findIndex(item => item == requirementId);
      this.showCommentBox.splice(index, 1);

      const requirementControl = this.requirementForm.controls.find(item => item.get('id')?.value === requirementId);
      requirementControl?.get('comments')?.reset();
      requirementControl?.get('comments')?.clearValidators();
      requirementControl?.get('comments')?.updateValueAndValidity();
      this.requirementForm.updateValueAndValidity();
      this.formGroup.updateValueAndValidity();
    } else {
      this.showCommentBox.push(requirementId);

      const requirementControl = this.requirementForm.controls.find(item => item.get('id')?.value === requirementId);
      requirementControl?.get('comments')?.setValidators([Validators.required]);
      requirementControl?.get('comments')?.updateValueAndValidity();
      this.requirementForm.updateValueAndValidity();
      this.formGroup.updateValueAndValidity();
    }
  }
}
