import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { select, Store } from '@ngrx/store';
import { UUID } from 'angular2-uuid';
import { MessageService } from 'primeng/api';
import { BehaviorSubject, Subscription } from 'rxjs';
import { AddressDto } from 'src/app/shared/models/Address';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';
import { BidderUiDto } from 'src/app/shared/models/UserEntityDto';
import { RegistrationService } from 'src/app/shared/services/registration.service';
import { UserService } from 'src/app/shared/services/user.service';
import { SessionActions } from 'src/app/shared/state-management/session/session.actions';
import { selectBidderUiDto } from 'src/app/shared/state-management/session/session.features';
import { AuctionExtConstant } from 'src/app/shared/util/AuctionExtConstant';
import { AuctionExtUtil } from 'src/app/shared/util/AuctionExtUtil';
import { Pattern } from 'src/app/shared/util/Patterns';

@Component({
  selector: 'app-user-address',
  templateUrl: './user-address.component.html',
  styleUrls: ['./user-address.component.sass']
})
export class UserAddressComponent {
  @Output() onNext: EventEmitter<void> = new EventEmitter();
  @Output() onPrevious: EventEmitter<void> = new EventEmitter();
  formGroup: FormGroup;
  
  userEntityDto?: BidderUiDto

  isLoading: boolean = false;
  errorMsg: string | undefined;
  latitude?: number;
  longitude?: number;
  countryShortName?: string
  zoom = 12;
  infoContent = ''
  position?: google.maps.LatLngLiteral;
  options: google.maps.MapOptions = {
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    zoomControl: false,
    scrollwheel: true,
    disableDoubleClickZoom: true,
    minZoom: 8,
  };
  marker = {
    label: { color: 'black', text: '' },
    title: '',
    options: { animation: google.maps.Animation.DROP },
    info: ''
  }

  _showSuccessToast$ = new BehaviorSubject<boolean>(false);
  _showErrorToast$ = new BehaviorSubject<boolean>(false);

  userEntityDtoSubscription$?: Subscription;
  
  @ViewChild(GoogleMap) map!: GoogleMap;
  @ViewChild(MapInfoWindow, { static: false }) info!: MapInfoWindow;

  constructor(
    private registrationService: RegistrationService,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private messageService: MessageService,
    private store: Store
  ) {    
    this.formGroup = this.formBuilder.group({
      searchAddress: new FormControl('',[ Validators.required, Validators.maxLength(1000)]),
      addressLine1: new FormControl('', [Validators.required, Validators.maxLength(250)]),
      addressLine2: new FormControl('',[Validators.maxLength(250)]),
      city: new FormControl('', [Validators.required, Validators.maxLength(250)]),
      state: new FormControl('', [Validators.required, Validators.maxLength(250)]),
      country: new FormControl('', [Validators.required, Validators.maxLength(250)]),
      zipCode: new FormControl('', [Validators.required,  Validators.maxLength(250), Validators.pattern(Pattern.zipCode)]),
    })
  }

  ngOnInit(): void {
    this.formGroup.reset();
    this.userEntityDtoSubscription$ = this.store.pipe(select(selectBidderUiDto)).subscribe(data => {
      if (data) {
        this.userEntityDto = data;
        this.populateUserDetails();
      }
    })
  }

  get fc(): any { return this.formGroup.controls; }
  
  populateUserDetails() {
    this.formGroup.controls['searchAddress'].patchValue(this.userEntityDto?.address?.searchAddress);
    this.formGroup.controls['addressLine1'].patchValue(this.userEntityDto?.address?.addressLine1);
    this.formGroup.controls['addressLine2'].patchValue(this.userEntityDto?.address?.addressLine2);
    this.formGroup.controls['city'].patchValue(this.userEntityDto?.address?.city);
    this.formGroup.controls['state'].patchValue(this.userEntityDto?.address?.state);
    this.formGroup.controls['country'].patchValue(this.userEntityDto?.address?.country);
    this.formGroup.controls['zipCode'].patchValue(this.userEntityDto?.address?.zipCode);

    if (this.userEntityDto?.address?.latitude && this.userEntityDto?.address?.longitude) {
      this.latitude = Number(this.userEntityDto?.address?.latitude);
      this.longitude = Number(this.userEntityDto?.address?.longitude);

      this.position = { lat: this.latitude!, lng: this.longitude! }
      this.marker.label.text = this.userEntityDto?.address?.searchAddress!;
      this.marker.title = this.userEntityDto?.address?.searchAddress!;
      this.marker.info = this.userEntityDto?.address?.searchAddress!;
    }

    this.formGroup.updateValueAndValidity();
  }

  handleAddressChange(address: google.maps.places.PlaceResult) {
    this.latitude = address.geometry!.location!.lat();
    this.longitude = address.geometry!.location!.lng();

    let city = AuctionExtUtil.getAddressByType(address, 'locality');
    if (!city) {
      city = AuctionExtUtil.getAddressByType(address, 'neighborhood');
    }
    let state = AuctionExtUtil.getAddressByType(address, 'administrative_area_level_1');
    let zip = AuctionExtUtil.getAddressByType(address, 'postal_code');
    let addressPart1 = AuctionExtUtil.getAddressByType(address, 'street_number');
    let addressPart2 = AuctionExtUtil.getAddressByType(address, 'route');
    let country = AuctionExtUtil.getAddressByType(address, 'country');
    this.countryShortName = AuctionExtUtil.getAddressShortNameByType(address, 'country');

    // Map View Init
    this.position = { lat: this.latitude, lng: this.longitude }
    this.marker.label.text = address.formatted_address!;
    this.marker.title = address.formatted_address!;
    this.marker.info = address.formatted_address!;

    if (addressPart1 == '' || addressPart2 == '') {
      if (address.formatted_address?.includes(',')) {
        let data = address.formatted_address.split(',')[0]
        this.formGroup.controls['addressLine1'].patchValue(data);
      } else {
        this.formGroup.controls['addressLine1'].patchValue(address.formatted_address)
      }
    } else {
      this.formGroup.controls['addressLine1'].patchValue(addressPart1 + " " + addressPart2);
    }
    this.formGroup.controls['searchAddress'].patchValue(address.formatted_address);
    this.formGroup.controls['state'].patchValue(state);
    this.formGroup.controls['city'].patchValue(city);
    this.formGroup.controls['country'].patchValue(country);
    this.formGroup.controls['zipCode'].patchValue(zip);
    this.formGroup.updateValueAndValidity();
  }

  mergeUserDetails() {
    let userEntityDto: BidderUiDto = AuctionExtUtil.clone(this.userEntityDto);

    let formValue = this.formGroup.value;

    if (!this.userEntityDto?.address) {
      userEntityDto.address = new AddressDto();
      userEntityDto.address.id = UUID.UUID().toString();
      userEntityDto.address!.addressType = 'Company Address';
    }

    userEntityDto.address!.searchAddress = formValue.searchAddress;
    userEntityDto.address!.addressLine1 = formValue.addressLine1;
    userEntityDto.address!.addressLine2 = formValue.addressLine2;
    userEntityDto.address!.city = formValue.city;
    userEntityDto.address!.state = formValue.state;
    userEntityDto.address!.country = formValue.country;
    userEntityDto.address!.zipCode = formValue.zipCode;
    userEntityDto.address!.countryShortName = this.countryShortName;

    userEntityDto.address!.latitude = this.latitude?.toString();
    userEntityDto.address!.longitude = this.longitude?.toString();
    return userEntityDto;
  }

  prevStep() {
    this.onPrevious.emit();
  }

  saveUserProfile() {
    if (this.formGroup.invalid) {
      this.formGroup.markAllAsTouched();
      return;
    }
    this.isLoading = true;
    

    let userEntityDto = this.mergeUserDetails();

    this.userService.updateUserDetails(userEntityDto).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        this.isLoading = false;

        if (apiResponseDto && apiResponseDto.code == "200") {
          let bidderUiDto = apiResponseDto.data as BidderUiDto;
          this.store.dispatch(SessionActions.updateBidderUiDto({ bidderUiDto }));

          this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Address saved successfully'});

          setTimeout(() => {
            this.onNext.emit();
          }, 2000)

        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: apiResponseDto.message!});
        }
      },
      error: (err) => {
        console.log(err);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error while updating details'});
        this.isLoading = false;
      }
    })
  }

  async handleValidSubmit() {
    

    if (this.formGroup.invalid) {
      this.formGroup.markAllAsTouched();
      return;
    }

    this.isLoading = true;

    let userEntityDto = this.mergeUserDetails();

    this.userService.updateUserDetails(userEntityDto).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == AuctionExtConstant.SUCCESS_CODE) {
          let bidderUiDto = apiResponseDto.data as BidderUiDto;
          this.store.dispatch(SessionActions.updateBidderUiDto({ bidderUiDto }));

          this.isLoading = false;
          this.messageService.add({ severity: 'success', summary: 'Success', detail: 'successfully searched'});
          setTimeout(() => {
          }, 2000)
        } else {
          this.isLoading = false;
          this.messageService.add({ severity: 'error', summary: 'Error', detail: apiResponseDto.message});
          

        }
      },
      error: (err) => {
        this.isLoading = false;
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error while updating address'});
      }
    })
  }

  openInfo(marker: MapMarker, content: string) {
    this.infoContent = content;
    this.info.open(marker)
  }

  ngOnDestroy(): void {
    if(this.userEntityDtoSubscription$) {
      this.userEntityDtoSubscription$.unsubscribe();
    }
  }
}
