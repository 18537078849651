import { FirestoreListenerService } from 'src/app/shared/services/firestore-listener.service';
import { AuctionExtConstant } from 'src/app/shared/util/AuctionExtConstant';
import { ServerAPIResponseDto } from '../../../../shared/models/ServerAPIResponseDto';
import { UserLotFavouriteDto } from '../../../../shared/models/user/UserLotFavouriteDto';
import { AfterViewInit, Component, Input, OnInit, OnDestroy } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { BehaviorSubject, firstValueFrom, Subscription } from 'rxjs';
import { UserAuctionEligibleStatus } from 'src/app/shared/enums/UserAuctionEligibleStatus';
import { AuctionBidsHighestData } from 'src/app/shared/models/user/AuctionBidsHighestData';
import { AuctionEntityDto } from 'src/app/shared/models/user/AuctionEntityDto';
import { AuctionLotEntityDto } from 'src/app/shared/models/user/AuctionLotEntityDto';
import { AuctionStatus } from 'src/app/shared/models/user/AuctionStatus';
import { BidIncrementalDto } from 'src/app/shared/models/user/BidIncrementalDto';
import { UserAuctionRegistrationDto } from 'src/app/shared/models/user/UserAuctionRegistrationDto';
import { UserBidsData } from 'src/app/shared/models/user/UserBidsData';
import { UserMaxBidsDto } from 'src/app/shared/models/user/UserMaxBidsDto';
import { LandingAndBidderService } from 'src/app/shared/services/LandingAndBidder.service';
import { UserService } from 'src/app/shared/services/user.service';
import { AuctionExtUtil } from 'src/app/shared/util/AuctionExtUtil';
import { ServerDataFetchService } from 'src/app/shared/services/server-data-fetch.service';
import { UserLotFavouriteWrapperDto } from 'src/app/shared/models/user/UserLotFavouriteWrapperDto';
import { DataRedirectionService } from 'src/app/shared/services/data-redirection.service';
import { select, Store } from '@ngrx/store';
import { selectBidderUiDto } from 'src/app/shared/state-management/session/session.features';
import { BidderUiDto } from 'src/app/shared/models/UserEntityDto';

@Component({
  selector: 'app-my-favourite-lot-card',
  templateUrl: './my-favourite-lot-card.component.html',
  styleUrls: ['./my-favourite-lot-card.component.sass']
})
export class MyFavouriteLotCardComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() userLotFavouriteWrapperDto?: UserLotFavouriteWrapperDto;
  // @Input() userBidsList?: Array<UserBidsData> = [];
  // @Input() userMaxBidsList?: Array<UserMaxBidsDto> = [];
  // @Input() bidsHighestDataList?: Array<AuctionBidsHighestData> = [];

  auctionEntityDto?: AuctionEntityDto;
  bidderUiDto?: BidderUiDto
  // auctionLotEntityDto? : AuctionLotEntityDto;

  userBid?: UserBidsData
  userMaxBid?: UserMaxBidsDto
  bidsHighestData?: AuctionBidsHighestData
  userAuctionRegistrationDto?: UserAuctionRegistrationDto | null;
  sortedBidIncrementalDtoList?: Array<BidIncrementalDto>;

  remainingDurations = 0;
  currentBidPrice: number = 0;
  responseMessage?: string;
  responseValue?: string;

  myBidIsHighest?: boolean
  myMaxBidIsHighest?: boolean
  bidPlaced?: boolean
  preBidPlaced?: boolean
  bidStatus: string = 'NEUTRAL';
  title: string = 'You do not have any bid placed';

  currentBidPrice$ = new BehaviorSubject<number>(0);
  highestBidPrice$ = new BehaviorSubject<number>(0);
  incrementalBidPrice$ = new BehaviorSubject<number>(0);
  isReservedNotMet$ = new BehaviorSubject<boolean>(false);
  nextEligibleBidPrice$ = new BehaviorSubject<number>(0);
  nextEligibleMaxBidPrice$ = new BehaviorSubject<number>(0);

  _showMaxBidButton$ = new BehaviorSubject<boolean>(false);
  _showBidPlaceButton$ = new BehaviorSubject<boolean>(false);
  _showSuccessToast$ = new BehaviorSubject<boolean>(false);
  _showErrorToast$ = new BehaviorSubject<boolean>(false);
  _showErrorButtonToast$ = new BehaviorSubject<boolean>(false);
  _disableBidButton$ = new BehaviorSubject<boolean>(false);
  isLoading$ = new BehaviorSubject<boolean>(false);

  // favoriteLotsList?: Array<UserLotFavouriteDto>;

  countdownTimerDays = 0;
  countdownTimerHours = 0;
  countdownTimerMinutes = 0;
  countdownTimerSeconds = 0;
  counterFunction: any;
  timer: boolean = false;
  isLoading?: boolean = false;

  allAuctionsListSubscription$?: Subscription;
  allLotListSubscription$?: Subscription;

  userInputMaxBid: FormControl = new FormControl('', Validators.required);
  userInputCurrentBid: FormControl = new FormControl('', Validators.required);

  renderView$ = new BehaviorSubject<boolean>(false);


  constructor(
    private dataRedirectionService: DataRedirectionService,
    public bidderService: LandingAndBidderService,
    private router: Router,
    private store: Store
  ) { }

  ngOnInit(): void {
    this.loadBidderUiDto();
    this.renderView$.next(false)

    this.allAuctionsListSubscription$ = this.bidderService.getAllAuctionCardWrapperDtos$.subscribe((auctionCardWrapperDtos) => {
      if (auctionCardWrapperDtos && auctionCardWrapperDtos.length > 0) {
        if (this.userLotFavouriteWrapperDto) {
          let data = auctionCardWrapperDtos.find(item => item.auctionEntityDto!.auctionId == this.userLotFavouriteWrapperDto?.auctionLotEntityDto?.auctionId);
          this.auctionEntityDto = data?.auctionEntityDto;
        }
        this.populateInitialData();
      }
    })

  }

  async loadBidderUiDto() {
    this.bidderUiDto = await firstValueFrom(this.store.pipe(select(selectBidderUiDto)));
  }

  populateInitialData() {
    if (this.userLotFavouriteWrapperDto && this.auctionEntityDto) {
      this.userAuctionRegistrationDto = this.userLotFavouriteWrapperDto.userAuctionRegistrationDto;

      if (this.userLotFavouriteWrapperDto.userBid) {
        this.userBid = this.userLotFavouriteWrapperDto.userBid;
      }

      if (this.userLotFavouriteWrapperDto.userMaxBid) {
        this.userMaxBid = this.userLotFavouriteWrapperDto.userMaxBid;
      }

      if (this.userLotFavouriteWrapperDto.bidsHighestData) {
        this.bidsHighestData = this.userLotFavouriteWrapperDto.bidsHighestData;
        if (this.userLotFavouriteWrapperDto.auctionLotEntityDto?.incrementalWrapperDto?.incrementalBidType != 'SINGLE') {
          this.sortedBidIncrementalDtoList = this.userLotFavouriteWrapperDto.auctionLotEntityDto?.incrementalWrapperDto?.bidIncrementalDtoList?.sort((a, b) => a.upToAmount! - b.upToAmount!);
        }

        // update only revelent fields, do not overwrite auctionBidDetails
        this.highestBidPrice$.next(Number.parseFloat(this.bidsHighestData!.highestBidPrice!.toFixed(2)));
        this.incrementalBidPrice$.next(this.getCalculatedIncrementalBid(this.highestBidPrice$.value));

        if (this.bidsHighestData?.highestBidUserId) {
          this.currentBidPrice$.next(this.highestBidPrice$.value + this.incrementalBidPrice$.value);
        } else {
          this.currentBidPrice$.next(this.highestBidPrice$.value);
        }
        this.userInputCurrentBid.patchValue(this.getFormattedPrice(this.currentBidPrice$.value));

        this.populateMyBidsValue()
      }

      Promise.resolve().then(() => this.renderView$.next(true));
    }

  }

  discardLotFavourite() {
    this.isLoading = true;
    let userLotFavouriteDto = new UserLotFavouriteDto();
    userLotFavouriteDto.auctionId = this.userLotFavouriteWrapperDto?.auctionLotEntityDto?.auctionId;
    userLotFavouriteDto.lotId = this.userLotFavouriteWrapperDto?.auctionLotEntityDto?.lotId;
    userLotFavouriteDto.auctionHouseId = this.userLotFavouriteWrapperDto?.auctionLotEntityDto?.auctionHouseId;
    userLotFavouriteDto.userId = this.bidderUiDto?.userId;

    this.bidderService.discardLotFavourite(userLotFavouriteDto).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == AuctionExtConstant.SUCCESS_CODE) {
          this.bidderService.removeMyFavoriteLotDto(this.userLotFavouriteWrapperDto?.auctionLotEntityDto?.lotId!);
          this.isLoading = false
        } else {
          this.isLoading = false
        }
      },
      error: (error) => {
        this.isLoading = false
      }
    })
  }

  ngAfterViewInit(): void {
    this.clearCountdown();
    this.countdown(this.userLotFavouriteWrapperDto?.auctionLotEntityDto!);
  }

  populateMyBidsValue() {
    if (this.bidsHighestData) {
      this.myBidIsHighest = this.bidsHighestData?.highestBidUserId == this.bidderUiDto?.userId;
      this.myMaxBidIsHighest = this.bidsHighestData?.bucketUserId == this.bidderUiDto?.userId
        && this.bidsHighestData?.bucketPrice! >= this.bidsHighestData.highestBidPrice!;
    }

    if (this.userBid || this.userMaxBid) {
      this.bidPlaced = true;
    } else {
      this.bidPlaced = false;
    }

    if (this.userMaxBid) {
      this.preBidPlaced = true;
    } else {
      this.preBidPlaced = false;
    }

    if (this.bidPlaced) {
      if (this.myBidIsHighest || this.myMaxBidIsHighest) {
        this.bidStatus = 'WIN';
        this.title = 'Highest Bid';
      } else {
        this.bidStatus = 'LOSE';
        this.title = 'Outbid';
      }
    } else {
      this.bidStatus = 'NEUTRAL';
      this.title = 'You do not have any bid placed';
    }
    this.checkPlaceBidButton();
    this.checkMaxBidButton();
  }

  clearCountdown() {
    if (this.timer == true) {
      console.log("For Auction :" + this.userLotFavouriteWrapperDto?.auctionLotEntityDto?.title + " Remaining time clear");
      clearInterval(this.counterFunction);
      this.timer = false;
    }
  }



  countdown( auctionLotEntityDto: AuctionLotEntityDto) {
    if (this.counterFunction) {
      //console.log("Clearing counter function :" + this.counterFunction);
      clearInterval(this.counterFunction);
    }

    let timeZone = auctionLotEntityDto?.timeZone;
    this.remainingDurations = AuctionExtUtil.getRemainingTime(auctionLotEntityDto?.endDate + " " + auctionLotEntityDto?.endTime!, timeZone!);

    this.counterFunction = setInterval(() => {
      let date = auctionLotEntityDto?.endDate;
      let time = auctionLotEntityDto?.endTime;

      this.countdownTimerDays = AuctionExtUtil.getCountdownTimerDays(date + ' ' + time, timeZone!);
      this.countdownTimerHours = AuctionExtUtil.getCountdownTimerHours(date + ' ' + time, timeZone!);
      this.countdownTimerMinutes = AuctionExtUtil.getCountdownTimerMinutes(date + ' ' + time, timeZone!);
      this.countdownTimerSeconds = AuctionExtUtil.getCountdownTimerSeconds(date + ' ' + time, timeZone!);
    }, 1000);

    console.log("Creating a new countdown function " + this.counterFunction);
  }
  
//   countdown1(auctionLotEntityDto: AuctionLotEntityDto) {
//     let _this = this;
//     let timeZone = auctionLotEntityDto.timeZone!;
// 
//     let date = auctionLotEntityDto?.endDate;
//     let time = auctionLotEntityDto?.endTime;
//     let remainingDurations = AuctionExtUtil.getRemainingTime(date + " " + time, timeZone!);
// 
//     if (this.timer == false) {
//       console.log("Creating a new countdown function in supplier small card component - " + auctionLotEntityDto?.title);
// 
//       this.timer = true;
//       this.counterFunction = setInterval(function () {
// 
//         let element = document.getElementById("days" + _this.counterFunction + auctionLotEntityDto.lotId);
//         if (element) {
//           let remainingDays = AuctionExtUtil.getCountdownTimerDays(date + " " + time, timeZone);
//           let remainingHours = AuctionExtUtil.getCountdownTimerHours(date + " " + time, timeZone);
//           let remainingMinutes = AuctionExtUtil.getCountdownTimerMinutes(date + " " + time, timeZone);
//           let remainingSeconds = AuctionExtUtil.getCountdownTimerSeconds(date + " " + time, timeZone);
// 
//           if (remainingDays) {
//             document.getElementById("days" + _this.counterFunction + auctionLotEntityDto.lotId)!.hidden = false;
//             document.getElementById("hours" + _this.counterFunction + auctionLotEntityDto.lotId)!.hidden = false;
//             document.getElementById("minutes" + _this.counterFunction + auctionLotEntityDto.lotId)!.hidden = true;
//             document.getElementById("second" + _this.counterFunction + auctionLotEntityDto.lotId)!.hidden = true;
// 
//             document.getElementById("days" + _this.counterFunction + auctionLotEntityDto.lotId)!.innerText = remainingDays + 'd ';
//             document.getElementById("hours" + _this.counterFunction + auctionLotEntityDto.lotId)!.innerText = remainingHours + 'h';
//           } else {
//             document.getElementById("days" + _this.counterFunction + auctionLotEntityDto.lotId)!.hidden = true;
//             document.getElementById("hours" + _this.counterFunction + auctionLotEntityDto.lotId)!.hidden = false;
//             document.getElementById("minutes" + _this.counterFunction + auctionLotEntityDto.lotId)!.hidden = false;
//             document.getElementById("second" + _this.counterFunction + auctionLotEntityDto.lotId)!.hidden = false;
// 
//             document.getElementById("hours" + _this.counterFunction + auctionLotEntityDto.lotId)!.innerText = ('0' + remainingHours).slice(-2) + ' : ';
//             document.getElementById("minutes" + _this.counterFunction + auctionLotEntityDto.lotId)!.innerText = ('0' + remainingMinutes).slice(-2) + ' : ';
//             document.getElementById("second" + _this.counterFunction + auctionLotEntityDto.lotId)!.innerText = ('0' + remainingSeconds).slice(-2);
//           }
// 
//           //do something later when date is reached
//           if (remainingDurations < 0) {
//             let headline = document.getElementById("headline" + _this.counterFunction + auctionLotEntityDto.lotId);
//             let countdown = document.getElementById("countdown" + _this.counterFunction + auctionLotEntityDto.lotId);
//             // let content = document.getElementById("content" + auction.auctionId);
// 
//             if (headline) {
//               headline.innerText = "Bidding Closed! ";
//             }
//             if (countdown) {
//               countdown.style.display = "none";
//             }
//             // content.style.display = "block";
// 
//             clearInterval(_this.counterFunction);
//           }
//         }
// 
//       }, 1000)
// 
//       console.log("New count down function created in supplier small card component - " + auctionLotEntityDto.title + "   : " + this.counterFunction)
//     }
//   }



  getAddressFromAction(id: string) {
    let address = this.auctionEntityDto?.addresses?.find((item) => item.id == id);
    if (address) {
      return address.city + ", " + address.countryShortName;
    } else {
      return "";
    }
  }

  // Bids
  updateCurrentBidPrice() {
    this.currentBidPrice$.next(this.nextEligibleBidPrice$.value);
    this.currentBidPrice = this.nextEligibleBidPrice$.value;
    this._showErrorButtonToast$.next(false);
  }

  updateMaxBidPrice() {
    this.userInputMaxBid.patchValue(this.getFormattedPrice(this.nextEligibleMaxBidPrice$.value));
    this.userInputMaxBid.updateValueAndValidity();
    this._showErrorButtonToast$.next(false);
    this._disableBidButton$.next(false);
  }

  incrementBid(bid: number, incrementBidValueBasedOnSlab: number) {
    return parseFloat((parseFloat(bid.toFixed(2)) + incrementBidValueBasedOnSlab).toFixed(2));
  }

  decrementBid(bid: number, incrementBidValueBasedOnSlab: number) {
    return parseFloat((parseFloat(bid.toFixed(2)) - incrementBidValueBasedOnSlab).toFixed(2));
  }

  incrementUserBid() {
    // if (!this.bidderUiDto) {
    //   this.landingDashboardService.setLoginButtonVisibility(true);
    // }

    if (this.userInputCurrentBid.value < this.highestBidPrice$.value) {
      this.currentBidPrice$.next(this.highestBidPrice$.value);
      this.userInputCurrentBid.patchValue(AuctionExtUtil.getFormattedPrice(this.auctionEntityDto?.currency?.locale, this.currentBidPrice$.value));
    }

    this.currentBidPrice$.next(AuctionExtUtil.resetPriceFormat(this.userInputCurrentBid.value));

    let incrementBidValueBasedOnSlab = this.getCalculatedIncrementalBid(this.currentBidPrice$.value);
    this.currentBidPrice$.next(this.incrementBid(this.currentBidPrice$.value, incrementBidValueBasedOnSlab));
    this.incrementalBidPrice$.next(this.getConfigureBidPriceBasedOnSlab(this.currentBidPrice$.value));
    this.userInputCurrentBid.patchValue(AuctionExtUtil.getFormattedPrice(this.auctionEntityDto?.currency?.locale, this.currentBidPrice$.value));
  }

  getCalculatedIncrementalBid(inputBidPrice: number): number {
    let isPriceOnEdge = false;

    if (this.checkPriceOnEdge(inputBidPrice)) {
      isPriceOnEdge = true;
    }

    let incrementBidValueBasedOnSlab;
    if (isPriceOnEdge) {
      incrementBidValueBasedOnSlab = this.getHigherSlabIncrementValue(inputBidPrice);
    } else {
      incrementBidValueBasedOnSlab = this.getConfigureBidPriceBasedOnSlab(inputBidPrice);
    }

    return incrementBidValueBasedOnSlab;
  }

  /**
 * Use the upper slab value for decrement and once the calculation is done use the lower slab to display
 * @returns
 */
  decrementUserBid() {
    // if (!this.bidderUiDto) {
    //   this.landingDashboardService.setLoginButtonVisibility(true);
    // }

    let changeSlab = false;
    let isPriceOnEdge = false;

    this.currentBidPrice$.next(AuctionExtUtil.resetPriceFormat(this.userInputCurrentBid.value));

    if (this.isDecrementChangeSlab(this.currentBidPrice$.value)) {
      changeSlab = true;
    }

    if (this.checkPriceOnEdge(this.currentBidPrice$.value)) {
      isPriceOnEdge = true;
    }

    let decrementBidValueBasedOnSlab;

    // if price is on the edge
    if (isPriceOnEdge) {
      decrementBidValueBasedOnSlab = this.getLowerSlabIncrementValue(this.currentBidPrice$.value);
    } else {
      decrementBidValueBasedOnSlab = this.getConfigureBidPriceBasedOnSlab(this.currentBidPrice$.value);
    }

    if (changeSlab && !isPriceOnEdge) {
      decrementBidValueBasedOnSlab = this.getLowerSlabIncrementValue(this.currentBidPrice$.value);
    } else {
      decrementBidValueBasedOnSlab = this.getConfigureBidPriceBasedOnSlab(this.currentBidPrice$.value);
    }

    this.incrementalBidPrice$.next(decrementBidValueBasedOnSlab);

    let bid = this.decrementBid(this.currentBidPrice$.value, decrementBidValueBasedOnSlab);

    // if any bid is placed make sure to check the decremented bid price with highest bid value
    if (this.bidsHighestData?.highestBidUserId) {
      if (bid == this.bidsHighestData?.highestBidPrice! || bid < this.bidsHighestData?.highestBidPrice!) {
        return;
      } else {
        this.currentBidPrice$.next(this.decrementBid(this.currentBidPrice$.value, decrementBidValueBasedOnSlab));
        this.userInputCurrentBid.patchValue(AuctionExtUtil.getFormattedPrice(this.auctionEntityDto?.currency?.locale, this.currentBidPrice$.value));
      }
    } else {
      if (bid < this.bidsHighestData?.highestBidPrice!) {
        return;
      } else {
        this.currentBidPrice$.next(this.decrementBid(this.currentBidPrice$.value, decrementBidValueBasedOnSlab));
        this.userInputCurrentBid.patchValue(AuctionExtUtil.getFormattedPrice(this.auctionEntityDto?.currency?.locale, this.currentBidPrice$.value));
      }
    }

    this.incrementalBidPrice$.next(this.getConfigureBidPriceBasedOnSlab(this.currentBidPrice$.value));
  }

  checkPlaceBidButton() {
    if (this.bidsHighestData && (this.auctionEntityDto?.status == AuctionStatus.LIVE || this.auctionEntityDto?.status == AuctionStatus.LIVE_WAIT)
      && this.auctionEntityDto?.active && this.userLotFavouriteWrapperDto?.auctionLotEntityDto?.active
      && this.userAuctionRegistrationDto?.userAuctionRegistrationStatus == UserAuctionEligibleStatus.APPROVED_BIDDERS
      && AuctionExtUtil.isTimeGreaterThenCurrentTime(this.userLotFavouriteWrapperDto?.auctionLotEntityDto?.endDate! + " " + this.userLotFavouriteWrapperDto?.auctionLotEntityDto?.endTime!, this.auctionEntityDto?.timeZone!)) {
      this._showBidPlaceButton$.next(true);
    } else {
      this._showBidPlaceButton$.next(false);
    }
  }

  checkMaxBidButton() {
    if (this.bidsHighestData && (this.auctionEntityDto?.status == AuctionStatus.LIVE || this.auctionEntityDto?.status == AuctionStatus.LIVE_WAIT)
      && this.auctionEntityDto?.active && this.userLotFavouriteWrapperDto?.auctionLotEntityDto?.active
      && this.userAuctionRegistrationDto?.userAuctionRegistrationStatus == UserAuctionEligibleStatus.APPROVED_BIDDERS
      && AuctionExtUtil.isTimeGreaterThenCurrentTime(this.userLotFavouriteWrapperDto?.auctionLotEntityDto?.endDate! + " " + this.userLotFavouriteWrapperDto?.auctionLotEntityDto?.endTime!, this.auctionEntityDto?.timeZone!)) {
      this._showMaxBidButton$.next(true);
    } else {
      this._showMaxBidButton$.next(false);
    }
  }

  checkPriceOnEdge(inputBidPrice: number): boolean {
    if (this.userLotFavouriteWrapperDto?.auctionLotEntityDto?.incrementalWrapperDto?.incrementalBidType == 'SINGLE') {
      return false;
    } else {
      if (this.sortedBidIncrementalDtoList && this.sortedBidIncrementalDtoList.length > 0) {
        for (var i = 0; i < this.sortedBidIncrementalDtoList!.length; i++) {
          if (inputBidPrice == this.sortedBidIncrementalDtoList![i].upToAmount!) {
            return true;
          }
        }
      }
      return false;
    }
  }

  getConfigureBidPriceBasedOnSlab(inputBidPrice: number): number {
    if (this.userLotFavouriteWrapperDto?.auctionLotEntityDto?.incrementalWrapperDto?.incrementalBidType == 'SINGLE') {
      return this.userLotFavouriteWrapperDto?.auctionLotEntityDto?.incrementalWrapperDto?.constantIncrement!;
    } else {
      if (this.sortedBidIncrementalDtoList && this.sortedBidIncrementalDtoList.length > 0) {
        for (var i = 0; i < this.sortedBidIncrementalDtoList!.length; i++) {
          if (inputBidPrice <= this.sortedBidIncrementalDtoList![i].upToAmount!) {
            return parseFloat(parseFloat(this.sortedBidIncrementalDtoList![i].incrementValue! + "").toFixed(2));
          }
        }
      }
      return 0;
    }
  }

  getIncrementBidPriceBasedOnSlabDecrement(inputBidPrice: number): number {
    if (this.userLotFavouriteWrapperDto?.auctionLotEntityDto?.incrementalWrapperDto?.incrementalBidType == 'SINGLE') {
      return this.userLotFavouriteWrapperDto?.auctionLotEntityDto?.incrementalWrapperDto?.constantIncrement!;
    } else {
      if (this.sortedBidIncrementalDtoList && this.sortedBidIncrementalDtoList.length > 0) {
        for (var i = 0; i < this.sortedBidIncrementalDtoList!.length; i++) {
          if (inputBidPrice < this.sortedBidIncrementalDtoList![i].upToAmount!) {
            return parseFloat(parseFloat(this.sortedBidIncrementalDtoList![i].incrementValue! + "").toFixed(2));
          }
        }
      }
      return 0;
    }
  }

  isDecrementChangeSlab(inputBidPrice: number): boolean {
    let incrementPrice = this.getConfigureBidPriceBasedOnSlab(inputBidPrice);
    let decrementValue = inputBidPrice - incrementPrice;
    let recalculateIncrementPrice = this.getIncrementBidPriceBasedOnSlabDecrement(decrementValue);
    if (incrementPrice != recalculateIncrementPrice) {
      //console.log("Input Price is changing slab " + inputBidPrice);
      return true;
    }
    return false;
  }

  isIncrementChangeSlab(inputBidPrice: number): boolean {
    let incrementPrice = this.getConfigureBidPriceBasedOnSlab(inputBidPrice);
    let decrementValue = inputBidPrice + incrementPrice;
    let recalculateIncrementPrice = this.getConfigureBidPriceBasedOnSlab(decrementValue);
    if (incrementPrice != recalculateIncrementPrice) {
      //console.log("Input Price is changing slab " + inputBidPrice);
      return true;
    }
    return false;
  }

  getLowerSlabIncrementValue(inputBidPrice: number): number {
    let incrementPrice = this.getConfigureBidPriceBasedOnSlab(inputBidPrice);
    let decrementValue = inputBidPrice - incrementPrice;
    let recalculateIncrementPrice = this.getConfigureBidPriceBasedOnSlab(decrementValue);
    return recalculateIncrementPrice;
  }

  getHigherSlabIncrementValue(inputBidPrice: number): number {
    let incrementPrice = this.getConfigureBidPriceBasedOnSlab(inputBidPrice);
    let incrementedValue = inputBidPrice + incrementPrice;
    let recalculateIncrementPrice = this.getConfigureBidPriceBasedOnSlab(incrementedValue);
    return recalculateIncrementPrice;
  }

  checkUserInputCurrentBid() {
    this.userInputCurrentBid.patchValue(AuctionExtUtil.getFormattedPrice(this.auctionEntityDto?.currency?.locale, this.userInputCurrentBid.value));
  }

  checkUserInputMaxCurrentBid() {
    this.userInputMaxBid.patchValue(AuctionExtUtil.getFormattedPrice(this.auctionEntityDto?.currency?.locale, this.userInputMaxBid.value));
  }

  getFormattedPrice(price: number) {
    return AuctionExtUtil.getFormattedPrice(this.auctionEntityDto?.currency?.locale, price);
  }

  getBucket() {
    let toReturn = '';
    if (this.bidsHighestData) {
      if (!this.bidsHighestData?.bucketPrice) {
        toReturn = "";
      } else {
        // if (auctionBidsDetails.userBucketSize && auctionBidsDetails.outplacedPreLotBid) {
        if (this.bidsHighestData.bucketPrice) {
          toReturn = "";
        } else {
          toReturn = this.auctionEntityDto?.currency?.symbol + " " + this.getFormattedPrice(this.bidsHighestData.bucketPrice);
        }
      }
    }
    return toReturn;
  }

  navigateLotDetails() {
    this.dataRedirectionService.clearData();
    this.dataRedirectionService.setResourceIds(this.userLotFavouriteWrapperDto?.auctionLotEntityDto!.auctionHouseId!, this.userLotFavouriteWrapperDto?.auctionLotEntityDto!.auctionId!, this.userLotFavouriteWrapperDto?.auctionLotEntityDto!.lotId!);
    this.bidderService.setSelectedAuctionLot(this.userLotFavouriteWrapperDto?.auctionLotEntityDto!);

    if (this.bidderUiDto && this.bidderUiDto.userId) {
      let auction = this.bidderService._selectedAuction$.value;
      this.router.navigateByUrl('/redirect-loader', { skipLocationChange: true }).then(() => this.router.navigate(['Bidder/lotDetails'], { skipLocationChange: true }));
    } else {
      this.router.navigate(['lotDetails'], { skipLocationChange: true });
    }
  }


  ngOnDestroy(): void {
    this.clearCountdown();

    if (this.allAuctionsListSubscription$) {
      this.allAuctionsListSubscription$.unsubscribe()
    }

  }

}
