import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule, routingComponents } from './app-routing.module';
import { AppComponent } from './app.component';
import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import { SharedModule } from './shared/shared.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BasicAuctionAuthHttpInterceptor } from './shared/interceptors/basic-auction-auth-http.interceptor';
import { environment } from 'src/environments/environment';
import { provideFirebaseApp, initializeApp, FirebaseApp } from '@angular/fire/app';
import { provideFirestore, getFirestore } from "@angular/fire/firestore";
import { getAnalytics, provideAnalytics } from "@angular/fire/analytics";
import { getPerformance, initializePerformance, providePerformance } from "@angular/fire/performance";
import { TagInputModule } from 'ngx-chips';
import { NgxEchartsModule } from 'ngx-echarts';
import { TimeAgoPipe } from './shared/pipes/time-ago.pipe';
import { icons, LucideAngularModule } from 'lucide-angular';
import { DialogService } from 'primeng/dynamicdialog';
import { StoreModule } from '@ngrx/store';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MessageService } from 'primeng/api';
import { sessionFeature } from './shared/state-management/session/session.features';
import { navigationFeature } from './shared/state-management/navigation/navigation.features';
import { OrdinalDatePipe } from './shared/pipes/ordinal-date.pipe';
import { ConfirmationService } from 'primeng/api';

@NgModule({
  declarations: [
    AppComponent,
    routingComponents,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    LucideAngularModule.pick(icons),
    TagInputModule,
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    provideFirestore(() => getFirestore()),
    provideAnalytics(() => getAnalytics()),
    providePerformance(() => getPerformance()),
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    }),
    StoreModule.forRoot({}),
    StoreModule.forFeature(sessionFeature),
    StoreModule.forFeature(navigationFeature),
  ],
  providers: [

    DecimalPipe, DatePipe, TimeAgoPipe, DialogService, NgbActiveModal, MessageService,OrdinalDatePipe,ConfirmationService,
    { provide: HTTP_INTERCEPTORS, useClass: BasicAuctionAuthHttpInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(
    firebaseApp: FirebaseApp
  ) {
    initializePerformance(firebaseApp);
  }
}
