import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, firstValueFrom, Subscription } from 'rxjs';
import { SourcingEnvelopeType } from 'src/app/shared/enums/questionnaire/SourcingEnvelopeType';
import { UserQuestionStatus } from 'src/app/shared/enums/questionnaire/UserQuestionStatus';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';
import { BidderUiDto } from 'src/app/shared/models/UserEntityDto';
import { AdditionalFinancialPrice } from 'src/app/shared/models/questionnaire/AdditionalFinancialPrice';
import { FinancialQuestionTemplate } from 'src/app/shared/models/questionnaire/FinancialQuestionTemplate';
import { QuestionnaireTemplate } from 'src/app/shared/models/questionnaire/QuestionnaireTemplate';
import { QuestionnaireWrapperDto } from 'src/app/shared/models/questionnaire/QuestionnaireWrapperDto';
import { UserRfxQuestionsUiDto } from 'src/app/shared/models/questionnaire/UserRfxQuestionsDto';
import { UserRfxSubcategoryUiDto } from 'src/app/shared/models/questionnaire/UserRfxSubcategoryDto';
import { RfxSubcategoryEntityDto } from 'src/app/shared/models/rfx/RfxSubcategoryEntityDto ';
import { UserRfxSubcategoryStatus } from 'src/app/shared/models/rfx/UserRfxSubcategoryStatus';
import { LandingAndBidderService } from 'src/app/shared/services/LandingAndBidder.service';
import { QuestionnaireService } from 'src/app/shared/services/questionnaire.service';
import { UserService } from 'src/app/shared/services/user.service';
import { selectBidderUiDto } from 'src/app/shared/state-management/session/session.features';
import { AuctionExtConstant } from 'src/app/shared/util/AuctionExtConstant';
import { AuctionExtUtil } from 'src/app/shared/util/AuctionExtUtil';
import { ToWords } from 'to-words';

@Component({
  selector: 'app-rfx-questionnaire-financial-question',
  templateUrl: './rfx-questionnaire-financial-question.component.html',
  styleUrls: ['./rfx-questionnaire-financial-question.component.sass']
})
export class RfxQuestionnaireFinancialQuestionComponent implements OnInit, OnDestroy {
  @Input() selectedFinancialQuestion!: FinancialQuestionTemplate;
  @Output() openNewSectionEvent = new EventEmitter<boolean>();


  subcategoryEntityDto?: RfxSubcategoryEntityDto;
  userEntityDto?: BidderUiDto
  questionnaireWrapperDto?: QuestionnaireWrapperDto;
  questionnaireTemplate?: QuestionnaireTemplate;
  financialQuestionsList: FinancialQuestionTemplate[] = []
  userRfxQuestions: UserRfxQuestionsUiDto[] = [];
  userRfxSubcategoryDto?: UserRfxSubcategoryUiDto;

  // ctrlQuestion: FormControl = new FormControl('');
  // ctrlDescription: FormControl = new FormControl('');
  // ctrlBidderComments: FormControl = new FormControl('');
  // ctrlBidderResponse: FormControl = new FormControl('', Validators.required);
  formGroup: FormGroup

  isLoading: boolean = false;
  isUploadLoading: boolean = false;
  isQuestionSubmitted: boolean = false;
  errorMsg: string | undefined;

  totalBidPrice: number = 0
  totalTaxAmount: number = 0
  totalAdditionalAmount: number = 0
  totalFinalAmount: number = 0

  countIncompleteQuestions: number = 0;
  countCompleteQuestions: number = 0;

  _showErrorToast$ = new BehaviorSubject<Boolean>(false);
  _showSuccessToast$ = new BehaviorSubject<Boolean>(false);

  questionnaireWrapperDtoSubscription$?: Subscription;
  selectedRfxSubscription$?: Subscription;
  userRfxQuestionsSubscription$?: Subscription;
  userRfxSubcategorySubscription$?: Subscription;

  constructor(
    private activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private questionnaireService: QuestionnaireService,
    private landingAndBidderService: LandingAndBidderService,
    private store: Store
  ) {
    this.formGroup = this.fb.group({
      bidderResponse: ['', Validators.required],
      bidderComments: [''],
      additionalPrices: this.fb.array([])
    })
  }

  ngOnInit(): void {
    this.loadBidderUiDto();

    this.userRfxSubcategorySubscription$ = this.landingAndBidderService.getUserRfxSubcategoryDto$.subscribe(data => {
      if (data) {
        this.userRfxSubcategoryDto = data;

        if (this.userRfxSubcategoryDto.status == UserRfxSubcategoryStatus.SUBMITTED) {
          this.isQuestionSubmitted = true;
        } else {
          this.isQuestionSubmitted = false;
        }
      } else {
        this.isQuestionSubmitted = false;
        this.userRfxSubcategoryDto = undefined;
      }
    })

    this.questionnaireWrapperDtoSubscription$ = this.questionnaireService.getQuestionnaireWrapper$.subscribe(data => {
      if (data) {
        this.questionnaireWrapperDto = data;
        this.questionnaireTemplate = data.questionnaireTemplate;

        this.financialQuestionsList = this.questionnaireWrapperDto?.financialQuestionTemplates?.filter(item => item.sectionId == this.selectedFinancialQuestion.sectionId) ?? [];
        this.financialQuestionsList.sort((a, b) => Number(a.sequenceNo) - Number(b.sequenceNo));
      }
    })

    this.selectedRfxSubscription$ = this.landingAndBidderService.getSelectedRfxSubcategory$.subscribe(data => {
      if (data) {
        this.subcategoryEntityDto = data;
      }
    })

    this.userRfxQuestionsSubscription$ = this.questionnaireService.getUserRfxQuestions$.subscribe(data => {
      if (data) {
        this.userRfxQuestions = data
        this.populateUserRfxQuestions();
      } else {
        this.userRfxQuestions = []
        this.populateUserRfxQuestions();
      }
    })
  }

  async loadBidderUiDto() {
    this.userEntityDto = await firstValueFrom(this.store.pipe(select(selectBidderUiDto)));
  }

  openNewSection(){
    this.openNewSectionEvent.emit(true);
  }

  get fc() { return this.formGroup.controls; }

  closeModal(modalRef?: NgbModalRef) {
    if (modalRef) {
      modalRef.close();
    } else {
      this.activeModal.close();
    }
  }

  populateUserRfxQuestions() {
    let currentQuestion = this.userRfxQuestions.find(item => item.questionId == this.selectedFinancialQuestion.questionId);
    if (currentQuestion) {
      this.formGroup.controls['bidderResponse'].patchValue(currentQuestion.bidderResponse);
      this.formGroup.controls['bidderComments'].patchValue(currentQuestion.bidderComments);

      if (this.isQuestionSubmitted) {
        this.formGroup.disable();
      } else {
        this.formGroup.enable();
      }

      this.formGroup.updateValueAndValidity();

      // let totalBidAmount = Number(currentQuestion.bidderResponse) + (Number(currentQuestion.bidderResponse) * this.selectedFinancialQuestion.tax!) / 100
      // this.totalBidAmount$.next(totalBidAmount);

      if (currentQuestion.additionalPrices && currentQuestion.additionalPrices.length > 0) {
        this.additionalPrices.clear();
        currentQuestion.additionalPrices.forEach(additionalPrice => {
          this.additionalPrices.push(this.updateAdditionalPrices(additionalPrice));
        })
      }

    } else {
      if (this.selectedFinancialQuestion?.additionalPrices && this.selectedFinancialQuestion.additionalPrices.length > 0) {
        this.additionalPrices.clear();
        this.selectedFinancialQuestion.additionalPrices.forEach(additionalPrice => {
          this.additionalPrices.push(this.updateAdditionalPrices(additionalPrice));
        })
      }
    }

    this.countCompleteQuestions = this.userRfxQuestions.filter(item => item.status == UserQuestionStatus.COMPLETED && item.sectionId == this.selectedFinancialQuestion.sectionId).length;
    this.countIncompleteQuestions = this.financialQuestionsList.length - this.countCompleteQuestions;

    this.refreshAmountValues();
  }

  resetCurrentQuestion() {
    this.additionalPrices.clear();

    this.formGroup.controls['bidderResponse'].reset();
    this.formGroup.controls['bidderComments'].reset();

    this.formGroup.controls['bidderResponse'].enable();
    this.formGroup.controls['bidderComments'].enable();

    this._showSuccessToast$.next(false);
    this._showErrorToast$.next(false);
    this.populateUserRfxQuestions();
  }

  nextQuestion() {
    let currentSequenceNo = this.selectedFinancialQuestion.sequenceNo;
    if (Number(currentSequenceNo) < this.financialQuestionsList.length) {
      let index = this.financialQuestionsList.findIndex(item => item.sequenceNo == currentSequenceNo);
      this.selectedFinancialQuestion = this.financialQuestionsList[index + 1];
      this.resetCurrentQuestion();
    }
  }

  prevQuestion() {
    let currentSequenceNo = this.selectedFinancialQuestion.sequenceNo;
    if (Number(currentSequenceNo) > 1) {
      let index = this.financialQuestionsList.findIndex(item => item.sequenceNo == currentSequenceNo);
      this.selectedFinancialQuestion = this.financialQuestionsList[index - 1];
      this.resetCurrentQuestion();
    }
  }

  isDisabledPrev() {
    return Number(this.selectedFinancialQuestion.sequenceNo) == 1;
  }

  isDisabledNext() {
    return Number(this.selectedFinancialQuestion.sequenceNo) == this.financialQuestionsList.length;
  }

  clearOptionSelection() {
    this.formGroup.controls['bidderResponse'].reset();
    this.additionalPrices.controls.forEach(item => {
      item.get('additionalPrice')?.patchValue('');
    })

    this.totalBidPrice = 0
    this.totalTaxAmount = 0
    this.totalAdditionalAmount = 0
    this.totalFinalAmount = 0
  }

  getTotalFinalAmountInWords() {
    const toWords = new ToWords();
    return toWords.convert(this.totalFinalAmount);
  }

  getFormattedPrice(price: number) {
    return AuctionExtUtil.getFormattedPrice(this.subcategoryEntityDto?.currency?.locale, price);
  }

  refreshAmountValues() {
    this.totalBidPrice = 0
    this.totalTaxAmount = 0
    this.totalAdditionalAmount = 0
    this.totalFinalAmount = 0

    if (this.formGroup.controls['bidderResponse'].invalid) {
      this.totalBidPrice = 0
    } else {
      let bidAmount = this.formGroup.controls['bidderResponse'].getRawValue();
      this.totalBidPrice = Number(bidAmount) * this.selectedFinancialQuestion.quantity!;
    }

    this.additionalPrices.controls.forEach(item => {
      let additionalPrice = item.get('additionalPrice')?.getRawValue();
      if (additionalPrice) {
        this.totalAdditionalAmount += Number(additionalPrice);
      }
    })

    if (this.selectedFinancialQuestion.tax) {
      this.totalTaxAmount = (this.totalBidPrice * this.selectedFinancialQuestion.tax) / 100;
    } else {
      this.totalTaxAmount = 0;
    }

    this.totalFinalAmount = this.totalBidPrice + this.totalTaxAmount + this.totalAdditionalAmount;

    this.totalBidPrice = parseFloat(this.totalBidPrice.toFixed(2))
    this.totalTaxAmount = parseFloat(this.totalTaxAmount.toFixed(2))
    this.totalAdditionalAmount = parseFloat(this.totalAdditionalAmount.toFixed(2))
    this.totalFinalAmount = parseFloat(this.totalFinalAmount.toFixed(2))
  }

  mergeUserRfxQuestionsDto() {
    let formGroupValue = this.formGroup.getRawValue();

    let userRfxQuestionsDto = new UserRfxQuestionsUiDto();
    userRfxQuestionsDto.questionId = this.selectedFinancialQuestion.questionId;
    userRfxQuestionsDto.sectionId = this.selectedFinancialQuestion.sectionId;
    userRfxQuestionsDto.questionnaireId = this.selectedFinancialQuestion.questionnaireId;
    userRfxQuestionsDto.orgCode = this.subcategoryEntityDto?.orgCode;
    userRfxQuestionsDto.rfxId = this.subcategoryEntityDto?.rfxId;
    userRfxQuestionsDto.rfxSubcategoryId = this.subcategoryEntityDto?.subcategoryId;
    userRfxQuestionsDto.envelopeType = SourcingEnvelopeType.FINANCIAL;
    userRfxQuestionsDto.name = this.userEntityDto?.name;
    userRfxQuestionsDto.firstName = this.userEntityDto?.firstName;
    userRfxQuestionsDto.lastName = this.userEntityDto?.lastName;
    userRfxQuestionsDto.companyId = this.userEntityDto?.companyId;
    userRfxQuestionsDto.emailId = this.userEntityDto?.primaryEmailId;
    userRfxQuestionsDto.bidderResponse = formGroupValue.bidderResponse;
    userRfxQuestionsDto.bidderComments = formGroupValue.bidderComments;
    userRfxQuestionsDto.companyName = this.userEntityDto?.companyName;
    userRfxQuestionsDto.status = UserQuestionStatus.COMPLETED;
    userRfxQuestionsDto.additionalPrices = formGroupValue.additionalPrices;

    userRfxQuestionsDto.unitPriceQtyTotal = Number(formGroupValue.bidderResponse) * this.selectedFinancialQuestion.quantity!;
    userRfxQuestionsDto.unitPriceQtyTaxTotal = this.totalBidPrice + this.totalTaxAmount;
    userRfxQuestionsDto.unitPriceQtyTaxAdditionalPricesTotal = this.totalFinalAmount;
    userRfxQuestionsDto.bidderQuantity = this.selectedFinancialQuestion.quantity;
    userRfxQuestionsDto.bidderUom = this.selectedFinancialQuestion.uom;
    userRfxQuestionsDto.bidderTax = this.selectedFinancialQuestion.tax;

    return userRfxQuestionsDto;
  }

  saveQuestion() {
    this._showErrorToast$.next(false);
    this.errorMsg = "";

    if (this.formGroup.invalid) {
      return;
    }

    let userRfxQuestionsDto = this.mergeUserRfxQuestionsDto()
    this.isLoading = true;

    this.questionnaireService.userRfxQuestions(userRfxQuestionsDto).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto.code == AuctionExtConstant.SUCCESS_CODE) {
          let data = apiResponseDto.data as UserRfxQuestionsUiDto[];
          this.questionnaireService.updateUserRfxQuestions(data);

          this._showSuccessToast$.next(true);
          this.isLoading = false;
          setTimeout(() => {
            this._showSuccessToast$.next(false);
            // Navigate to next question
            if (Number(this.selectedFinancialQuestion.sequenceNo) == this.financialQuestionsList.length) {
              this.openNewSection()
              this.closeModal();
;
            } else {
              this.nextQuestion();
            }
          }, 2000)
        } else {
          this.errorMsg = apiResponseDto.message;
          this._showErrorToast$.next(true);
          this.isLoading = false;
        }
      },
      error: (err) => {
        console.error(err);
        this.errorMsg = "Error while saving rfx registration. Try again.";
        this._showErrorToast$.next(true);
        this.isLoading = false;

      }
    })
  }

  // question Options
  get additionalPrices(): FormArray {
    return this.formGroup.get("additionalPrices") as FormArray
  }

  updateAdditionalPrices(option: AdditionalFinancialPrice): FormGroup {
    return this.fb.group({
      label: [option.label, Validators.required],
      mandatory: [option.mandatory],
      additionalPrice: option.mandatory ? [option.additionalPrice, Validators.required] : [option.additionalPrice],
    })
  }

  ngOnDestroy(): void {
    if (this.questionnaireWrapperDtoSubscription$) {
      this.questionnaireWrapperDtoSubscription$.unsubscribe();
    }
    if (this.selectedRfxSubscription$) {
      this.selectedRfxSubscription$.unsubscribe()
    }
    if (this.userRfxQuestionsSubscription$) {
      this.userRfxQuestionsSubscription$.unsubscribe()
    }
    if (this.userRfxSubcategorySubscription$) {
      this.userRfxSubcategorySubscription$.unsubscribe()
    }
  }
}
