import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, firstValueFrom, Subscription } from 'rxjs';
import { QuestionResponseType } from 'src/app/shared/enums/questionnaire/QuestionResponseType';
import { SourcingEnvelopeType } from 'src/app/shared/enums/questionnaire/SourcingEnvelopeType';
import { QuestionnaireTemplate } from 'src/app/shared/models/questionnaire/QuestionnaireTemplate';
import { QuestionnaireWrapperDto } from 'src/app/shared/models/questionnaire/QuestionnaireWrapperDto';
import { SectionTemplate } from 'src/app/shared/models/questionnaire/SectionTemplate';
import { TechnicalQuestionTemplate } from 'src/app/shared/models/questionnaire/TechnicalQuestionTemplate';
import { AuctionEntityDto } from 'src/app/shared/models/user/AuctionEntityDto';
import { LandingAndBidderService } from 'src/app/shared/services/LandingAndBidder.service';
import { QuestionnaireService } from 'src/app/shared/services/questionnaire.service';
import { QuestionnaireTechnicalQuestionComponent } from '../questionnaire-technical-question/questionnaire-technical-question.component';
import { FormControl, Validators } from '@angular/forms';
import { AuctionExtConstant } from 'src/app/shared/util/AuctionExtConstant';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';
import { UserAuctionRegistrationDto } from 'src/app/shared/models/user/UserAuctionRegistrationDto';
import { UserAuctionQuestionsDto } from 'src/app/shared/models/questionnaire/UserAuctionQuestionsDto';
import { UserQuestionStatus } from 'src/app/shared/enums/questionnaire/UserQuestionStatus';
import { Store } from '@ngrx/store';
import { selectBidderUiDto, selectSessionInfo } from 'src/app/shared/state-management/session/session.features';

@Component({
  selector: 'app-questionnaire',
  templateUrl: './questionnaire.component.html',
  styleUrls: ['./questionnaire.component.sass']
})
export class QuestionnaireComponent implements OnInit, OnDestroy {
  @Input() selectedAuction?: AuctionEntityDto;
  @Input() isReadOnly: boolean = false;

  ctrlTermsCondition: FormControl = new FormControl(false, Validators.requiredTrue);

  questionnaireWrapperDto?: QuestionnaireWrapperDto;
  questionnaireTemplate?: QuestionnaireTemplate;
  sectionTemplateList: SectionTemplate[] = []
  technicalQuestionsList: TechnicalQuestionTemplate[] = []
  userAuctionQuestions: UserAuctionQuestionsDto[] = [];

  selectedSectionTemplate?: SectionTemplate;

  isLoading: boolean = false;
  isDataLoading: boolean = false;
  isEnableTermsConditions: boolean = false;
  questionModalRef?: NgbModalRef;
  errorMsg: string | undefined;

  _showSuccessToast$ = new BehaviorSubject<boolean>(false);
  _showErrorToast$ = new BehaviorSubject<boolean>(false);

  questionnaireWrapperDtoSubscription$?: Subscription;
  userAuctionQuestionsSubscription$?: Subscription;

  constructor(
    private activeModal: NgbActiveModal,
    private ngbModal: NgbModal,
    private landingAndBidderService: LandingAndBidderService,
    private questionnaireService: QuestionnaireService,
    private store: Store
  ) { }

  ngOnInit(): void {
    let auctionHouseDto = this.landingAndBidderService.getCurrentAuctionHouse$.value;

    this.loadQuestionnaire();

    this.questionnaireWrapperDtoSubscription$ = this.questionnaireService.getQuestionnaireWrapper$.subscribe(data => {
      if (data) {
        this.questionnaireWrapperDto = data;
        this.questionnaireTemplate = data.questionnaireTemplate;

        this.sectionTemplateList = this.questionnaireWrapperDto?.sectionTemplateList?.filter(item => item.envelopeType == SourcingEnvelopeType.TECHNICAL) ?? [];
        this.sectionTemplateList.sort((a, b) => Number(a.sequenceNo) - Number(b.sequenceNo));
        if (!this.selectedSectionTemplate) {
          this.selectedSectionTemplate = this.sectionTemplateList[0];
        }

        this.technicalQuestionsList = this.questionnaireWrapperDto?.technicalQuestionTemplates?.filter(item => item.sectionId == this.selectedSectionTemplate?.sectionId) ?? [];
        this.technicalQuestionsList.sort((a, b) => Number(a.sequenceNo) - Number(b.sequenceNo));

        this.checkQuestionsCompletion();
      } else {
        this.questionnaireWrapperDto = undefined;
        this.questionnaireTemplate = undefined;
        this.sectionTemplateList = []
        this.technicalQuestionsList = []
      }
    })

    this.userAuctionQuestionsSubscription$ = this.questionnaireService.getUserAuctionQuestions$.subscribe(data => {
      if (data) {
        this.userAuctionQuestions = data;
        this.checkQuestionsCompletion();
      } else {
        this.userAuctionQuestions = []
      }
    })
  }

  async loadQuestionnaire() {
    let bidderUiDto = await firstValueFrom(this.store.select(selectBidderUiDto));
    if (this.selectedAuction?.questionnaireId) {
      this.isDataLoading = true;
      await this.questionnaireService.loadQuestionnaireWrapperDto(this.selectedAuction.questionnaireId!);
      if (!this.isReadOnly) {
        await this.questionnaireService.loadUserAuctionQuestions(this.selectedAuction.auctionId!, bidderUiDto?.userId!);
      }
      this.isDataLoading = false;

      this.checkQuestionsCompletion();
    }
  }

  checkQuestionsCompletion() {
    // this.totalCompleteQuestions = this.userAuctionQuestions.filter(item => item.status == UserQuestionStatus.COMPLETED
    //   && item.sectionId == this.selectedSectionTemplate!.sectionId).length;
    // this.totalIncompleteQuestions = this.technicalQuestionsList.length - this.totalCompleteQuestions;

    let mandatoryQuestions = this.questionnaireWrapperDto?.technicalQuestionTemplates?.filter(item => item.responseMandatory).map(item => item.questionId) ?? [];

    // this.isTechnicalCompleted = mandatoryQuestions.every(id => this.userAuctionQuestions.some(item => item.questionId === id));

    this.isEnableTermsConditions = mandatoryQuestions.every(id => this.userAuctionQuestions.some(item => item.questionId === id));
  }

  closeModal() {
    this.activeModal.close();
  }

  changeSection(sectionTemplate: SectionTemplate) {
    this.selectedSectionTemplate = sectionTemplate;
    this.technicalQuestionsList = this.questionnaireWrapperDto?.technicalQuestionTemplates?.filter(item => item.sectionId == sectionTemplate.sectionId) ?? []

    this.checkQuestionsCompletion();
  }

  openTechnicalQuestion(technicalQuestion: TechnicalQuestionTemplate) {
    this.questionModalRef = this.ngbModal.open(QuestionnaireTechnicalQuestionComponent, {
      size: 'xl', centered: true,
      backdrop: 'static', keyboard: false,
    })
    this.questionModalRef.componentInstance.selectedTechnicalQuestion = technicalQuestion;
  }

  getTitleResponseType(responseType: QuestionResponseType) {
    if (responseType == QuestionResponseType.SINGLE_CHOICE) {
      return "Single Choice Response";
    } else if (responseType == QuestionResponseType.MULTI_CHOICE) {
      return "Multi Choice Response";
    } else if (responseType == QuestionResponseType.DATE) {
      return "Date Response";
    } else if (responseType == QuestionResponseType.DATE_RANGE) {
      return "Date Range Response";
    } else if (responseType == QuestionResponseType.ALPHANUMERIC) {
      return "Alphanumeric Response";
    }
    return "Select Response Type";
  }

  getQuestionStatus(questionId: string) {
    let currentQuestion = this.userAuctionQuestions.find(item => item.questionId == questionId);
    if (currentQuestion) {
      return currentQuestion.status == UserQuestionStatus.COMPLETED ? 'Complete' : 'Incomplete';
    }
    return 'Incomplete';
  }


  async saveAuctionRegistration() {
    this._showErrorToast$.next(false);
    this.errorMsg = "";

    if (!this.isEnableTermsConditions || this.ctrlTermsCondition.invalid) {
      return;
    }

    let sessionInfoDto = await firstValueFrom(this.store.select(selectSessionInfo));

    let userAuctionRegistration = new UserAuctionRegistrationDto();
    userAuctionRegistration.auctionId = this.selectedAuction?.auctionId;
    userAuctionRegistration.userId = sessionInfoDto?.bidderUiDto?.userId;
    userAuctionRegistration.emailId = sessionInfoDto?.bidderUiDto?.primaryEmailId;
    userAuctionRegistration.name = sessionInfoDto?.bidderUiDto?.name;
    userAuctionRegistration.auctionHouseId = sessionInfoDto?.orgCode;

    this.isLoading = true;
    this.landingAndBidderService.saveUserAuctionRegistration(userAuctionRegistration).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto.code == AuctionExtConstant.SUCCESS_CODE) {
          this._showSuccessToast$.next(true);
          this.isLoading = false;
          setTimeout(() => {
            this._showSuccessToast$.next(false);
            this.closeModal();

          }, 2000)

        } else {
          this.errorMsg = apiResponseDto.message;
          this._showErrorToast$.next(true);
          this.isLoading = false;
        }
      },
      error: (err) => {
        console.error(err);
        this.errorMsg = "Error while saving Auction registration. Try again.";
        this._showErrorToast$.next(true);
        this.isLoading = false;
      }
    })
  }

  ngOnDestroy(): void {
    if (this.questionnaireWrapperDtoSubscription$) {
      this.questionnaireWrapperDtoSubscription$.unsubscribe();
    }
    if (this.userAuctionQuestionsSubscription$) {
      this.userAuctionQuestionsSubscription$.unsubscribe()
    }
  }
}
