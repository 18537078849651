import { Injectable } from '@angular/core';
import { getAnalytics, logEvent } from '@angular/fire/analytics';
import { Firestore, Timestamp, Unsubscribe, collection, onSnapshot, query, where } from '@angular/fire/firestore';
import { AdminDashboardService } from './admin-dashboard.service';
import { OrganizationUiDto } from '../models/user/AuctionHouseDto';
import { LandingAndBidderService } from './LandingAndBidder.service';
import { firstValueFrom } from 'rxjs';
import { ServerDataFetchService } from './server-data-fetch.service';
import { AuctionExtConstant } from '../util/AuctionExtConstant';
import { ServerAPIResponseDto } from '../models/ServerAPIResponseDto';
import { AuctionDataCardsWrapperDto } from '../models/AuctionDataCardsWrapperDto';
import { AuctionEntityDto } from '../models/user/AuctionEntityDto';
import { AuctionCardWrapperDto } from '../models/AuctionCardWrapperDto';
import { MultiLotWrapperDto } from '../models/MultiLotWrapperDto';
import { AuctionAggregateDto as AuctionsAggregateDto } from '../models/user/AuctionAggregateDto';
import { UserAuctionRegistrationWrapperDto } from '../models/UserAuctionRegistrationWrapperDto';
import { selectSessionInfo } from '../state-management/session/session.features';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root'
})
export class ApplicationListenerService {

  collectionAuctions: string = "AUCTIONS";
  collectionAuctionsAggregate: string = "AUCTIONS_AGGREGATE";
  collectionOrganization: string = "ORGANIZATION";
  collectionUserAuctionRegistration: string = "USER_AUCTION_REGISTRATION";
  collectionDeploymentConfiguration: string = "DEPLOYMENT_CONFIGURATION";

  organizationUnsubscribe: Unsubscribe | undefined;
  allUserAuctionRegistrationsUnsubscribe: Unsubscribe | undefined;
  myAuctionRegistrationsUnsubscribe: Unsubscribe | undefined;
  lastFloorBidMessagesUnsubscribe: Unsubscribe | undefined;

  lastFetchTimestamp = Timestamp.now();

  constructor(
    private firestore: Firestore,
    private adminService: AdminDashboardService,
    private landingAndBidderService: LandingAndBidderService,
    private serverDataFetchService: ServerDataFetchService,
    private store: Store
  ) { }



  // This is the first method to be called on application startup.
  async loadApplicationInitialData() {

    // TODO - change all method to Sync
    await this.loadDeploymentConfiguration();
    // this.queryAuctionHouseBasedOnDomainName();
    await this.getOrganizationDtoFromServerSync();
    await this.loadTimezoneDataFromServerSync();
    await this.loadCategoriesDataFromServerSync();
    await this.loadAllAuctionCardWrapperDtosSync();
    this.attachFirestoreAuctionChangeListener();
    this.attachFirestoreAuctionAggregateChangeListener();
    this.attachFirestoreOrganizationChangeListener();
  }




  /**
   * Read the auction house based on auction house Id.
   */
  // queryAuctionHouseBasedOnDomainName() {
  //   logEvent(getAnalytics(), 'queryAuctionHouseBasedOnDomainName');

  //   this.adminService.setAuctionHouseDataLoaded(false);
  //   let domainName = AuctionExtUtil.getSubDomainName();
  //   if (domainName) {
  //     let auctionHouseRef = collection(this.firestore, this.collectionAuctionHouses);
  //     const auctionHouseQuery = query(auctionHouseRef, where('domainName', '==', domainName), where('active', '==', true));

  //     this.auctionHouseUnsubscribe = onSnapshot(auctionHouseQuery, (querySnapshot) => {
  //       let auctionsHouses = querySnapshot.docs.map(item => item.data());

  //       logEvent(getAnalytics(), 'queryAuctionHouseBasedOnDomainName Data Received size : ' + auctionsHouses.length);
  //       console.log("queryAuctionHouseBasedOnDomainName Data Received size: " + auctionsHouses.length);

  //       let auctionHouse = auctionsHouses[0] as AuctionHouseDto;

  //       if (auctionHouse == undefined) {
  //         auctionHouse = new AuctionHouseDto();
  //         auctionHouse.isAvailable = false;
  //       } else {
  //         auctionHouse.isAvailable = true;
  //         // this.listenTotalBidsOfAuction(auctionHouse.auctionHouseId!);
  //       }

  //       this.adminService.updateCurrentAuctionHouse(auctionHouse)
  //       this.landingAndBidderService.updateCurrentAuctionHouse(auctionHouse)
  //       this.adminService.setAuctionHouseDataLoaded(true);
  //     })
  //   }
  // }

  async getOrganizationDtoFromServerSync() {
    this.adminService.setAuctionHouseDataLoaded(false);
    try {
      let apiResponseDto = await firstValueFrom(this.serverDataFetchService.getOrganizationDataFromServer());

      if (apiResponseDto && apiResponseDto.code == AuctionExtConstant.SUCCESS_CODE) {
        let auctionHouse = apiResponseDto.data as OrganizationUiDto;

        if (auctionHouse == undefined) {
          auctionHouse = new OrganizationUiDto();
          auctionHouse.isAvailable = false;
        } else {
          auctionHouse.isAvailable = true;
        }

        this.adminService.updateCurrentAuctionHouse(auctionHouse)
        this.landingAndBidderService.updateCurrentAuctionHouse(auctionHouse)
        this.adminService.setAuctionHouseDataLoaded(true);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async loadDeploymentConfiguration() {
    try {
      let apiResponseDto = await firstValueFrom(this.serverDataFetchService.getDeploymentConfiguration());

      if (apiResponseDto && apiResponseDto.code == AuctionExtConstant.SUCCESS_CODE) {
        this.adminService.updateDeploymentConfiguration(apiResponseDto.data!);
      }
    } catch (error) {
      console.error(error);
    }
  }

  attachFirestoreOrganizationChangeListener() {
    let organizationDto = this.adminService._currentAuctionHouse$.getValue();
    if (organizationDto) {
      let auctionHouseRef = collection(this.firestore, this.collectionOrganization);
      let querySnapshot = query(auctionHouseRef,
        where('orgCode', '==', organizationDto.orgCode),
        where('active', '==', true),
        where('updateTimeUtc', '>', this.lastFetchTimestamp));

        this.organizationUnsubscribe = onSnapshot(querySnapshot, documentSnapshots => {
        let organizations = documentSnapshots.docChanges().map(change => change.doc.data());

        if (organizations && organizations.length > 0) {
          let organization = organizations[0] as OrganizationUiDto;

          let timestamp = organization.updateTimeUtc as Timestamp;
          organization.updateTimeUtc = (timestamp.seconds * 1000).toString();
          organization.isAvailable = true;

          this.adminService.updateCurrentAuctionHouse(organization);
          this.landingAndBidderService.updateCurrentAuctionHouse(organization);
        }
      });
    }
  }

  /**
   *
   * @param lastFetchTimestamp
   */
  attachFirestoreAuctionChangeListener() {
    let organizationDto = this.adminService._currentAuctionHouse$.getValue();
    if (organizationDto) {
      let auctionRef = collection(this.firestore, this.collectionAuctions);
      let auctionQuery = query(auctionRef, where('orgCode', '==', organizationDto.orgCode), where('updateTimeUtc', '>', this.lastFetchTimestamp));

      onSnapshot(auctionQuery, documentSnapshots => {
        let updatedAuctionDtos = documentSnapshots.docChanges().map(change => change.doc.data() as AuctionEntityDto);

        // update only auction object in landing service
        this.landingAndBidderService.updateAuctionDtosChanges(updatedAuctionDtos);

        // update wrraper dto in landing service after 2 secs
        setTimeout( () => {
          let auctionIdsSet = updatedAuctionDtos.map(item => item.auctionId!);
          if (auctionIdsSet.length > 0) {
            this.getAuctionCardWrapperDtoSelected(auctionIdsSet);
          }
        }, 2000)
      });
    }
  }

  /**
   *
   * @param lastFetchTimestamp
   */
  attachFirestoreAuctionAggregateChangeListener() {
    let organizationDto = this.adminService._currentAuctionHouse$.getValue();
    if (organizationDto) {
      let auctionRef = collection(this.firestore, this.collectionAuctionsAggregate);
      let auctionQuery = query(auctionRef, where('orgCode', '==', organizationDto.orgCode), where('updateTimeUtc', '>', this.lastFetchTimestamp));

      onSnapshot(auctionQuery, documentSnapshots => {
        let updatedAuctionAggregateDtos = documentSnapshots.docChanges().map(change => change.doc.data() as AuctionsAggregateDto);

        // update auction aggregate data
        this.landingAndBidderService.updateAuctionAggregateChanges(updatedAuctionAggregateDtos);

      });
    }
  }

    /**
  * Fetch the master data from server
  * This method must be call ones
  */
    async loadTimezoneDataFromServerSync() {
      this.adminService.setMasterDataLoaded(false);


      try {
        let apiResponseDto = await firstValueFrom(this.serverDataFetchService.getTimezonesDataFromServer());

        if (apiResponseDto && apiResponseDto.code == AuctionExtConstant.SUCCESS_CODE) {
          let timezones = apiResponseDto.data as any;

          this.adminService.updateTimezonesData(timezones);
          this.landingAndBidderService.updateTimezonesData(timezones);
          this.adminService.setMasterDataLoaded(true);
        }
      } catch (error) {
        console.error(error);
      }
    }

      /**
  * Fetch the master data from server
  * This method must be call ones
  */
  async loadCategoriesDataFromServerSync() {
    this.adminService.setMasterDataLoaded(false);

    try {
      let apiResponseDto = await firstValueFrom(this.serverDataFetchService.getCategoriesDataFromServer());

      if (apiResponseDto && apiResponseDto.code == AuctionExtConstant.SUCCESS_CODE) {
        let categories = apiResponseDto.data as any;

        this.adminService.updateCategoriesData(categories);
        this.landingAndBidderService.updateCategoriesData(categories);
        this.adminService.setMasterDataLoaded(true);
      }
    } catch (error) {
      console.error(error);
    }
  }

  // loadAuctionCardWrapperDtosInitial() {
  //   this.serverDataFetchService.getAuctionCardWrapperDtosInitial().subscribe({
  //     next: (apiResponseDto: ServerAPIResponseDto) => {
  //       if (apiResponseDto && apiResponseDto.code == AuctionExtConstant.SUCCESS_CODE) {
  //         let auctionCardWrapperDtosInitial = apiResponseDto.data as AuctionDataCardsWrapperDto;
  //         this.landingAndBidderService.updateAuctionWrapperDtos(auctionCardWrapperDtosInitial);

  //         // Load Remaining AuctionCardWrapperDtos
  //         this.asyncloadAllAuctionCardWrapperDtos();
  //       }
  //     },
  //     error: (error) => {
  //       console.error(error);
  //     }
  //   })
  // }

  private async loadAllAuctionCardWrapperDtosSync() {

    try {
      let apiResponseDto = await firstValueFrom(this.serverDataFetchService.getAuctionCardWrapperDtosRemaining());

      if (apiResponseDto && apiResponseDto.code == AuctionExtConstant.SUCCESS_CODE) {
        let auctionCardWrapperDtosRemaining = apiResponseDto.data as AuctionDataCardsWrapperDto;
        this.landingAndBidderService.setApplicationStartupAuctionWrapperDtos(auctionCardWrapperDtosRemaining);
        console.log("All auctions data loaded from server ")
      }
    } catch (error) {
      console.error(error);
    }
  }

  /**
 * Get the AuctionCardWrapperDtos for a given auctionIds.
 * This method is called when client receive firestore update event
 * @param auctionIds
 */
  private getAuctionCardWrapperDtoSelected(auctionIds: string[]) {
    if (auctionIds && auctionIds.length > 0) {
      console.log("updating auctionIds : " + auctionIds.length);
      this.serverDataFetchService.getAuctionCardWrapperDtosByIds(auctionIds).subscribe({
        next: (apiResponseDto: ServerAPIResponseDto) => {
          if (apiResponseDto && apiResponseDto.code == AuctionExtConstant.SUCCESS_CODE) {
            let updatedAuctionCardWrapperDtos = apiResponseDto.data as AuctionCardWrapperDto[];
            console.log("Updated AuctionCardWrapperDto received " + updatedAuctionCardWrapperDtos?.length)
            if (updatedAuctionCardWrapperDtos) {
              this.landingAndBidderService.updateAuctionWrapperDtosChanges(updatedAuctionCardWrapperDtos);
            }
          }
        },
        error: (error) => {
          console.error(error);
        }
      })
    }
  }

  async loadAndSetMyUserAuctionRegistrationWrappersSync() {
    // Load all the registration of user
    let sessionInfoDto = await firstValueFrom(this.store.select(selectSessionInfo));
    if (sessionInfoDto) {
      try {
        let apiResponseDto: ServerAPIResponseDto = await firstValueFrom(this.serverDataFetchService.getUserAuctionRegistrationWrappers(sessionInfoDto.orgCode!));
        if (apiResponseDto && apiResponseDto.code == AuctionExtConstant.SUCCESS_CODE) {
          if (apiResponseDto.data) {
            let userAuctionRegistrationWrappers = apiResponseDto.data as UserAuctionRegistrationWrapperDto[];
            this.landingAndBidderService.setMyUserAuctionRegistrationWrappers(userAuctionRegistrationWrappers)
          }
        }
      } catch (error) {
        console.error(error);
      }
    }
  }

  async listenMyAuctionRegistrations() {
    logEvent(getAnalytics(), 'listenMyAuctionRegistrations');

    let sessionInfoDto = await firstValueFrom(this.store.select(selectSessionInfo));

    if (sessionInfoDto) {
      let collectionRef = collection(this.firestore, this.collectionUserAuctionRegistration);
      const registrationQuery = query(collectionRef,
        where('auctionHouseId', '==', sessionInfoDto.orgCode),
        where('userId', '==', sessionInfoDto?.bidderUiDto?.userId),
        where('updateTimeUtc', '>', this.lastFetchTimestamp));

      this.myAuctionRegistrationsUnsubscribe = onSnapshot(registrationQuery, (querySnapshot) => {
        let registrations = querySnapshot.docs.map(item => item.data());

        logEvent(getAnalytics(), 'listenMyAuctionRegistrations Data Received size : ' +  registrations.length);
        console.log("listenMyAuctionRegistrations Data Recieved size: " + registrations.length);

        if (registrations && registrations.length > 0) {
          this.landingAndBidderService.updateUserAuctionRegistrations(registrations);
        }
      })
    }
  }

  loadMultiLotWrapperData(auctionId: string, userId: string) {
    this.serverDataFetchService.getMultiLotWrapperDto(auctionId, userId).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == AuctionExtConstant.SUCCESS_CODE) {
          let multiLotWrapperData = apiResponseDto.data as MultiLotWrapperDto;
          this.landingAndBidderService.updateMultiLotWrapperDto(multiLotWrapperData);
          this.landingAndBidderService.updateTotalBids(auctionId, multiLotWrapperData.totalBids);
        }
      },
      error: (error) => {
        console.error(error);
      }
    })
  }

  loadLandingMultiLotWrapperData(auctionId: string) {
    this.serverDataFetchService.getLandingMultiLotWrapperDto(auctionId).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == AuctionExtConstant.SUCCESS_CODE) {
          let multiLotWrapperData = apiResponseDto.data as MultiLotWrapperDto;
          this.landingAndBidderService.updateLandingMultiLotWrapperDto(multiLotWrapperData);
          this.landingAndBidderService.updateTotalBids(auctionId, multiLotWrapperData.totalBids);
        }
      },
      error: (error) => {
        console.error(error);
      }
    })
  }
}
