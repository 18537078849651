
<div class="model-body">
  <header class="m-2">
    <div class="row justify-content-between">
      <div class="col-9 d-flex align-items-center">
        <h2>Bidder Details</h2>
        <!-- <span class="badge bg-primary padge-pill ms-3">{{getUserRole()}}</span> -->
      </div>
      <div class="col-auto text-end" [ngClass]="{'disabled': isLoading}">
        <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" (click)="closeUserDetailsModal()">
      </div>
    </div>
  </header>
  <div class="page-divider"></div>
  <div class="model-body-top bg-white-new">
    <div class="m-3">
      <!-- <div class="row mt-2">

        <div class="col-lg-2 col-3 div-class  mb-1">
          <img [alt]="globalBidderEntityDto.firstName" src="assets/images/avatar05.jpg" class="profile-image-large border mt-0"
            *ngIf="!globalBidderEntityDto.profileImage">
          <img [alt]="globalBidderEntityDto.firstName" src="/downloadLandingBlob?fileId={{globalBidderEntityDto.profileImage?.fileId}}"
            class="profile-image-class border" *ngIf="globalBidderEntityDto.profileImage">
        </div>
        <div class="col-lg-7 col-6">
          <div class="div-class ">
            <h5 class="heading-h5 mb-0">
              {{globalBidderEntityDto.firstName}} {{globalBidderEntityDto.lastName}}
            </h5>
          </div>
          <div class="div-class">
            <b>{{globalBidderEntityDto.designation}}</b>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-3 div-class  my-2 ">
          Company Name
        </div>

      <div class="div-class my-2  col-9" >
        <b>{{globalBidderEntityDto.companyName}}</b>
      </div>
      <div class="col-3 div-class  my-2 "  *ngIf="globalBidderEntityDto.companyUrl">
        Company Url
      </div>
      <div class="div-class  col-9" *ngIf="globalBidderEntityDto.companyUrl">
        <a [href]="globalBidderEntityDto.companyUrl"> {{globalBidderEntityDto.companyUrl}}</a>
     </div>
     <div class="col-3 div-class  my-2 ">
     Address
    </div>
    <div class="div-class my-2  col-9">

      <span *ngIf="globalBidderEntityDto.address?.addressLine1">
        {{globalBidderEntityDto.address?.addressLine1}}, {{globalBidderEntityDto.address?.addressLine2 ? globalBidderEntityDto.address?.addressLine2 + ', ' : ''}}
        {{globalBidderEntityDto.address?.city}}, {{globalBidderEntityDto.address?.state}}, {{globalBidderEntityDto.address?.country}} - {{globalBidderEntityDto.address?.zipCode}}
      </span>
    </div>
    <div class="col-3 div-class  my-2 " *ngIf="globalBidderEntityDto.faxNo">
      Fax No
     </div>
     <div class="div-class my-2  col-9" *ngIf="globalBidderEntityDto.faxNo">
      {{getCountryCode(globalBidderEntityDto.faxNoCountryCode!)}} {{globalBidderEntityDto.faxNo}}
    </div>
    </div> -->
    <div class="">
      <div class=" ">
        <div class="row">

          <div class=" col-3 div-class  mb-1 ms-3" >
            <img [alt]="globalBidderEntityDto.firstName" src="assets/images/avatar05.jpg" class="user-profile-image border mt-0"
              *ngIf="!globalBidderEntityDto.profileImage">
            <img [alt]="globalBidderEntityDto.firstName" src="/downloadLandingBlob?fileId={{globalBidderEntityDto.profileImage!.fileId}}"
              class="user-profile-image border" *ngIf="globalBidderEntityDto.profileImage"
              [ngClass]="{'img-portrait': globalBidderEntityDto.profileImage!.dataType == 'Portrait'}">
          </div>
          <div class="col-lg-6 col-5  me-3 text-start">
            <div class="div-class ">
              <h5 class="heading-h5 mb-0">
                {{globalBidderEntityDto.firstName}} {{globalBidderEntityDto.lastName}}
              </h5>
            </div>
            <div class="div-class">
              <b> {{globalBidderEntityDto.designation}}</b>
            </div>
           <div>
            <small class="small-class">
              {{globalBidderEntityDto.companyName}}
            </small>
           </div>
           <div>
            <small class=""  *ngIf="globalBidderEntityDto.companyUrl">
              <a [href]="globalBidderEntityDto.companyUrl" target="_blank"> {{globalBidderEntityDto.companyUrl}}</a>
             </small>
           </div>
           <div  *ngIf="globalBidderEntityDto.faxNo">
            <small class="small-class"><span>Fax No.</span> {{getCountryCode(globalBidderEntityDto.faxNoCountryCode!)}} {{globalBidderEntityDto.faxNo}}</small>
                </div>
            <div>
              <small class="small-class">
                <span *ngIf="globalBidderEntityDto.address?.addressLine1">
                  {{globalBidderEntityDto.address?.addressLine1}}, {{globalBidderEntityDto.address?.addressLine2 ? globalBidderEntityDto.address?.addressLine2 + ', ' : ''}}
                  {{globalBidderEntityDto.address?.city}}, {{globalBidderEntityDto.address?.state}},<br> {{globalBidderEntityDto.address?.country}} - {{globalBidderEntityDto.address?.zipCode}}
                </span>
              </small>
            </div>


          </div>
          <!-- <div class="col-3   col-lg-2  text-end mt-3 pe-0">
            <button class="btn btn-new-class btn-sm" (click)="toggleEditMode()">
              EDIT
            </button>
          </div> -->
        </div>

      <!-- <div class="m-3">
        <div class="text-center">
         <div class="div-class">
         <b>  {{globalBidderEntityDto.companyName}}</b>

         </div>
         <div class="div-class" *ngIf="globalBidderEntityDto.companyUrl">
          <a target="_blank" [href]="globalBidderEntityDto.companyUrl"> {{globalBidderEntityDto.companyUrl}}</a>
         </div>
         <div class="div-class" >
           <span *ngIf="globalBidderEntityDto.address?.addressLine1">
             {{globalBidderEntityDto.address?.addressLine1}}, {{globalBidderEntityDto.address?.addressLine2 ? globalBidderEntityDto.address?.addressLine2 + ', ' : ''}}
             {{globalBidderEntityDto.address?.city}}, {{globalBidderEntityDto.address?.state}}, {{globalBidderEntityDto.address?.country}} - {{globalBidderEntityDto.address?.zipCode}}
           </span>
          </div>
          <div class="div-class"  *ngIf="globalBidderEntityDto.faxNo">
          <span>Fax No.</span> {{getCountryCode(globalBidderEntityDto.faxNoCountryCode!)}} {{globalBidderEntityDto.faxNo}}
          </div>

        </div>
      </div> -->

    </div>
      <div class="col-12 mt-3" >
        <div class="row ">
          <div class="col-lg-6">
           <div class="border p-2 bg-white">
            <div class="row">
              <div class="col-6">
                <h6 class="heading-h5 mt-2">Email</h6>
              </div>
            </div>
            <hr class="hr my-2">
            <div class="mt-3 mx-2">
              <ul style="list-style: none;" class="ps-2">
                <li *ngFor="let item of globalBidderEntityDto.emailList;">
                  <div class="row my-3">
                    <div class="div-class col-8">
                      {{item.email}}
                    </div>
                  </div>
                </li>
              </ul>
            </div>
           </div>
          </div>
          <div class="col-lg-6">
           <div class="border p-2 bg-white">
            <div class="row">
              <div class="col-6">
                <h6 class="heading-h5 mt-2">Contact</h6>
              </div>
            </div>
            <hr class="my-2 hr">
            <div class=" mt-3 mx-2">
              <ul style="list-style: none;" class="ps-2">
                <li *ngFor="let item of globalBidderEntityDto.mobileList;">
                  <div class="row my-3">
                    <div class="div-class col-8">
                      {{getCountryCode(item.countryCode!)}} {{item.mobileNo}}
                    </div>
                  </div>
                </li>
              </ul>
            </div>
           </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
</div>

