import { createFeature, createReducer, createSelector, on } from "@ngrx/store";
import { NavigationActions } from "./navigation.actions";
import { NavigationEventsDto } from "../../models/NavigationEventsDto";

interface State {
  navigation: NavigationEventsDto | null;
  breadcrumbs: string[];
}

const initialState: State = {
  navigation: null,
  breadcrumbs: []
};

const SIDEBAR_PAGES: string[] = [
  // ApplicationConstants.SOURCING_PAGE,
  // ApplicationConstants.ANALYTICS_PAGE,
  // ApplicationConstants.DASHBOARD_PAGE,
  // ApplicationConstants.IN_HOUSE_USER_PAGE,
  // ApplicationConstants.PURCHASE_REQUEST_PAGE,
  // ApplicationConstants.BIDDER_NETWORK_PAGE,
  // ApplicationConstants.ADMIN_CATALOGUE_PAGE,
  // ApplicationConstants.APPROVED_PAGE,
  // ApplicationConstants.PROJECTS_PAGE,
  // ApplicationConstants.ADMIN_PURCHASE_ORDER_PAGE,
  // ApplicationConstants.ADMIN_INVOICE_PAGE,
  // ApplicationConstants.ONBOARDING_PAGE,
  // ApplicationConstants.MATERIAL_PAGE,
];

export const navigationFeature = createFeature({
  name: 'navigation',
  reducer: createReducer(
    initialState,
    on(NavigationActions.storeNavigationContext, (state, { navigation }) => {
      let breadcrumbs = [...state.breadcrumbs];

      // if (navigation.pageName === ApplicationConstants.DASHBOARD_PAGE) {
      //   // Clear breadcrumbs when navigating to Home
      //   breadcrumbs = [];
      // } else if (SIDEBAR_PAGES.includes(navigation.pageName!)) {
      //   // If it's a sidebar page, show only "Home"
      //   breadcrumbs = [navigation.pageName!];
      // } else {
      //   // Otherwise, add the new page to the breadcrumbs
      //   breadcrumbs = [...breadcrumbs, navigation.pageName!];
      // }

      return {
        ...state,
        navigation,
        breadcrumbs,
      };
    }),
    on(NavigationActions.getNavigationContext, (state) => ({
      ...state
    })),
    on(NavigationActions.clearSessionCookies, (state) => ({
      ...state,
      navigation: null,
      breadcrumbs: []
    })),
    on(NavigationActions.getBreadcrumbs, (state) => ({
      ...state
    })),
  )
});

export const {
  name,
  reducer,
  selectNavigationState,
  selectNavigation,
  selectBreadcrumbs
} = navigationFeature