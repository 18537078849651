import { DatePipe } from '@angular/common';
import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { DocumentReference } from '@angular/fire/firestore';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationService, MessageService } from 'primeng/api';
import { BehaviorSubject } from 'rxjs';
import { DocumentArchiveStatus } from 'src/app/shared/enums/DocumentArchiveStatus';
import { DocumentType } from 'src/app/shared/enums/DocumentType';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';
import { DocumentArchiveUiDto } from 'src/app/shared/models/user/DocumentArchiveEntityDto';
import { FileService } from 'src/app/shared/services/file.service';
import { UserService } from 'src/app/shared/services/user.service';
import { AuctionExtConstant } from 'src/app/shared/util/AuctionExtConstant';
import { AuctionExtUtil } from 'src/app/shared/util/AuctionExtUtil';

@Component({
  selector: 'app-user-documents',
  templateUrl: './user-documents.component.html',
  styleUrls: ['./user-documents.component.sass']
})
export class UserDocumentsComponent implements OnInit {
  @Output() onNext: EventEmitter<void> = new EventEmitter();
  @Output() onPrevious: EventEmitter<void> = new EventEmitter();

  DocumentType: typeof DocumentType = DocumentType;

  formGroup: FormGroup;
  isLoading$ = new BehaviorSubject<boolean>(false);
  listLoading$ = new BehaviorSubject<boolean>(false);
  _showErrorToast$ = new BehaviorSubject<Boolean>(false);
  _showSuccessToast$ = new BehaviorSubject<Boolean>(false);

  openFileModal: boolean = false;
  openFileViewModal: boolean = false;
  expireyMinimumDate = new Date();

  _showListSuccessToast$ = new BehaviorSubject<boolean>(false);
  _showListErrorToast$ = new BehaviorSubject<boolean>(false);
  errorListMsg: string | undefined;

  documentTypes = ['application/pdf'];
  errorMsg: string | undefined;
  currentFile?: File | null;
  fileUploadError: boolean = false;
  _fileDisplayName$ = new BehaviorSubject<string | null>("");
  _fileSize$ = new BehaviorSubject<string | null>("");


  selectedDocumentArchive?: DocumentArchiveUiDto;
  documentArchiveList: DocumentArchiveUiDto[] = [];
  countExpiredDocument: number = 0; 
  docPdfUrl?: string
  documentTypeList = [
    {
        "id": 1,
        "documentTypeName": "Articles of Association (AoA)",
        "expiryApplicable": true,
		   "documentRefrenceNoApplicable" : false,
		   "addAndVerifyButtonApplicable" : false,
        "required": false
    },
    {
        "id": 2,
        "documentTypeName": "Business License/Registration Certificate",
        "expiryApplicable": true,
		    "documentRefrenceNoApplicable" : false,
		    "addAndVerifyButtonApplicable" : false,
        "required": false
    },
    {
        "id": 3,
        "documentTypeName": "Certificate of Incorporation",
        "expiryApplicable": true,
		"documentRefrenceNoApplicable" : false,
		"addAndVerifyButtonApplicable" : false,
        "required": false
    },
    {
        "id": 4,
        "documentTypeName": "Company Master Data",
        "expiryApplicable": true,
		"documentRefrenceNoApplicable" : false,
		"addAndVerifyButtonApplicable" : false,
        "required": false
    },
    {
        "id": 5,
        "documentTypeName": "Exemption Certificate (if applicable)",
        "expiryApplicable": true,
		"documentRefrenceNoApplicable" : false,
		"addAndVerifyButtonApplicable" : false,
        "required": false
    },
    {
        "id": 6,
        "documentTypeName": "Financial Audit Certificate",
        "expiryApplicable": true,
		"documentRefrenceNoApplicable" : false,
		"addAndVerifyButtonApplicable" : false,
        "required": false
    },
    {
        "id": 7,
        "documentTypeName": "GST Registration Certificate",
        "expiryApplicable": false,
		"documentRefrenceNoApplicable" : true,
		"addAndVerifyButtonApplicable" : true,
        "required": null
    },
    {
        "id": 8,
        "documentTypeName": "Memorandum of Association (MoA)",
        "expiryApplicable": true,
		"documentRefrenceNoApplicable" : false,
		"addAndVerifyButtonApplicable" : false,
        "required": false
    },
    {
        "id": 9,
        "documentTypeName": "PAN Card",
        "expiryApplicable": false,
		"documentRefrenceNoApplicable" : true,
		"addAndVerifyButtonApplicable" : true,
        "required": null
    },
    {
        "id": 10,
        "documentTypeName": "Partnership Deed (if applicable)",
        "expiryApplicable": true,
		"documentRefrenceNoApplicable" : false,
		"addAndVerifyButtonApplicable" : false,
        "required": false
    },
    {
        "id": 11,
        "documentTypeName": "Startup Certificate",
        "expiryApplicable": true,
		"documentRefrenceNoApplicable" : false,
		"addAndVerifyButtonApplicable" : false,
        "required": false
    },
    {
        "id": 12,
        "documentTypeName": "Shop and Establishment Certificate",
        "expiryApplicable": true,
		"documentRefrenceNoApplicable" : false,
		"addAndVerifyButtonApplicable" : false,
        "required": false
    },
    {
        "id": 13,
        "documentTypeName": "Udyog Aadhar Number",
        "expiryApplicable": true,
		"documentRefrenceNoApplicable" : false,
		"addAndVerifyButtonApplicable" : false,
        "required": false
    },
    {
        "id": 14,
        "documentTypeName": "Others",
        "expiryApplicable": true,
		"documentRefrenceNoApplicable" : false,
		"addAndVerifyButtonApplicable" : false,
        "required": false
    }
];



  constructor(
    private fileService: FileService, 
    private confirmationService: ConfirmationService,
    private datePipe: DatePipe,
    private messageService: MessageService,
    private formBuilder: FormBuilder,
    public userService: UserService) {
    this.formGroup = this.formBuilder.group({
      documentType: new FormControl('', [Validators.required]),
      documentName: new FormControl('', [Validators.required]),
      isExpiry: new FormControl(false),
      documentReferenceNo: new FormControl(''),
      expiryDate: new FormControl('')
    });
  }
  ngOnInit(): void {
    this.loadDocumentArchive();
    
    this.formGroup.controls['isExpiry'].valueChanges.subscribe(value => {
      if (value) {
        this.formGroup.controls['expiryDate'].setValidators(Validators.required);
        this.formGroup.controls['expiryDate'].updateValueAndValidity();
      } else {
        this.formGroup.controls['expiryDate'].clearValidators();
        this.formGroup.controls['expiryDate'].updateValueAndValidity();
      }
    })


    this.formGroup.controls['documentType'].valueChanges.subscribe(value => {
      if (value == DocumentType.GST_REGISTRATION_CERTIFICATE) {
        this.formGroup.controls['documentReferenceNo'].setValidators([Validators.required, Validators.minLength(15), Validators.maxLength(15)]);
      } else if (value == DocumentType.PAN_CARD) {
        this.formGroup.controls['documentReferenceNo'].setValidators([Validators.required, Validators.minLength(10), Validators.maxLength(10)]);
      } else{
        this.formGroup.controls['documentReferenceNo'].clearValidators();
      }

      this.formGroup.controls['documentReferenceNo'].updateValueAndValidity();

      if (value != DocumentType.OTHERS) {
        this.formGroup.controls['documentName'].setValue(value);
      } else {
        this.formGroup.controls['documentName'].setValue('');
      }
    })

  }

  get fc(): any { return this.formGroup.controls; }

  loadDocumentArchive() {

    this.listLoading$.next(true)
    this.userService.getDocumentArchives().subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        this.listLoading$.next(false)
        this.documentArchiveList = apiResponseDto.data as DocumentArchiveUiDto[];
        this.populateDefualtDocumentListIfRequied();
        this.getCountExpiredDocument();
      },
      error: (error) => {
        this.listLoading$.next(false)
      }
    })
  }

  populateInitialDocumentDetails() {
    this.formGroup.controls['documentType'].setValue(this.selectedDocumentArchive?.documentType);
    this.formGroup.controls['documentReferenceNo'].setValue(this.selectedDocumentArchive?.documentReferenceNo);

    if (this.selectedDocumentArchive?.documentType == "Others") {
      this.formGroup.controls['documentName'].setValue(this.selectedDocumentArchive?.documentName);
    }

    if (this.selectedDocumentArchive?.expiryDate) {
      this.formGroup.controls['isExpiry'].setValue(true);
      this.formGroup.controls['expiryDate'].setValue(this.selectedDocumentArchive?.expiryDate);
    }
    this.formGroup.updateValueAndValidity();

    this._fileDisplayName$.next(this.selectedDocumentArchive?.fileName!)
    this._fileSize$.next(this.selectedDocumentArchive?.size!)
  }

  populateDefualtDocumentListIfRequied() {
    if (this.documentArchiveList.length == 0) {
      this.documentArchiveList.push(this.getDefaultPAN());
      this.documentArchiveList.push(this.getDefaultGST());
    }

    if (this.documentArchiveList) {
      let panDoument = this.documentArchiveList.filter(item => item.documentType == "PAN Card")[0];
      let gstDoument = this.documentArchiveList.filter(item => item.documentType == "GST Registration Certificate")[0];

      if (!panDoument) {
        this.documentArchiveList.push(this.getDefaultPAN());
      }
      if (!gstDoument) {
        this.documentArchiveList.push(this.getDefaultGST());
      }
    }
  }


  confirmRemoveSection(documentArchiveId: string) {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to delete?',
      header: 'Delete Document',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.deleteDocumentArchive(documentArchiveId);
      },
      reject: () => {
        console.log('Cancel Remove Section!');
      }
    });
  }

  isCheckExpiryApplicable(documentTypeName: string): boolean | null {
    const document = this.documentTypeList.find(doc => doc.documentTypeName === documentTypeName);
    return document ? document.expiryApplicable : null; // Return null if document is not found
  }

  isCheckReferenceNoApplicable(documentTypeName: string): boolean | null {
    const document = this.documentTypeList.find(doc => doc.documentTypeName === documentTypeName);
    return document ? document.documentRefrenceNoApplicable : false; // Return false if document is not found
  }

  isCheckAddAndVerifiyApplicable(documentTypeName: string): boolean | null {
    const document = this.documentTypeList.find(doc => doc.documentTypeName === documentTypeName);
    return document ? document.addAndVerifyButtonApplicable : false; // Return false if document is not found
  }


  clear() {
    this.selectedDocumentArchive = undefined;
    this.formGroup.reset();
    this.formGroup.enable();
    this.deleteFile();
    this.isLoading$.next(false);
  }

  deleteFile() {
    this._fileDisplayName$.next('');
    this._fileSize$.next('');
    this.currentFile = null;

  }

  formatBytes(size: any) {
    return AuctionExtUtil.formatBytes(size);
  }

  openUploadFile() {
    this.clear();
    this.openFileModal = true
  }

  openPanOrGstAddModel(documentArchive: DocumentArchiveUiDto){
    this.clear();
    this.formGroup.controls['documentType'].setValue(documentArchive?.documentType);
    this.openFileModal = true
  }

  chooseFile(event: any) {
    this.currentFile = event.target.files[0];

    if (!this.documentTypes.includes(this.currentFile!.type)) {
      this.fileUploadError = true;
      this.messageService.add({ severity:'error', summary: 'Error', detail: 'Extension not supported'});
      this.currentFile = null;
      this._fileDisplayName$.next(null);
      return;
    }

    this._fileDisplayName$.next(this.getFileName(this.currentFile?.name!) as string);
    this._fileSize$.next(this.currentFile?.size + "");
  }

  getDefaultPAN() {
    let document = new DocumentArchiveUiDto();
    document.documentName = "PAN Card"
    document.documentType = "PAN Card"
    document.status = DocumentArchiveStatus.PENDING
    return document;
  }

  getDefaultGST() {
    let document = new DocumentArchiveUiDto();
    document.documentType = "GST Registration Certificate"
    document.documentName = "GST Registration Certificate"
    document.status = DocumentArchiveStatus.PENDING
    return document;
  }

  getFileName(fileName: string) {
    let extensionName = fileName?.slice(fileName.lastIndexOf('.'), fileName.length)
    let name = fileName?.slice(0, fileName.lastIndexOf('.'));
    let finalFileName = AuctionExtUtil.removeSpecialCharacters(name!) as string
    return finalFileName + "" + extensionName;
  }



  // enableAndDisableExpiry(){
  //   this.isExpiry$.next(!this.isExpiry$.value);

  //   if(this.isExpiry$.value){
  //     this.formGroup.controls['expiryDate'].setValidators([Validators.required]);
  //   }else{
  //     this.formGroup.controls['expiryDate'].clearValidators();
  //   }
  //   this.formGroup.controls['expiryDate'].updateValueAndValidity();
  //   this.formGroup.updateValueAndValidity();
  // }

  selectDocumentArchive(documentArchive: DocumentArchiveUiDto) {
    this.clear()
    this.selectedDocumentArchive = documentArchive;
    this.populateInitialDocumentDetails()
    this.openFileModal = true
  }

  

  viewDocument(documentArchive: DocumentArchiveUiDto) {
    this.selectedDocumentArchive = documentArchive;
    this.openFileViewModal = true
    this.downloadDocument();
  }

  populateDocumentDetails() {
    let formValue = this.formGroup.getRawValue();
    let formData = new FormData();
    let documentArchiveWrapper = new DocumentArchiveUiDto();

    if (this.selectedDocumentArchive) {
      documentArchiveWrapper.documentArchiveId = this.selectedDocumentArchive.documentArchiveId;
    }

    documentArchiveWrapper.documentType = formValue.documentType;
    documentArchiveWrapper.documentReferenceNo = formValue.documentReferenceNo;

    if (documentArchiveWrapper.documentType == 'Others') {
      documentArchiveWrapper.documentName = formValue.documentName;
    } else {
      documentArchiveWrapper.documentName = documentArchiveWrapper.documentType;
    }

    if (this.formGroup.controls['isExpiry'].value) {
      documentArchiveWrapper.expiryDate = this.datePipe.transform(formValue.expiryDate, 'dd/MM/yyyy HH:mm')!;
    }

    //document data populate
    if (this.currentFile) {

      let metaData = {
        'mimeType': this.currentFile.type,
        'version': 0,
        'publicApi': true,
        'dataType': 'Square'
      };

      documentArchiveWrapper.metaData = JSON.stringify(metaData)
      formData.append("file", this.currentFile);
    }
    formData.append("documentArchiveWrapper", JSON.stringify(documentArchiveWrapper));
    return formData;
  }

  getStatus(status: string): string {
    switch (status) {
      case DocumentArchiveStatus.NOT_APPLICABLE:
        return 'Not applicable';
      case DocumentArchiveStatus.PENDING:
        return 'Pending';
      case DocumentArchiveStatus.VERIFIED:
        return 'Verified';
      case DocumentArchiveStatus.VERIFIED_FAILED:
        return 'Verified-failed';
      default:
        return '';
    }
  }

  
  downloadDocument() {
    this.isLoading$.next(true);

    this.fileService.downloadFile(this.selectedDocumentArchive?.fileId!).subscribe({
      next: (response) => {
        if (response) {
          let file = new Blob([response], { type: 'application/pdf' });
          this.docPdfUrl = URL.createObjectURL(file);
          this.isLoading$.next(false);
        }
      },
      error: (err) => {
        this.isLoading$.next(false);
        console.error(err);
        console.log("Error while previewing document");
      }
    });
  }



  saveDocumentArchive() {
    this.isLoading$.next(false);
    this.formGroup.markAllAsTouched();

    if (this.formGroup.invalid) {
      return;
    } else {
      //document validation
      if (!this._fileDisplayName$.value) {

        this.messageService.add({ severity:'error', summary: 'Error', detail: 'Please Upload Document'});
        return;
      }
    }

    let formData = this.populateDocumentDetails();
    this.isLoading$.next(true);
    this.userService.saveDocumentArchive(formData).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == AuctionExtConstant.SUCCESS_CODE) {
          this.isLoading$.next(true);
          this.messageService.add({ severity:'success', summary: 'Success', detail: 'Document Saved Successfully'});
          this.openFileModal = false;
          this.clear()
          this.loadDocumentArchive();
          setTimeout(() => {
          }, 2000);
        } else {
          this.isLoading$.next(false);
          this.messageService.add({ severity:'error', summary: 'Error', detail: apiResponseDto.message});
        }
      },
      error: () => {
        this.isLoading$.next(false);
        this.messageService.add({ severity:'error', summary: 'Error', detail: 'Error while saving document archive'});
      }
    })
  }

  getCountExpiredDocument() {
    let errorDataList = this.documentArchiveList?.filter(item => item.expiryDate && AuctionExtUtil.convertedDate(item.expiryDate!, "23:59")! < new Date());
    if (errorDataList) {
      this.countExpiredDocument = errorDataList.length;
      return;
    }

    this.countExpiredDocument = 0;
  }

  deleteDocumentArchive(id: string) {
    this.listLoading$.next(false);
    this._showListErrorToast$.next(false);
    this.listLoading$.next(true);
    this.userService.deleteDocumentArchive(id).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == AuctionExtConstant.SUCCESS_CODE) {
          this.listLoading$.next(false);
          this._showListSuccessToast$.next(true);
          this.loadDocumentArchive();
          setTimeout(() => {
            this._showListSuccessToast$.next(false);
          }, 2000);
        } else {
          this.listLoading$.next(false);
          this._showListErrorToast$.next(true);
          this.errorListMsg = apiResponseDto.message;
        }
      },
      error: () => {
        this.isLoading$.next(false);
        this._showListErrorToast$.next(true);
        this.errorListMsg = "Error while saving document archive";
      }
    })
  }

  prevStep() {
    this.onPrevious.emit()
  }

  isPanAndGSTAreVerified(){
      let toReturn = false;
      let panDoument = this.documentArchiveList.filter(item => item.documentType == "PAN Card")[0];
      let gstDoument = this.documentArchiveList.filter(item => item.documentType == "GST Registration Certificate")[0];

      if(panDoument.status == DocumentArchiveStatus.VERIFIED && gstDoument.status == DocumentArchiveStatus.VERIFIED){
        toReturn = true;
      }
    return toReturn
  }

  nextStep(){
    if(!this.isPanAndGSTAreVerified()){
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Please upload mandatory documents before proceeding.',
      });
      return;
    }
    this.onNext.emit()
  }
}
