<div class="bg-blue-50 h-screen">
  <div class="px-8 py-12 ">
    <div class="flex justify-center items-center mb-6">
      <div class="text-2xl text-gray-800 font-bold">
        Check your email for a code
      </div>
    </div>
    <div class="flex justify-center items-center mb-6">
      <div class=" bg-white rounded-lg p-8 w-96 h-96">
        <div class=" text-gray-700 text-center text-base font-medium mb-4">
          A verification code has been sent to your email (<span
            class="text-blue-600 text-base font-bold">{{registrationUserModalDto?.emailId}}</span>). Check and enter the
          code below
        </div>
        <div class="text-gray-500 text-center text-xs  font-medium mb-8">
          OTP will expire in <span class="text-gray-700 font-bold ">5minutes</span>, so enter it soon.
        </div>

        <div class=" text-start mb-6">
          <label class="text-gray-600  text-sm font-medium leading-4 text-start" for="userOtp">Enter 6-digit Email
            OTP</label>
          <input type="number" [formControl]="ctrlOtp" id="otp"
            class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-600 text-sm" />
        </div>

        <div class="my-3">
          <app-message-toast *ngIf="_showSuccessMsg$ | async" [message]="_successMsg$ | async"></app-message-toast>
          <app-message-toast *ngIf="authService.errorMsg" [isError]="true"
            [message]="authService.errorMsg"></app-message-toast>
        </div>

        <!-- <div class="d-grid gap-2 my-3" *ngIf="isLoading">
          <div class="e-spinner"></div>
        </div> -->

        <div class="grid text-end mb-8 ">
          <p-button
            [loading]="isLoading"
            class="text-center basis-1/2 text-white bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-300 font-medium rounded text-sm px-3 py-1.5 "
            (click)="handleValidSubmit('Loading...')">Verify</p-button>
        </div>
        <div class="text-xs font-medium underline cursor-pointer text-center">
          <a class="btn-link" (click)="resendOTP()"> Resend OTP</a>
        </div>
      </div>
    </div>
    <div class="flex justify-center items-center mb-2 ">
      <!-- <div class=" text-xs text-gray-700 font-medium" >
    Back to Signup
  </div> -->
      <div class="cursor-pointer text-xs text-gray-700 font-medium">
        <i-lucide name="arrow-left" [size]="18" class="my-icon inline-block" style="color: black;"></i-lucide>
        <span class="text-xs font-semibold text-black" (click)="backToSignup()">Back To The Signup</span>
      </div>
    </div>
    <div class="flex justify-center items-center">
      <div class="w-96 text-center text-xs text-gray-700 font-medium">
        By creating an account, you agree to the <span class="underline">Terms of Service</span> and <span
          class="underline">
          Privacy Policy
        </span>
      </div>
    </div>
  </div>

</div>

<p-toast></p-toast>