<!-- <div class="mt-6rem">
  <div id="stepProgressBar">
    <div class="step" [ngClass]="{'step-active': activeStage == registrationStages.BASIC_DETAILS, 'completed': currentStageIndex > 1}">
      <p class="step-text">Add Basic Details</p>
      <div class="bullet step-progress" [ngClass]="{'completed': currentStageIndex > 1, 'current': activeStage == registrationStages.BASIC_DETAILS}">
        <div class="bullet-child" [ngClass]="{'half-filled': currentStageIndex == 1}"></div>
        <i-lucide *ngIf="currentStageIndex > 1" [size]="16" name="check" class="my-icon"></i-lucide>
      </div>
    </div>
    <div class="step" [ngClass]="{'step-active': activeStage == registrationStages.BANK_DETAILS, 'completed': currentStageIndex > 2}">
      <p class="step-text">Add Bank Details</p>
      <div class="bullet step-progress" [ngClass]="{'completed': currentStageIndex > 2, 'current': activeStage == registrationStages.BANK_DETAILS}">
        <div class="bullet-child" [ngClass]="{'half-filled': currentStageIndex == 2}"></div>
        <i-lucide *ngIf="currentStageIndex > 2" [size]="16" name="check" class="my-icon"></i-lucide>
      </div>
    </div>
    <div class="step" [ngClass]="{'step-active': activeStage == registrationStages.ADDRESS, 'completed': currentStageIndex > 3}">
      <p class="step-text">Add Address</p>
      <div class="bullet step-progress" [ngClass]="{'completed': currentStageIndex > 3, 'current': activeStage == registrationStages.ADDRESS}">
        <div class="bullet-child" [ngClass]="{'half-filled': currentStageIndex == 3}"></div>
        <i-lucide *ngIf="currentStageIndex > 3" [size]="16" name="check" class="my-icon"></i-lucide>
      </div>
    </div>
    <div class="step" [ngClass]="{'step-active': activeStage == registrationStages.DOCUMENTS, 'completed': currentStageIndex > 4}">
      <p class="step-text">Add Documents</p>
      <div class="bullet step-progress" [ngClass]="{'completed': currentStageIndex > 4, 'current': activeStage == registrationStages.DOCUMENTS}">
        <div class="bullet-child" [ngClass]="{'half-filled': currentStageIndex == 4}"></div>
        <i-lucide *ngIf="currentStageIndex > 4" [size]="16" name="check" class="my-icon"></i-lucide>
      </div>
    </div>
    <div class="step" [ngClass]="{'step-active': activeStage == registrationStages.DSC, 'completed': currentStageIndex > 5}">
      <p class="step-text">Add DSC</p>
      <div class="bullet step-progress" [ngClass]="{'completed': currentStageIndex > 5, 'current': activeStage == registrationStages.DSC}">
        <div class="bullet-child" [ngClass]="{'half-filled': currentStageIndex == 5}"></div>
        <i-lucide *ngIf="currentStageIndex > 5" [size]="16" name="check" class="my-icon"></i-lucide>
      </div>
    </div>
  </div>
</div>    -->


<div class="mt-6rem">
  <div id="stepProgressBar">
    <div class="flex w-4/6 mx-auto items-center justify-center">
      <ol class="flex text-gray-900 items-center relative w-full">
        <div class="absolute top-1/2 left-1/2 w-full h-0.5  bg-gray-300 -translate-x-1/2"></div>
        <li class="relative flex-1 flex flex-col items-center text-center">

          <div
            [ngClass]="{ 'active': activeStage === registrationStages.BASIC_DETAILS, 'complete': currentStageIndex > 1, 'step': currentStageIndex < 1}">
            <span *ngIf="currentStageIndex <= 1" class="flex items-center">1</span>
            <span class="flex items-center"><i-lucide *ngIf="currentStageIndex > 1" color="#fff" [size]="16" name="check"
                class="my-icon"></i-lucide></span>
          </div>
          <p class="text-xs font-bold text-gray-500">Basic Detail</p>

          <div class="absolute top-1/2 w-full left-1/2 h-0.5 bg-gray-300 -translate-x-1/2"></div>
        </li>
        <li class="relative flex-1 flex flex-col items-center text-center">
          <div
            [ngClass]="{'complete': currentStageIndex > 2, 'active': activeStage === registrationStages.BANK_DETAILS, 'step': currentStageIndex < 2} " class="relative z-10 w-5 h-5 rounded-full mt-4 mb-0 bg-gray-200 flex items-center justify-center ">
            <span *ngIf="currentStageIndex <= 2" class="flex items-center">2</span>
            <span  class="flex items-center"><i-lucide *ngIf="currentStageIndex > 2" color="#fff" [size]="16" name="check"
                class="my-icon"></i-lucide></span>
          </div>
          <p class="text-xs font-bold text-gray-500">Add Bank Details</p>
          <!-- <i-lucide *ngIf="currentStageIndex > 2" [size]="16" name="check" class="my-icon"></i-lucide> -->
          <div class="absolute top-1/2 w-full left-0 h-0.5 bg-gray-300 -translate-x-1/2"></div>
        </li>
        <li class="relative flex-1 flex flex-col items-center text-center">
          <div
            [ngClass]="{'complete': currentStageIndex > 3, 'active': activeStage == registrationStages.ADDRESS, 'step': currentStageIndex < 3}" class="relative z-10 w-5 h-5 rounded-full mt-4 mb-0 bg-gray-200 flex items-center justify-center ">
            <span *ngIf="currentStageIndex <= 3" class="flex items-center" >3</span>
            <span class="flex items-center"><i-lucide *ngIf="currentStageIndex > 3" color="#fff" [size]="16" name="check"
                class="my-icon"></i-lucide></span>
          </div>
          <p class="text-xs font-bold text-gray-500">Add Address</p>
          <!-- <i-lucide *ngIf="currentStageIndex > 3" [size]="16" name="check" class="my-icon"></i-lucide> -->
          <div class="absolute top-1/2 w-full left-0 h-0.5 bg-gray-300 -translate-x-1/2"></div>
        </li>
        <li class="relative flex-1 flex flex-col items-center text-center">
          <div
            [ngClass]="{'complete': currentStageIndex > 4, 'active': activeStage == registrationStages.DOCUMENTS, 'step': currentStageIndex < 4}"
            class="relative z-10 w-5 h-5 rounded-full mt-4 mb-0 bg-gray-200 flex items-center justify-center">
            <span *ngIf="currentStageIndex <= 4" class="flex items-center">4</span>
            <span class="flex items-center"><i-lucide *ngIf="currentStageIndex > 4" color="#fff" [size]="16" name="check"
                class="my-icon"></i-lucide></span>
          </div>
          <p class="text-xs font-bold text-gray-500">Add Documents</p>
          <i-lucide *ngIf="currentStageIndex > 4" [size]="16" name="check" class="my-icon"></i-lucide>
          <div class="absolute top-1/2 left-0 w-full h-0.5 bg-gray-300 -translate-x-1/2"></div>
        </li>
        <li class="relative flex-1 flex flex-col items-center text-center">
          <div
            [ngClass]="{'complete': currentStageIndex > 5, 'active': activeStage == registrationStages.DSC, 'step': currentStageIndex < 5}"
            class="relative z-10 w-5 h-5 rounded-full mt-4 mb-0 bg-gray-200 flex items-center justify-center">
            <span *ngIf="currentStageIndex <= 5" class="flex items-center">5</span>
            <span class="flex items-center"><i-lucide *ngIf="currentStageIndex > 5" color="#fff" [size]="16" name="check"
                class="my-icon"></i-lucide></span>
          </div>
          <p class="text-xs font-bold text-gray-500">Add DSC</p>
          <i-lucide *ngIf="currentStageIndex > 5" [size]="16" name="check" class="my-icon"></i-lucide>
          <div class="absolute top-1/2 left-0 w-full h-0.5 bg-gray-300 -translate-x-1/2"></div>
        </li>
      </ol>
    </div>
  </div>
</div>



<ng-container [ngTemplateOutlet]="activeStage == registrationStages.BASIC_DETAILS ? basicDetailsPage
  : activeStage == registrationStages.BANK_DETAILS ? bankDetailsPage
  : activeStage == registrationStages.ADDRESS ? addressPage
  : activeStage == registrationStages.DOCUMENTS ? documentsPage
  : activeStage == registrationStages.DSC ? dscPage
  : emptyTemplate">
</ng-container>


<ng-template #basicDetailsPage>
  <app-user-basic-details (onNext)="nextStep()"></app-user-basic-details>
</ng-template>

<ng-template #bankDetailsPage>
  <app-user-bank-details (onNext)="nextStep()" (onPrevious)="prevStep()"></app-user-bank-details>
</ng-template>

<ng-template #addressPage>
  <app-user-address (onNext)="nextStep()" (onPrevious)="prevStep()"></app-user-address>
</ng-template>

<ng-template #documentsPage>
  <app-user-documents (onNext)="nextStep()" (onPrevious)="prevStep()"></app-user-documents>
</ng-template>

<ng-template #dscPage>
  <app-user-dsc-document (onPrevious)="prevStep()"></app-user-dsc-document>
</ng-template>

<ng-template #emptyTemplate></ng-template>

<!-- <div class="position-relative mt-5">
  <div class=" text-center col-12"> -->
<!-- <a class="link-class me-3" *ngIf="isShowSkipButton" (click)="nextStep()">Skip</a> -->
<!-- <button class="btn btn-primary-clr-class btn-sm me-3" *ngIf="activeStage != registrationStages.BASIC_DETAILS"
      [disabled]="isDisabledPrevButton" (click)="prevStep()">Back</button> -->
<!-- <button class="btn btn-primary-clr-class btn-sm me-3" *ngIf="activeStage != registrationStages.DSC"
      [disabled]="isDisabledNextButton" (click)="saveUserProfile()">Next</button> -->

<!-- <button class="btn btn-grey-clr-class btn-sm me-3 mt-3 mt-md-0" *ngIf="activeStage == registrationStages.DSC" (click)="redirectToHomePage()">Go to Dashboard</button>
  </div>
</div> -->

<!-- <div class="position-relative mt-5">
  <div class="text-center col-12">
    <button class="btn btn-primary-clr-class btn-sm me-3" *ngIf="activeStage != registrationStages.BASIC_DETAILS"
      [disabled]="isDisabledPrevButton" (click)="prevStep()">Back</button>
    <button class="btn btn-primary-clr-class btn-sm me-3" *ngIf="activeStage != registrationStages.DSC"
      [disabled]="isDisabledNextButton" (click)="saveUserProfile()">Next</button>
    <button class="btn btn-grey-clr-class btn-sm me-3 mt-3 mt-md-0" *ngIf="activeStage == registrationStages.DSC" (click)="redirectToHomePage()">Go to Dashboard</button>
  </div>
</div> -->