import { DatePipe } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, firstValueFrom, Subscription } from 'rxjs';
import { QuestionResponseType } from 'src/app/shared/enums/questionnaire/QuestionResponseType';
import { SourcingEnvelopeType } from 'src/app/shared/enums/questionnaire/SourcingEnvelopeType';
import { UserQuestionStatus } from 'src/app/shared/enums/questionnaire/UserQuestionStatus';
import { FileInfoDto } from 'src/app/shared/models/FileInfoDto';
import { FileSaveResponseDto } from 'src/app/shared/models/FileSaveResponseDto';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';
import { BidderUiDto } from 'src/app/shared/models/UserEntityDto';
import { QuestionOptions } from 'src/app/shared/models/questionnaire/QuestionOptions';
import { QuestionnaireTemplate } from 'src/app/shared/models/questionnaire/QuestionnaireTemplate';
import { QuestionnaireWrapperDto } from 'src/app/shared/models/questionnaire/QuestionnaireWrapperDto';
import { TechnicalQuestionTemplate } from 'src/app/shared/models/questionnaire/TechnicalQuestionTemplate';
import { UserAuctionQuestionsDto } from 'src/app/shared/models/questionnaire/UserAuctionQuestionsDto';
import { AuctionEntityDto } from 'src/app/shared/models/user/AuctionEntityDto';
import { DocumentArchiveUiDto } from 'src/app/shared/models/user/DocumentArchiveEntityDto';
import { LandingAndBidderService } from 'src/app/shared/services/LandingAndBidder.service';
import { FileService } from 'src/app/shared/services/file.service';
import { QuestionnaireService } from 'src/app/shared/services/questionnaire.service';
import { UserService } from 'src/app/shared/services/user.service';
import { selectBidderUiDto } from 'src/app/shared/state-management/session/session.features';
import { AuctionExtConstant } from 'src/app/shared/util/AuctionExtConstant';
import { AuctionExtUtil } from 'src/app/shared/util/AuctionExtUtil';

@Component({
  selector: 'app-questionnaire-technical-question',
  templateUrl: './questionnaire-technical-question.component.html',
  styleUrls: ['./questionnaire-technical-question.component.sass']
})
export class QuestionnaireTechnicalQuestionComponent implements OnInit, OnDestroy {
  @Input() selectedTechnicalQuestion!: TechnicalQuestionTemplate;

  questionResponseType: typeof QuestionResponseType = QuestionResponseType;

  auctionEntityDto?: AuctionEntityDto;
  userEntityDto?: BidderUiDto;
  questionnaireWrapperDto?: QuestionnaireWrapperDto;
  questionnaireTemplate?: QuestionnaireTemplate;
  technicalQuestionsList: TechnicalQuestionTemplate[] = []
  documentArchiveList: DocumentArchiveUiDto[] = [];
  userAuctionQuestions: UserAuctionQuestionsDto[] = [];

  selectedDocuments: FileInfoDto[] = [];
  bidderResponse: any;

  ctrlQuestion: FormControl = new FormControl('');
  ctrlBidderComments: FormControl = new FormControl('');
  ctrlDateStart: FormControl = new FormControl('', Validators.required);
  ctrlDateEnd: FormControl = new FormControl('', Validators.required);
  ctrlAlphaNumeric: FormControl = new FormControl('', Validators.required);

  isLoading: boolean = false;
  isUploadLoading: boolean = false;
  documentModalRef?: NgbModalRef;
  isQuestionSubmitted: boolean = false;

  countIncompleteQuestions: number = 0;
  countCompleteQuestions: number = 0;

  documentTypes = ['application/pdf'];
  errorMsg: string | undefined;
  currentFile?: File | null;
  fileUploadError: boolean = false;
  _fileDisplayName$ = new BehaviorSubject<string | null>("");
  _fileSize$ = new BehaviorSubject<string | null>("");

  _showErrorToast$ = new BehaviorSubject<Boolean>(false);
  _showSuccessToast$ = new BehaviorSubject<Boolean>(false);

  questionnaireWrapperDtoSubscription$?: Subscription;
  selectedAuctionSubscription$?: Subscription;
  userAuctionQuestionsSubscription$?: Subscription;

  constructor(
    private activeModal: NgbActiveModal,
    private ngbModal: NgbModal,
    private questionnaireService: QuestionnaireService,
    private landingAndBidderService: LandingAndBidderService,
    private userService: UserService,
    private fileService: FileService,
    private datePipe: DatePipe,
    private store: Store
  ) { }

  ngOnInit(): void {
    this.loadBidderUiDto();

    this.questionnaireWrapperDtoSubscription$ = this.questionnaireService.getQuestionnaireWrapper$.subscribe(data => {
      if (data) {
        this.questionnaireWrapperDto = data;
        this.questionnaireTemplate = data.questionnaireTemplate;

        this.technicalQuestionsList = this.questionnaireWrapperDto?.technicalQuestionTemplates?.filter(item => item.sectionId == this.selectedTechnicalQuestion.sectionId) ?? [];
        this.technicalQuestionsList.sort((a, b) => Number(a.sequenceNo) - Number(b.sequenceNo));
        this.countIncompleteQuestions = this.technicalQuestionsList.length - this.countCompleteQuestions;
      }
    })

    this.selectedAuctionSubscription$ = this.landingAndBidderService.getSelectedAuction$.subscribe(data => {
      if (data) {
        this.auctionEntityDto = data;
      }
    })

    this.userAuctionQuestionsSubscription$ = this.questionnaireService.getUserAuctionQuestions$.subscribe(data => {
      if (data) {
        this.userAuctionQuestions = data
        this.populateUserAuctionQuestions();
      } else {
        this.userAuctionQuestions = []
        this.populateUserAuctionQuestions();
      }
    })


    this.ctrlDateStart.valueChanges.subscribe((val) => {
      this.checkDates();
    })

    this.ctrlDateEnd.valueChanges.subscribe((val) => {
      this.checkDates();
    })

    this.loadDocumentArchive();
  }

  async loadBidderUiDto() {
    this.userEntityDto = await firstValueFrom(this.store.pipe(select(selectBidderUiDto)));
  }

  closeModal(modalRef?: NgbModalRef) {
    if (modalRef) {
      modalRef.close();
    } else {
      this.activeModal.close();
    }
  }

  checkDates() {
    if (this.ctrlDateStart.value && this.ctrlDateEnd.value) {
      let startDateInStr = AuctionExtUtil.getDateFromNgDatePicker(this.datePipe, this.ctrlDateStart.value);
      let endDateInStr = AuctionExtUtil.getDateFromNgDatePicker(this.datePipe, this.ctrlDateEnd.value);
      let startDate = AuctionExtUtil.convertedDate(startDateInStr!, "23:59") as Date;
      let endDate = AuctionExtUtil.convertedDate(endDateInStr!, "23:59") as Date;
      let isNotValid = startDate.getTime() >= endDate.getTime();

      if (isNotValid) {
        this.errorMsg = "Start date is not greater than end date";
        this._showErrorToast$.next(true);
      } else {
        this.errorMsg = "";
        this._showErrorToast$.next(false);
      }

      return !isNotValid;
    }
    return false;
  }

  populateUserAuctionQuestions() {
    this.ctrlQuestion.patchValue(`${this.selectedTechnicalQuestion.preSequenceText}.${this.selectedTechnicalQuestion.sequenceNo} ${this.selectedTechnicalQuestion.questionText}`);
    this.ctrlQuestion.disable();
    this.ctrlQuestion.updateValueAndValidity();

    let currentQuestion = this.userAuctionQuestions.find(item => item.questionId == this.selectedTechnicalQuestion.questionId);
    if (currentQuestion) {
      this.isQuestionSubmitted = true;
      if (this.selectedTechnicalQuestion.responseType == QuestionResponseType.SINGLE_CHOICE) {
        this.bidderResponse = currentQuestion.bidderResponse;
      } else if (this.selectedTechnicalQuestion.responseType == QuestionResponseType.MULTI_CHOICE) {
        this.bidderResponse = currentQuestion.bidderResponse;
      } else if (this.selectedTechnicalQuestion.responseType == QuestionResponseType.DATE) {
        this.ctrlDateStart.patchValue(AuctionExtUtil.getNgbPickerDate(currentQuestion.bidderResponse));
      } else if (this.selectedTechnicalQuestion.responseType == QuestionResponseType.DATE_RANGE) {
        this.ctrlDateStart.patchValue(AuctionExtUtil.getNgbPickerDate(currentQuestion.bidderResponse[0]));
        this.ctrlDateEnd.patchValue(AuctionExtUtil.getNgbPickerDate(currentQuestion.bidderResponse[1]));
      } else if (this.selectedTechnicalQuestion.responseType == QuestionResponseType.ALPHANUMERIC) {
        this.ctrlAlphaNumeric.patchValue(currentQuestion.bidderResponse);
      }

      this.selectedDocuments = currentQuestion.bidderDocuments ?? [];
      this.ctrlBidderComments.patchValue(currentQuestion.bidderComments);

      this.ctrlDateStart.disable();
      this.ctrlDateEnd.disable();
      this.ctrlAlphaNumeric.disable();
      this.ctrlBidderComments.disable();
    } else {
      this.isQuestionSubmitted = false;
      this.selectedDocuments = [];
    }

    this.countCompleteQuestions = this.userAuctionQuestions.filter(item => item.status == UserQuestionStatus.COMPLETED && item.sectionId == this.selectedTechnicalQuestion.sectionId).length;
    this.countIncompleteQuestions = this.technicalQuestionsList.length - this.countCompleteQuestions;
  }

  resetCurrentQuestion() {
    this.bidderResponse = undefined;
    this.ctrlDateStart.reset();
    this.ctrlDateEnd.reset();
    this.ctrlAlphaNumeric.reset();
    this.ctrlBidderComments.reset();
    this.ctrlBidderComments.enable();
    this.ctrlDateStart.enable();
    this.ctrlDateEnd.enable();
    this.ctrlAlphaNumeric.enable();
    this._showSuccessToast$.next(false);
    this._showErrorToast$.next(false);
    this.populateUserAuctionQuestions();
  }

  nextQuestion() {
    let currentSequenceNo = this.selectedTechnicalQuestion.sequenceNo;
    if (Number(currentSequenceNo) < this.technicalQuestionsList.length) {
      let index = this.technicalQuestionsList.findIndex(item => item.sequenceNo == currentSequenceNo);
      this.selectedTechnicalQuestion = this.technicalQuestionsList[index + 1];
      this.resetCurrentQuestion();
    }
  }

  prevQuestion() {
    let currentSequenceNo = this.selectedTechnicalQuestion.sequenceNo;
    if (Number(currentSequenceNo) > 1) {
      let index = this.technicalQuestionsList.findIndex(item => item.sequenceNo == currentSequenceNo);
      this.selectedTechnicalQuestion = this.technicalQuestionsList[index - 1];
      this.resetCurrentQuestion();
    }
  }

  isDisabledPrev() {
    return Number(this.selectedTechnicalQuestion.sequenceNo) == 1;
  }

  isDisabledNext() {
    return Number(this.selectedTechnicalQuestion.sequenceNo) == this.technicalQuestionsList.length;
  }

  clearOptionSelection() {
    this.bidderResponse = undefined;
    this.ctrlDateStart.reset();
    this.ctrlDateEnd.reset();
    this.ctrlAlphaNumeric.reset();
  }

  formatBytes(size: any) {
    return AuctionExtUtil.formatBytes(size);
  }

  loadDocumentArchive() {
    this.userService.getDocumentArchives().subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        this.documentArchiveList = apiResponseDto.data as DocumentArchiveUiDto[];
      },
      error: (error) => {
        // this.listLoading$.next(false)
      }
    })
  }

  openDocumentModal(content: any) {
    if (this.isQuestionSubmitted) {
      return;
    }

    this.documentModalRef = this.ngbModal.open(content, {
      size: 'md', centered: true, backdrop: 'static', keyboard: false
    })
  }

  selectDocumentArchive(documentArchive: DocumentArchiveUiDto) {
    let isDocumentSelected = this.selectedDocuments.find(item => item.fileId == documentArchive.fileId);
    if (!isDocumentSelected) {
      let fileInfoDto = new FileInfoDto();
      fileInfoDto.fileId = documentArchive.fileId;
      fileInfoDto.displayName = documentArchive.documentName;
      fileInfoDto.fileName = documentArchive.fileName;
      fileInfoDto.fileSize = documentArchive.size;
      fileInfoDto.fileType = 'application/pdf';

      this.selectedDocuments.push(documentArchive);
    } else {
      let index = this.selectedDocuments.findIndex(item => item.fileId == documentArchive.fileId);
      if (index > -1) {
        this.selectedDocuments.splice(index, 1);
      }
    }
  }

  removeSelectedDocument(fileId: string) {
    let index = this.selectedDocuments.findIndex(item => item.fileId == fileId);
    this.selectedDocuments.splice(index, 1);
  }

  chooseFile(event: any) {
    if (this.isQuestionSubmitted) {
      return;
    }

    this._showErrorToast$.next(false);
    this.currentFile = event.target.files[0];

    if (!this.documentTypes.includes(this.currentFile!.type)) {
      this.fileUploadError = true;
      this.errorMsg = "Extension not supported";
      this._showErrorToast$.next(true);
      this.currentFile = null;
      this._fileDisplayName$.next(null);
      return;
    }

    this._fileDisplayName$.next(this.getFileName(this.currentFile?.name!) as string);
    this._fileSize$.next(this.currentFile?.size + "");

    this.uploadFile();
  }

  getFileName(fileName: string) {
    let extensionName = fileName?.slice(fileName.lastIndexOf('.'), fileName.length)
    let name = fileName?.slice(0, fileName.lastIndexOf('.'));
    let finalFileName = AuctionExtUtil.removeSpecialCharacters(name!) as string
    return finalFileName + "" + extensionName;
  }

  uploadFile() {
    this.fileUploadError = false;
    this.isUploadLoading = true;
    this.errorMsg = "";

    let metaData = {
      'mimeType': this.currentFile?.type,
      'version': 0,
      'publicApi': false,
      'dataType': 'Rectangle'
    };

    let formData = new FormData();
    formData.append("file", this.currentFile!);
    formData.append('metaData', JSON.stringify(metaData));

    this.fileService.uploadFile(formData).subscribe(apiResponseDto => {
      if (apiResponseDto) {
        let fileSaveResponseDto = apiResponseDto.data as FileSaveResponseDto;
        if (apiResponseDto.code == AuctionExtConstant.SUCCESS_CODE) {
          this.fileUploadError = false;
          this.isUploadLoading = false;

          let currentFileInfoDto = new FileInfoDto();
          currentFileInfoDto.fileId = fileSaveResponseDto.fileId;
          currentFileInfoDto.fileName = fileSaveResponseDto.fileName;
          currentFileInfoDto.displayName = fileSaveResponseDto.fileName;
          currentFileInfoDto.dataType = fileSaveResponseDto.dataType;
          currentFileInfoDto.fileSize = this.currentFile?.size.toString();
          currentFileInfoDto.fileType = this.currentFile?.type;
          currentFileInfoDto.uploadDate = new Date().toDateString();

          this.selectedDocuments.push(currentFileInfoDto);
        }
      } else {
        this.fileUploadError = true;
        this.isUploadLoading = false;
      }
    })
  }

  selectBidderResponse(questionOption: QuestionOptions) {
    if (this.isQuestionSubmitted) {
      return;
    }

    if (this.selectedTechnicalQuestion.responseType == QuestionResponseType.SINGLE_CHOICE) {
      this.bidderResponse = questionOption;
    } else if (this.selectedTechnicalQuestion.responseType == QuestionResponseType.MULTI_CHOICE) {
      if (!this.bidderResponse) {
        this.bidderResponse = [];
      }

      let index = this.bidderResponse.findIndex((item: QuestionOptions) => item.optionSequence == questionOption.optionSequence);
      if (index != -1) {
        this.bidderResponse.splice(index, 1);
      } else {
        this.bidderResponse.push(questionOption);
      }
    }
  }

  isOptionSelected(questionOption?: QuestionOptions) {
    if (questionOption && this.bidderResponse) {
      if (this.selectedTechnicalQuestion.responseType == QuestionResponseType.SINGLE_CHOICE) {
        return this.bidderResponse.optionSequence == questionOption.optionSequence;
      } else if (this.selectedTechnicalQuestion.responseType == QuestionResponseType.MULTI_CHOICE) {
        let option = this.bidderResponse.find((item: QuestionOptions) => item.optionSequence == questionOption.optionSequence)
        return option != undefined;
      }
    }
    return false;
  }

  mergeUserAuctionQuestionsDto() {
    let userAuctionQuestionsDto = new UserAuctionQuestionsDto();
    userAuctionQuestionsDto.questionId = this.selectedTechnicalQuestion.questionId;
    userAuctionQuestionsDto.sectionId = this.selectedTechnicalQuestion.sectionId;
    userAuctionQuestionsDto.questionnaireId = this.selectedTechnicalQuestion.questionnaireId;
    userAuctionQuestionsDto.auctionHouseId = this.auctionEntityDto?.orgCode;
    userAuctionQuestionsDto.auctionId = this.auctionEntityDto?.auctionId;
    userAuctionQuestionsDto.envelopeType = SourcingEnvelopeType.TECHNICAL;
    userAuctionQuestionsDto.name = this.userEntityDto?.name;
    userAuctionQuestionsDto.firstName = this.userEntityDto?.firstName;
    userAuctionQuestionsDto.lastName = this.userEntityDto?.lastName;
    userAuctionQuestionsDto.userId = this.userEntityDto?.userId;
    userAuctionQuestionsDto.emailId = this.userEntityDto?.primaryEmailId;
    userAuctionQuestionsDto.responseType = this.selectedTechnicalQuestion.responseType;
    userAuctionQuestionsDto.bidderDocuments = this.selectedDocuments;
    userAuctionQuestionsDto.bidderComments = this.ctrlBidderComments.value;
    userAuctionQuestionsDto.status = UserQuestionStatus.NEW;

    if (this.selectedTechnicalQuestion.responseType == QuestionResponseType.SINGLE_CHOICE || this.selectedTechnicalQuestion.responseType == QuestionResponseType.MULTI_CHOICE) {
      userAuctionQuestionsDto.bidderResponse = this.bidderResponse;

    } else if (this.selectedTechnicalQuestion.responseType == QuestionResponseType.DATE) {
      userAuctionQuestionsDto.bidderResponse = AuctionExtUtil.getDateFromNgDatePicker(this.datePipe, this.ctrlDateStart.value);

    } else if (this.selectedTechnicalQuestion.responseType == QuestionResponseType.DATE_RANGE) {
      let startDate = AuctionExtUtil.getDateFromNgDatePicker(this.datePipe, this.ctrlDateStart.value);
      let endDate = AuctionExtUtil.getDateFromNgDatePicker(this.datePipe, this.ctrlDateEnd.value);

      userAuctionQuestionsDto.bidderResponse = [startDate, endDate];

    } else if (this.selectedTechnicalQuestion.responseType == QuestionResponseType.ALPHANUMERIC) {
      userAuctionQuestionsDto.bidderResponse = this.ctrlAlphaNumeric.value;
    }

    return userAuctionQuestionsDto;
  }

  isValidBidderResponse() {
    if (this.selectedTechnicalQuestion.responseType == QuestionResponseType.SINGLE_CHOICE) {
      return this.bidderResponse != undefined && this.bidderResponse != '';
    } else if (this.selectedTechnicalQuestion.responseType == QuestionResponseType.MULTI_CHOICE) {
      return this.bidderResponse && this.bidderResponse.length > 0;
    } else if (this.selectedTechnicalQuestion.responseType == QuestionResponseType.DATE) {
      return this.ctrlDateStart.valid;
    } else if (this.selectedTechnicalQuestion.responseType == QuestionResponseType.DATE_RANGE) {
      return this.ctrlDateStart.valid && this.ctrlDateEnd.valid && this.checkDates();
    } else if (this.selectedTechnicalQuestion.responseType == QuestionResponseType.ALPHANUMERIC) {
      return this.ctrlAlphaNumeric.valid && (this.ctrlAlphaNumeric.value as string).trim();
    }
  }

  saveQuestion() {
    let isUploadRequired = this.selectedTechnicalQuestion.questionOptions?.find((item : QuestionOptions) => item.uploadRequired);
    this._showErrorToast$.next(false);
    this.errorMsg = "";

    if (!this.isValidBidderResponse() && (isUploadRequired && this.selectedDocuments.length == 0)) {
      return;
    }

    let userAuctionQuestionsDto = this.mergeUserAuctionQuestionsDto()
    this.isLoading = true;

    this.questionnaireService.userAuctionQuestions(userAuctionQuestionsDto).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto.code == AuctionExtConstant.SUCCESS_CODE) {
          let data = apiResponseDto.data as UserAuctionQuestionsDto[];
          this.questionnaireService.updateUserAuctionQuestions(data);

          this._showSuccessToast$.next(true);
          this.isLoading = false;
          setTimeout(() => {
            this._showSuccessToast$.next(false);
            this.closeModal()
          }, 2000)
        } else {
          this.errorMsg = apiResponseDto.message;
          this._showErrorToast$.next(true);
          this.isLoading = false;
        }
      },
      error: (err) => {
        console.error(err);
        this.errorMsg = "Error while saving Auction registration. Try again.";
        this._showErrorToast$.next(true);
        this.isLoading = false;

      }
    })
  }

  isDocumentSelected(fileId: string) {
    let document = this.selectedDocuments.find(item => item.fileId == fileId);
    return document != undefined;
  }

  getExpiryDate(fileId: string) {
    let documentArchive = this.documentArchiveList.find(item => item.fileId == fileId);
    if (documentArchive) {
      return documentArchive.expiryDate;
    }
    return '';
  }

  ngOnDestroy(): void {
    if (this.questionnaireWrapperDtoSubscription$) {
      this.questionnaireWrapperDtoSubscription$.unsubscribe();
    }
    if (this.selectedAuctionSubscription$) {
      this.selectedAuctionSubscription$.unsubscribe()
    }
    if (this.userAuctionQuestionsSubscription$) {
      this.userAuctionQuestionsSubscription$.unsubscribe()
    }
  }

}

