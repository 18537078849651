import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, Subscription } from 'rxjs';
import { RegistrationStages } from 'src/app/shared/enums/RegistrationStages';
import { UserRegistrationStatus } from 'src/app/shared/enums/UserRegistrationStatus';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';
import { BidderUiDto } from 'src/app/shared/models/UserEntityDto';
import { DashboardRedirectionService } from 'src/app/shared/services/dashboard-redirection.service';
import { RegistrationService } from 'src/app/shared/services/registration.service';
import { UserService } from 'src/app/shared/services/user.service';
import { SessionActions } from 'src/app/shared/state-management/session/session.actions';
import { selectBidderUiDto } from 'src/app/shared/state-management/session/session.features';

@Component({
  selector: 'app-profile-stage',
  templateUrl: './profile-stage.component.html',
  styleUrls: ['./profile-stage.component.sass']
})
export class ProfileStageComponent implements OnInit, OnDestroy {
  userEntityDto?: BidderUiDto;
  registrationStages: typeof RegistrationStages = RegistrationStages;
  @Output() onFinishProcess = new EventEmitter<void>();

  isShowSkipButton: boolean = false;
  isDisabledPrevButton: boolean = false;
  isDisabledNextButton: boolean = false;

  activeStage: RegistrationStages = RegistrationStages.BASIC_DETAILS;
  currentStageIndex: number = 1;

  currentRegistrationStage$ = new BehaviorSubject<RegistrationStages>(RegistrationStages.BASIC_DETAILS);

  userEntityDtoSubscription$?: Subscription;

  constructor(
    private registrationService: RegistrationService,
    private router: Router,
    private dashboardRedirectionService: DashboardRedirectionService,
    private userService: UserService,
    private store: Store
  ) { }

  ngOnInit(): void {
    this.userEntityDtoSubscription$ = this.store.pipe(select(selectBidderUiDto)).subscribe(data => {
      if (data) {
        this.userEntityDto = data;
        this.checkCurrentStage();
      }
    })
  }

  toggleCurrentStage(newStage: RegistrationStages) {
    this.activeStage = newStage;
    this.currentRegistrationStage$.next(this.activeStage);

    this.checkFooterButtons();
  }

  async checkCurrentStage() {
    if (this.userEntityDto?.registrationStatus == UserRegistrationStatus.INCOMPLETE) {
   
    } else {
      if (!this.userEntityDto?.companyName) {
        this.currentStageIndex = 1;
        this.toggleCurrentStage(RegistrationStages.BASIC_DETAILS);
      }else if(!this.userEntityDto?.bankDetailsDto){
        this.currentStageIndex = 2;
        this.toggleCurrentStage(RegistrationStages.BANK_DETAILS);
      } else if (!this.userEntityDto?.address?.searchAddress) {
        this.currentStageIndex = 3;
        this.toggleCurrentStage(RegistrationStages.ADDRESS);
      } else {
        this.currentStageIndex = 4;
        this.toggleCurrentStage(RegistrationStages.DOCUMENTS);
      }
    }

    this.checkFooterButtons();
  }

  checkFooterButtons() {
    switch (this.activeStage) {
      case RegistrationStages.BASIC_DETAILS:
        this.isShowSkipButton = false;
        this.isDisabledPrevButton = true;
        if (this.userEntityDto?.registrationStatus == UserRegistrationStatus.INCOMPLETE) {
          this.isDisabledNextButton = true;
        } else {
          this.isDisabledNextButton = false;
        }
        break;

      case RegistrationStages.BANK_DETAILS:
        this.isShowSkipButton = false;
        this.isDisabledPrevButton = false;
        if (this.userEntityDto?.companyName) {
          this.isDisabledNextButton = false;
        } else {
          this.isDisabledNextButton = true;
        }
        break;

      case RegistrationStages.ADDRESS:
        this.isShowSkipButton = false;
        this.isDisabledPrevButton = false;
        if (this.userEntityDto?.address && this.userEntityDto?.address.searchAddress) {
          this.isDisabledNextButton = false;
        } else {
          this.isDisabledNextButton = true;
        }
        break;

      case RegistrationStages.DOCUMENTS:
        this.isShowSkipButton = true;
        this.isDisabledPrevButton = false;
        this.isDisabledNextButton = false;
        break;

      case RegistrationStages.DSC:
        this.isShowSkipButton = false;
        this.isDisabledPrevButton = false;
        this.isDisabledNextButton = true;
        break;

      default:
        this.isShowSkipButton = false;
        this.isDisabledPrevButton = true;
        this.isDisabledNextButton = false;
        break;
    }
  }

  nextStep() {
    switch (this.activeStage) {
      case RegistrationStages.BASIC_DETAILS:
        this.currentStageIndex = 2;
        this.toggleCurrentStage(RegistrationStages.BANK_DETAILS);
        break;
      case RegistrationStages.BANK_DETAILS:
        this.currentStageIndex = 3;
        this.toggleCurrentStage(RegistrationStages.ADDRESS);
        break;
      case RegistrationStages.ADDRESS:
        this.currentStageIndex = 4;
        this.toggleCurrentStage(RegistrationStages.DOCUMENTS);
        break;
      case RegistrationStages.DOCUMENTS:
        this.currentStageIndex = 5;
        this.toggleCurrentStage(RegistrationStages.DSC);
        break;
      case RegistrationStages.DSC:
        this.onFinishProcess.emit();
        break;

      default:
        break;
    }
  }

  prevStep() {
    switch (this.activeStage) {
      case RegistrationStages.BANK_DETAILS:
        this.currentStageIndex = 1;
        this.toggleCurrentStage(RegistrationStages.BASIC_DETAILS);
        break;
      case RegistrationStages.ADDRESS:
        this.currentStageIndex = 2;
        this.toggleCurrentStage(RegistrationStages.BANK_DETAILS);
        break;
      case RegistrationStages.DOCUMENTS:
        this.currentStageIndex = 3;
        this.toggleCurrentStage(RegistrationStages.ADDRESS);
        break;
      case RegistrationStages.DSC:
        this.currentStageIndex = 4;
        this.toggleCurrentStage(RegistrationStages.DOCUMENTS);
        break;

      default:
        break;
    }
  }

  redirectToHomePage(){
    this.dashboardRedirectionService.clearSessionCookies();
    this.router.navigateByUrl('/redirect-loader', { skipLocationChange: true }).then(() => this.router.navigate(['Bidder/Dashboard'], { skipLocationChange: true, queryParamsHandling: 'merge' }));
  }

  ngOnDestroy(): void {
    this.currentStageIndex = 1;

    if(this.userEntityDtoSubscription$) {
      this.userEntityDtoSubscription$.unsubscribe();
    }
  }
}

