import { Injectable } from "@angular/core";
import { BidderUiDto } from "../models/UserEntityDto";
import { RegistrationUserModalDto } from "../models/user/RegistrationUserModalDto";

@Injectable({
  providedIn: 'root'
})
export class RegistrationService {

  private tempRegistrationUserModalDto: RegistrationUserModalDto | undefined;
  private selectedPassword: string | undefined;
  authPage: string = 'LOGIN';    // REGISTER


  get getCurrentAuthPage() { return this.authPage; }

  getTempRegistrationUserModalDto() {
    return this.tempRegistrationUserModalDto;

  }

  setTempRegistrationUserModalDto(tempRegistrationUserModalDto: RegistrationUserModalDto) {
    return this.tempRegistrationUserModalDto = tempRegistrationUserModalDto;

  }

  getSelectedPassword() {
    return this.selectedPassword;
  }

  setSelectedPassword(password: string) {
    this.selectedPassword = password
  }

  setCurrentAuthPage(authPage: string) {
    this.authPage = authPage;
  }

};