<div class="mt-5">
  <div class="flex w-4/6 mx-auto">
    <!-- <div class="text-orange" *ngIf="!userEntityDto?.companyName">
      <img src="assets/icons/pending-icon.svg" class="svg-icon-class c-pointer me-2"> Your basic details are pending
    </div>
    <div class="text-success" *ngIf="_showSuccessToast$|async">
      <img src="assets/icons/filter-check.svg" class="svg-icon-class-md c-pointer me-2">
      Your basic details are saved
    </div>
    <div class="text-danger" *ngIf="_showErrorToast$|async">
      <img src="assets/icons/filter-check.svg" class="svg-icon-class-md c-pointer me-2">
      {{errorMsg}}
    </div> -->
    <div class="w-full bg-white pt-6 pb-8 px-8 rounded-lg">
      <div class="flex items-center mb-6">
        <div class="">
          <span class="h-10 w-10  items-center justify-between bg-blue-100 rounded-lg inline-flex p-2">
            <i-lucide name="building-2" color="#374151" class="my-icon"></i-lucide>
          </span>
        </div>
        <div class="inline-block ms-2">
          <div class="font-semibold text-gray-800 text-lg">
            Basic details
          </div>
          <div class="text-gray-500 text-xs text-medium font-medium">
            Add your company details to continue.
          </div>
        </div>
      </div>
      <form class="needs-validation" novalidate [formGroup]="formGroup" (keyup.enter)="handleValidSubmit()">
        <div class="flex mt-1 gap-3">
          <div class="md:basis-1/2  mb-4">
            <div class="">
              <label for="firstName" class="text-gray-600  text-sm font-medium leading-4 text-start">First Name</label>
              <input type="text"
                class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-600 text-sm"
                id="firstName" formControlName="firstName"
                [ngClass]="{'is-invalid': fc['firstName'].invalid && (fc['firstName'].dirty || fc['firstName'].touched)}" />

              <div *ngIf="fc['firstName'].invalid && (fc['firstName'].dirty || fc['firstName'].touched)">
                <p class="text-danger" *ngIf="fc['firstName'].errors?.['required']">
                  <i-lucide name="triangle-alert" [size]="16" color="rgb(239 68 68)" class="my-icon inline-flex me-1"></i-lucide>First
                  Name Required
                </p>
                <p class="text-danger" *ngIf="fc['firstName'].errors?.['maxlength']">
                  <i-lucide name="triangle-alert" [size]="16" color="rgb(239 68 68)"
                    class="my-icon inline-flex me-1"></i-lucide>Maximum length 100
                </p>
              </div>
            </div>
          </div>
          <div class="md:basis-1/2 mb-4">
            <div class="">
              <label for="lastName" class="text-gray-600  text-sm font-medium leading-4 text-start">Last Name</label>
              <input
                class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-600 text-sm"
                id="lastName" formControlName="lastName"
                [ngClass]="{'is-invalid': fc['lastName'].invalid && (fc['lastName'].dirty || fc['lastName'].touched)}" />
              <div *ngIf="fc['lastName'].invalid && (fc['lastName'].dirty || fc['lastName'].touched)">
                <p class="text-danger" *ngIf="fc['lastName'].errors?.['required']">
                  <i-lucide name="triangle-alert" [size]="16" color="rgb(239 68 68)" class="my-icon inline-flex me-1"></i-lucide>Last
                  Name Required
                </p>
                <p class="text-danger" *ngIf="fc['lastName'].errors?.['maxlength']">
                  <i-lucide name="triangle-alert" [size]="16" color="rgb(239 68 68)" class="my-icon inline-flex me-1"></i-lucide>Maximum length 100
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="flex mt-1 gap-3">
          <div class="md:basis-1/2 mb-4">
            <label for="companyName" class="text-gray-600  text-sm font-medium leading-4 text-start">Company
              Name</label>
            <div>
              <input
                class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-600 text-sm"
                formControlName="companyName" id="companyName"
                [ngClass]="{'is-invalid': fc['companyName'].invalid && (fc['companyName'].dirty || fc['companyName'].touched)}" />
              <div *ngIf="fc['companyName'].invalid && (fc['companyName'].dirty || fc['companyName'].touched)">
                <p class="text-danger" *ngIf="fc['companyName'].errors?.['required']">
                  <i-lucide name="triangle-alert" [size]="16" color="rgb(239 68 68)"
                    class="my-icon inline-flex me-1"></i-lucide>Company Name Required
                </p>
                <p class="text-danger" *ngIf="fc['companyName'].errors?.['maxlength']">
                  <i-lucide name="triangle-alert" [size]="16" color="rgb(239 68 68)"
                    class="my-icon inline-flex me-1"></i-lucide>Maximum length 250
                </p>
              </div>
            </div>
          </div>

          <div class="md:basis-1/2 mb-4">
            <label for="designation" class="text-gray-600  text-sm font-medium leading-4 text-start">
              Designation
            </label>
            <select name="designation" formControlName="designation" id="designation"
              class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-400 h-8 px-2 py-1.5 text-gray-800 text-sm form-select"
              [ngClass]="{'is-invalid': fc['designation'].invalid && (fc['designation'].dirty || fc['designation'].touched)}">
              <option value="" disabled [selected]="!fc['designation'].value">Select Designation (Optional)</option>
              <option value="managing_director">Managing Director</option>
              <option value="director">Director</option>
              <option value="procurement_head">Procurement Head</option>
            </select>
            <div *ngIf="fc['designation'].invalid && (fc['designation'].dirty || fc['designation'].touched)">
              <p class="text-red-500" *ngIf="fc['designation'].errors?.['required']">
                <i-lucide name="triangle-alert" [size]="16" color="rgb(239 68 68)"
                  class="my-icon inline-flex me-1"></i-lucide>Select Designation
              </p>
              <p class="text-red-500" *ngIf="fc['designation'].errors?.['maxlength']">
                <i-lucide name="triangle-alert" [size]="16" color="rgb(239 68 68)"
                  class="my-icon inline-flex me-1"></i-lucide>Maximum length 250
              </p>
            </div>
          </div>
        </div>
        <div class="flex mt-1 gap-3">
          <div class="md:basis-1/2 mb-4">
            <label for="companyUrl" class="text-gray-600  text-sm font-medium leading-4 text-start w-full">
              <div class="flex  flex-row items-center justify-between">
                <div>
                  Company Website URL
                </div>
                <div>[Optional]</div>
              </div>
              </label>
            <div class="">
              <input
                class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-400 h-8 px-2 py-1.5 text-gray-800 text-sm form-select"
                id="app country" formControlName="companyUrl"
                [ngClass]="{'is-invalid': fc['companyUrl'].invalid && (fc['companyUrl'].dirty || fc['companyUrl'].touched)}" />
              <div *ngIf="fc['companyUrl'].invalid && (fc['companyUrl'].dirty || fc['companyUrl'].touched)">
                <p class="text-danger" *ngIf="fc['companyUrl'].errors?.['maxlength']">
                  <i-lucide name="triangle-alert" [size]="16" color="rgb(239 68 68)"
                    class="my-icon inline-flex me-1"></i-lucide>Maximum length 250
                </p>
                <p class="text-danger" *ngIf="fc['companyUrl'].errors?.['pattern']">
                  <i-lucide name="triangle-alert" [size]="16" color="rgb(239 68 68)"
                    class="my-icon inline-flex me-1"></i-lucide>Invalid Url
                </p>
              </div>
            </div>
          </div>
          <div class="md:basis-1/2 mb-4">
            <label for="emailId" class="text-gray-600  text-sm font-medium leading-4 text-start">Email Id</label>

            <div>
              <input
                class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-400 h-8 px-2 py-1.5 text-gray-800 text-sm "
                id="emailId" formControlName="emailId" />
            </div>
          </div>

        </div>
        <div class="flex mt-1 gap-3">
          <div class="md:basis-1/2 mb-4">
            <div class="text-start">
              <label class="text-gray-600  text-sm font-medium leading-4" for="mobileNo">Phone Number</label>
              <div class="flex w-full mb-3">
                <div class="input-group-append">
                  <span class="border border-solid border-slate-50 border-r-0 rounded-l-lg h-8 flex scroll-px-32 px-3">
                    <app-country-code-select #countryCode (countryCodeEvent)="handleCountryCodeEvent($event)"
                      [countryCode$]="countryCodeForMobileNo$"></app-country-code-select>
                  </span>
                </div>

                <input type="text" class="border border-solid border-slate-50 border-l-0 rounded-r-lg h-8 flex  px-3  w-full "
                  id="mobileNo" placeholder="Phone Number" formControlName="mobileNo"
                  [ngClass]="{'is-invalid': fc['mobileNo'].invalid && (fc['mobileNo'].dirty || fc['mobileNo'].touched)}">

              </div>
              <div *ngIf="fc['mobileNo'].invalid && (fc['mobileNo'].dirty || fc['mobileNo'].touched)">
                <p class="text-danger" *ngIf="fc['mobileNo'].errors?.['required']">
                  <i-lucide name="triangle-alert" [size]="16" color="rgb(239 68 68)" class="my-icon inline-flex me-1"></i-lucide>Phone Number</p>
                <p class="text-danger" *ngIf="fc['mobileNo'].errors?.['pattern']">
                  <i-lucide name="triangle-alert" [size]="16" color="rgb(239 68 68)" class="my-icon inline-flex me-1"></i-lucide>Invalid</p>
              </div>

            </div>
            <!-- <div class="input-group">
              <span class="input-group-addon">
                <app-country-code-select #countryCode (countryCodeEvent)="handleCountryCodeEvent($event)"
                  [countryCode$]="countryCodeForMobileNo$"></app-country-code-select>
              </span>
              <div class="form-floating">
                <input class="form-control" id="mobileNo" formControlName="mobileNo"
                  [ngClass]="{'is-invalid': fc['mobileNo'].invalid && (fc['mobileNo'].dirty || fc['mobileNo'].touched)}" />
                <label for="mobileNo">Phone Number</label>
                <div *ngIf="fc['mobileNo'].invalid && (fc['mobileNo'].dirty || fc['mobileNo'].touched)">
                  <p class="text-danger" *ngIf="fc['mobileNo'].errors?.['required']">Required</p>
                  <p class="text-danger" *ngIf="fc['mobileNo'].errors?.['pattern']">Invalid</p>
                </div>
              </div>
            </div> -->
          </div>
          <div class="md:basis-1/2 mb-4">
            <div class="text-start">
              <label class="text-gray-600  text-sm font-medium leading-4" for="faxNo">Fax Number</label>
              <div class="flex w-full mb-3">
                <div class="input-group-append">
                  <span class="border border-solid border-slate-50 border-r-0 rounded-l-lg h-8 flex px-3">
                    <app-country-code-select #countryCode (countryCodeEvent)="handleCountryCodeEventForFaxNo($event)"
                      [countryCode$]="countryCodeForFaxNo$"></app-country-code-select>
                  </span>
                </div>

                <input type="number" class="border border-solid border-slate-50 border-l-0 rounded-r-lg h-8 flex  px-3 w-full "
                  id="faxNo" placeholder="Fax" formControlName="faxNo"
                  [ngClass]="{'is-invalid': fc['faxNo'].invalid && (fc['faxNo'].dirty || fc['faxNo'].touched)}">
              </div>
              <div *ngIf="fc['faxNo'].invalid && (fc['faxNo'].dirty || fc['faxNo'].touched)">
                <p class="text-danger" *ngIf="fc['faxNo'].errors?.['pattern']">
                  <i-lucide name="triangle-alert" [size]="16" color="rgb(239 68 68)" class="my-icon inline-flex me-1"></i-lucide>Invalid</p>
              </div>

            </div>
            <!-- <div class="input-group">
              <span class="input-group-addon">
                <app-country-code-select #countryCode (countryCodeEvent)="handleCountryCodeEventForFaxNo($event)"
                  [countryCode$]="countryCodeForFaxNo$"></app-country-code-select>
              </span>
              <div class="form-floating">
                <input class="form-control" formControlName="faxNo" id="faxNo"
                  [ngClass]="{'is-invalid': fc['faxNo'].invalid && (fc['faxNo'].dirty || fc['faxNo'].touched)}" />
                <label for="faxNo" class="text-gray-600  text-sm font-medium leading-4 text-start">Fax Number (Optional)</label>
                <div *ngIf="fc['faxNo'].invalid && (fc['faxNo'].dirty || fc['faxNo'].touched)">
                  <p class="text-danger" *ngIf="fc['faxNo'].errors?.['pattern']">Invalid</p>
                </div>
              </div>
            </div> -->
          </div>
         

          <!-- <div class="col-12">
            <div class="row">
              <div class="col-md-6">
                <div class="form-floating mb-3">
                  <input name="" type="text" id="companyLogo" (click)="companyLogo.click()" 
                    class="form-control c-pointer" [value]="this._fileDisplayName$ | async"/>
                  <input #companyLogo name="companyLogo" onclick="this.value=null;" type="file" class="d-none"
                  (change)="chooseFile($event)" />
                  <label for="companyLogo">Add Company Logo (for invoice)</label>
                  <div *ngIf="fc['companyLogo'].invalid && (fc['companyLogo'].dirty || fc['companyLogo'].touched)">
                    <p class="text-danger" *ngIf="fc['companyLogo'].errors?.['required']">Required</p>
                  </div>
                </div>
              </div>

             <div class="col-md-6">
              <div class="row">
                  <div class="col-6">
                    <img alt="" [src]="imageUrl" *ngIf="!currentFileInfoDto"
                      class="modal-logo-image" />
                    <img alt="" src="/downloadLandingBlob?fileId={{currentFileInfoDto!.fileId}}"
                      class="modal-logo-image"
                      *ngIf="currentFileInfoDto" />
                  </div>
              <div class="col-6 mt-3 text-end" *ngIf="currentFileInfoDto || currentFile">
                <button class="btn btn-sm btn-new-class me-2"  (click)="openViewImageModal(imageUrl)">
                  <img src="assets/icons/view_dark.svg"  class="svg-icon-class c-pointer">
                </button>
    
                <span (click)="deleteImage()"
                  [ngClass]="{'disabled' : isLoading }">
                  <img src="assets/icons/delete_danger.svg"  class="svg-icon-class c-pointer">
                </span>
              </div>
            </div>
             </div>
    
            </div>
          </div> -->
        </div>


        <div class=" mt-5 flex flex-row justify-end">
          <div class="  text-end flex">
            <p-button   [loading]="isLoading" class="text-center items-center px-4 py-1.5  bg-blue-700 text-white border-1 border-blue-300 hover:border-blue-600 focus:ring-4 focus:ring-blue-300 font-medium rounded text-sm flex-inline " (onClick)="saveUserProfile()"> Next: Bank details  <i-lucide [size]="16" name="move-right"
              class="my-icon inline-flex ms-1"></i-lucide></p-button>
          </div>
        </div>
       

        <!-- <div class="col-12 text-center" *ngIf="(!isLoading && !(_showSuccessToast$ | async))">
          <button class="btn btn-primary-clr-class btn-sm" (click)="handleValidSubmit()">Save</button>
        </div> -->
      </form>
    </div>
  </div>
</div>

<app-loader #appLoader></app-loader>