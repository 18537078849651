import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { SessionInfoDto } from '../../models/SessionInfoDto';
import { BidderUiDto } from '../../models/UserEntityDto';

export const SessionActions = createActionGroup({
  source: 'Session',
  events: {
    'Save Session Info': props<{ sessionInfo: SessionInfoDto }>(),
    'Get Session Info': emptyProps(),
    'Update Bidder Ui Dto': props<{ bidderUiDto: BidderUiDto }>(),
    'Clear Session': emptyProps(),
  },
});
