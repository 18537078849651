      <div class="mt-5">
  <div class="flex w-4/6 mx-auto">
    <div class="w-full bg-white pt-6 pb-8 px-8 rounded-lg">
      <div class="flex items-center mb-6">
        <div class="">
          <span class="h-10 w-10  items-center justify-between bg-blue-100 rounded-lg inline-flex p-2">
            <i-lucide name="landmark"  color="#374151" class="my-icon"></i-lucide>
          </span>
        </div>
        <div class="inline-block ms-2">
          <div class="font-semibold text-gray-800 text-lg">
            Bank details
          </div>
          <div class="text-gray-500 text-xs text-medium font-medium ">
            Add your bank details to continue.
          </div>
        </div>
      </div>
      <form class="needs-validation" novalidate [formGroup]="formGroup">
        <div class="flex mt-1 gap-3">
          <div class="md:basis-1/2  mb-4">
            <div class="">
              <label for="firstName" class="text-gray-600  text-sm font-medium leading-4 text-start">Account holder name</label>
              <input type="text"
                class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-600 text-sm"
                id="accountHolderName" formControlName="accountHolderName"
                [ngClass]="{'is-invalid': fc['accountHolderName'].invalid && (fc['accountHolderName'].dirty || fc['accountHolderName'].touched)}" />
              <div *ngIf="fc['accountHolderName'].invalid && (fc['accountHolderName'].dirty || fc['accountHolderName'].touched)">
                <p class="text-red-500" *ngIf="fc['accountHolderName'].errors?.['required']">
                  <i-lucide name="triangle-alert" [size]="16" color="rgb(239 68 68)"
                    class="my-icon inline-flex me-1"></i-lucide>Account Holder Name Required
                </p>
                <p class="text-red-500" *ngIf="fc['accountHolderName'].errors?.['maxlength']">
                  <i-lucide name="triangle-alert" [size]="16" color="rgb(239 68 68)"
                    class="my-icon inline-flex me-1"></i-lucide>Maximum length 250
                </p>
              </div>
            </div>
          </div>
          <div class="md:basis-1/2  mb-4">
            <div class="">
              <label for="firstName" class="text-gray-600  text-sm font-medium leading-4 text-start">Bank name</label>
              <input type="text"
                class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-600 text-sm"
                id="bankName" formControlName="bankName"
                [ngClass]="{'is-invalid': fc['bankName'].invalid && (fc['bankName'].dirty || fc['bankName'].touched)}" />
              <div *ngIf="fc['bankName'].invalid && (fc['bankName'].dirty || fc['bankName'].touched)">
                <p class="text-red-500" *ngIf="fc['bankName'].errors?.['required']">
                  <i-lucide name="triangle-alert" [size]="16" color="rgb(239 68 68)"
                    class="my-icon inline-flex me-1"></i-lucide>Bank Name Required
                </p>
                <p class="text-red-500" *ngIf="fc['bankName'].errors?.['maxlength']">
                  <i-lucide name="triangle-alert" [size]="16" color="rgb(239 68 68)"
                    class="my-icon inline-flex me-1"></i-lucide>Maximum length 250
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="flex mt-1 gap-3">
          <div class="md:basis-1/2  mb-4">
            <div class="">
              <label for="firstName" class="text-gray-600  text-sm font-medium leading-4 text-start">Account number</label>
              <input type="text"
                class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-600 text-sm"
                id="accountNumber" formControlName="accountNumber"
                [ngClass]="{'is-invalid': fc['accountNumber'].invalid && (fc['accountNumber'].dirty || fc['accountNumber'].touched)}" />
              <div *ngIf="fc['accountNumber'].invalid && (fc['accountNumber'].dirty || fc['accountNumber'].touched)">
                <p class="text-red-500" *ngIf="fc['accountNumber'].errors?.['required']">
                  <i-lucide name="triangle-alert" [size]="16" color="rgb(239 68 68)"
                    class="my-icon inline-flex me-1"></i-lucide>Account Number Required
                </p>
                <p class="text-red-500" *ngIf="fc['accountNumber'].errors?.['maxlength']">
                  <i-lucide name="triangle-alert" [size]="16" color="rgb(239 68 68)"
                    class="my-icon inline-flex me-1"></i-lucide>Maximum Length 50
                </p>
                <p class="text-red-500" *ngIf="fc['accountNumber'].errors?.['pattern']">
                  <i-lucide name="triangle-alert" [size]="16" color="rgb(239 68 68)"
                    class="my-icon inline-flex me-1"></i-lucide>Enter Only Numbers
                </p>
              </div>
            </div>
          </div>
          <div class="md:basis-1/2  mb-4">
            <div class="">
              <label for="firstName" class="text-gray-600  text-sm font-medium leading-4 text-start">Branch</label>
              <input type="text"
                class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-600 text-sm"
                id="branch" formControlName="branch"
                [ngClass]="{'is-invalid': fc['branch'].invalid && (fc['branch'].dirty || fc['branch'].touched)}" />
              <div *ngIf="fc['branch'].invalid && (fc['branch'].dirty || fc['branch'].touched)">
                <p class="text-red-500" *ngIf="fc['branch'].errors?.['required']">
                  <i-lucide name="triangle-alert" [size]="16" color="rgb(239 68 68)"
                    class="my-icon inline-flex me-1"></i-lucide>Branch Required
                </p>
                <p class="text-red-500" *ngIf="fc['branch'].errors?.['maxlength']">
                  <i-lucide name="triangle-alert" [size]="16" color="rgb(239 68 68)"
                    class="my-icon inline-flex me-1"></i-lucide>Maximum length 250
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="flex mt-1 gap-3">
          <div class="md:basis-1/2  mb-4">
            <div class="">
              <label for="firstName" class="text-gray-600  text-sm font-medium leading-4 text-start">IFSC Code</label>
              <input type="text"
                class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-600 text-sm"
                id="ifscCode" formControlName="ifscCode"
                [ngClass]="{'is-invalid': fc['ifscCode'].invalid && (fc['ifscCode'].dirty || fc['ifscCode'].touched)}" />
              <div *ngIf="fc['ifscCode'].invalid && (fc['ifscCode'].dirty || fc['ifscCode'].touched)">
                <p class="text-red-500" *ngIf="fc['ifscCode'].errors?.['required']">
                  <i-lucide name="triangle-alert" [size]="16" color="rgb(239 68 68)"
                    class="my-icon inline-flex me-1"></i-lucide>IFSC Code Required
                </p>
                <p class="text-red-500" *ngIf="fc['ifscCode'].errors?.['maxlength']">
                  <i-lucide name="triangle-alert" [size]="16" color="rgb(239 68 68)"
                    class="my-icon inline-flex me-1"></i-lucide>Maximum length 50
                </p>
              </div>
            </div>
          </div>
       
        
      </div>
      <div class="flex flex-row justify-between mt-5">
        <div class=" text-start flex justify-start">
          <p-button class="text-center items-center text-blue-500 border-1 border-gray-300 hover:border-blue-600 focus:ring-4 focus:ring-blue-300 font-medium rounded text-sm px-4 py-1.5 inline-flex" (onClick)="prevStep()">
            <i-lucide [size]="16" name="move-left" class="my-icon flex-inline me-1"></i-lucide>Back</p-button>
        </div>
        <div class=" text-end flex justify-end">
          <p-button [loading]="isLoading" class="text-center items-center px-4 bg-blue-700 text-white border-1 border-blue-300 hover:border-blue-600 focus:ring-4 focus:ring-blue-300 font-medium rounded text-sm  inline-flex" (onClick)="saveUserProfile()">Next:Address <i-lucide [size]="16" name="move-right"
            class="my-icon inline-flex ms-1"></i-lucide></p-button>
        </div>
      </div>
        
         
      </form>
    </div>
  </div>
</div>