<div class="scroll" id="main">
  <app-header></app-header>
  <div class="container my-5" id="idForDataLoaderAPP">
    <div class="bg-grey py-5">
      <div class="col-9 m-auto text-center">
        <div>
          <img src="assets/images/loading.gif" alt="" class="profile-image-class">
        </div>
      </div>
    </div>
  </div>
  <router-outlet></router-outlet>
  <!-- <app-footer></app-footer> -->
</div>

<ng-template #ErrorTemp>
  <div class="my-2">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>
            Navigation Alert
            </h2>
        </div>
        <div class="col-auto text-end">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" (click)="closeModal()">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
      <div class="m-3">
       <div class="text-start text-danger border-danger p-2">
        <p style="margin-top: 0 !important;">
          The application strongly discourages the use of the browser's back and forward buttons, as they may cause significant data disparity. Therefore, it is highly recommended that you use the application's reliable and user-friendly navigation buttons to move seamlessly between pages.
        </p>
       </div>
       <div class="text-end my-3">
        <button type="button" class="btn btn-new-class btn-sm px-3"
        (click)="closeModal()">OK</button>
      </div>
    </div>
  </div>
</ng-template>

<app-loader #appLoader></app-loader>
