import { Injectable } from '@angular/core';
import { ErrorService } from './error.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AuctionExtConstant } from '../util/AuctionExtConstant';
import { ServerAPIResponseDto } from '../models/ServerAPIResponseDto';
import { Observable, catchError, firstValueFrom, tap } from 'rxjs';
import { LandingAndBidderService } from './LandingAndBidder.service';
import { AdminDashboardService } from './admin-dashboard.service';
import { AuctionLotEntityDto } from '../models/user/AuctionLotEntityDto';
import { AdminLotsDataHolderService } from './adminLotsDataHolderService.service';
import { UserLotFavouriteWrapperDto } from '../models/user/UserLotFavouriteWrapperDto';
import { LotDetailsWrapperDto } from '../models/LotDetailsWrapperDto';
import { UserAuctionRegistrationDto } from '../models/user/UserAuctionRegistrationDto';
import { MyBidsWrapperDto } from '../models/user/MyBidsWrapperDto';
import { Store } from '@ngrx/store';
import { selectSessionInfo } from '../state-management/session/session.features';

@Injectable({
  providedIn: 'root'
})
export class ServerDataFetchService {

  constructor(
    private http: HttpClient,
    private errorService: ErrorService,
    private bidderService: LandingAndBidderService,
    private adminService: AdminDashboardService,
    private adminLotsDataHolderService: AdminLotsDataHolderService,
    private store: Store
  ) { }

  /**
   * This method to be called when you need to load the data synchronous
   * Fetch the lots from Server for a given Auction for bidder role.
   * This method returns active lots with status =  LIVE, LIVE_WAIT, CLOSE
   * @param auctionId
   */
  async loadAllLotsOfAuctionForBidderSync(auctionId: string) {

    try {
      let apiResponseDto = await firstValueFrom(this.getAuctionLotsByAuctionIdForBidder(auctionId));

      if (apiResponseDto && apiResponseDto.code == AuctionExtConstant.SUCCESS_CODE) {
        let auctionLotEntityDtos = apiResponseDto.data as AuctionLotEntityDto[];

        this.bidderService.setAllLotsList(auctionLotEntityDtos);
      } else {
        console.error("Error while calling getAuctionLotsByAuctionIdForBidder : " + JSON.stringify(apiResponseDto));
      }
    } catch (error) {
      console.error(error);
    }
  }


  async loadAllSubcategoriesForBidderSync(rfxId: string) {

    try {
      let apiResponseDto = await firstValueFrom(this.bidderService.getRfxSubcategories(rfxId));

      if (apiResponseDto && apiResponseDto.code == AuctionExtConstant.SUCCESS_CODE) {
        let subcategories = apiResponseDto.data as AuctionLotEntityDto[];

        this.bidderService.setAllSubcategoryList(subcategories);
      } else {
        console.error("Error while calling loadAllSubcategoriesForBidderSync : " + JSON.stringify(apiResponseDto));
      }
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * This method to be called when you need to load the data synchronous
   * Fetch the lots from server for a given auction for admin and agent.
   * This methods returns all lots except discarded
   * @param auctionId
   */
  async loadAllLotsOfAuctionForAdminSync(auctionId: string) {
    this.adminService.setAllLotsDataLoaded(false);

    try {
      let apiResponseDto = await firstValueFrom(this.getAuctionLotsByAuctionIdForAdmin(auctionId));

      if (apiResponseDto && apiResponseDto.code == AuctionExtConstant.SUCCESS_CODE) {
        let auctionLotEntityDtos = apiResponseDto.data as AuctionLotEntityDto[];

        this.adminLotsDataHolderService.updateAllLotsData(auctionLotEntityDtos);
        this.adminService.setAllLotsDataLoaded(true);
      } else {
        console.error("Error while calling getAuctionLotsByAuctionIdForAdmin : " + JSON.stringify(apiResponseDto));
      }
    } catch (error) {
      console.error(error);
    }
  }

  listenAuctionLotDtoSelected(lotIds: string[]) {
    console.log("updating lotIds : " + lotIds.length);
    this.getAuctionLotsByLotIds(lotIds).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == AuctionExtConstant.SUCCESS_CODE) {
          let auctionLotEntityDtos = apiResponseDto.data as AuctionLotEntityDto[];
          console.log("Updated LotEntityDtos received " + auctionLotEntityDtos.length)
          this.bidderService.updateAuctionLotEntityDtosChanges(auctionLotEntityDtos);
        }
      },
      error: (error) => {
        console.error(error);
      }
    })
  }

  async getSimilarLotsByCategorySubcategoryIdSync(lotId: string, categorySubcategoryId: string): Promise<AuctionLotEntityDto[]> {
    let similarLots: AuctionLotEntityDto[] = []
    try {
      let apiResponseDto = await firstValueFrom(this.getLotBasedOnCategorySubcategoryId(categorySubcategoryId));

      if (apiResponseDto && apiResponseDto.code == AuctionExtConstant.SUCCESS_CODE) {
        if (apiResponseDto.data) {
          let lots = apiResponseDto.data as AuctionLotEntityDto[];
          if(lots && lots.length > 0){
            similarLots = lots.filter(lot => lot.lotId != lotId) ?? [];
          }else{
            similarLots = [];
          }

        } else {
          similarLots = [];
        }
      }
    } catch (error) {
      console.error(error);
    }
    return similarLots;
  }

  loadAllLotsOfPostAuctions(auctionIdsSet: string[]) {
    this.getAllLotsOfPostAuctions(auctionIdsSet).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == AuctionExtConstant.SUCCESS_CODE) {
          let lotsData = apiResponseDto.data as AuctionLotEntityDto[];
          this.adminLotsDataHolderService.updateAllPostAuctionLots(lotsData);
        }
      },
      error: (error) => {
        console.error(error);
      }
    })
  }

  async loadMyFavoriteLotsSync() {
    let sessionInfoDto = await firstValueFrom(this.store.select(selectSessionInfo));
    if (sessionInfoDto) {
      try{
        let apiResponseDto: ServerAPIResponseDto = await firstValueFrom(this.getMyFavoriteLots());
        if (apiResponseDto && apiResponseDto.code == AuctionExtConstant.SUCCESS_CODE) {
          let favoriteLots = apiResponseDto.data as UserLotFavouriteWrapperDto[];
          if(favoriteLots){
            this.bidderService.updateMyFavoriteAllLots(favoriteLots);
          }else{
            this.bidderService.updateMyFavoriteAllLots([]);
          }

        }
      }catch(error){
        console.error(error);
      }
    }
  }

  async loadAndGetLoadDetailsWrapperDtoSync(auctionId: string, lotId: string) {
    let lotDetailsWrapperDto: LotDetailsWrapperDto | undefined;
    let apiResponseDto: ServerAPIResponseDto;
    let sessionInfoDto = await firstValueFrom(this.store.select(selectSessionInfo));

    try {
      if (sessionInfoDto) {
        let userEntityDto = sessionInfoDto.bidderUiDto;
        apiResponseDto = await firstValueFrom(this.getLotDetailsWrapperDto(auctionId, lotId, userEntityDto?.userId!));
      } else {
        apiResponseDto = await firstValueFrom(this.getLandingLotDetailsWrapperDto(auctionId, lotId));
      }

      if (apiResponseDto && apiResponseDto.code == AuctionExtConstant.SUCCESS_CODE) {
        if (apiResponseDto.data) {
          lotDetailsWrapperDto = apiResponseDto.data as LotDetailsWrapperDto
          this.bidderService.updateTotalBids(auctionId, lotDetailsWrapperDto.totalBids);
        }
      }
    } catch (error) {
      console.error(error);
    }
    return lotDetailsWrapperDto;
  }

  async getUserAuctionRegistrationDataSync(auctionId: string, userId: string) {
    let userAuctionRegistrationDto: UserAuctionRegistrationDto | undefined;
    try {
      let apiResponseDto: ServerAPIResponseDto = await firstValueFrom(this.getUserAuctionRegistrationData(auctionId, userId));
      if (apiResponseDto && apiResponseDto.code == AuctionExtConstant.SUCCESS_CODE) {
        if (apiResponseDto.data) {
          userAuctionRegistrationDto = apiResponseDto.data as UserAuctionRegistrationDto
        }
      }
    } catch (error) {
      console.error(error);
    }
    return userAuctionRegistrationDto;
  }

  async getMyBidsDetailsDataSync() {
    let myBidsWrapperDto: MyBidsWrapperDto[] = [];
    let sessionInfoDto = await firstValueFrom(this.store.select(selectSessionInfo));

    try {
      if (sessionInfoDto) {
        let auctionHouseId = sessionInfoDto.orgCode!;
        let userId = sessionInfoDto.bidderUiDto?.userId!;

        let apiResponseDto: ServerAPIResponseDto = await firstValueFrom(this.getMyBidsWrapperData(auctionHouseId, userId));
        if (apiResponseDto && apiResponseDto.code == AuctionExtConstant.SUCCESS_CODE) {
          if (apiResponseDto.data) {
            myBidsWrapperDto = apiResponseDto.data as MyBidsWrapperDto[]
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
    return myBidsWrapperDto;
  }

  /// API CALLS

  getOrganizationDataFromServer(): Observable<ServerAPIResponseDto> {
    return this.http.get<ServerAPIResponseDto>('landing/orgDetails').pipe(
      tap(_ => console.log("Get landingAuctionHouse")),
      catchError(this.errorService.handleError<any>("Error while getting organization details from landing/orgDetails ")));
  }

  getDeploymentConfiguration(): Observable<ServerAPIResponseDto> {
    return this.http.get<ServerAPIResponseDto>('deploymentConfiguration').pipe(
      tap(_ => console.log("Get DeploymentConfig")),
      catchError(this.errorService.handleError<any>("Error while getting applicationDeploymentConfig")));
  }

    /**
   * Get the timezones data from server.
   * This methods is called on application startup. Its a one time call.
   * @returns
   */
    getTimezonesDataFromServer(): Observable<ServerAPIResponseDto> {
      return this.http.get<ServerAPIResponseDto>('assets/timezones.json').pipe(
        tap(_ => console.log("Get timezones")),
        catchError(this.errorService.handleError<any>("Error while getting timezones")));
    }


      /**
   * Get the categories data from server.
   * This methods is called on application startup. Its a one time call.
   * @returns
   */
  getCategoriesDataFromServer(): Observable<ServerAPIResponseDto> {
    return this.http.get<ServerAPIResponseDto>('assets/categories.json').pipe(
      tap(_ => console.log("Get categories")),
      catchError(this.errorService.handleError<any>("Error while getting categories")));
  }

  /**
   * Get all the lots for a given auction.
   * This method is called when Bidder selects any auction card.
   * @param auctionId
   * @returns
   */
  getAuctionLotsByAuctionIdForBidder(auctionId: string): Observable<any> {
    let params = new HttpParams().set('auctionId', auctionId);
    return this.http.get<ServerAPIResponseDto>('auctionLotsByAuctionId', { params: params }).pipe(
      tap(_ => console.log("Get auctionLotsByLotIds")),
      catchError(this.errorService.handleError<any>("Error while getting auctionLotsByLotIds")));
  }

  /**
   * Get all the lots for a given auction.
   * This method is called when Admin selects any auction card.
   * @param auctionId
   * @returns
   */
  getAuctionLotsByAuctionIdForAdmin(auctionId: string): Observable<any> {
    let params = new HttpParams().set('auctionId', auctionId);
    return this.http.get<ServerAPIResponseDto>('auctionLotsByAuctionIdForAdmin', { params: params }).pipe(
      tap(_ => console.log("Get auctionLotsByAuctionIdForAdmin")),
      catchError(this.errorService.handleError<any>("Error while getting auctionLotsByAuctionIdForAdmin")));
  }

  /**
   * Get the AuctionCardWrapperDto for given auction Ids.
   * @param auctionIds
   * @returns
   */
  getAuctionCardWrapperDtosByIds(auctionIds: string[]): Observable<any> {
    return this.http.post<ServerAPIResponseDto>('auctionCardWrapperDtosByIds', { auctionIdsInString: auctionIds }).pipe(
      tap(_ => console.log("Get auctionCardWrapperDtos")),
      catchError(this.errorService.handleError<any>("Error while getting auctionCardWrapperDtos")));
  }

  getAuctionLotsByLotIds(lotIds: string[]): Observable<any> {
    return this.http.post<ServerAPIResponseDto>('auctionLotsByLotIds', { lotIdsInString: lotIds }).pipe(
      tap(_ => console.log("Get auctionLotsByLotIds")),
      catchError(this.errorService.handleError<any>("Error while getting auctionLotsByLotIds")));
  }

  getLotBasedOnCategorySubcategoryId(categoryAndSubCategoryId: string): Observable<any> {
    let params = new HttpParams().set('categoryAndSubCategoryId', categoryAndSubCategoryId);
    return this.http.get<ServerAPIResponseDto>('similarLots', { params: params }).pipe(
      tap(_ => console.log("Get SimilarLots")),
      catchError(this.errorService.handleError<any>("Error while getting SimilarLots")));
  }

  getAllLotsOfPostAuctions(auctionIds: string[]) {
    return this.http.post<ServerAPIResponseDto>('auctionLotsByAuctionIdsForAdmin', {'auctionIdsInString': auctionIds}).pipe(
      tap(_ => console.log("Get auctionLotsByAuctionIdsForAdmin")),
      catchError(this.errorService.handleError<any>("Error while getting auctionLotsByAuctionIdsForAdmin")));
  }

  getCurrentUserAuctionRegistrations() {
    return this.http.get<ServerAPIResponseDto>('userAuctionRegistrations').pipe(
      tap(_ => console.log("Get userAuctionRegistrations")),
      catchError(this.errorService.handleError<any>("Error while getting userAuctionRegistrations")));
  }

  getMyFavoriteLots() {
    return this.http.get<ServerAPIResponseDto>('myFavouriteLots').pipe(
      tap(_ => console.log("Get myFavouriteLots ")),
      catchError(this.errorService.handleError<any>("Error while getting myFavouriteLots")));
  }

  getMultiLotWrapperDto(auctionId: string, userId: string) {
    let params = new HttpParams().set('auctionId', auctionId).set('userId', userId);
    return this.http.get<ServerAPIResponseDto>('multiLotWrapperData', {params: params}).pipe(
      tap(_ => console.log("Get multiLotWrapperData ")),
      catchError(this.errorService.handleError<any>("Error while getting multiLotWrapperData")));
  }

  getAuctionCardWrapperDtosInitial() {
    let params = { pageSize: AuctionExtConstant.PAGE_SIZE };
    return this.http.get<ServerAPIResponseDto>('auctionCardWrapperDtosInitial', { params: params }).pipe(
      tap(_ => console.log("Get AuctionCardWrapperDtosInitial")),
      catchError(this.errorService.handleError<any>("Error while getting AuctionCardWrapperDtosInitial")));
  }

  getAuctionCardWrapperDtosRemaining() {
    return this.http.get<ServerAPIResponseDto>('auctionCardWrapperDtosRemaining').pipe(
      tap(_ => console.log("Get AuctionCardWrapperDtosRemaining")),
      catchError(this.errorService.handleError<any>("Error while getting AuctionCardWrapperDtosRemaining")));
    }

  getLandingMultiLotWrapperDto(auctionId: string) {
    let params = new HttpParams().set('auctionId', auctionId);
    return this.http.get<ServerAPIResponseDto>('landingMultiLotWrapperData', {params: params}).pipe(
      tap(_ => console.log("Get landingMultiLotWrapperData ")),
      catchError(this.errorService.handleError<any>("Error while getting multiLotWrapperData")));
  }

  getLotDetailsWrapperDto(auctionId: string, lotId: string, userId: string) {
    let params = new HttpParams().set('auctionId', auctionId).set('lotId', lotId).set('userId', userId);
    return this.http.get<ServerAPIResponseDto>('lotDetailsWrapperDto', { params: params }).pipe(
      tap(_ => console.log("Get lotDetailsWrapperDto ")),
      catchError(this.errorService.handleError<any>("Error while getting lotDetailsWrapperDto")));
  }

  getLandingLotDetailsWrapperDto(auctionId: string, lotId: string) {
    let params = new HttpParams().set('auctionId', auctionId).set('lotId', lotId);
    return this.http.get<ServerAPIResponseDto>('landingLotDetailsWrapperDto', { params: params }).pipe(
      tap(_ => console.log("Get landingLotDetailsWrapperDto")),
      catchError(this.errorService.handleError<any>("Error while getting landingLotDetailsWrapperDto")));
  }

  getUserAuctionRegistrationData(auctionId: string, userId: string) {
    let params = new HttpParams().set('auctionId', auctionId).set('userId', userId);
    return this.http.get<ServerAPIResponseDto>('userAuctionRegistrationData', { params: params }).pipe(
      tap(_ => console.log("Get userAuctionRegistrationData")),
      catchError(this.errorService.handleError<any>("Error while getting userAuctionRegistrationData")));
  }

  getMyBidsWrapperData(auctionHouseId: string, userId: string) {
    let params = new HttpParams().set('auctionHouseId', auctionHouseId).set('userId', userId);
    return this.http.get<ServerAPIResponseDto>('myBidsWrapper', { params: params }).pipe(
      tap(_ => console.log("Get myBidsWrapper")),
      catchError(this.errorService.handleError<any>("Error while getting myBidsWrapper")));
  }

  getUserResourceRegistrationWrappers(auctionHouseId: string) {
    let params = new HttpParams().set('auctionHouseId', auctionHouseId);
    return this.http.get<ServerAPIResponseDto>('userResourceRegistrationWrappers', { params }).pipe(
      tap(_ => console.log("Get userResourceRegistrationWrappers")),
      catchError(this.errorService.handleError<any>("Error while getting userResourceRegistrationWrappers")));
  }
  getUserAuctionRegistrationWrappers(auctionHouseId: string) {
    let params = new HttpParams().set('auctionHouseId', auctionHouseId);
    return this.http.get<ServerAPIResponseDto>('userResourceRegistrationWrappers', { params }).pipe(
      tap(_ => console.log("Get userResourceRegistrationWrappers")),
      catchError(this.errorService.handleError<any>("Error while getting userResourceRegistrationWrappers")));
  }
}
