import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ServerAPIResponseDto } from '../shared/models/ServerAPIResponseDto';
import { AuctionExtConstant } from '../shared/util/AuctionExtConstant';
import { AuthenticationService } from '../shared/services/authentication.service';
import { FormControl, Validators } from '@angular/forms';
import { UserService } from '../shared/services/user.service';
import { RegistrationService } from '../shared/services/registration.service';
import { AuthenticationResponseDto } from '../shared/models/AuthenticationResponseDto';
import { LoaderComponent } from '../shared/components/loader/loader.component';
import { RegistrationUserModalDto } from '../shared/models/user/RegistrationUserModalDto';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-verify-otp',
  templateUrl: './verify-otp.component.html',
  styleUrls: ['./verify-otp.component.sass']
})
export class VerifyOtpComponent implements OnInit {
  @Output() onChangeViewPage = new EventEmitter<string>();


  appLoader?: LoaderComponent;
  _isSaveButtonDisable$ = new BehaviorSubject<boolean>(false);
  _validationErrorPresent$ = new BehaviorSubject<boolean>(false);
  _showSuccessMsg$ = new BehaviorSubject<boolean>(false);
  _successMsg$ = new BehaviorSubject<string>("");
  _isPasswordMatch$ = new BehaviorSubject<boolean>(false);
  
  isLoading: boolean = false;
  isShowOtpVerify: boolean = false;
  ctrlOtp : FormControl = new FormControl('', Validators.required)
  registrationUserModalDto: RegistrationUserModalDto | undefined;

  constructor(
    public registrationService: RegistrationService,
    public authService: AuthenticationService,
    public userService: UserService,
    private _router: Router,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.registrationUserModalDto = this.registrationService.getTempRegistrationUserModalDto();
  
  }

  resendOTP() {

    this.isLoading = true;
    this.authService.errorMsg = "";
    let registrationUserModalDto = this.registrationService.getTempRegistrationUserModalDto();

    this.userService.resendOTP(registrationUserModalDto?.emailId!, registrationUserModalDto?.emailId!, registrationUserModalDto?.otpId as string).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        this.isLoading = false;
        if (apiResponseDto && apiResponseDto.code == AuctionExtConstant.SUCCESS_CODE) {
          let otpId = apiResponseDto.data as string;
          let registrationUserModalDto = this.registrationService.getTempRegistrationUserModalDto();
          registrationUserModalDto!.otpId = otpId;
          this.registrationService.setTempRegistrationUserModalDto(registrationUserModalDto!);
          // this._showSuccessMsg$.next(true);
          // this._successMsg$.next("OTP resent")
          this.messageService.add({severity:'success', summary:'Success', detail:'OTP resent'});
          setTimeout(() => {
            // this._showSuccessMsg$.next(false);
            // this._successMsg$.next("")
          }, 2000);
        } else {
          // this.authService.errorMsg = "Error While sending OTP";
          this.messageService.add({ severity:'error', summary:'Error', detail: this.authService.errorMsg });
        }
      },
      error: (err) => {
        this.isLoading = false;
        // this.authService.errorMsg = "Error While sending OTP";
        this.messageService.add({ severity:'error', summary:'Error', detail: this.authService.errorMsg });

      }
    })
  }

  backToSignup() {
    this.registrationService.setCurrentAuthPage('REGISTER');
    this._router
      .navigateByUrl('/redirect-loader', { skipLocationChange: true })
      .then(() => this._router.navigate(["/landing"], { skipLocationChange: true }))
  }

  // handleValidSubmit(loaderMsg: string) {
  //   this.authService.errorMsg = "";

  //   this.isLoading = true;

  //   if (!this.ctrlOtp) {
  //     return;
  //   }
  //     if (this.appLoader) {
  //       this.appLoader.openLoaderIcon(AuctionExtConstant.VERIFING_OTP, loaderMsg);
  //     }
  //     let userEnteredOtp = this.ctrlOtp.value;
  //     this.userService.verifyOTP(this.otpId!, userEnteredOtp).subscribe({
  //       next: (apiResponseDto: ServerAPIResponseDto) => {
  //         this.appLoader?.closeLoaderIcon(AuctionExtConstant.VERIFING_OTP);
  //         if (apiResponseDto && apiResponseDto.code == "200") {
  //           let data = apiResponseDto.data as AuthenticationResponseDto;
  //           this.userService.setUser(data.sessionInfoDto!);
  //           localStorage.setItem('AUC_SESSION_ID', data.sessionID!);
  //           this.authService.handleServerResponse(apiResponseDto.data, this._isSaveButtonDisable$);
  //         } else {
  //           this._isSaveButtonDisable$.next(false);
  //           this.appLoader?.closeLoaderIcon(AuctionExtConstant.USER_REGISTRATION_PROGRESS);
  //           this.authService.errorMsg = apiResponseDto.message!;

  //         }
  //       },
  //       error: (err) => {
  //         console.log(err);
  //         this.isLoading = false
  //         this._isSaveButtonDisable$.next(false);
  //         this.authService.errorMsg = "Error while Registration of User";
  //         this.appLoader?.closeLoaderIcon(AuctionExtConstant.VERIFING_OTP);
  //       }
  //     })
  //   }

  handleValidSubmit(loaderMsg: string) {
    this.authService.errorMsg = "";

    this.isLoading = true;

    if (!this.ctrlOtp) {
      return;
    }
      if (this.appLoader) {
        this.appLoader.openLoaderIcon(AuctionExtConstant.VERIFING_OTP, loaderMsg);
      }
      let registrationUserModalDto = this.registrationService.getTempRegistrationUserModalDto();
      registrationUserModalDto!.userEnteredOtp  = this.ctrlOtp.value!;
      let password = this.registrationService.getSelectedPassword();
      this.userService.registerUsers(registrationUserModalDto!, registrationUserModalDto?.emailId!, password!).subscribe({
        next: (apiResponseDto: ServerAPIResponseDto) => {
          this.isLoading = false;
          this.appLoader?.closeLoaderIcon(AuctionExtConstant.VERIFING_OTP);
          if (apiResponseDto && apiResponseDto.code == "200") {
            let data = apiResponseDto.data as AuthenticationResponseDto;
            localStorage.setItem('AUC_SESSION_ID', data.sessionID!);
            this.authService.handleServerResponse(data, this._isSaveButtonDisable$);
            this.registrationService.setCurrentAuthPage('LOGIN');
          } else {
            this._isSaveButtonDisable$.next(false);
            this.appLoader?.closeLoaderIcon(AuctionExtConstant.USER_REGISTRATION_PROGRESS);
            // this.authService.errorMsg = apiResponseDto.message!;
            this.messageService.add({ severity:'error', summary:'Error', detail: apiResponseDto.message!});
          }
        },
        error: (err) => {
          this.isLoading = false;
          console.log(err);
          this._isSaveButtonDisable$.next(false);
          // this.authService.errorMsg = "Error while Registration of User";
          this.messageService.add({ severity:'error', summary:'Error', detail: this.authService.errorMsg });
          this.appLoader?.closeLoaderIcon(AuctionExtConstant.VERIFING_OTP);
        }
      })
    }
}

