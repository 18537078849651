import { AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, Output, SecurityContext } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SafeUrl, DomSanitizer } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MessageService } from 'primeng/api';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ImagePreviewComponent } from 'src/app/shared/components/image-preview/image-preview.component';
import { CountryCodeDto } from 'src/app/shared/models/CountryCodeDto';
import { FileInfoDto } from 'src/app/shared/models/FileInfoDto';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';
import { BidderUiDto } from 'src/app/shared/models/UserEntityDto';
import { FileService } from 'src/app/shared/services/file.service';
import { RegistrationService } from 'src/app/shared/services/registration.service';
import { UserService } from 'src/app/shared/services/user.service';
import { SessionActions } from 'src/app/shared/state-management/session/session.actions';
import { selectBidderUiDto } from 'src/app/shared/state-management/session/session.features';
import { AuctionExtConstant } from 'src/app/shared/util/AuctionExtConstant';
import { AuctionExtUtil } from 'src/app/shared/util/AuctionExtUtil';
import { Pattern } from 'src/app/shared/util/Patterns';

@Component({
  selector: 'app-user-basic-details',
  templateUrl: './user-basic-details.component.html',
  styleUrls: ['./user-basic-details.component.sass']
})
export class UserBasicDetailsComponent implements OnInit, AfterViewInit, OnDestroy {
  @Output() onNext: EventEmitter<void> = new EventEmitter();

  formGroup: FormGroup;

  userEntityDto?: BidderUiDto;

  errorMsg: string = '';
  isLoading: boolean = false;

  selectedCountryCode = CountryCodeDto.default();
  selectedFaxNoCountryCode = CountryCodeDto.default();

  countryCodeForFaxNo$ = new BehaviorSubject<CountryCodeDto>(CountryCodeDto.default());
  countryCodeForMobileNo$ = new BehaviorSubject<CountryCodeDto>(CountryCodeDto.default());

  _showSuccessToast$ = new BehaviorSubject<boolean>(false);
  _showErrorToast$ = new BehaviorSubject<boolean>(false);

  userEntityDtoSubscription$?: Subscription;

  imageTypes = ['image/png', 'image/jpg', 'image/jpeg'];
  fileUploadError: boolean = false;
  currentFile?: File;
  imageType?: string;

  currentFileInfoDto?: FileInfoDto
  imageUrl?: string | ArrayBuffer | SafeUrl | null;
  _fileDisplayName$ = new BehaviorSubject<string | null>("");
  _fileUploaded$ = new BehaviorSubject<boolean>(false);

  constructor(
    private registrationService: RegistrationService,
    private ngbModal: NgbModal,
    private messageService: MessageService,
    private formBuilder: FormBuilder,
    private sanitizer: DomSanitizer,
    private fileService: FileService,
    private userService: UserService,
    private store: Store
  ) {
    this.formGroup = this.formBuilder.group({
      firstName: new FormControl('', [Validators.required,Validators.maxLength(100)]),
      lastName: new FormControl('', [Validators.required, Validators.maxLength(100)]),
      companyName: new FormControl('', [Validators.required, Validators.maxLength(250)]),
      designation: new FormControl('',[Validators.required, Validators.maxLength(250)]),
      emailId: new FormControl('', [Validators.required, Validators.pattern(Pattern.email)]),
      mobileNo: new FormControl('', [Validators.required, Validators.pattern(Pattern.mobile)]),
      companyUrl: new FormControl('',[Validators.maxLength(250), Validators.pattern(Pattern.url)]),
      faxNo: new FormControl('', [Validators.pattern(Pattern.mobile)]),
    });
  }

  ngOnInit(): void {
    this.formGroup.reset();
    this.userEntityDtoSubscription$ = this.store.pipe(select(selectBidderUiDto)).subscribe(data => {
      if (data) {
        this.userEntityDto = data;
        this.populateBasicDetails();
      }
    })

    // this.formGroup.valueChanges.subscribe(() => {
    //   this.registrationService.setTempBidderUiDto(this.mergeUserEntityDto());
    // })
  }

  ngAfterViewInit(): void {
    this.populateBasicDetails();
  }

  get fc() { return this.formGroup.controls; }

  populateBasicDetails() {
    this.formGroup.controls['firstName'].patchValue(this.userEntityDto?.firstName);
    this.formGroup.controls['lastName'].patchValue(this.userEntityDto?.lastName);
    this.formGroup.controls['designation'].patchValue(this.userEntityDto?.designation);
    this.formGroup.controls['companyName'].patchValue(this.userEntityDto?.companyName);
    this.formGroup.controls['companyUrl'].patchValue(this.userEntityDto?.companyUrl);
    this.formGroup.controls['emailId'].patchValue(this.userEntityDto?.primaryEmailId);
    this.formGroup.controls['faxNo'].patchValue(this.userEntityDto?.faxNo);

    this.formGroup.controls['emailId'].disable();
    this.formGroup.controls['mobileNo'].disable();

    let mobileWrapper = this.userEntityDto?.mobileList?.find(item => item.primary);
    this.formGroup.controls['mobileNo'].patchValue(mobileWrapper?.mobileNo);

    this.handleCountryCodeEvent(mobileWrapper?.countryCode ?? CountryCodeDto.default());
    this.handleCountryCodeEventForFaxNo(this.userEntityDto?.faxNoCountryCode ?? CountryCodeDto.default());

    if (this.userEntityDto?.companyLogo != null) {
      this.imageUrl = this.sanitizer.sanitize(SecurityContext.URL, `/downloadLandingBlob?fileId=${this.userEntityDto?.companyLogo?.fileId}`);
      this.currentFileInfoDto = this.userEntityDto?.companyLogo;

      if (this.userEntityDto?.companyLogo?.displayName) {
        this._fileDisplayName$.next(this.userEntityDto?.companyLogo?.displayName!);
        this._fileUploaded$.next(true);
      } else {
        this._fileDisplayName$.next("");
        this._fileUploaded$.next(false);
      }
    }
  }

  handleCountryCodeEvent(countryCodeDto: CountryCodeDto) {
    this.selectedCountryCode = countryCodeDto;
    this.countryCodeForMobileNo$.next(countryCodeDto)

    let mobileNoLength = parseInt(countryCodeDto.mobileNoLength!);
    let pattern = "^[0-9]{" + mobileNoLength + "}"

    this.formGroup.controls['mobileNo'].clearValidators();
    this.formGroup.controls['mobileNo'].setValidators([Validators.required, Validators.pattern(pattern)]);
    this.formGroup.controls['mobileNo'].updateValueAndValidity();
  }

  handleCountryCodeEventForFaxNo(countryCodeDto: CountryCodeDto) {
    this.selectedFaxNoCountryCode = countryCodeDto;
    this.countryCodeForFaxNo$.next(countryCodeDto)

    let faxNoLength = parseInt(countryCodeDto.mobileNoLength!);
    let pattern = "^[0-9]{" + faxNoLength + "}"

    this.formGroup.controls['faxNo'].clearValidators();
    this.formGroup.controls['faxNo'].setValidators(Validators.pattern(pattern));
    this.formGroup.controls['faxNo'].updateValueAndValidity();
  }

  // chooseFile(event: any) {
  //   this._showErrorToast$.next(false);
  //   this.currentFile = event.target.files[0];

  //   if (!this.imageTypes.includes(this.currentFile!.type)) {
  //     this.fileUploadError = true;
  //     this.errorMsg = "Extension not supported";
  //     this._showErrorToast$.next(true);
  //     return;
  //   }

  //   this._fileDisplayName$.next(this.currentFile?.name as string);
  //   this._fileUploaded$.next(true);
  //   this.formGroup.get('companyLogo')?.setValue(this.currentFile?.name);

  //   let reader = new FileReader();
  //   reader.readAsDataURL(this.currentFile!);
  //   reader.onload = (e) => {
  //     let size = (this.currentFile?.size! / 1024) / 1024;

  //     // Create New Image
  //     var newImage = new Image();
  //     newImage.src = e.target!.result as string;

  //     newImage.onload = (el) => {
  //       this.imageType = newImage.width > newImage.height ? 'Rectangle' : 'Portrait';
  //       if (size > 1) {
  //         var canvas = document.createElement("canvas");
  //         canvas.width = newImage.width;
  //         canvas.height = newImage.height;

  //         var ctx = canvas.getContext("2d");
  //         ctx?.drawImage(el.target as CanvasImageSource, 0, 0, canvas.width, canvas.height);
  //         var srcEncoded;
  //         if (size >= 5) {
  //           srcEncoded = ctx?.canvas.toDataURL('image/jpeg', 0.1);
  //         } else {
  //           // size less then 5 MB
  //           srcEncoded = ctx?.canvas.toDataURL('image/jpeg', 0.5);
  //         }

  //         AuctionExtUtil.base64toFile(srcEncoded, this.currentFile!.name, this.currentFile!.type)
  //           .then((file: File) => {
  //             this.currentFile = file;
  //           })
  //       }

  //       this.imageUrl = reader.result?.toString();
  //     }
  //   }
  // }

  mergeUserEntityDto() {
    let userEntityDto: BidderUiDto = AuctionExtUtil.clone(this.userEntityDto);

    userEntityDto.firstName = this.formGroup.controls['firstName'].value;
    userEntityDto.lastName = this.formGroup.controls['lastName'].value;

    userEntityDto.companyName = this.formGroup.controls['companyName'].value;
    userEntityDto.designation = this.formGroup.controls['designation'].value;
    userEntityDto.companyUrl = this.formGroup.controls['companyUrl'].value;

    let mobileWrapper = userEntityDto!.mobileList!.find(item => item.primary)!;
    mobileWrapper.mobileNo = this.formGroup.controls['mobileNo'].value;
    mobileWrapper.countryCode = this.selectedCountryCode;
    mobileWrapper.primary = true;

    let mobileNoIndex = userEntityDto!.mobileList!.findIndex(item => item.primary)!;
    userEntityDto!.mobileList!.splice(mobileNoIndex, 1);
    userEntityDto!.mobileList!.push(mobileWrapper);

    userEntityDto.faxNo = this.formGroup.controls['faxNo'].value;
    userEntityDto.faxNoCountryCode = this.selectedFaxNoCountryCode;

    userEntityDto!.companyLogo = this.currentFileInfoDto;

    return userEntityDto;
  }

  handleValidSubmit() {
    this.isLoading = false;
    if (this.formGroup.invalid) {
      this.formGroup.markAllAsTouched();
      return;
    }

    if (this.currentFile) {
      this.uploadFile(this.currentFile!);
    } else {
      this.saveUserProfile();
    }
  }

  saveUserProfile() {
    if (this.formGroup.invalid) {
      this.formGroup.markAllAsTouched();
      return;
    } 
      this.isLoading = true;

      let userEntityDto = this.mergeUserEntityDto();

      this.userService.updateUserDetails(userEntityDto).subscribe({
        next: (apiResponseDto: ServerAPIResponseDto) => {
          this.isLoading = false;

        if (apiResponseDto && apiResponseDto.code == "200") {
          let bidderUiDto = apiResponseDto.data as BidderUiDto;
          this.store.dispatch(SessionActions.updateBidderUiDto({ bidderUiDto }));

            this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Details Saved Successfully' });

            setTimeout(() => {
              this.onNext.emit();
            }, 2000)

          } else {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: apiResponseDto.message! });
          }
        },
        error: (err) => {
          console.log(err);
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error while updating details' });
          this.isLoading = false;
        }
      })
    
}

  uploadFile(currentFile: File) {
    this.isLoading = true;
    this.fileUploadError = false;
    this.errorMsg = "";

    let metaData = {
      'mimeType': currentFile.type,
      'version': 0,
      'publicApi': true,
      'dataType': this.imageType
    };

    let formData = new FormData();
    formData.append("file", currentFile);
    formData.append('metaData', JSON.stringify(metaData));

    this.fileService.uploadFile(formData).subscribe(apiResponseDto => {
      if (apiResponseDto) {
        let fileSaveResponseDto = apiResponseDto.data as FileInfoDto;
        if (apiResponseDto.code == AuctionExtConstant.SUCCESS_CODE) {
          this.fileUploadError = false;

          this.currentFileInfoDto = new FileInfoDto();
          this.currentFileInfoDto.fileId = fileSaveResponseDto.fileId;
          this.currentFileInfoDto.fileName = fileSaveResponseDto.fileName;
          this.currentFileInfoDto.displayName = fileSaveResponseDto.fileName;
          this.currentFileInfoDto.fileSize = fileSaveResponseDto.fileSize;
          this.currentFileInfoDto.fileType = fileSaveResponseDto.fileType;
          this.currentFileInfoDto.dataType = fileSaveResponseDto.dataType;
          this.currentFileInfoDto.uploadDate = new Date().toDateString();

          this.formGroup.get('companyLogo')?.setValue(this.currentFileInfoDto.displayName);
          this.formGroup.updateValueAndValidity();
          this.saveUserProfile();
        }
      } else {
        this.isLoading = false;
        this.fileUploadError = true;
      }
    })
  }

  deleteImage() {
    this.currentFileInfoDto = null!;
    this.currentFile = null!;
    this.imageUrl = null!;
    this.formGroup.controls['companyLogo'].reset();
    this._fileDisplayName$.next(null);
    this._fileUploaded$.next(false);
  }

  openViewImageModal(imageUrl:any) {
    let modalRef = this.ngbModal.open(ImagePreviewComponent, {
      size: 'md', backdrop: 'static', keyboard: false , centered: true
    });
    modalRef.componentInstance.imageUrl=imageUrl
  }

  ngOnDestroy(): void {
    if(this.userEntityDtoSubscription$) {
      this.userEntityDtoSubscription$.unsubscribe();
    }
  }
}
