import { SessionInfoDto } from './../models/SessionInfoDto';
import { LandingAndBidderService } from 'src/app/shared/services/LandingAndBidder.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { BehaviorSubject, firstValueFrom, map, Observable } from 'rxjs';
import { LoaderComponent } from '../components/loader/loader.component';
import { AuctionExtConstant } from 'src/app/shared/util/AuctionExtConstant';
import { BidderUiDto } from '../models/UserEntityDto';
import { ErrorService } from './error.service';
import { NavigationService } from './navigation.service';
import { RoutingService } from './routing.service';
import { UserService } from './user.service';
import { DataRedirectionService } from './data-redirection.service';
import { ApplicationListenerService } from './application-listener.service';
import { UserCategorySelectionComponent } from '../components/user-category-selection/user-category-selection.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { selectBidderUiDto } from '../state-management/session/session.features';
import { SessionActions } from '../state-management/session/session.actions';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  loginModal$ = new BehaviorSubject(false);
  errorMsg: string = "";

  constructor(
    private bidderService: LandingAndBidderService,
    private dataRedirection: DataRedirectionService,
    private httpClient: HttpClient,
    private modalService: NgbModal,
    private errorService: ErrorService,
    private navigationService: NavigationService,
    private routingService: RoutingService,
    private applicationListenerService: ApplicationListenerService,
    private userService: UserService,
    private store: Store
  ) {


  }

  isUserLoggedIn() {
    let user = localStorage.getItem('username')
    return !(user === null)
  }

  async logout(appLoader?: LoaderComponent) {
    let bidderUiDto = await firstValueFrom(this.store.select(selectBidderUiDto));
    await firstValueFrom(this.userService.logout(bidderUiDto?.primaryEmailId));

    localStorage.clear();
    this.modalService.dismissAll();
    this.errorService.setResetDialogFlag();
    this.userService.clear();
    this.dataRedirection.clearData();
    this.bidderService.clearData();
    appLoader?.closeLoaderForcefully();

    this.store.dispatch(SessionActions.clearSession())

    // DOMAIN CHANGES
    //this.router.navigate([AuctionwareUtil.getBaseUrl()]);
    this.dataRedirection.navigationToHomePage();

    // this.userService.logout(email).subscribe(apiResponseDto => {
    //   if (apiResponseDto) {
    //     if (apiResponseDto.code == AuctionExtConstant.SUCCESS_CODE) {
    //       // //console.log("Logout called");
    //       // this.notificationService.notifyDataLoading(AuctionwareUtil.getNotificationEvent(false));
    //       // window.location.reload();
    //     }
    //   }
    // });
  }

  handleServerResponse(data: any, _isSaveButtonEnable$: BehaviorSubject<boolean>) {

    let sessionInfo = data.sessionInfoDto as SessionInfoDto;
    let currentUser: BidderUiDto = sessionInfo.bidderUiDto!;

    _isSaveButtonEnable$.next(false);
    this.navigationService.emitChange(currentUser);
    this.store.dispatch(SessionActions.saveSessionInfo({ sessionInfo }));
    this.modalService.dismissAll();

    // Initialize Firestore Listeners
    this.applicationListenerService.loadAndSetMyUserAuctionRegistrationWrappersSync();
    this.applicationListenerService.listenMyAuctionRegistrations();


    // NEW CODE
    this.routingService.doRouting(sessionInfo);
    // NEW CODE

    // OLD CODE
    // if (currentUser?.userPrivilege == UserPrivilege.BIDDER) {
    //   if (currentUser.registrationStatus == UserRegistrationStatus.INCOMPLETE) {
    //     let modalRef = this.modalService.open(EmailVerifyModalComponent, {
    //       backdrop: 'static', keyboard: false, centered: true
    //     });

    //     modalRef.result.then(() => {
    //       this.checkForOpenPreferenceCategoryModal(this.userService.getSessionInfo!);
    //     }).catch(() => {
    //       this.checkForOpenPreferenceCategoryModal(this.userService.getSessionInfo!);
    //     })

    //   } else {
    //     this.checkForOpenPreferenceCategoryModal(this.userService.getSessionInfo!);
    //   }
    // } else {
    //   this.routingService.doRouting(this.userService.getSessionInfo!);
    // }
    // OLD CODE

  }

  authenticate(username?: string, password?: string, otpId?: string): Observable<any> {
    //const headers = new HttpHeaders({ Authorization: 'Basic ' + window.btoa(encodeURIComponent(username!) + ':' + password + ':' +otpId) });

    if (!otpId) {
      otpId = "";
    }

    let headers = new HttpHeaders({ Authorization: 'Basic ' + window.btoa(username + ':' + password + ':' + otpId) });
    headers.append('Content-Type', 'application/x-www-form-urlencoded');


    return this.httpClient.post<any>('authenticate', null, { headers, observe: 'response' }).pipe(
      map(
        apiResponseDto => {
          if (apiResponseDto.body.code == AuctionExtConstant.SUCCESS_CODE) {
            // session info only when authentication is successful.
            let sessionInfo = <SessionInfoDto>apiResponseDto.body.data.sessionInfoDto;
            this.store.dispatch(SessionActions.saveSessionInfo({ sessionInfo }));
            localStorage.setItem('AUC_SESSION_ID', apiResponseDto.body.data.sessionID);
          }
          return apiResponseDto.body;
        }
      )
    )
  }

  doAuthentication(
    userName: string,
    password: string,
    appLoader: LoaderComponent,
    _isSaveButtonEnable$: BehaviorSubject<boolean>,
    otpId?: string
  ) {
    this.authenticate(userName, password, otpId).subscribe({
      next: (apiResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == AuctionExtConstant.SUCCESS_CODE) {
          this.handleServerResponse(apiResponseDto.data, _isSaveButtonEnable$);
        } else {
          localStorage.clear();
          //this.notificationService.notifyDataLoading(AuctionExtUtil.getNotificationEvent(false));
          appLoader.closeLoaderForcefully();
          _isSaveButtonEnable$.next(false)
          this.store.dispatch(SessionActions.clearSession());

          if (apiResponseDto.code == "USER-107") {
            this.errorMsg = "Email Id Not Registered";
          } else if (apiResponseDto.code == "USER-142") {
            this.errorMsg = "Account Disabled";
          }
          else if (apiResponseDto.code == "USER-144") {
            this.errorMsg = "You are not authorized to login";
          } else {
            this.errorMsg = apiResponseDto.message;
          }

        }
      },
      error: (error) => {
        _isSaveButtonEnable$.next(false)
        appLoader.closeLoaderForcefully();
        this.errorMsg = "Wrong Credentials";
        localStorage.clear();
      }
    });
  }

  authenticateWithOtp(username: string, userOtp: string, otpId: string): Observable<any> {
    //const headers = new HttpHeaders({ Authorization: 'Basic ' + window.btoa(encodeURIComponent(username!) + ':' + password) });
    const headers = new HttpHeaders({ Authorization: 'Basic ' + window.btoa(username + ':' + userOtp + ':' + otpId) });
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('WORKFLOW', 'LOGIN_WITH_OTP');

    return this.httpClient.post<any>('authenticate', null, { headers, observe: 'response' }).pipe(
      map(
        apiResponseDto => {
          if (apiResponseDto.body.code == AuctionExtConstant.SUCCESS_CODE) {
            // session info only when authentication is successful.
            let sessionInfo = <SessionInfoDto>apiResponseDto.body.data.sessionInfoDto;
            this.store.dispatch(SessionActions.saveSessionInfo({ sessionInfo }));
            localStorage.setItem('AUC_SESSION_ID', apiResponseDto.body.data.sessionID);
          }
          return apiResponseDto.body;
        }
      )
    )
  }

  openPreferenceCategoryModal(userEntityDto: BidderUiDto) {
    let modalRef = this.modalService.open(UserCategorySelectionComponent, {
      centered: true,
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
    });

    modalRef.componentInstance.userId = undefined;
    modalRef.componentInstance.userDto = userEntityDto;

    modalRef.result.then(() => {
      this.dataRedirection.navigateToPage();
    }).catch(() => {
      this.dataRedirection.navigateToPage();
    })
  }

}
