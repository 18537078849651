import { DataRedirectionService } from 'src/app/shared/services/data-redirection.service';
import { AfterViewInit, Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, firstValueFrom, Subscription } from 'rxjs';
import { AuctionEntityDto } from 'src/app/shared/models/user/AuctionEntityDto';
import { LandingAndBidderService } from 'src/app/shared/services/LandingAndBidder.service';
import { FirestoreListenerService } from 'src/app/shared/services/firestore-listener.service';
import { UserService } from 'src/app/shared/services/user.service';
import { AuctionExtUtil } from 'src/app/shared/util/AuctionExtUtil';
import { AuctionLotEntityDto } from 'src/app/shared/models/user/AuctionLotEntityDto';
import { UserAuctionRegistrationDto } from 'src/app/shared/models/user/UserAuctionRegistrationDto';
import { UserRegistrationStatus } from 'src/app/shared/enums/UserRegistrationStatus';
import { UserAuctionEligibleStatus } from 'src/app/shared/enums/UserAuctionEligibleStatus';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BidderAuctionRegisterComponent } from '../../bidder-auction-register/bidder-auction-register.component';
import { UserPrivilege } from 'src/app/shared/enums/UserPrivilege';
import { EmailVerifyModalComponent } from 'src/app/shared/components/email-verify-modal/email-verify-modal.component';
import { CountryCodeDto } from 'src/app/shared/models/CountryCodeDto';
import { ListingContactDto } from 'src/app/shared/models/ListingContactDto';
import { OrganizationUiDto } from 'src/app/shared/models/user/AuctionHouseDto';
import { AuctionCardWrapperDto } from 'src/app/shared/models/AuctionCardWrapperDto';
import { AuctionExtConstant } from 'src/app/shared/util/AuctionExtConstant';
import { select, Store } from '@ngrx/store';
import { BidderUiDto } from 'src/app/shared/models/UserEntityDto';
import { selectBidderUiDto } from 'src/app/shared/state-management/session/session.features';

@Component({
  selector: 'app-premium-auction-card',
  templateUrl: './premium-auction-card.component.html',
  styleUrls: ['./premium-auction-card.component.sass']
})
export class PremiumAuctionCardComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() auctionCardWrapperDto?: AuctionCardWrapperDto;

  // nextClosingLot?: AuctionLotEntityDto;
  auctionHouse?: OrganizationUiDto
  auctionEntityDto?: AuctionEntityDto
  bidderUiDto?: BidderUiDto

  countdownTimerDays = 0;
  countdownTimerHours = 0;
  countdownTimerMinutes = 0;
  countdownTimerSeconds = 0;
  counterFunction: any;
  timer: boolean = false;

  _renderView$ = new BehaviorSubject<boolean>(false);

  currentAuctionHouseSubscription$?: Subscription;

  userAuctionRegistrationDto?: UserAuctionRegistrationDto;

  public screenWidth: any;
  isLoading: boolean = false;

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.screenWidth = window.innerWidth;
  }

  constructor(
    private dataRedirectionService: DataRedirectionService,
    private bidderService: LandingAndBidderService,
    private ngbModal: NgbModal,
    private store: Store
  ) { }

  ngOnInit(): void {
    this.auctionEntityDto = this.auctionCardWrapperDto?.auctionEntityDto;

    this.currentAuctionHouseSubscription$ = this.bidderService.getCurrentAuctionHouse$.subscribe((data) => {
      if (data) {
        this.auctionHouse = data;
      }
    })
  }

  ngAfterViewInit(): void {
    this.loadBidderUiDto();
    this.clearCountdown();
    this.countdown(this.auctionEntityDto!);
    Promise.resolve().then(() => this._renderView$.next(true));

    this.screenWidth = window.innerWidth;
  }

  async loadBidderUiDto() {
    this.bidderUiDto = await firstValueFrom(this.store.pipe(select(selectBidderUiDto)));
  }

  navigateAuctionView() {
    this.isLoading = true;
    this.dataRedirectionService.storeNavigationContext(AuctionExtConstant.ANY_PAGE, AuctionExtConstant.AUCTION, AuctionExtConstant.ANY_PAGE, '', this.auctionEntityDto?.sequenceNo!+"", undefined)
    this.dataRedirectionService.navigateToPage();
  }

  getCompanyDto(companyId?: string) {

    return null;
  }

  clearCountdown() {
    if (this.timer == true) {
      console.log("For Auction :" + this.auctionEntityDto?.auctionName + " Remaining time clear");
      clearInterval(this.counterFunction);
      this.timer = false;
    }
  }

  countdown(auctionEntityDto: AuctionEntityDto) {
    let _this = this;
    let timeZone = auctionEntityDto.timeZone!;

    let date: string ;
    let time: string ;

    if (auctionEntityDto?.singleLot || auctionEntityDto.noOfActiveLots == 1) {
      date = auctionEntityDto?.status == 'LIVE' ? auctionEntityDto?.endDate! : auctionEntityDto?.startDate!;
      time = auctionEntityDto?.status == 'LIVE' ? auctionEntityDto?.endTime! : auctionEntityDto?.startTime!;
    } else {
      date = auctionEntityDto?.status == 'LIVE' ? this.auctionCardWrapperDto?.nextLotEndDate! : auctionEntityDto?.startDate!;
      time = auctionEntityDto?.status == 'LIVE' ? this.auctionCardWrapperDto?.nextLotEndTime! : auctionEntityDto?.startTime!;
    }
    let remainingDurations = AuctionExtUtil.getRemainingTime(date + " " + time, timeZone!);


    if (this.timer == false) {
      console.log("Creating a new countdown function in supplier small card component - " + auctionEntityDto?.auctionName);

      this.timer = true;
      this.counterFunction = setInterval(function () {

        let element = document.getElementById("days" + _this.counterFunction + auctionEntityDto.auctionId);
        if (element) {
          let remainingDays = AuctionExtUtil.getCountdownTimerDays(date + " " + time, timeZone);
          let remainingHours = AuctionExtUtil.getCountdownTimerHours(date + " " + time, timeZone);
          let remainingMinutes = AuctionExtUtil.getCountdownTimerMinutes(date + " " + time, timeZone);
          let remainingSeconds = AuctionExtUtil.getCountdownTimerSeconds(date + " " + time, timeZone);

          if (remainingDays) {
            document.getElementById("days" + _this.counterFunction + auctionEntityDto.auctionId)!.hidden = false;
            document.getElementById("hours" + _this.counterFunction + auctionEntityDto.auctionId)!.hidden = false;
            document.getElementById("minutes" + _this.counterFunction + auctionEntityDto.auctionId)!.hidden = true;
            document.getElementById("second" + _this.counterFunction + auctionEntityDto.auctionId)!.hidden = true;

            document.getElementById("days" + _this.counterFunction + auctionEntityDto.auctionId)!.innerText = remainingDays + 'd ';
            document.getElementById("hours" + _this.counterFunction + auctionEntityDto.auctionId)!.innerText = remainingHours + 'h';
          } else {
            document.getElementById("days" + _this.counterFunction + auctionEntityDto.auctionId)!.hidden = true;
            document.getElementById("hours" + _this.counterFunction + auctionEntityDto.auctionId)!.hidden = false;
            document.getElementById("minutes" + _this.counterFunction + auctionEntityDto.auctionId)!.hidden = false;
            document.getElementById("second" + _this.counterFunction + auctionEntityDto.auctionId)!.hidden = false;

            document.getElementById("hours" + _this.counterFunction + auctionEntityDto.auctionId)!.innerText = ('0' + remainingHours).slice(-2) + ' : ';
            document.getElementById("minutes" + _this.counterFunction + auctionEntityDto.auctionId)!.innerText = ('0' + remainingMinutes).slice(-2) + ' : ';
            document.getElementById("second" + _this.counterFunction + auctionEntityDto.auctionId)!.innerText = ('0' + remainingSeconds).slice(-2);
          }

          //do something later when date is reached
          if (remainingDurations < 0) {
            let headline = document.getElementById("headline" + _this.counterFunction + auctionEntityDto.auctionId);
            let countdown = document.getElementById("countdown" + _this.counterFunction + auctionEntityDto.auctionId);
            // let content = document.getElementById("content" + auction.auctionId);

            if (headline) {
              headline.innerText = "Bidding Closed!";
            }
            if (countdown) {
              countdown.style.display = "none";
            }
            // content.style.display = "block";

            clearInterval(_this.counterFunction);
          }
        }

      }, 1000)


      console.log("New count down function created in supplier small card component - " + auctionEntityDto.auctionName + "   : " + this.counterFunction)
    }
  }

  isUserLogin() {
    let userEntityDto = this.bidderUiDto;
    if (userEntityDto) {
      return true;
    }
    return false;
  }

  isShowRegisterToBid() {
    this.userAuctionRegistrationDto = this.bidderService.getUserAuctionRegistration(this.auctionEntityDto?.auctionId!);

    let status = this.userAuctionRegistrationDto?.userAuctionRegistrationStatus;
    if (this.bidderUiDto
      && (status == undefined || this.bidderUiDto.registrationStatus == UserRegistrationStatus.INCOMPLETE || status == UserAuctionEligibleStatus.AWAITING_APPROVAL)) {
      return true;
    }
    return false;
  }

  isAuctionRegistrationIncomplete() {
    let toReturn = false;
    if (this.bidderUiDto?.registrationStatus == UserRegistrationStatus.COMPLETED &&
      (this.userAuctionRegistrationDto?.userAuctionRegistrationStatus == null
        || this.userAuctionRegistrationDto.userAuctionRegistrationStatus == UserAuctionEligibleStatus.INCOMPLETE)) {
      toReturn = true;
    }
    return toReturn;
  }

  getTextForShowRegisterToBid() {
    let status = this.userAuctionRegistrationDto?.userAuctionRegistrationStatus;
    if (status && status == UserAuctionEligibleStatus.AWAITING_APPROVAL) {
      return "AWAITING APPROVAL TO BID"
    }
    return "REGISTER TO BID";
  }

  openRegisterToBidModal(event: any) {
    event.stopPropagation();
    if (this.bidderUiDto?.registrationStatus == UserRegistrationStatus.INCOMPLETE) {
      this.ngbModal.open(EmailVerifyModalComponent, {
        backdrop: 'static', keyboard: false, centered: true
      });
      return;
    }

    if (!this.isAuctionRegistrationIncomplete()) {
      return;
    }
    let model = this.ngbModal.open(BidderAuctionRegisterComponent, { size: 'md', backdrop: 'static', keyboard: false, centered: true });
    model.componentInstance.selectedAuction = this.auctionEntityDto;
  }

  getCountryCode(countryCodeDto?: CountryCodeDto) {
    if (countryCodeDto) {
      return countryCodeDto.countryCode?.replace('(', '').replace(')', '');
    }
    return '+1';
  }


  ngOnDestroy(): void {
    this.clearCountdown();

    if (this.currentAuctionHouseSubscription$) {
      this.currentAuctionHouseSubscription$.unsubscribe();
    }
  }
}
